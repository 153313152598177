import React, { type Node } from 'react';

import moment from 'moment';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { EPOCH } from 'lib/date';
import { selectGoogleProviders, selectProductProviders } from 'stores/accounts';

import { BaseBanner } from './common';

const Style = styled(BaseBanner)`
  background-color: #f8a51b;
`;

const MAX_HOURS = 48;
const DATE_FORMAT = 'YYYY-MM-DD';
const now = moment().utc();

const getHoursDiff = (comparedDate) => moment.duration(now.diff(comparedDate)).asHours();
const hasOutdatedData = (lastProcessedAt) =>
  lastProcessedAt.isAfter(moment(EPOCH), 'hour') && getHoursDiff(lastProcessedAt) > MAX_HOURS;
const isNewProvider = (createdAt) => getHoursDiff(createdAt) < MAX_HOURS;

const getGoogleLastProcessedAt = (providers): string => {
  const filteredProviders = providers.filter(
    (p) => p.campaignMetricsProcessingStatus?.lastProcessedAt || p.campaignStatusProcessingStatus?.lastProcessedAt,
  );

  for (let i = 0; i < filteredProviders.length; i++) {
    const p = filteredProviders[i];
    const metricsLastProcessedAt = moment(p.campaignMetricsProcessingStatus.lastProcessedAt);
    const statusLastProcessedAt = moment(p.campaignStatusProcessingStatus.lastProcessedAt);
    const createdAt = moment(p.createdAt);

    if (isNewProvider(createdAt)) {
      continue;
    }

    if (hasOutdatedData(metricsLastProcessedAt)) {
      return metricsLastProcessedAt.format(DATE_FORMAT);
    } else if (hasOutdatedData(statusLastProcessedAt)) {
      return statusLastProcessedAt.format(DATE_FORMAT);
    }
  }

  return '';
};

const getAmazonLastProcessedAt = (providers): string => {
  for (let i = 0; i < providers.length; i++) {
    const p = providers[i];
    const lastProcessedAt = moment(p.lastProcessedAt);
    const createdAt = moment(p.createdAt);

    if (!isNewProvider(createdAt) && hasOutdatedData(lastProcessedAt)) {
      return lastProcessedAt.format(DATE_FORMAT);
    }
  }

  return '';
};

export const DataOutdatedBanner = (): Node => {
  const googleProviders = useSelector(selectGoogleProviders);
  const amazonProfiles = useSelector(selectProductProviders);

  const googleLastProcessedAt = getGoogleLastProcessedAt(googleProviders);
  const amazonLastProcessedAt = getAmazonLastProcessedAt(amazonProfiles);

  if (!googleLastProcessedAt && !amazonLastProcessedAt) {
    return null;
  }

  return (
    <Style>
      WARNING: Data is out of date.{' '}
      {googleLastProcessedAt && `Google Ads Data was last processed on ${googleLastProcessedAt}. `}
      {amazonLastProcessedAt && `Amazon Attribution Data was last processed on ${amazonLastProcessedAt}. `}
      Contact Support for more information
    </Style>
  );
};
