import React from 'react';

import { Route, Switch, Redirect } from 'react-router-dom';
import styled from 'styled-components';

import API from './API';
import Box from '../../../common/Box';
import { MenuItem } from '../../../layout/MenuItem';
import { SubMenu } from '../../../layout/SubMenu';
import Domains from '../../../pages/settings/Domains';
import Gdpr from '../../../pages/settings/Gdpr';
import Pixel from '../../../pages/settings/Pixels';
import { Analytics } from '../../settings/Analytics';
import { Tags } from '../../settings/Tags';
import { Utms } from '../../settings/Utms';

const StyledBox = styled(Box).withConfig({
  displayName: 'StyledBox',
})`
  margin-bottom: 1em;
  padding: 1rem 0;
`;

const Settings = ({ match, account }) => (
  <>
    {/* // TODO: Use breadcrump component */}

    <StyledBox>
      <SubMenu>
        <MenuItem label="Pixels" to={`${match.url}/settings/pixels`} />
        <MenuItem label="Custom domains" to={`${match.url}/settings/domains`} />
        <MenuItem label="API" to={`${match.url}/settings/api`} />
        <MenuItem label="GDPR settings" to={`${match.url}/settings/gdpr`} />
        <MenuItem label="Utms" to={`${match.url}/settings/utms`} />
        <MenuItem label="Tags" to={`${match.url}/settings/tags`} />
        <MenuItem label="Analytics" to={`${match.url}/settings/analytics`} />
      </SubMenu>
    </StyledBox>

    <Switch>
      <Route path={`${match.url}/settings/pixels`} render={(props) => <Pixel />} />
      ); }} />
      <Route path={`${match.url}/settings/domains`} render={(props) => <Domains />} />
      <Route path={`${match.url}/settings/api`} render={(props) => <API account={account} />} />
      <Route path={`${match.url}/settings/gdpr`} render={(props) => <Gdpr account={account} />} />
      <Route path={`${match.url}/settings/tags`} render={(props) => <Tags account={account} />} />
      <Route path={`${match.url}/settings/utms`} render={(props) => <Utms account={account} />} />
      <Route path={`${match.url}/settings/analytics`} render={(props) => <Analytics account={account} />} />
      <Redirect to={`${match.url}/settings/pixels`} />
    </Switch>
  </>
);

export default Settings;
