import { createApi } from '@reduxjs/toolkit/query/react';

import { addApiError, trackFromBackend } from 'actions';
import axios from 'lib/axios.factory';
import { getMessageFromAxiosError } from 'lib/error';
import { toCamelCaseKeys, toSnakeCaseKeys } from 'lib/util';

export const TAGS = {
  AUDITS: 'Audits',
};
const CACHE_EXPIRY = 60 * 60; // 1 hour

const axiosBaseQuery = async (args, { dispatch }, extraOptions = {}) => {
  const accessToken = localStorage.getItem('access-token');
  const auth0Token = localStorage.getItem('auth0-access-token');
  const impersonate = localStorage.getItem('impersonate');
  const { url, method, data, params, headers = {} } = args;
  const { secure = true } = extraOptions; // Custom args we pass per endpoint

  if (secure) {
    if (auth0Token) {
      headers.AuthorizationAuth0 = `Bearer ${auth0Token}`;
    } else if (accessToken) {
      headers.Authorization = `Bearer ${accessToken}`;
    } else {
      return {
        error: {
          status: 401,
          data: {
            message: 'Must be logged to execute this query.',
            code: 'NOT_LOGGED',
          },
        },
      };
    }

    if (impersonate) {
      headers.Impersonate = impersonate;

      if (localStorage.getItem('impersonate_access_override') === 'true') {
        headers.ImpersonateAccessOverride = 'true';
      }
    }
  }

  try {
    const payload = {
      url,
      method,
      data: toSnakeCaseKeys(data),
      params: toSnakeCaseKeys(params),
      headers,
    };

    const response = await axios(payload);

    dispatch(trackFromBackend(response.data));

    return { data: toCamelCaseKeys(response.data) };
  } catch (err) {
    dispatch(addApiError(err, url, method));

    const errRes = getMessageFromAxiosError(err);

    return {
      error: {
        status: err.response?.status,
        data: { ...err, ...errRes },
      },
    };
  }
};

// $FlowFixMe
export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: axiosBaseQuery,
  tagTypes: Object.keys(TAGS),
  keepUnusedDataFor: CACHE_EXPIRY,
  endpoints: () => ({
    // Other slices will call `apiSlice.injectEndpoints` to add their endpoints here.
    // This separation is for maintainability, but all endpoints are still under the same api slice.
  }),
});
