import React, { Fragment, useEffect, useState } from 'react';

import { Alert, Modal } from 'antd';

import axios from 'lib/axios.factory';
import { getAllProductProviders } from 'lib/productsPlateformApi';

import { ProductProviderAccounts } from './ProductProviderAccounts';
import type { accountT } from '../../../../../flow-typed/pixelme/types';

let alreadyClose = false;

export function AmazonReLoginModal(props: { account: accountT }) {
  const [productProviders, setProductProviders] = useState([]);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const productProvidersResponse = await getAllProductProviders(localStorage, axios, {
        accountId: props.account.id,
      });
      const productProvidersLoginNeeded =
        productProvidersResponse.providers.filter((p) => p.loginNeeded || p.spa.errorAt !== null).length > 0 &&
        !alreadyClose;

      setProductProviders(productProvidersResponse.providers);
      setVisible(productProvidersLoginNeeded);
    };

    fetchData();
  }, []);

  return (
    <Modal
      visible={visible}
      okButtonProps={{ disabled: true }}
      title="Amazon account was disconnected"
      width="780px"
      onCancel={() => {
        alreadyClose = true;
        setVisible(false);
      }}
    >
      <Fragment>
        <br />
        <Alert
          type="error"
          message="Your account was disconnected by Amazon. Please reconnect it."
          description={
            <>
              <span>If the problem persists, please contact us.</span>
            </>
          }
        ></Alert>
        <br />
        <ProductProviderAccounts
          providers={productProviders.filter((p) => p.loginNeeded || p.spa.errorAt !== null)}
          account={props.account}
        />
      </Fragment>
    </Modal>
  );
}
