import React, { Fragment, useEffect, useState } from 'react';

import { LoadingOutlined } from '@ant-design/icons';
import { Alert, Form, Modal, notification, Progress, Select, Table, Tag } from 'antd';
import Column from 'antd/es/table/Column';
import Button from 'antd/lib/button';
import _ from 'lodash';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import axios from 'lib/axios.factory';

import { trackedEvents } from '../../../config/trackedEvents.config';
import { useLoading } from '../../../hooks/useLoading';
import { saveProductOnProvider } from '../../../lib/adsPlateformApi';
import { track } from '../../../lib/analytics';
import {
  getAllProductProviders,
  getProductsOnProvider,
  importProductCampaigns,
} from '../../../lib/productsPlateformApi';
import { CurrencyInput } from '../../common/antd/inputs/CurrencyInput';
import { Amazon } from '../../common/icons/Amazon';
import { MutedText } from '../../common/MutedText';
import { keywordsNormalizer } from '../../common/normalizers/Keyword';
import { TYPE_PRODUCT } from '../AdsProviders/NewCampaign/NewProductModal';
import { ProductProviderSerlectorContent } from '../AdsProviders/NewCampaign/ProductProviderSelector';

/**
 * Amazon Product Page
 * @returns {JSX.Element}
 * @constructor
 */
function IAmazonProductPage(props: { account: accountT }) {
  const history = useHistory();

  const [progress, setProgress] = useState(0);
  const [products, setProducts] = useState(undefined);
  const [campaignsImported, setCampaignsImported] = useState([]);
  const [productProviders, setProductProviders] = useState([]);
  const [productProviderToImport, setProductProviderToImport] = useState(undefined);
  const [productsToImport, setProductsToImport] = useState([]);

  const { doAction, ExclusiveInlineLoadingContainer } = useLoading(true);

  const maxKeywords = props.limitations.limitProductKeyword;

  const campaignsNotImported = campaignsImported.filter((c) => c.status !== 'SUCCESS');

  function confirmExit() {
    return 'We are importing your products (do not close this tab! Take around one minute per product)';
  }

  useEffect(() => {
    window.onbeforeunload = undefined;

    if (progress > 0 && progress < 100) {
      window.onbeforeunload = confirmExit;
    }
  }, [progress]);

  // Empty search when navigate
  useEffect(() => {
    doAction(async () => {
      await loadAllProductProviders();
    });
  }, []);

  useEffect(() => {
    if (productProviderToImport) {
      doAction(async () => {
        await loadProducts();
      });
    }
  }, [JSON.stringify(productProviderToImport)]);

  const columns = [
    {
      title: '',
      dataIndex: 'productImageUrl',
      key: 'productImageUrl',
      width: 80,
      render: (v) => {
        if (v) {
          return (
            <div>
              <img height={40} src={v} />
            </div>
          );
        } else {
          return (
            <div>
              <Amazon size={40} />
            </div>
          );
        }
      },
    },
    {
      title: 'Product Name',
      dataIndex: 'name',
      key: 'name',
      width: 400,
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (v) => (
        <div
          style={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            maxWidth: '350px',
          }}
        >
          {v}
        </div>
      ),
    },
    {
      title: 'Product ASIN',
      dataIndex: 'externalId',
      key: 'externalId',
      width: 110,
    },
    {
      title: 'Net Margin',
      dataIndex: 'margin',
      width: 120,
      editable: true,
      className: 'editable',
      render: (v, product) => (
        <Form.Item
          name={[product.externalId, 'netMargin']}
          onChange={(v) => {
            product.netMargin = v.target.value;
          }}
          style={{ marginBottom: 0 }}
          label=""
        >
          <CurrencyInput currencyCode={product.priceToPay.currency} />
        </Form.Item>
      ),
    },
    {
      title: 'Keywords',
      dataIndex: 'keyword',
      width: 400,
      editable: true,
      className: 'editable',
      render: (v, product) => (
        <Form.Item
          style={{ marginBottom: 0 }}
          label=""
          name={[product.externalId, 'keywords']}
          normalize={(values) => keywordsNormalizer(values || [])}
          rules={[
            {
              max: maxKeywords,
              type: 'array',
              message: `maximum ${maxKeywords} keywords per product`,
            },
          ]}
        >
          <Select
            onSelect={(v) => {
              if (Array.isArray(v)) {
                product.keywords = v;
              } else {
                product.keywords = [v];
              }
            }}
            size="large"
            style={{ width: 400 }}
            maxTagCount="responsive"
            mode="tags"
          />
        </Form.Item>
      ),
    },
    {
      title: 'Brand',
      dataIndex: 'brand',
      key: 'brand',
      width: 260,
    },
    {
      title: 'Availability',
      dataIndex: 'availability',
      key: 'availability',
      width: 260,
    },
  ].filter((e) => !e.hidden);

  const rowSelection = {
    onChange: (_, selectedRows: DataType[]) => {
      setProductsToImport(selectedRows.filter((r) => !r.foundOnPixelMe));
    },
    getCheckboxProps: (record: DataType) => ({
      disabled: record.foundOnPixelMe === true, // Column configuration not to be checked
      name: record.name,
    }),
  };

  return (
    <>
      {productProviders.length > 0 && (
        <>
          <MutedText>Select your Marketplace:</MutedText>
          <Form.Item>
            <ProductProviderSerlectorContent
              account={props.account}
              productProviders={productProviders}
              onChange={(provider) => {
                setProductProviderToImport(provider);
              }}
            />
          </Form.Item>
        </>
      )}
      <ExclusiveInlineLoadingContainer>
        <div style={{ height: '100%' }}>
          <MutedText>
            Please set net margins, keywords and select the products you want to import into PixelMe
          </MutedText>
          <Form
            style={{
              maxHeight: 'calc(100% - 160px)',
              overflow: 'scroll',
            }}
          >
            <Table
              rowSelection={{
                type: 'checkbox',
                render: () => <></>,
                ...rowSelection,
              }}
              pagination={false}
              style={{ cursor: 'pointer' }}
              rowKey="externalId"
              dataSource={products}
              columns={columns}
            />
          </Form>
          <div style={{ padding: '10px', textAlign: 'right', width: '100%' }}>
            <Button style={{ marginRight: '1em' }} ghost onClick={skip}>
              Skip
            </Button>
            <Button disabled={productsToImport.length === 0} type="primary" onClick={importProducts}>
              Import products
            </Button>
          </div>
        </div>
      </ExclusiveInlineLoadingContainer>
      <Modal
        icon={<LoadingOutlined style={{ fontSize: 24 }} spin />}
        width="100vw"
        style={{
          maxWidth: 'unset',
          margin: 'unset',
          top: 0,
          right: 0,
          height: '100vh !important',
          width: '100vw !important',
        }}
        title={
          progress < 100
            ? 'We are importing your products (do not close this tab! Take around one minute per product)'
            : 'Products imported'
        }
        visible={progress > 0}
        cancelButtonProps={{ disabled: true }}
        onOk={() => {
          history.push(`/${props.account.id}/adsprovider`);
        }}
        okButtonProps={{ disabled: progress < 100 }}
      >
        {progress < 100 && <MutedText>Products import in Progress:</MutedText>}
        <Progress percent={progress} size="large" showInfo={false} />
        <br />
        <br />
        {campaignsNotImported.length > 0 && (
          <Alert
            type="error"
            message={<>{campaignsNotImported.length} Campaigns ignored, because they already exist on PixelMe</>}
          ></Alert>
        )}
        <br />
        <Table dataSource={campaignsImported} size="small" rowKey="id" scroll={{ x: 1000 }}>
          <Column title="Campaign" dataIndex="id" key="id" width={100} />
          <Column title="Name" dataIndex="name" key="name" width={300} />
          <Column
            title="Status"
            dataIndex="status"
            key="status"
            width={100}
            render={(_: any, c: DataType) =>
              c.status === 'SUCCESS' ? (
                <Tag color="#87d068">Imported</Tag>
              ) : c.status === 'ALREADY_EXISTS' ? (
                <Tag color="#2db7f5">This Campaign already exists on PixelMe</Tag>
              ) : c.status === 'NOT_FOUND_ON_GOOGLE' ? (
                <Tag color="#2db7f5">This Campaign was not found on Google</Tag>
              ) : (
                <></>
              )
            }
          />
        </Table>
      </Modal>
    </>
  );

  async function loadAllProductProviders() {
    const getProductProvidersResponse = await getAllProductProviders(localStorage, axios, {
      accountId: props.account.id,
    });

    switch (getProductProvidersResponse.providers.length) {
      case 0:
        skip();
        break;
      case 1:
        setProductProviderToImport(getProductProvidersResponse.providers[0]);
        break;
      default:
        setProductProviders(getProductProvidersResponse.providers);
    }
  }

  async function loadProducts() {
    try {
      const getProductsResponse = await getProductsOnProvider(
        localStorage,
        axios,
        {
          accountId: props.account.id,
        },
        productProviderToImport.id,
      );

      setProducts(getProductsResponse.productsMetadata);
    } catch (e) {
      if (e.response && e.response.status === 400) {
        skip();

        throw e;
      }
    }
  }

  function skip() {
    window.location.href = `/${props.account.id}/amazon/attribution`;
  }

  function importProducts() {
    doAction(async () => {
      let i = 0;
      let campaigns = [];

      for (const product of productsToImport) {
        const productToSave = {
          ...product,
          type: TYPE_PRODUCT,
          provider: productProviderToImport.providerType,
          productUrl: product.productUrl,
          netMarginMicros: Math.round(product.netMargin * 1000000),
          priceMicros: Math.round(product.priceToPay.amount * 1000000),
          keywords: product.keywords || [],
          currency: product.priceToPay.currency,
          tags: product.tags,
          imported: true,
          marketplaceName: productProviderToImport.amazonMarketPlaceId,
          productData: product,
        };

        await saveProductOnProvider(
          localStorage,
          axios,
          { accountId: props.account.id },
          { product: productToSave },
          productProviderToImport.id,
        );
        track(trackedEvents.addProduct, { product: productToSave });
        const importProductCampaignsResponse = await importProductCampaigns(
          localStorage,
          axios,
          {
            accountId: props.account.id,
          },
          productProviderToImport.id,
          productToSave.externalId,
        );

        campaigns = [
          ...campaigns,
          ...(_.get(importProductCampaignsResponse, ['campaigns']) || []).sort(
            (a, b) => -a.status.localeCompare(b.status),
          ),
        ];
        setCampaignsImported([...campaigns]);
        i++;
        setProgress((i * 100) / productsToImport.length);
      }
    });
  }
}

const mapStateToProps = function (state, ownProps): any {
  return {
    limitations: state.accounts.limitations,
    ...ownProps,
  };
};

export const AmazonProductPage = connect(mapStateToProps)(IAmazonProductPage);
