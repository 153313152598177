export const tiktokPlacements = [
  {
    label: 'Tiktok',
    value: 'PLACEMENT_TIKTOK',
  },
  {
    label: 'TopBuzz',
    value: 'PLACEMENT_TOPBUZZ',
  },
  {
    label: 'Pangle',
    value: 'PLACEMENT_PANGLE',
  },
];
