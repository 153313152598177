import './BufferOauth.css';
import React, { Component } from 'react';

import { Redirect } from 'react-router-dom';

import { bufferOauthCallback } from 'lib/api';
import axios from 'lib/axios.factory';

import { GlobalPlaceholder } from '../../../components/common/Placeholders';

type stateT = {
  calling: boolean,
};

type propsT = {
  accountId: string,
  code: string,
};

class BufferOAuth extends Component<propsT, stateT> {
  constructor(props: propsT) {
    super(props);
    this.state = { calling: true };
  }

  componentDidMount() {
    const { accountId, code } = this.props;

    this.setState({ calling: true });
    bufferOauthCallback(localStorage, axios, { accountId }, { code })
      .then((_) => {
        this.setState({
          calling: false,
        });
      })
      .catch((_) => {
        this.setState({ calling: false });
      });
  }

  render() {
    const { accountId } = this.props;
    const { calling } = this.state;

    if (calling) {
      return <GlobalPlaceholder loadingText="Configuring Buffer account..." />;
    }

    return <Redirect to={`/${accountId}/s/integrations`} />;
  }
}

export default BufferOAuth;
