import { InfoCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import styled from 'styled-components';

import { strings } from '../../../locales/strings';

const HeaderContainer = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: center;
  padding: 0 0.5rem;
`;

export const AuditPreviewTableHeaders = ({ title, tooltip }: { title: string, tooltip: string }) => (
  <HeaderContainer>
    <div>{strings.get(title)}</div>
    <Tooltip title={strings.get(tooltip)} overlayInnerStyle={{ whiteSpace: 'pre-wrap' }}>
      <InfoCircleOutlined />
    </Tooltip>
  </HeaderContainer>
);
