import React, { useContext, useEffect, useState, type Node } from 'react';

import { useUpdateEffect } from 'react-use';
import styled from 'styled-components';
import { useImmer } from 'use-immer';

import { DashboardFilterContext } from 'context/DashboardFilterProvider';
import { useLoading } from 'hooks/useLoading';
import { getAllCampaignsForProduct } from 'lib/adsPlateformApi';
import axios from 'lib/axios.factory';
import { useFeatureFlag, CAMPAIGN_ANALYTICS_UPDATE } from 'lib/configcat';
import { getMetrics } from 'lib/metrics';

import { CampaignsDashboardHeader } from './CampaignsDashboardHeader';
import { CampaignsStats } from './CampaignsStats';
import { CampaignsTable } from './CampaignsTable';
import type { adsCampaignT, adsReportDataT } from '../../../../../../flow-typed/pixelme/adsPlateform';
import { calculateStats } from '../../common/adsStats';
import { DashboardFilter } from '../../common/DashboardFilter';
import { KeyMetricsContainer } from '../KeyMetrics/KeyMetricsContainer';
import { ProductGraphsContainer } from '../ProductsTable/ProductGraphsContainer';

export type StateT = {
  allCampaigns: Array<adsCampaignT>,
  campaigns: Array<adsCampaignT>,
  stats: adsReportDataT,
};

const initialState: StateT = {
  allCampaigns: [],
  campaigns: [],
  stats: {},
};

const Style = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  .ant-table-column-sorters {
    width: 100%;
    justify-content: space-between;
  }
`;

const FilterContainer = styled.div`
  background-color: #fff;
  padding: 0.75rem 1rem;
`;

export function CampaignsDashBoard({
  account,
  productProviderId,
  productASIN,
}: {
  account: accountT,
  productProviderId: string,
  productASIN: string,
}): Node {
  const [showMetricsUpdate, isShowMetricsUpdateLoading] = useFeatureFlag(CAMPAIGN_ANALYTICS_UPDATE);
  const { ctx, updateCtx } = useContext(DashboardFilterContext);
  const [state, updateState] = useImmer(initialState);
  const { doAction, ExclusiveInlineLoadingContainer } = useLoading(true);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [product, setProduct] = useState({});
  const [productStats, setProductStats] = useState({});
  const [metrics, setMetrics] = useState({});
  const [metricsLoading, setMetricsLoading] = useState(true);

  const isAmazonProduct = product.type === '';

  let [from, to] = ctx.adsReportsRange;

  from = from.toISOString();
  to = to.toISOString();

  const loadMetrics = async (filteredCampaignIds) => {
    const withArr = ['keywordMetrics', 'keyMetrics'];

    if (showMetricsUpdate) {
      withArr.push('tacos');
    }

    try {
      setMetricsLoading(true);
      const metrics = await getMetrics(localStorage, axios, productProviderId, productASIN, {
        accountId: account.id,
        from,
        to,
        campaigns: filteredCampaignIds ? filteredCampaignIds.join(',') : null,
        with: withArr.join(','),
      });

      setMetrics(metrics);
    } finally {
      setMetricsLoading(false);
    }
  };

  const filterCampaigns = (allCampaigns) => {
    let tmpCampaigns = allCampaigns || [];

    if (ctx.filterCampaignId) {
      tmpCampaigns = tmpCampaigns.filter((c) => !ctx.filterCampaignId.length || ctx.filterCampaignId.includes(c.id));
    }

    if (ctx.filterCampaignStatus) {
      tmpCampaigns = tmpCampaigns.filter((c) => c.status === ctx.filterCampaignStatus);
    }

    if (ctx.filterProviderType) {
      tmpCampaigns = tmpCampaigns.filter(
        (c) => ctx.filterProviderType.length <= 0 || ctx.filterProviderType.includes(c.providerType),
      );
    }

    const tmpStats = calculateStats(tmpCampaigns);

    updateState((draft) => {
      draft.campaigns = tmpCampaigns;
      draft.stats = tmpStats;
    });
    loadMetrics(tmpCampaigns.length === state.allCampaigns.length ? [] : tmpCampaigns.map((c) => c.id));
  };

  const loadCampaigns = async () => {
    try {
      const getCampaignsResponse = await getAllCampaignsForProduct(
        localStorage,
        axios,
        {
          accountId: account.id,
          from,
          to,
          with: showMetricsUpdate ? 'productStats' : '',
        },
        productProviderId,
        productASIN,
      );

      setProduct(getCampaignsResponse.product);
      setProductStats(getCampaignsResponse.productStats);
      const campaigns = getCampaignsResponse.campaigns.map((campaign) => {
        const adsReport = (campaign.selectedAdsReports || {}).data;
        const result = { ...adsReport, ...campaign };

        return result;
      });

      const urlParams = new URLSearchParams(window.location.search);
      const campaign = urlParams.get('campaign');

      if (campaign) {
        updateCtx((draft) => {
          draft.filterCampaignId = [campaign];
          draft.filterCampaignStatus = undefined;
        });
      }

      updateState((draft) => {
        draft.allCampaigns = campaigns;
        draft.campaigns = campaigns;
        draft.stats = calculateStats(campaigns);
      });
      filterCampaigns(campaigns);
    } catch (err) {
      setMetricsLoading(false);

      throw err;
    }
  };

  useEffect(() => {
    if (!isShowMetricsUpdateLoading && productProviderId && productASIN) {
      doAction(async () => {
        await loadCampaigns();
      });
    }
  }, [from, to, productProviderId, productASIN, isShowMetricsUpdateLoading]);

  useUpdateEffect(() => {
    filterCampaigns(state.allCampaigns);
  }, [ctx.filterCampaignId, ctx.filterCampaignStatus, ctx.filterProviderType]);

  return (
    <Style>
      <CampaignsDashboardHeader
        account={account}
        product={product}
        productStats={productStats}
        selectedRowKeys={selectedRowKeys}
      />
      <FilterContainer>
        <DashboardFilter attributedData={false} campaigns={state.allCampaigns} statusFilter channelsFilter />
      </FilterContainer>
      <ExclusiveInlineLoadingContainer>
        {isAmazonProduct && <KeyMetricsContainer keyMetrics={metrics.keyMetrics} loading={metricsLoading} />}
        <ProductGraphsContainer account={account} product={product} metrics={metrics} loading={metricsLoading} />
        <CampaignsStats
          account={account}
          stats={state.stats}
          productASIN={productASIN}
          productProviderId={productProviderId}
        />
        {product && (
          <CampaignsTable
            account={account}
            campaigns={state.campaigns}
            allCampaigns={state.allCampaigns}
            setSelectedRowKeys={setSelectedRowKeys}
            productASIN={productASIN}
            productId={product.id}
            productProviderId={productProviderId}
          />
        )}
      </ExclusiveInlineLoadingContainer>
    </Style>
  );
}
