import { useState, type Node } from 'react';

import { LinkOutlined } from '@ant-design/icons';
import { Button, Modal, message } from 'antd';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import styled from 'styled-components';

import { TagInput } from 'components/common/antd/TagInput';
import { validateEmail } from 'lib/util';
import { strings } from 'locales/strings';
import { useSendAuditMutation } from 'stores/auditsSlice';

const InputContainer = styled.div`
  display: flex;
  column-gap: 0.75rem;

  button {
    font-size: 0.875rem;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;

  button {
    padding: 0 0.5rem;
    color: var(--text-muted);
    font-weight: 500;
  }
`;

type propsT = {
  visible: boolean,
  onCancel: () => void,
  auditId: string,
};
export const ShareModal = ({ visible, onCancel, auditId, ...props }: propsT): Node => {
  const [emails, setEmails] = useState([]);
  const [sendAudit] = useSendAuditMutation();

  const shareUrl = `${window.env.REACT_APP_BASE_URL}/audit/share?id=${auditId}`;

  const handleShare = async () => {
    const validEmails = emails.filter(validateEmail);

    if (validEmails.length > 0) {
      try {
        await sendAudit({ id: auditId, email: validEmails }).unwrap();
        const successMessage =
          validEmails.length === 1
            ? `${strings.get('asinAudit.share.sentTo')} ${validEmails[0]}`
            : strings.get('asinAudit.share.sentSuccess', { numOfEmails: validEmails.length });

        message.success(successMessage);
      } catch (err) {
        message.error(strings.get('error.apiError'));
      }
    }
  };

  return (
    <Modal
      title={strings.get('asinAudit.share.title')}
      visible={visible}
      onCancel={onCancel}
      footer={
        <ButtonContainer>
          <CopyToClipboard text={shareUrl} onCopy={() => message.success(strings.get('asinAudit.share.clipboard'))}>
            <Button type="text" icon={<LinkOutlined />}>
              {strings.get('asinAudit.share.copyLink')}
            </Button>
          </CopyToClipboard>
        </ButtonContainer>
      }
      destroyOnClose // Allows input to auto focus on subsequent opens
      {...props}
    >
      <p>{strings.get('asinAudit.share.inputLabel')}</p>
      <InputContainer>
        <TagInput
          value={emails}
          onChange={setEmails}
          placeholder={strings.get('asinAudit.share.inputPlaceholder')}
          icon={null}
          autoFocus
          validate={validateEmail}
        />
        <Button type="primary" size="large" onClick={handleShare}>
          {strings.get('asinAudit.share.submit')}
        </Button>
      </InputContainer>
    </Modal>
  );
};
