import React, { useEffect, useState } from 'react';

import rename from 'deep-rename-keys';

import { useLoading } from 'hooks/useLoading';
import axios from 'lib/axios.factory';
import { getTiktokTools } from 'lib/tiktokPlateformApi';

import { TiktokGenericSelector } from './TiktokGenericSelector';
import { buildInterestCategoriesTree } from '../buildTree';

/**
 * Tiktok Interest Categories selector
 * https://ads.tiktok.com/marketing_api/docs?id=1701890988062722
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export function InterestCategoriesSelector(props: {
  account: accountT,
  adsProvider: adsProviderT,
  value: any,
  onChange: any,
  defaultValue: any,
}) {
  const [treeData, setTreeData] = useState([]);
  const [flatData, setFlatData] = useState([]);
  const { doAction, ExclusiveInlineLoadingContainer } = useLoading(true);

  async function load() {
    const genericeResponse = await getTiktokTools(
      localStorage,
      axios,
      props.adsProvider,
      { accountId: props.account.id },
      'interest_category',
    );
    const d = buildInterestCategoriesTree(genericeResponse.interestCategories);

    setFlatData(genericeResponse.interestCategories);
    setTreeData(d);
  }

  useEffect(() => {
    doAction(load);
  }, [JSON.stringify(props.adsProvider)]);

  return (
    <ExclusiveInlineLoadingContainer>
      <TiktokGenericSelector
        account={props.account}
        value={props.value}
        defaultValue={props.defaultValue}
        onChange={props.onChange}
        flatData={rename(flatData, (key) => (key === 'id' ? 'key' : key === 'name' ? 'title' : key))}
        treeData={rename(treeData, (key) => (key === 'id' ? 'key' : key === 'name' ? 'title' : key))}
        adsProvider={props.adsProvider}
      />
    </ExclusiveInlineLoadingContainer>
  );
}
