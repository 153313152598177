import React, { Fragment, useContext, useEffect, useState } from 'react';

import { PauseCircleTwoTone, PlayCircleTwoTone } from '@ant-design/icons';
import { Card, message, Popover, Spin, Table, Tooltip } from 'antd';
import { parse } from 'json2csv';
import _ from 'lodash';
import moment from 'moment';
import fileDownload from 'react-file-download';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { HoverLink } from 'components/common/HoverLink';
import { Analytics } from 'components/common/icons';
import { Csv } from 'components/common/illustrations/Csv';
import { MutedText } from 'components/common/MutedText';
import { SmallPlaceholder } from 'components/common/Placeholders';
import { DATE_FORMAT } from 'consts/consts';
import { DashboardFilterContext } from 'context/DashboardFilterProvider';
import { useLoading } from 'hooks/useLoading';
import {
  editProduct,
  getKeywordsRanking,
  getVariationTimeSeries,
  pauseVariation,
  runVariation,
} from 'lib/adsPlateformApi';
import axios from 'lib/axios.factory';
import { sn } from 'lib/safe';
import { getCurrency } from 'utils/currency';

import { GOOGLE_RMF, useFeatureFlag } from '../../../../../lib/configcat';
import {
  ACOSValue,
  CurrencyValue,
  NumberVal,
  ROASValue,
} from '../../../AudienceBuilder/Campaigns/campaigns/RedirectsListLine';
import { DisabledIconInsideButton, IconInsideButton } from '../../../AudienceBuilder/Campaigns/campaignTree/Common';
import { AdsProviderLogo } from '../../common/AdsProviderLogo';
import { KeywordFloatingCellAction } from '../../common/KeywordFloatingCellAction';
import { randomESTBids } from '../../GoogleRMF/constantsRMF';
import Graph from '../../Ranking/Graph';
import { BrbValue } from '../BrbValue';

const Style = styled.div`
  overflow: auto;
  max-width: 100%;

  .ant-table-row:hover .floating-row-action-container {
    width: auto !important;
  }

  .ant-table-pagination.ant-pagination {
    justify-content: left;
    margin-left: 1em;
  }

  .ant-table-thead > tr > th {
    text-align: center;
  }

  .ant-table-column-title {
    font-size: 13px;
  }
`;

function ExportVariation(props: { account: accountT, variation: variationT }) {
  const { doAction, ExclusiveInlineLoadingContainer } = useLoading(false);

  return (
    <ExclusiveInlineLoadingContainer>
      <HoverLink
        style={{ width: '100%' }}
        content="Download data"
        onClick={(e) => {
          doAction(async () => {
            const data = await getVariationTimeSeries(
              localStorage,
              axios,
              { accountId: props.account.id },
              _.get(props, 'variation.id'),
            );

            const result = parse(_.get(data, 'timeseriesAdsReports'), []);

            fileDownload(result, `${_.get(props, 'variation.name')}.csv`);
          });
        }}
        children={<Csv />}
      />
    </ExclusiveInlineLoadingContainer>
  );
}

function ShowRanking(props: {
  account: accountT,
  product: productT,
  keyword: string,
  rankings: any,
  onProductSave: (product: productT) => void,
  limitations: any,
}) {
  const [saving, setSaving] = useState(false);

  if (!props.product.keywords.includes(props.keyword)) {
    if (props.product.keywords.length >= props.limitations.limitProductKeyword) {
      return (
        <DisabledIconInsideButton>
          <Tooltip placement="top" title="You have reached your limit of tracked keywords">
            <Analytics color="#d93f21"></Analytics>
          </Tooltip>
        </DisabledIconInsideButton>
      );
    }

    const startTracking = async () => {
      setSaving(true);

      try {
        const response = await editProduct(
          localStorage,
          axios,
          { accountId: props.account.id },
          {
            tags: props.product.tags,
            name: props.product.name,
            net_margin_micros: props.product.netMargin,
            keywords: [...props.product.keywords, props.keyword],
          },
          props.product.providerId,
          props.product.externalId,
        );

        message.success('Product updated');
        props.onProductSave(response.product);
      } catch (e) {
        message.error('Error on Product update');

        throw e;
      } finally {
        setSaving(false);
      }
    };

    return (
      <IconInsideButton>
        <Tooltip placement="top" title={`Start tracking Amazon ranking for "${props.keyword}"`}>
          {saving ? <Spin /> : <Analytics color="#d93f21" onClick={startTracking}></Analytics>}
        </Tooltip>
      </IconInsideButton>
    );
  }

  const graphRankings = props.rankings[props.keyword] || [];
  const title = `Organic search ranking for "${props.keyword}"`;

  return (
    <IconInsideButton>
      <Popover
        placement="bottomRight"
        arrowPointAtCenter={true}
        title={title}
        content={
          <div style={{ width: '500px', maxWidth: '100%' }}>
            <Graph total={1} rankings={graphRankings} rankingMode="" />
          </div>
        }
        trigger="click"
      >
        <Analytics color="#2e89e2"></Analytics>
      </Popover>
    </IconInsideButton>
  );
}

const stateToProps = function (state): internalPropsT {
  return {
    limitations: state.accounts.limitations,
  };
};

export const ConnectedShowRanking = connect(stateToProps)(ShowRanking);

export function VariationsTable(props: {
  product: productT,
  variations: variationT[],
  account: accountT,
  variationType: string,
  onProductSave: (product: productT) => void,
  setSelectedRowKeys: any,
}) {
  const { ctx } = useContext(DashboardFilterContext);
  const { currencyCode, currencySymbol } = getCurrency(props.account);
  const [loading] = useState(false);
  const history = useHistory();

  const [variations, setVariations] = useState(props.variations);
  const [rankings, setRankings] = useState({});
  const [showKeywordRMFOptions] = useFeatureFlag(GOOGLE_RMF);

  useEffect(() => {
    const fetchData = async () => {
      const data = await getKeywordsRanking(
        localStorage,
        axios,
        {
          accountId: props.account.id,
          rangeId: 'LAST_30D',
        },
        props.product.providerId,
        props.product.id,
      );

      const rankings = {};

      (data.ranking || []).forEach((r) => {
        rankings[r.keyword] = r.ranks;
      });
      setRankings(rankings);
    };

    fetchData();
  }, [props.product]);

  useEffect(() => {
    setVariations(props.variations);
  }, [props.variations]);

  let columns = [
    {
      title: '',
      dataIndex: 'providerType',
      key: 'providerType',
      width: 60,
      render: (value, row) => (
        <div className="small-provider">
          <AdsProviderLogo providerType={value} />
        </div>
      ),
    },
  ];

  switch (props.variationType) {
    case 'KEYWORD': {
      columns.push(
        {
          title: 'Keyword',
          dataIndex: 'keyword',
          key: 'keyword',
          className: 'keyword',
          width: 200,
          sorter: (a, b) => a.keyword.localeCompare(b.keyword),
          render: (value, row) => (
            <KeywordFloatingCellAction keyword={value} marketplaceId={props.product.marketplaceId} />
          ),
        },
        {
          title: 'Match Type',
          dataIndex: 'matchType',
          key: 'matchType',
          width: 100,
          render: (a) => (a === 'PHRASE' ? 'Phrase Match' : a === 'BROAD' ? 'Broad Match' : 'Exact Match'),
          sorter: (a, b) => a.matchType.localeCompare(b.matchType),
        },
      );
      break;
    }
    case 'CREATIVE': {
      columns.push(
        {
          title: 'Creative',
          dataIndex: 'keyword',
          key: 'keyword',
          width: 200,
          sorter: (a, b) => a.keyword.localeCompare(b.keyword),
        },
        {
          title: 'Ad Group',
          dataIndex: 'matchType',
          key: 'matchType',
          width: 100,
          sorter: (a, b) => a.matchType.localeCompare(b.matchType),
        },
      );
      break;
    }
  }
  columns.push(
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 140,
      sorter: (a, b) => a.status - b.status,
      render: (value, variation) => (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {value === -5 && <span style={{ color: '#FFEA00' }}>Waiting (import)</span>}
          {value === 1 && <span style={{ color: '#F8A51B' }}>Paused</span>}
          {value === 2 && <span style={{ color: 'red' }}>Deleted</span>}
          {value === 3 && <span style={{ color: 'red' }}>Rejected</span>}
          {(value === 4 || value === -10) && <span style={{ color: '#0F993E' }}>Active</span>}
          {value === 5 && <span style={{ color: '#0F993E' }}>Finished</span>}
          {variation.status === 4 /* ACTIVE*/ && (
            <PauseCircleTwoTone
              style={{ fontSize: '16px' }}
              twoToneColor="#F8A51B"
              title="Pause Variation"
              onClick={async () => {
                let updatedVariations = [...variations];

                updatedVariations.forEach((c, i) => {
                  if (c.id === variation.id) {
                    updatedVariations[i].status = -10;
                  }
                });
                setVariations(updatedVariations);
                const variationResult = await pauseVariation(
                  localStorage,
                  axios,
                  { accountId: props.account.id },
                  variation.id,
                );

                updatedVariations = [...variations];
                updatedVariations.forEach((c, i) => {
                  if (c.id === variation.id) {
                    updatedVariations[i].status = variationResult.variation.status;
                  }
                });

                setVariations(updatedVariations);
                message.success('Paused');
              }}
            />
          )}
          {variation.status === 1 /* PAUSED*/ && (
            <PlayCircleTwoTone
              style={{ fontSize: '16px' }}
              twoToneColor="#52c41a"
              title="Launch Variation"
              onClick={async () => {
                let updatedVariations = [...variations];

                updatedVariations.forEach((c, i) => {
                  if (c.id === variation.id) {
                    updatedVariations[i].status = -10;
                  }
                });
                setVariations(updatedVariations);
                const variationResult = await runVariation(
                  localStorage,
                  axios,
                  { accountId: props.account.id },
                  variation.id,
                );

                updatedVariations = [...variations];
                updatedVariations.forEach((c, i) => {
                  if (c.id === variation.id) {
                    updatedVariations[i].status = variationResult.variation.status;
                  }
                });
                setVariations(updatedVariations);
                message.success('Enabled');
              }}
            />
          )}
        </div>
      ),
    },

    {
      title: 'Amazon Search Volume',
      dataIndex: ['volumes', 'amazonVolume'],
      key: 'amazonSearchVolume',
      hidden: !ctx.showRank,
      width: 120,
      className: 'amazon',
      render: (v, variation) => <NumberVal clicks={v}></NumberVal>,
      sorter: (a, b) => sn(a, 'volumes.amazonVolume', 0) - sn(b, 'volumes.amazonVolume', 0),
    },
    {
      title: 'Amazon Current Rank',
      dataIndex: ['volumes', 'rank'],
      key: 'amazonCurrentRank',
      hidden: !ctx.showRank,
      width: 120,
      className: 'amazon',
      render: (v, variation) => {
        if (!v) {
          return 'N/A';
        }

        return v;
      },
      sorter: (a, b) => sn(a, 'volumes.rank', 0) - sn(b, 'volumes.rank', 0),
    },
    {
      title: 'Google Est. Cost per Click',
      hidden: !ctx.showRank,
      width: 120,
      render: (_, variation) => {
        if (!variation.volumes) {
          return 'N/A';
        }

        return `${currencySymbol + sn(variation, 'volumes.topOfPageBidLow', 0).toFixed(2)} - ${sn(
          variation,
          'volumes.topOfPageBidHigh',
          0,
        ).toFixed(2)}`;
      },
      sorter: (a, b) => sn(a, 'volumes.topOfPageBidLow', 0) - sn(b, 'volumes.topOfPageBidLow', 0),
    },
    {
      title: 'Google Search Volume',
      dataIndex: ['volumes', 'googleImpressions'],
      key: 'amazonSearchVolume',
      hidden: !ctx.showRank,
      width: 120,
      render: (v, variation) => <NumberVal clicks={v}></NumberVal>,
      sorter: (a, b) => sn(a, 'volumes.googleImpressions', 0) - sn(b, 'volumes.googleImpressions', 0),
    },

    {
      title: 'Live Impressions',
      dataIndex: 'liveImpressions',
      key: 'liveImpressions',
      hidden: ctx.showAttributedData,
      width: 120,
      render: (v, variation) => <NumberVal clicks={v}></NumberVal>,
      sorter: (a, b) => a.impressions - b.impressions,
    },
    {
      title: 'Live Clicks',
      dataIndex: 'liveClicks',
      key: 'liveClicks',
      hidden: ctx.showAttributedData,
      width: 120,
      render: (v, variation) => (
        <>
          <NumberVal clicks={v}></NumberVal>
        </>
      ),
      sorter: (a, b) => a.clicks - b.clicks,
    },
    {
      title: 'Live Ad Cost',
      dataIndex: 'liveAdSpent',
      key: 'liveAdSpent',
      hidden: ctx.showAttributedData,
      width: 120,
      sorter: (a, b) => a.adSpent - b.adSpent,
      render: (adSpent, variation) => (
        <>
          <CurrencyValue amount={adSpent} currency={currencySymbol}></CurrencyValue>
        </>
      ),
    },
    {
      title: 'Live Cpc',
      dataIndex: 'liveAverageCpc',
      key: 'liveAverageCpc',
      hidden: ctx.showAttributedData,
      width: 140,
      sorter: (a, b) => a.liveAverageCpc - b.liveAverageCpc,
      render: (v, variation) => (
        <>
          <CurrencyValue amount={v} currency={currencySymbol}></CurrencyValue>
        </>
      ),
    },
    {
      title: 'Attributed Impressions',
      dataIndex: 'impressions',
      key: 'impressions',
      hidden: !ctx.showAttributedData,
      width: 120,
      render: (v, variation) => <NumberVal clicks={v}></NumberVal>,
      sorter: (a, b) => a.impressions - b.impressions,
    },
    {
      title: 'Attributed Clicks',
      dataIndex: 'clicks',
      key: 'clicks',
      hidden: !ctx.showAttributedData,
      width: 120,
      render: (v, variation) => <NumberVal clicks={v}></NumberVal>,
      sorter: (a, b) => a.clicks - b.clicks,
    },
    {
      title: 'Amazon Clickthroughs',
      dataIndex: 'amazonClickThroughs',
      key: 'amazonClickThroughs',
      hidden: true,
      width: 120,
      render: (v, variation) => <NumberVal clicks={v}></NumberVal>,
      sorter: (a, b) => a.clicks - b.clicks,
    },
    {
      title: 'Attributed Ad Cost',
      dataIndex: 'adSpent',
      key: 'adSpent',
      hidden: !ctx.showAttributedData,
      width: 120,
      sorter: (a, b) => a.adSpent - b.adSpent,
      render: (adSpent, variation) => <CurrencyValue amount={adSpent} currency={currencySymbol}></CurrencyValue>,
    },
    {
      title: 'Attributed Cpc',
      dataIndex: 'averageCpc',
      key: 'averageCpc',
      hidden: !ctx.showAttributedData,
      width: 140,
      sorter: (a, b) => a.averageCpc - b.averageCpc,
      render: (v, variation) => <CurrencyValue amount={v} currency={currencySymbol}></CurrencyValue>,
    },
    {
      title: 'Raw Data ',
      width: 140,
      style: { padding: 0 },
      hidden: ctx.showAttributedData,
      className: 'no-padding',
      render: (v, variation) => (
        <div>
          <ExportVariation variation={variation} account={props.account} />
        </div>
      ),
    },
    {
      title: 'Add to Cart',
      dataIndex: 'amazonTotalAddToCart',
      key: 'amazonTotalAddToCart',
      hidden: !ctx.showAttributedData,
      className: 'amazon',
      width: 140,
      sorter: (a, b) => a.amazonTotalAddToCart - b.amazonTotalAddToCart,
      render: (v, variation) => <NumberVal clicks={v}></NumberVal>,
    },

    {
      title: 'Purchases',
      dataIndex: 'amazonTotalPurchases',
      key: 'amazonTotalPurchases',
      className: 'amazon',
      hidden: !ctx.showAttributedData,
      width: 120,
      sorter: (a, b) => a.amazonTotalPurchases - b.amazonTotalPurchases,
      render: (v, variation) => <NumberVal clicks={v}></NumberVal>,
    },
    {
      title: 'Revenue',
      dataIndex: 'amazonTotalSales',
      key: 'amazonTotalSales',
      className: 'amazon',
      hidden: !ctx.showAttributedData,
      width: 120,
      render: (v, variation) => <CurrencyValue amount={v} currency={currencySymbol}></CurrencyValue>,
      sorter: (a, b) => a.amazonTotalSales - b.amazonTotalSales,
    },
    {
      title: 'Net Margin',
      dataIndex: 'netMargin',
      key: 'netMargin',
      hidden: !ctx.showAttributedData,
      width: 120,
      sorter: (a, b) => a.netMargin - b.netMargin,
      render: (netMargin) => <CurrencyValue amount={netMargin} currency={currencySymbol}></CurrencyValue>,
    },
    {
      title: 'ROAS',
      dataIndex: 'roas',
      key: 'roas',
      hidden: !ctx.showAttributedData,
      width: 100,
      render: (v, variation) => <ROASValue roas={v} />,
      sorter: (a, b) => a.roas - b.roas,
    },
    {
      title: 'ACOS',
      dataIndex: 'acos',
      key: 'acos',
      hidden: !ctx.showAttributedData,
      width: 100,
      render: (v, variation) => <ACOSValue acos={v} />,
      sorter: (a, b) => a.acos - b.acos,
    },
    {
      title: (
        <Tooltip placement="bottom" title="On average, Brands can earn a 10% bonus from their qualifying sales">
          <span>
            Average Brand
            <br /> Referral Bonus ℹ
          </span>
        </Tooltip>
      ),
      dataIndex: 'amazonTotalSales',
      key: 'amazonTotalSales',
      hidden: !ctx.showAttributedData,
      width: 160,
      render: (v, variation) => (
        <Fragment>
          {variation.amazonTotalSales > 0 && (
            <BrbValue
              brbBonusAmountCalculated={variation.brbBonusAmountCalculated}
              brbBonusAmount={variation.brbBonusAmount}
              currencyCode={currencyCode}
              currencySymbol={currencySymbol}
              brbEnrolled={_.get(
                props.account.productProviders.find((p) => p.id === props.productProviderId),
                'brbEnrolled',
              )}
            />
          )}
        </Fragment>
      ),
      sorter: (a, b) => a.amazonTotalSales - b.amazonTotalSales,
    },
    {
      title: 'First Page Est. Bid',
      hidden: !ctx.showAttributedData || !showKeywordRMFOptions,
      width: 100,
      render: () => <span>{randomESTBids()}</span>,
    },
    {
      title: 'First Position Est. Bid',
      hidden: !ctx.showAttributedData || !showKeywordRMFOptions,
      width: 100,
      render: () => <span>{randomESTBids()}</span>,
    },
    {
      title: 'Amazon Ranking',
      key: 'amazonRanking',
      align: 'center',
      width: 120,
      render: (v, variation) => (
        <ConnectedShowRanking
          account={props.account}
          product={props.product}
          keyword={variation.keyword.toLowerCase()}
          rankings={rankings}
          onProductSave={props.onProductSave}
        />
      ),
    },
    {
      title: 'CreatedAt',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 120,
      render: (v) => v && moment(v).format(DATE_FORMAT),
    },
  );
  columns = columns.filter((c) => !c.hidden);
  const rowSelection = {
    selectionType: 'checkbox',
    onChange: (selectedRowKeys: any, selectedRows: any) => {
      props.setSelectedRowKeys(selectedRowKeys);
    },
  };

  if (loading) {
    return <SmallPlaceholder />;
  }

  if (!variations || variations.length === 0) {
    return (
      <Fragment>
        <br />
        <Card style={{ width: '100%', textAlign: 'center' }}>
          <MutedText>
            There is no data for the campaign.
            <br />
            Data are fetched every day for active campaigns.
          </MutedText>
          <br />
        </Card>
        <br />
      </Fragment>
    );
  }

  return (
    <Style>
      <Table
        style={{ cursor: 'pointer' }}
        rowKey="id"
        tableLayout="fixed"
        dataSource={variations}
        columns={columns}
        rowSelection={rowSelection}
        pagination={{
          defaultPageSize: 50,
        }}
        onRow={(campaign, rowIndex) => ({
          onClick: (event) => {
            if (campaign.totalVariationsCount > 0) {
              history.push(
                `/${props.account.id}/adsprovider/${props.productProviderId}/${props.productASIN}/campaigns/${campaign.id}/variations`,
              );
            }
          },
        })}
        rowClassName={(record, index) =>
          `${record.totalCampaignsCount === 0 || !record.lastClicks ? 'row-empty' : ''} ${
            record.outOfLimits ? 'row-out-of-limits' : ''
          }`
        }
      />
    </Style>
  );
}
