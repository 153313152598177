import React from 'react';

import _ from 'lodash';

/**
 * Allow to deep filter on the treeData
 * @param object
 * @param key
 * @param value
 * @returns {null|*}
 */
export function deepFindOnTree(object, key, value) {
  if (!object) {
    return;
  }

  if (Array.isArray(object)) {
    for (const obj of object) {
      const result = deepFindOnTree(obj, key, value);

      if (result) {
        return result;
      }
    }
  } else {
    if (object.hasOwnProperty(key) && object[key] === value) {
      return object;
    }

    for (const k of Object.keys(object)) {
      if (typeof object[k] === 'object') {
        const o = deepFindOnTree(object[k], key, value);

        if (o !== null && typeof o !== 'undefined') {
          return o;
        }
      }
    }

    return null;
  }
}

export function deepFilterOnTree(object, key, test) {
  let result = [];

  if (!object) {
    return;
  }

  if (Array.isArray(object)) {
    for (const obj of object) {
      const resultToAdd = deepFilterOnTree(obj, key, test);

      if (result) {
        result = [...result, ...resultToAdd];
      }
    }
  } else {
    if (object.hasOwnProperty(key) && test(object)) {
      result.push(object);
    }

    for (const k of Object.keys(object)) {
      if (typeof object[k] === 'object') {
        const resultToAdd = deepFilterOnTree(object[k], key, test);

        if (resultToAdd !== null && typeof resultToAdd !== 'undefined') {
          result = [...result, ...resultToAdd];
        }
      }
    }
  }

  return result;
}

/**
 * Build tree from flat tiktok data. Must to reconstruct tree based on children array
 * @returns {unknown[]}
 * @param geoTargetsData
 */
export function buildGeoTargetsTree(geoTargetsData) {
  const active = geoTargetsData || [];

  console.log('active', active, geoTargetsData);
  const result = (active || [])
    .filter((brandEl) => !!brandEl)
    .filter((el) => el.level === 'COUNTRY')
    .map((brandEl) => {
      const newEl = brandEl;

      newEl.children = _.sortBy(
        active
          .filter((el) => (brandEl.nextLevelIds || []).includes(el.id))
          .filter((modelEl) => !!modelEl)
          .map((modelEl) => {
            const newModelEl = modelEl;

            newModelEl.children = _.sortBy(
              active.filter((el) => (modelEl.nextLevelIds || []).includes(el.id)).filter((newModelEl) => !!newModelEl),
              'name',
            );

            return newModelEl;
          }),
        'name',
      );

      return newEl;
    });

  console.log('result', result);

  return _.sortBy(result, 'name');
}

/**
 * Build tree from flat tiktok data. Must to reconstruct tree based on children array
 * https://ads.tiktok.com/marketing_api/docs?id=1701890988062722
 * @returns {unknown[]}
 * @param interestCategoriesData
 */
export function buildInterestCategoriesTree(interestCategoriesData) {
  const active = interestCategoriesData || [];
  const result = (active || [])
    .filter((el) => el.level === 1)
    .map((brandEl) => {
      const newEl = brandEl;

      newEl.children = _.sortBy(
        active
          .filter((el) => (brandEl.children || []).includes(el.id))
          .map((modelEl) => {
            const newModelEl = modelEl;

            newModelEl.children = _.sortBy(
              active.filter((el) => (modelEl.children || []).includes(el.id)),
              'name',
            );

            return newModelEl;
          }),
        'name',
      );

      return newEl;
    });

  return _.sortBy(result, 'name');
}

/**
 * Build tree from flat tiktok data. Must to reconstruct tree based on children array
 * https://ads.tiktok.com/marketing_api/docs?id=1701890992972802
 * @param actionCategoriesData
 * @param actionScene
 * @returns {unknown[]}
 */
export function buildActionCategoriesTree(actionCategoriesData, actionScene: 'CREATOR_RELATED' | 'VIDEO_RELATED') {
  const activeAction = (actionCategoriesData || []).filter((el) => el.actionScene === actionScene);
  const result = (activeAction || [])
    .filter((el) => el.level === 1)
    .map((brandEl) => {
      const newEl = brandEl;

      newEl.children = _.sortBy(
        activeAction
          .filter((el) => (brandEl.children || []).includes(el.id))
          .map((modelEl) => {
            const newModelEl = modelEl;

            newModelEl.children = _.sortBy(
              activeAction.filter((el) => (modelEl.children || []).includes(el.id)),
              'name',
            );

            return newModelEl;
          }),
        'name',
      );

      return newEl;
    });

  return _.sortBy(result, 'name');
}

/**
 * Build tree from flat tiktok data. Must to reconstruct tree based on children array
 * https://ads.tiktok.com/marketing_api/docs?id=1701890997101570
 * @param deviceModelsData
 * @returns {unknown[]}
 */
export function buildDeviceModelsTree(deviceModelsData) {
  const activeDevice = (deviceModelsData || []).filter((el) => el.isActive);
  const result = (activeDevice || [])
    .filter((el) => el.level === 'BRAND')
    .map((brandEl) => {
      const newEl = brandEl;

      newEl.children = _.sortBy(
        activeDevice
          .filter((el) => (brandEl.children || []).includes(el.id))
          .map((modelEl) => {
            const newModelEl = modelEl;

            newModelEl.children = _.sortBy(
              activeDevice.filter((el) => (modelEl.children || []).includes(el.id)),
              'name',
            );

            return newModelEl;
          }),
        'name',
      );

      return newEl;
    });

  return _.sortBy(result, 'name');
}
