import { useRef, useState } from 'react';

import { WarningOutlined } from '@ant-design/icons';
import { Alert, Form, Radio } from 'antd';
import { useUpdateEffect } from 'react-use';
import styled from 'styled-components';

import { TagInput } from 'components/common/antd/TagInput';
import { strings } from 'locales/strings';

import { ASIN_LIMIT, SELLER_ID_LIMIT, validateAsin, validateSellerId } from './constants';

export const AUDIT_SEARCH_TYPES = {
  SELLER_ID: 'SELLER_ID',
  ASIN: 'ASIN',
};

const options = [
  { label: strings.get('asinAudit.sellerId.search'), value: AUDIT_SEARCH_TYPES.SELLER_ID },
  { label: strings.get('asinAudit.asin.search'), value: AUDIT_SEARCH_TYPES.ASIN },
];

const Style = styled.div`
  display: grid;
  row-gap: 0.5rem;
  > * {
    margin-bottom: 0;
  }
`;

export const AuditSearch = () => {
  const form = Form.useFormInstance();
  const searchType = Form.useWatch('searchType', form);
  const sellerIds = Form.useWatch('sellerIds', form);
  const asins = Form.useWatch('asins', form);
  const sellerIdsInputRef = useRef();
  const asinsInputRef = useRef();
  const [allowFocus, setAllowFocus] = useState(false);

  useUpdateEffect(() => {
    if (allowFocus) {
      if (searchType === AUDIT_SEARCH_TYPES.SELLER_ID) {
        sellerIdsInputRef?.current?.focus();
      } else if (searchType === AUDIT_SEARCH_TYPES.ASIN) {
        asinsInputRef?.current?.focus();
      }
    } else {
      setAllowFocus(true);
    }
  }, [searchType]);

  return (
    <Style>
      <Form.Item name="searchType">
        <Radio.Group>
          {options.map((option) => (
            <Radio key={option.value} value={option.value}>
              {option.label}
            </Radio>
          ))}
        </Radio.Group>
      </Form.Item>
      {searchType === AUDIT_SEARCH_TYPES.SELLER_ID && (
        <>
          <Form.Item
            name="sellerIds"
            rules={[
              () => ({
                validator(_, value) {
                  return value.filter((x) => validateSellerId(x))?.length
                    ? Promise.resolve()
                    : Promise.reject(new Error(strings.get('asinAudit.sellerId.requiredError')));
                },
              }),
            ]}
          >
            <TagInput
              maxTags={SELLER_ID_LIMIT}
              placeholder={strings.get('asinAudit.sellerId.placeholder')}
              validate={validateSellerId}
              autoCapitalize="characters"
              ref={sellerIdsInputRef}
            />
          </Form.Item>
          {sellerIds?.length > 0 && !validateSellerId(sellerIds[0]) && (
            <Alert message={strings.get('asinAudit.sellerId.error')} type="error" showIcon icon={<WarningOutlined />} />
          )}
        </>
      )}
      {searchType === AUDIT_SEARCH_TYPES.ASIN && (
        <>
          <Form.Item
            name="asins"
            rules={[
              () => ({
                validator(_, value) {
                  return value.filter((x) => validateAsin(x))?.length
                    ? Promise.resolve()
                    : Promise.reject(new Error(strings.get('asinAudit.asin.requiredError')));
                },
              }),
            ]}
          >
            <TagInput
              maxTags={ASIN_LIMIT}
              placeholder={strings.get('asinAudit.asin.placeholder', { max: ASIN_LIMIT })}
              validate={validateAsin}
              autoCapitalize="characters"
              ref={asinsInputRef}
            />
          </Form.Item>
          {asins?.filter((x) => !validateAsin(x))?.length > 0 && (
            <Alert
              message={strings.get('asinAudit.asin.error')}
              description={strings.get('asinAudit.asin.errorDescription')}
              type="error"
              showIcon
              icon={<WarningOutlined />}
            />
          )}
        </>
      )}
    </Style>
  );
};
