import React, { Fragment, useEffect, useState } from 'react';

import { Alert, Button, Card, Empty, Steps } from 'antd';
import { useImmer } from 'use-immer';

import axios from 'lib/axios.factory';

import { trackedEvents } from '../../../../config/trackedEvents.config';
import { createAdsProvider } from '../../../../lib/adsPlateformApi';
import { track } from '../../../../lib/analytics';
import { getFacebookAccounts } from '../../../../lib/facebookPlateformApi';
import { ImageOnRightSide } from '../../../common/ImageOnRightSide';
import { AdsProviderLogo } from '../common/AdsProviderLogo';

/**
 * Page use to select the right Facebook Customer ID after OAuth
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export function FacebookSettingsPage(props: { style: ?any, accountId: string, code: string }) {
  const [state, updateState] = useImmer({ error: undefined, loading: false });
  const [accounts, setAccounts] = useState(undefined);
  const fbLoginResponseString = localStorage.getItem('adsProvider');

  if (!fbLoginResponseString) {
    window.location.href = `/${props.accountId}/adsprovider`;
  }

  const fbLoginResponse = JSON.parse(fbLoginResponseString);

  async function load() {
    console.log(fbLoginResponse);
    const accounts = await getFacebookAccounts(localStorage, axios, {
      id: fbLoginResponse.authResponse.userID,
      accessToken: fbLoginResponse.authResponse.accessToken,
    });

    for (const account of accounts) {
      account.status = 'wait';
    }
    for (const account of accounts) {
      try {
        account.status = 'process';
        setAccounts([...accounts]);
        await onCustomerSelection(account);
        account.status = 'finish';
      } catch (e) {
        account.status = 'error';
      }

      setAccounts([...accounts]);
    }
    setTimeout(() => {
      //  don't use history here, we need to reload the page to load the account
      window.location.href = `/${props.accountId}/adsprovider/new-facebook-campaign?productId=undefined`;
    }, 1000);
  }
  useEffect(() => {
    load().then();
  }, []);
  async function onCustomerSelection(account) {
    const adsProvider: adsProviderT = {
      accessToken: fbLoginResponse.authResponse.accessToken,
      externalAccountDescriptiveName: account.name,
      externalAccountId: account.id,
      externalAccountResourceName: fbLoginResponse.authResponse.userID,
      externalId: fbLoginResponse.authResponse.userID,
      providerType: 'FACEBOOK',
      refreshToken: fbLoginResponse.authResponse.accessToken,
      facebookPages: accounts,
    };

    try {
      updateState((d) => {
        d.loading = true;
        d.error = undefined;
      });

      await createAdsProvider(
        localStorage,
        axios,
        { accountId: props.accountId },
        {
          adsProvider,
        },
      ).then((_) => {
        track(trackedEvents.connectFacebook);
      });
    } finally {
      await updateState((d) => {
        d.loading = false;
      });
    }
  }

  return (
    <ImageOnRightSide
      image={
        <div style={{}}>
          <Image />
        </div>
      }
    >
      <div
        style={{
          flex: '0 0 750px',
          alignItems: 'center',
          display: 'flex',
          textAlign: 'center',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <br />
        <br />
        <br />
        <br />
        <AdsProviderLogo providerType="FACEBOOK" style={{ width: '150px' }} />
        <br />
        <br />
        <br />
        <br />
        {accounts !== undefined && (
          <>
            {accounts.length === 0 && (
              <Alert
                type="warning"
                message={
                  <>
                    <Empty description="" />
                    <br />
                    We can't find any Facebook Business Account link to your Facebook.
                    <br />
                    <br /> You might try with another account.
                    <br />
                    <br />
                    <br />
                    <Button href={`/${props.accountId}/adsprovider/new-facebook-campaign?productId=undefined`}>
                      Back{' '}
                    </Button>
                  </>
                }
              />
            )}
            {accounts.length > 0 && (
              <Card
                style={{ width: '300px' }}
                title={
                  <>
                    We found {accounts.length} accounts <br />
                    on your Facebook
                  </>
                }
              >
                <br />
                <Steps direction="vertical" size="large">
                  {accounts.map((account) => (
                    <Fragment key={account.name}>
                      <Steps.Step status={account.status} title={account.name} />
                    </Fragment>
                  ))}
                </Steps>
              </Card>
            )}
          </>
        )}
        <br />
      </div>
    </ImageOnRightSide>
  );
}

function Image() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="341" height="296" fill="none" viewBox="0 0 341 296">
      <g clipPath="url(#clip0_251_3113)">
        <path
          fill="#F2F2F2"
          d="M341 143.805c0 65.855-57.977 121.367-137.089 138.409a240.771 240.771 0 01-39.027 5.096 252.1 252.1 0 01-12.245.301c-6.03 0-11.988-.215-17.874-.643a239.485 239.485 0 01-9.715-.889c-31.228-3.5-59.965-12.849-84.238-26.546a187.413 187.413 0 01-8.884-5.333 176.801 176.801 0 01-15.306-10.921c-32.423-25.825-5.391-80.95 11.506-115.655C73.737 33.957 48.611 0 152.64 0 256.666 0 341 64.382 341 143.805z"
        ></path>
        <path
          fill="#fff"
          d="M266.448 59.285c-32.333-.58-32.327-48.6.001-49.174 32.332.579 32.327 48.6-.001 49.174z"
        ></path>
        <path
          fill="#3F3D56"
          d="M278.391 27.52a6.539 6.539 0 01-3.952-1.321 6.557 6.557 0 01-2.613-5.18h-4.238v11.583l-.006 6.344a3.836 3.836 0 01-2.635 3.64 3.855 3.855 0 01-1.424.189 3.822 3.822 0 01-1.755-.538 3.836 3.836 0 01-1.875-3.233 3.84 3.84 0 013.844-3.893c.423 0 .83.07 1.21.195v-4.304c-.401-.06-.81-.09-1.222-.09-2.346 0-4.54.975-6.108 2.731a7.955 7.955 0 00-2.006 4.797 7.942 7.942 0 002.366 6.187 8.131 8.131 0 005.748 2.353c.412 0 .821-.03 1.222-.09a8.106 8.106 0 004.526-2.264 7.937 7.937 0 002.38-5.647l-.022-9.474a10.714 10.714 0 006.572 2.233V27.52h-.012z"
        ></path>
        <path
          fill="#fff"
          d="M205.303 130.846c-32.333-.58-32.328-48.6.001-49.175 32.331.58 32.326 48.6-.001 49.175zM269.084 203.512c-32.332-.58-32.327-48.6.001-49.175 32.332.58 32.327 48.6-.001 49.175z"
        ></path>
        <path
          fill="#3F3D56"
          d="M257 180c0-6.617 5.383-12 12-12 2.672 0 5.202.86 7.315 2.486l-2.789 3.623a7.353 7.353 0 00-4.526-1.538c-4.096 0-7.429 3.333-7.429 7.429s3.333 7.429 7.429 7.429c3.299 0 6.103-2.162 7.069-5.143H269v-4.572h12V180c0 6.617-5.383 12-12 12s-12-5.383-12-12z"
        ></path>
        <path
          fill="#E6E6E6"
          d="M266.448 59.285c-32.333-.58-32.327-48.6.001-49.174 32.332.579 32.327 48.6-.001 49.174zm0-47.684c-30.373.544-30.368 45.654.001 46.194 30.372-.545 30.367-45.654-.001-46.194z"
        ></path>
        <path
          fill="#2F2E41"
          d="M124.936 285.737a3.732 3.732 0 01-.15-.711l-9.391-53.511a4.834 4.834 0 00-4.109-4.23l-53.549.233a4.803 4.803 0 00-5.035 2.657 225.535 225.535 0 00-2.559 5.52c-3.38 7.516-6.49 15.462-9.331 23.838 24.273 13.697 53.01 23.046 84.238 26.546l-.114-.342z"
        ></path>
        <path
          fill="#FFB8B8"
          d="M177.209 142.542a11.235 11.235 0 00-.257 1.759l-46.089 26.572-11.203-6.445-11.943 15.625 18.723 13.336a8.59 8.59 0 0010.298-.25l47.526-37.471a11.27 11.27 0 0012.405-3.166 11.257 11.257 0 001.426-12.716 11.26 11.26 0 00-11.396-5.832 11.264 11.264 0 00-9.49 8.588z"
        ></path>
        <path
          fill="#2E89E2"
          d="M130.231 168.218a4.832 4.832 0 00-2.376-3.245l-21.149-11.819a13.416 13.416 0 00-18.783 2.393 13.395 13.395 0 002.342 18.779l16.861 17.498a4.819 4.819 0 003.742 1.472 4.848 4.848 0 001.984-.546 4.846 4.846 0 001.576-1.323l3.283-4.257 1.122-1.449 10.49-13.588a4.733 4.733 0 00.826-1.668 4.797 4.797 0 00.082-2.247z"
        ></path>
        <path
          fill="#FFB8B8"
          d="M99.61 132.18c10.291-10.285 10.291-26.96 0-37.244-10.29-10.285-26.976-10.285-37.267 0-10.29 10.284-10.29 26.959 0 37.244 10.291 10.284 26.976 10.284 37.267 0z"
        ></path>
        <path
          fill="#2E89E2"
          d="M114.296 213.238c1.058-1.582 7.785-12.257 5.24-24.769a27.657 27.657 0 00-.702-2.748 30.687 30.687 0 00-5.642-10.306c.032-.838.023-1.663-.027-2.484v-.005a23.689 23.689 0 00-6.815-15.133 23.713 23.713 0 00-15.077-6.955L61.801 148.6a23.499 23.499 0 00-20.104 8.551 25.318 25.318 0 00-1.555 2.097h-.002l-.002.001v.003a23.43 23.43 0 00-3.038 19.249l.25 1.103 3.143 13.77 5.254 23.041 4.397 19.28.465 2.033a4.837 4.837 0 005.491 3.687l57.028-9.344a4.76 4.76 0 002.203-.966 4.98 4.98 0 00.999-1.071 4.763 4.763 0 00.739-3.724l-2.773-13.072z"
        ></path>
        <path
          fill="#FFB8B8"
          d="M2.062 291.007a11.517 11.517 0 014.582-17.059l32.47-99.769 18.817 8.923-35.527 97.144a11.575 11.575 0 01-5.389 14.41 11.59 11.59 0 01-14.953-3.649z"
        ></path>
        <path
          fill="#2E89E2"
          d="M57.71 150.61a14.368 14.368 0 00-17.568 8.638h-.002l-.002.001v.002l-.001.001c-.119.306-.228.625-.324.944l-2.75 5.83-8.31 17.616a5.16 5.16 0 003.179 7.147l1.62.488 6.94 2.097 12.32 3.719 4.104 1.24a5.164 5.164 0 004.265-.584 5.177 5.177 0 002.344-3.61l3.794-25.725a14.332 14.332 0 00-1.128-10.884 14.35 14.35 0 00-8.481-6.92z"
        ></path>
        <path
          fill="#2F2E41"
          d="M105.174 103.981c-9.39 8.209-23.805 10.115-35.008 4.629 5.033 5.966 14.59 35.419 14.59 35.419s-26.986-2.834-30.799-3.649c-22.519-4.812-14.333-63.097 9.662-62.803 4.064.05 7.617.54 10.322 3.573 1.796-5.03 7.505-7.993 12.834-7.61 5.33.384 10.15 3.583 13.579 7.68a29.378 29.378 0 016.218 24.087l-1.398-1.326z"
        ></path>
        <path
          fill="#E6E6E6"
          d="M205.303 130.846c-32.333-.58-32.328-48.6.001-49.175 32.331.58 32.326 48.6-.001 49.175zm0-47.684c-30.373.544-30.369 45.654.001 46.194 30.372-.545 30.367-45.654-.001-46.194zM269.084 203.512c-32.332-.58-32.327-48.6.001-49.175 32.332.58 32.327 48.6-.001 49.175zm0-47.684c-30.373.544-30.368 45.654.001 46.194 30.372-.545 30.368-45.654-.001-46.194z"
        ></path>
        <path
          fill="#CCC"
          d="M224.671 183.07a.747.747 0 01-.701-.44.74.74 0 01.139-.815l2.482-2.621a.426.426 0 00-.016-.603l-2.637-2.494a.771.771 0 01-.239-.534.759.759 0 01.209-.546.766.766 0 011.077-.033l3.548 3.313a.715.715 0 01.028 1.027l-3.373 3.518a.742.742 0 01-.517.228zM221.961 179.256a.46.46 0 01-.297.12l-8.271.228a.478.478 0 01-.175-.03.467.467 0 01-.294-.413.461.461 0 01.444-.469l8.271-.227a.45.45 0 01.262.074.457.457 0 01.06.717zM160.82 110.404a.747.747 0 01-.701-.44.74.74 0 01.139-.815l2.482-2.621a.426.426 0 00-.016-.603l-2.637-2.494a.771.771 0 01-.239-.534.759.759 0 01.209-.546.766.766 0 011.077-.033l3.548 3.313a.733.733 0 01.181.788.735.735 0 01-.153.239l-3.373 3.518a.744.744 0 01-.517.228zM158.109 106.59a.458.458 0 01-.296.12l-8.271.228a.461.461 0 01-.469-.443.441.441 0 01.125-.326.456.456 0 01.319-.143l8.271-.227a.451.451 0 01.435.284.46.46 0 01-.114.507zM221.935 38.843a.745.745 0 01-.755-.868.74.74 0 01.193-.386l2.482-2.622a.427.427 0 00-.017-.603l-2.637-2.494a.777.777 0 01-.238-.534.757.757 0 01.209-.546.766.766 0 011.077-.033l3.547 3.313a.718.718 0 01.181.788.718.718 0 01-.152.24l-3.373 3.517a.744.744 0 01-.517.228zM219.224 35.029a.454.454 0 01-.296.12l-8.271.228a.455.455 0 11-.025-.911l8.271-.228a.459.459 0 01.435.284.457.457 0 01-.114.507z"
        ></path>
        <path
          fill="#fff"
          d="M213.466 271.147c-2.139 4.44-5.624 8.031-9.555 11.067a240.786 240.786 0 01-39.027 5.096 253.918 253.918 0 01-12.246.301c-6.029 0-11.987-.214-17.874-.643a14.505 14.505 0 01-.091-4.093c.652-5.328 4.698-10.224 9.947-11.354 5.25-1.13 11.234 2.001 12.825 7.124.881-9.882 1.889-20.174 7.147-28.588 4.757-7.616 12.998-13.068 21.933-14.043a27.624 27.624 0 0115.265 2.794c.434.219.862.447 1.286.684a25.474 25.474 0 017.357 6.276c5.573 7.047 6.937 17.293 3.033 25.379z"
        ></path>
        <path
          fill="#F2F2F2"
          d="M203.076 239.492a.687.687 0 01-.283.624 144.457 144.457 0 00-37.909 47.194c-.639.032-1.277.064-1.92.086a145.47 145.47 0 012.527-4.886 146.77 146.77 0 0115.921-23.551 145.098 145.098 0 0120.186-20.033.9.9 0 01.192-.118.932.932 0 01.807.034.937.937 0 01.479.65z"
        ></path>
        <path
          fill="#3F3D56"
          fillRule="evenodd"
          d="M218.859 106.338a13.665 13.665 0 01-11.53 13.497v-9.545h3.183l.607-3.952h-3.79c-.128-3.291-.256-5.137 3.95-4.694v-3.36c-6.299-1.385-8.926 1.805-8.219 8.054h-3.471v3.952h3.471v9.54c-16.337-2.511-14.417-27.211 2.13-27.147a13.67 13.67 0 0112.628 8.429 13.665 13.665 0 011.041 5.226z"
          clipRule="evenodd"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_251_3113">
          <path fill="#fff" d="M0 0H341V296H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}
