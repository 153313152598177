// @flow

import { CAMPAIGN_STATUSES } from 'consts/consts';

export const COLUMN_NAMES = {
  subscriptionStatus: 'Subscription Status',
  googleAdsIds: 'Google Ads IDs',
  activeAndTotalCampaigns: 'Active/Total Campaigns',
  startDate: 'Start Date',
  dailyBudget: 'Daily Budget',
  impressions: 'Impressions',
  clicks: 'Clicks',
  clickThroughRate: 'Click Through Rate',
  cost: 'Cost',
  averageCpc: 'Average CPC',
  addToCarts: 'Add to Carts',
  addToCartRate: 'Add to Cart Rate',
  conversions: 'Conversions',
  conversionRate: 'Conversion Rate',
  unitsSold: 'Units Sold',
  revenue: 'Revenue',
  brandReferralBonus: 'Brand Referral Bonus',
  roas: 'ROAS',
  acos: 'ACOS',
  acosRange: 'ACOS Range',
};

export const DEFAULT_COLUMNS = [
  'subscriptionStatus',
  'googleAdsIds',
  'activeAndTotalCampaigns',
  'startDate',
  'dailyBudget',
  'impressions',
  'clicks',
  'cost',
  'averageCpc',
  'addToCarts',
  'conversions',
  'conversionRate',
  'unitsSold',
  'revenue',
  'acos',
  'acosRange',
];

export const calculatePercentages = (oldValue: number, newValue: number): number => {
  if (oldValue === 0) {
    return 0;
  }

  return ((newValue - oldValue) / oldValue) * 100;
};

export const COLUMNS_WITH_DOLLARS = ['dailyBudget', 'cost', 'averageCpc', 'revenue', 'brandReferralBonus'];

export const COLUMNS_WITH_PERCENTAGES = ['clickThroughRate', 'addToCartRate', 'conversionRate', 'acos', 'roas'];

export const CAMPAIGN_STATUS_OPTIONS = [
  { value: CAMPAIGN_STATUSES.STATUS_ACTIVE, label: 'Active Campaigns' },
  { value: CAMPAIGN_STATUSES.STATUS_PAUSED, label: 'Paused Campaigns' },
  { value: 0, label: 'Active & Paused Campaigns' },
];

export const COLUMNS_WITH_REVERSED_COLORS = ['acos', 'averageCpc'];
