import { type Node } from 'react';

import { Select, Tooltip } from 'antd';
import styled from 'styled-components';

import { strings } from 'locales/strings';

import { MATCH_TYPES } from './constants';

const StyledSelect = styled(Select)`
  &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 36px;
  }
  &.ant-select-single .ant-select-selector .ant-select-selection-item {
    line-height: 36px;
  }
`;

export const MatchTypeSelect: Node = (
  <StyledSelect>
    <Select.Option value={MATCH_TYPES.BROAD}>
      <Tooltip placement="right" title={strings.get('campaigns.matchType.broadDescription')}>
        {strings.get('campaigns.matchType.broad')}
      </Tooltip>
    </Select.Option>
    <Select.Option value={MATCH_TYPES.EXACT}>
      <Tooltip placement="right" title={strings.get('campaigns.matchType.exactDescription')}>
        {strings.get('campaigns.matchType.exact')}
      </Tooltip>
    </Select.Option>
    <Select.Option value={MATCH_TYPES.PHRASE}>
      <Tooltip placement="right" title={strings.get('campaigns.matchType.phraseDescription')}>
        {strings.get('campaigns.matchType.phrase')}
      </Tooltip>
    </Select.Option>
  </StyledSelect>
);
