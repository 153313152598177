import React, { Fragment, useContext, useEffect, useState } from 'react';

import { CaretRightOutlined, PauseOutlined } from '@ant-design/icons';
import { Button, Card, Popconfirm } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useImmer } from 'use-immer';

import DismissableInfo from 'components/common/DismissableInfo';
import { DashboardFilterContext } from 'context/DashboardFilterProvider';
import { useLoading } from 'hooks/useLoading';
import { getAllAdGroupsForCampaign, pauseAdGroup, runAdGroup } from 'lib/adsPlateformApi';
import axios from 'lib/axios.factory';

import { AdGroupsStats } from './AdGroupsStats';
import { AdGroupsTable } from './AdGroupsTable';
import { NewAdGroupButton } from './NewAdGroupButton';
import type { adsCampaignT } from '../../../../../../flow-typed/pixelme/adsPlateform';
import { AdsProviderLogo } from '../../common/AdsProviderLogo';
import { calculateStats } from '../../common/adsStats';
import { DashboardFilter } from '../../common/DashboardFilter';
import { ShowProduct } from '../../common/ShowProduct';
import { HeaderStyle } from '../Style';

export type StateT = {
  adGroups: Array<adGroupT>,
  allAdGroups: Array<adGroupT>,
  products: Array<productT>,
  stats: adsReportDataT,
  campaign: adsCampaignT,
};

const Style = styled.div`
  .ant-table-column-sorters {
    width: 100%;
    justify-content: space-between;
  }
`;

export function AdGroupsDashBoard({
  account,
  campaignId,
  productProviderId,
  productASIN,
}: {
  account: accountT,
  campaignId: string,
  productProviderId: string,
  productASIN: string,
}) {
  const history = useHistory();
  const { ctx, updateCtx } = useContext(DashboardFilterContext);
  const initialState: StateT = {
    stats: {},
    allAdGroups: [],
    adGroups: [],
    campaign: {},
    product: {},
  };
  const [state, updateState] = useImmer(initialState);
  const { doAction, ExclusiveInlineLoadingContainer } = useLoading(true);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onProductSave = (p) => {
    updateState((draft) => {
      draft.product = p;
    });
  };

  async function loadAdGroups() {
    if (campaignId) {
      let from = _.get(ctx, ['adsReportsRange', 0]);

      if (from) {
        from = moment(from).toISOString();
      }

      let to = _.get(ctx, ['adsReportsRange', 1]);

      if (to) {
        to = moment(to).toISOString();
      }

      const adGroupsData = await getAllAdGroupsForCampaign(
        localStorage,
        axios,
        {
          accountId: account.id,
          from,
          to,
        },
        campaignId,
      );

      const { campaign } = adGroupsData;
      const adGroups = adGroupsData.adgroups.map((adgroup) => {
        const adsReport = (adgroup.selectedAdsReports || {}).data;
        const result = { ...adgroup, ...adsReport };

        return result;
      });
      const stats = calculateStats(adGroups);

      updateState((draft) => {
        draft.allAdGroups = adGroups;
        draft.adGroups = adGroups;
        draft.stats = stats;
        draft.campaign = campaign;
        draft.product = adGroupsData.product;
      });

      filterOnSearch(adGroups);

      // Facebook campaigns have no variations so it's not accurate to redirect to variations page
      if (
        window.location.href.includes('skipAdGroup') &&
        adGroupsData.adgroups &&
        adGroupsData.adgroups.length === 1 &&
        adGroupsData.adgroups[0].totalVariationsCount > 0
      ) {
        history.push(
          `/${account.id}/adsprovider/${productProviderId}/${productASIN}/${adGroupsData.adgroups[0].providerType}/campaigns/${adGroupsData.adgroups[0].campaignId}/adgroups/${adGroupsData.adgroups[0].id}/variations`,
        );
      }
    }
  }

  // Empty search when navigate
  useEffect(() => {
    doAction(async () => {
      updateCtx((draft) => {
        draft.searchAdGroup = '';
      });
      await loadAdGroups();
    });
  }, []);

  function filterOnSearch(allAdGroups) {
    let tmpAdGroups = allAdGroups || [];

    if (ctx.searchAdGroup) {
      tmpAdGroups = tmpAdGroups.filter((c) => c.name.toLowerCase().includes(ctx.searchAdGroup));
    }

    const tmpStats = calculateStats(tmpAdGroups);

    updateState((draft) => {
      draft.adGroups = tmpAdGroups;
      draft.stats = tmpStats;
    });
  }

  useEffect(() => {
    filterOnSearch(state.allAdGroups);
  }, [ctx.searchAdGroup]);

  useEffect(() => {
    doAction(async () => {
      await loadAdGroups();
    });
  }, [JSON.stringify(ctx.adsReportsRange), campaignId]);

  return (
    <Style>
      <DismissableInfo
        style={{ margin: '20px 0' }}
        name="shopper-privacy"
        learnMore="https://www.carbon6.io/pixelme-help/why-am-i-seeing-less-purchases-and-add-to-carts-on-my-keywords-than-my-campaign-2"
      >
        <p>
          The data displayed here is limited by Amazon's shopper privacy policy, which only shows purchase data for
          keywords with more than 10 clicks.{' '}
        </p>
        <p>Keyword with less than 10 clicks will be display as zero until the click threshold is reached.</p>
      </DismissableInfo>

      <AdGroupsDashBoardHeader
        account={account}
        productName={_.get(state.allAdGroups, [0, 'productName'])}
        campaign={state.campaign}
        product={state.product}
        productASIN={productASIN}
        productProviderId={productProviderId}
        selectedRowKeys={selectedRowKeys}
        reload={() =>
          doAction(async () => {
            await loadAdGroups();
          })
        }
      />
      <ExclusiveInlineLoadingContainer>
        <AdGroupsStats
          account={account}
          stats={state.stats}
          productASIN={productASIN}
          productProviderId={productProviderId}
        />
        <AdGroupsTable
          account={account}
          product={state.product}
          productASIN={productASIN}
          adGroupType={_.get(state.adGroups, '0.type')}
          adGroups={state.adGroups}
          setSelectedRowKeys={setSelectedRowKeys}
          onProductSave={onProductSave}
          productProviderId={productProviderId}
        />
      </ExclusiveInlineLoadingContainer>
    </Style>
  );
}

function AdGroupsDashBoardHeader({ account, campaign, product, selectedRowKeys, reload }) {
  if (!campaign) {
    return <Fragment />;
  }

  return (
    <HeaderStyle>
      <Card bordered={false}>
        <Card.Meta
          avatar={<AdsProviderLogo style={{ height: 30 }} providerType={campaign.providerType} />}
          title={`Ad Groups: ${campaign.name}`}
        />
      </Card>
      <ShowProduct product={product} />
      {(selectedRowKeys || []).length > 0 && (
        <>
          <Popconfirm
            title="Are you sure to pause all selected adGroups?"
            disabled={(selectedRowKeys || []).length === 0}
            onConfirm={async () => {
              for (const k of selectedRowKeys || []) {
                try {
                  await pauseAdGroup(localStorage, axios, { accountId: account.id }, k);
                } catch (e) {
                  // ignore pause error
                }
              }
              reload();
            }}
          >
            <Button
              style={{
                color: 'rgb(248, 165, 27)',
                borderColor: 'rgb(248, 165, 27)',
              }}
            >
              <PauseOutlined />
            </Button>
          </Popconfirm>
          <Popconfirm
            title="Are you sure to active all selected adGroups?"
            disabled={(selectedRowKeys || []).length === 0}
            onConfirm={async () => {
              for (const k of selectedRowKeys || []) {
                try {
                  await runAdGroup(localStorage, axios, { accountId: account.id }, k);
                } catch (e) {
                  // ignore run error
                }
              }
              reload();
            }}
          >
            <Button
              style={{
                color: 'rgb(82, 196, 26)',
                borderColor: 'rgb(82, 196, 26)',
              }}
            >
              <CaretRightOutlined />
            </Button>
          </Popconfirm>
        </>
      )}
      <DashboardFilter searchAdGroup />
      <NewAdGroupButton account={account} campaign={campaign} />
    </HeaderStyle>
  );
}
