// @flow
import React, { type Node } from 'react';

import styled from 'styled-components';

import { AGENCY_TOOLS, useFeatureFlag } from 'lib/configcat';

import AccountsFilterProvider from './AccountsFilterProvider';
import { AccountsTable } from './AccountsTable';
import { AccountSwitcher } from './AccountSwitcher.jsx';
import { Filters } from './Filters';

const Style = styled.div`
  background-color: white;
  padding: 1em;
  height: 100%;
`;

type propsT = {
  account: accountT,
};
export const AccountsPage = ({ account }: propsT): Node => {
  const [isAgencyToolsEnabled, isFlagLoading] = useFeatureFlag(AGENCY_TOOLS);

  if (isFlagLoading || !isAgencyToolsEnabled) {
    return null;
  }

  return (
    <Style>
      <AccountsFilterProvider>
        <Filters />
        <AccountsTable account={account} />
        <AccountSwitcher />
      </AccountsFilterProvider>
    </Style>
  );
};
