import styled from 'styled-components';

import { strings } from 'locales/strings';

import { CompetitorList } from './CompetitorList';
import { STRATEGIES, STRATEGIES_LABELS } from './constants';
import { KeywordTable } from './KeywordTable';
import { ProductKeyMetrics } from './ProductKeyMetrics';

const Style = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
  margin-bottom: 1.5rem;
`;

const StrategyLabel = styled.p`
  margin-bottom: 0.75rem;
  b {
    font-weight: 600;
  }
`;

const SectionHeader = styled.h2`
  font-weight: 600;
  margin-bottom: 0.75rem;
`;

export const AuditResultsExpanded = ({ auditItem }) => (
  <Style>
    <div>
      {STRATEGIES[auditItem.strategy] && (
        <StrategyLabel>
          {strings.get('asinAudit.product.strategy')}: <b>{STRATEGIES_LABELS[auditItem.strategy]}</b>
        </StrategyLabel>
      )}
      <ProductKeyMetrics auditItem={auditItem} />
    </div>
    {auditItem.keywords?.length > 0 && (
      <div>
        <SectionHeader>{strings.get('asinAudit.topKeywords')}</SectionHeader>
        <KeywordTable keywords={auditItem.keywords} />
      </div>
    )}
    {auditItem.competitors?.length > 0 && (
      <div>
        <SectionHeader>{strings.get('asinAudit.topCompetitors')}</SectionHeader>
        <CompetitorList competitors={auditItem.competitors} />
      </div>
    )}
  </Style>
);
