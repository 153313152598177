import React, { useContext, useMemo } from 'react';

import { Checkbox, Form, Select, Switch, Tag } from 'antd';
import Search from 'antd/es/input/Search';
import _ from 'lodash';
import moment from 'moment';
import styled from 'styled-components';

import { DateRangePicker } from 'components/common/antd/DateRangePicker';
import { AmazonReLoginModal } from 'components/pages/AmazonAttribution/AmazonReLoginModal';
import { DashboardFilterContext, STATUS } from 'context/DashboardFilterProvider';
import { strings } from 'locales/strings';
import { getDefaultRange, DATE_FORMAT } from 'selectors/ranges.selector';

import { AdsProviderLogo } from './AdsProviderLogo';
import { AdsProviderReLoginModal } from './AdsProviderReLoginModal';
import type { adsCampaignT } from '../../../../../flow-typed/pixelme/adsPlateform';
import { ACTIVE_CAMPAIGN_OPTIONS } from '../../../../consts/consts';
import { CheckboxMultiSelect } from '../../../common/antd/CheckboxMultiSelect';
import { ProductTagsSelector } from '../NewCampaign/ProductTagsSelector';

const Style = styled.div`
  display: flex;
  gap: 10px;
  .ant-picker,
  .ant-input {
    min-height: 32px;
  }

  flex: 2 0 400px;
`;

const StyledForm = styled(Form)`
  row-gap: 0.5rem;
`;

const SingleSelect = styled(Select)`
  &.ant-select-single .ant-select-selector .ant-select-selection-item::after {
    content: 'Campaigns';
    margin-left: 0.2rem;
    visibility: visible;
  }
  &.ant-select-single.ant-select-open .ant-select-selection-item {
    color: #071629;
  }
  .ant-select-selection-placeholder {
    font-size: 0.875rem !important;
    font-weight: 400 !important;
  }
`;

const allMarketingChannelsOptions = [
  { value: 'GOOGLE', label: 'Google' },
  { value: 'FACEBOOK', label: 'Facebook' },
  { value: 'TIKTOK', label: 'TikTok' },
  { value: 'GENERIC', label: 'Custom links' },
];

const statusOptions = [
  { value: STATUS.ACTIVE, label: 'Active' },
  { value: STATUS.PAUSED, label: 'Paused' },
  { value: 0, label: 'All' }, // Should omit status filter when value is 0
];

export const CAMPAIGN_OPTIONS = [
  { value: ACTIVE_CAMPAIGN_OPTIONS.ACTIVE_GOOGLE, label: 'Active Google Campaigns' },
  { value: ACTIVE_CAMPAIGN_OPTIONS.ACTIVE_TIKTOK, label: 'Active Tiktok Campaigns' },
  { value: ACTIVE_CAMPAIGN_OPTIONS.ACTIVE_FACEBOOK, label: 'Active Facebook Campaigns' },
  { value: '', label: 'All Campaigns' },
];

export function DashboardFilter({
  tagsFilter,
  activeFilter,
  rankColumns,
  searchProduct,
  searchVariation,
  attributedData = true,
  campaigns = [],
  statusFilter,
  channelsFilter,
  productByActiveCampaignFilter,
}: {
  +tagsFilter?: boolean,
  +activeFilter?: boolean,
  +rankColumns?: boolean,
  +searchProduct?: boolean,
  +searchVariation?: boolean,
  +attributedData?: boolean,
  +campaigns?: Array<adsCampaignT>,
  +statusFilter?: boolean,
  +channelsFilter?: boolean,
  +productByActiveCampaignFilter?: boolean,
}) {
  const { ctx, updateCtx } = useContext(DashboardFilterContext);

  // List of available marketing channel options
  const marketingChannelsOptions = useMemo(() => {
    if (!channelsFilter) {
      return allMarketingChannelsOptions;
    }

    const providerTypes = _.uniq(campaigns.map((c) => c.providerType));

    return allMarketingChannelsOptions.filter((o) => providerTypes.includes(o.value));
  }, [JSON.stringify(campaigns), channelsFilter]);

  // List of sorted campaign names options
  const campaignsOptions = useMemo(
    () => campaigns.map((c) => ({ value: c.id, label: c.name })).sort((a, b) => a.label.localeCompare(b.label)),
    [JSON.stringify(campaigns)],
  );

  return (
    <Style>
      <StyledForm layout="inline">
        <AdsProviderReLoginModal account={ctx.account} />
        <AmazonReLoginModal account={ctx.account} />
        {searchProduct && (
          <Form.Item>
            <Search
              value={ctx.searchProduct}
              placeholder="Search for anything"
              onChange={(e) => {
                const { value } = e.target;

                updateCtx((draft) => {
                  draft.searchProduct = value;
                });
              }}
            />
          </Form.Item>
        )}
        {searchVariation && (
          <Form.Item>
            <Search
              value={ctx.searchVariation}
              placeholder="Search for anything"
              onChange={(e) => {
                const { value } = e.target;

                updateCtx((draft) => {
                  draft.searchVariation = value;
                });
              }}
            />
          </Form.Item>
        )}
        {tagsFilter && (
          <Form.Item>
            <ProductTagsSelector
              account={ctx.account}
              value={ctx.selectedTags}
              maxWidth="211px"
              placeholder={strings.get('productTags.placeholder')}
              useOnlyExistingTags
              onChange={(v) => {
                updateCtx((draft) => {
                  draft.selectedTags = v;
                });
              }}
            />
          </Form.Item>
        )}
        {activeFilter && (
          <Form.Item>
            <Checkbox
              checked={ctx.filterStatus === 4}
              onChange={(v) => {
                const { checked } = v.target;

                updateCtx((draft) => {
                  draft.filterStatus = checked ? 4 : undefined;
                });
              }}
            >
              Only Active
            </Checkbox>
          </Form.Item>
        )}
        {productByActiveCampaignFilter && (
          <Form.Item>
            <Select
              options={CAMPAIGN_OPTIONS}
              placeholder="Filter Products By Active Campaigns"
              style={{ maxWidth: 280, minWidth: 250 }}
              value={ctx.filterProductByActiveCampaign}
              onChange={(v) => {
                updateCtx((draft) => {
                  draft.filterProductByActiveCampaign = v;
                });
              }}
            />
          </Form.Item>
        )}
        {rankColumns && (
          <Form.Item>
            <Checkbox
              checked={ctx.showRank}
              onChange={(v) => {
                updateCtx((draft) => {
                  draft.showRank = v.target.checked;
                });
              }}
            >
              Show Rank
            </Checkbox>
          </Form.Item>
        )}
        <Form.Item>
          <DateRangePicker
            value={ctx.adsReportsRange}
            onChange={(v, s) => {
              updateCtx((draft) => {
                const [start, end] = s;

                draft.adsReportsRange =
                  start && end
                    ? [moment.utc(start, DATE_FORMAT), moment.utc(end, DATE_FORMAT).endOf('day')]
                    : getDefaultRange();
              });
            }}
          />
        </Form.Item>
        {statusFilter && (
          <Form.Item>
            <SingleSelect
              options={statusOptions}
              placeholder="Campaign Status"
              style={{ maxWidth: 211, minWidth: 211 }}
              value={ctx.filterCampaignStatus}
              onChange={(v) => {
                updateCtx((draft) => {
                  draft.filterCampaignStatus = v;
                });
              }}
            />
          </Form.Item>
        )}
        <Form.Item>
          <CheckboxMultiSelect
            options={marketingChannelsOptions}
            name="filterProviderType"
            placeholder="All Marketing Channels"
            style={{ minWidth: 220 }}
            hideTags={false}
            context={DashboardFilterContext}
            tagRender={(props) => {
              const { value } = props;
              const onPreventMouseDown = (event) => {
                event.preventDefault();
                event.stopPropagation();
              };

              return (
                <Tag
                  color={value}
                  onMouseDown={onPreventMouseDown}
                  closable={false}
                  style={{ background: 'none', padding: '0', maxWidth: '45px' }}
                >
                  <AdsProviderLogo
                    providerType={value}
                    style={{
                      height: '22px',
                      marginLeft: '6px',
                      maxHeight: '15px',
                    }}
                  />
                </Tag>
              );
            }}
          />
        </Form.Item>
        {campaigns.length > 0 && (
          <Form.Item>
            <CheckboxMultiSelect
              options={campaignsOptions}
              name="filterCampaignId"
              placeholder="All Campaigns"
              dropdownMatchSelectWidth={false}
              optionFilterProp="label"
              context={DashboardFilterContext}
              tagRender={() => `(${ctx.filterCampaignId.length}) Campaigns Selected`}
            />
          </Form.Item>
        )}
        {attributedData && (
          <Form.Item>
            <Switch
              size="large"
              checkedChildren="Attributed data"
              unCheckedChildren="Live Data"
              account={ctx.account}
              checked={ctx.showAttributedData}
              onChange={(v) => {
                updateCtx((draft) => {
                  draft.showAttributedData = v;
                });
              }}
            />
          </Form.Item>
        )}
      </StyledForm>
    </Style>
  );
}
