import { i18n } from './i18n';

export function get(key: string, variables: Object = {}): string {
  try {
    let result = key.split('.').reduce((o, i) => o[i], i18n(variables));

    if (result === undefined) {
      result = key;
    }

    return result;
  } catch (error) {
    if (error.message.indexOf('Cannot read property') !== -1) {
      return key;
    } else {
      throw error;
    }
  }
}

/** Gets generic error messages based on the error status code */
export function getError(status: string | number, resourceKey?: string): string {
  const key = `error.${status}`;
  const message = get(key, { resource: get(resourceKey) });
  const messageExists = message !== key;

  return messageExists ? message : get('error.apiError');
}

export const strings = {
  get,
  getError,
};
