/**
 * Return true if user is owner
 * @param user
 * @returns {{errorMessage: string, code: string}}
 */
function isOwner(user: userT): boolean {
  if (!user) {
    throw new Error('Missing user');
  }

  return Object.values(user.accountsRights || {}).reduce(
    (prev, accountRight) => prev || accountRight.role === 'owner',
    false,
  );
}

/**
 * Return true if user is owner for current account
 * @param user
 * @param account
 * @returns {any}
 */
function isAccountOwner(user: userT, account: accountT): boolean {
  if (!user) {
    throw new Error('Missing user');
  }

  if (!account) {
    throw new Error('Missing account');
  }

  return (user.accountsRights[account.id] || {}).role === 'owner';
}

/**
 * Return true if user is owner for the first org
 * @param user
 * @returns {any}
 */
function isOrgOwner(user: userT): boolean {
  if (!user) {
    throw new Error('Missing user');
  }

  return Object.values(user.orgsRights || {}).reduce((prev, orgRights) => prev || orgRights.role === 'owner', false);
}
/**
 * Return all owners for a account
 * @param members
 * @param account
 */
function getOwners(members: userT[], account: accountT): userT[] {
  if (!members) {
    throw new Error('Missing members');
  }

  if (!account) {
    throw new Error('Missing account');
  }

  return members.filter((user) => user.accountsRights[account.id].role === 'owner');
}

/**
 * Return first available owner
 * @param members
 * @param account
 * @returns {undefined}
 */
function getFirstOwner(members: userT[], account: accountT): userT {
  if (!members) {
    throw new Error('Missing members');
  }

  if (!account) {
    throw new Error('Missing account');
  }

  const owners = getOwners(members, account);

  return owners.length > 0 ? owners[0] : undefined;
}

export const User = {
  isOwner,
  isOrgOwner,
  isAccountOwner,
  getOwners,
  getFirstOwner,
};
