import React from 'react';

import { message, Popconfirm } from 'antd';

import axios from 'lib/axios.factory';

import type { adsCampaignT } from '../../../../../../flow-typed/pixelme/adsPlateform';
import type { accountT } from '../../../../../../flow-typed/pixelme/types';
import { useLoading } from '../../../../../hooks/useLoading';
import { deleteAdGroup } from '../../../../../lib/adsPlateformApi';
import { Delete } from '../../../../common/icons';
import { IconInsideButton } from '../../../AudienceBuilder/Campaigns/campaignTree/Common';

export function DeleteAdGroup(props: {
  account: accountT,
  adGroups: adGroupT[],
  setAdGroups: (adsCampaignT[]) => void,
  adGroup: adGroupT,
}) {
  const { doAction, ExclusiveInlineLoadingContainer } = useLoading(false);

  return (
    <ExclusiveInlineLoadingContainer>
      <Popconfirm
        title="Are you sure to want to delete"
        onConfirm={async () => {
          doAction(async () => {
            try {
              await deleteAdGroup(localStorage, axios, { accountId: props.account.id }, props.adGroup.id);
              props.setAdGroups(props.adGroups.filter((a) => a.id !== props.adGroup.id));
              message.success('Ad group deleted');
            } catch (e) {
              message.error('Unable to delete ad group');
            }
          });
        }}
      >
        <div>
          <IconInsideButton>
            <Delete />
          </IconInsideButton>
        </div>
      </Popconfirm>
    </ExclusiveInlineLoadingContainer>
  );
}
