import React, { Fragment, useEffect } from 'react';

import { useDetectAdBlock } from 'adblock-detect-react';
import { Alert } from 'antd';
import styled from 'styled-components';

import type { adsProviderT } from '../../../../../flow-typed/pixelme/adsPlateform';
import { trackedEvents } from '../../../../config/trackedEvents.config';
import { track } from '../../../../lib/analytics';
import { LimitedButton } from '../../../common/antd/LimitedButton';
import { AdsProviderLogo } from '../common/AdsProviderLogo';

const ProviderButton = styled(LimitedButton)`
  height: 51px;
  padding: 1em;
  display: flex;
  gap: 1em;
`;

const Style = styled.div`
  a {
    text-decoration: none !important;
  }
  .btn-text {
    width: 180px;
  }
`;

/**
 * Facebook Login Button
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export function FacebookLoginButton(props: {
  account: accountT,
  history: any,
  onConnected: any,
  +adsProvider: adsProviderT,
  +type: string,
  +danger: boolean,
  block: boolean,
}) {
  const adBlockDetected = useDetectAdBlock();

  async function load() {}

  useEffect(() => {
    load().then();
  }, []);

  if (adBlockDetected) {
    return (
      <Alert
        message="We are not able to connect to your Facebook Ads Account if you are using AdBlock,"
        type="error"
        description={
          <Fragment>
            <a
              href="https://help.getadblock.com/support/solutions/articles/6000055743-how-to-disable-adblock-on-specific-sites"
              target="_blank"
              rel="noreferrer"
            >
              How do I turn off my Ad Blocker?
            </a>
          </Fragment>
        }
      ></Alert>
    );
  }

  return (
    <Style>
      <a>
        <ProviderButton
          limitationTest={(limitations, usage) => usage.facebookAdsProvider < limitations.limitFacebookAdsProvider}
          feature="Facebook Ads"
          block={props.block}
          type={props.type}
          danger={props.danger}
          icon={<AdsProviderLogo providerType="FACEBOOK" style={{ height: 22 }} />}
          onClick={() => {
            // eslint-disable-next-line no-undef
            FB.login(
              (response) => {
                if (response.authResponse) {
                  const adsProvider = props.adsProvider || {};

                  localStorage.setItem('adsProvider', JSON.stringify({ ...adsProvider, ...response }));
                  track(trackedEvents.connectGoogle);
                  setTimeout(() => {
                    props.history.push(
                      `/${props.account.id}/adsprovider/facebook/${adsProvider.externalAccountId || ''}`,
                    );
                  }, 500);
                  props.onConnected();
                }
              },
              {
                scope:
                  'ads_management,public_profile,business_management,pages_show_list,read_insights,pages_read_engagement,pages_manage_ads',
                auth_type: 'rerequest',
              },
            );
          }}
        >
          <p className="btn-text">{props.adsProvider ? 'Reconnect Facebook ads' : 'Connect Facebook ads'}</p>
        </ProviderButton>
      </a>
    </Style>
  );
}
