import { useImmer } from 'use-immer';

import axios from 'lib/axios.factory';

import { API, apiCall } from '../../../lib/api';

export function usePage(accountId, handleRefresh) {
  const [state, updateState] = useImmer({
    page: undefined,
    loading: false,
    userNameError: undefined,
  });

  async function savePage(page) {
    if (!page) {
      return;
    }

    try {
      updateState((draft) => {
        draft.loading = true;
      });
      const res = await apiCall({
        localStorage,
        axios,
        secure: true,
        method: API.PAGE.POST.method,
        url: API.PAGE.POST.url,
        data: { page },
        params: { accountId },
      });

      handleRefresh();

      return res;
    } finally {
      updateState((draft) => {
        draft.loading = false;
      });
    }
  }

  async function loadPage() {
    try {
      updateState((draft) => {
        draft.loading = true;
        draft.userNameError = undefined;
      });
      const data = await apiCall({
        localStorage,
        axios,
        secure: true,
        method: API.PAGE.GET.method,
        url: API.PAGE.GET.url,
        params: { accountId },
      });

      updateState((draft) => {
        draft.page = data.page;
      });
    } catch (e) {
      if (e.errorMessage === 'not found') {
        updateState((draft) => {
          draft.userNameError = true;
        });
      }
    } finally {
      updateState((draft) => {
        draft.loading = false;
      });
    }
  }

  return { savePage, loadPage, state, updateState };
}
