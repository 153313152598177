import React, { Fragment, useEffect } from 'react';

import { Redirect, Route, Switch } from 'react-router-dom';

import { AmazonLoginPage } from './AmazonLoginPage';
import './AmazonAttributionContent.css';

import { useImmer } from 'use-immer';

import axios from 'lib/axios.factory';

import { AmazonFAQ } from './AmazonFAQ';
import { AmazonOnBoardingPage } from './AmazonOnBoardingPage';
import { AmazonProductPage } from './AmazonProductPage';
import { AmazonReportTable } from './AmazonReportTable';
import { ProductProviderAccounts } from './ProductProviderAccounts.jsx';
import { getAllProductProviders, getProductProviderSPAStatus } from '../../../lib/productsPlateformApi';
import { SmallPlaceholder } from '../../common/Placeholders';

export function AmazonAttributionContent({ account, match }: { account: accountT, match: Match }) {
  const [state, updateState] = useImmer({
    providers: [],
    loading: true,
    loaded: false,
    spaStep: null,
  });

  const load = async () => {
    updateState((draft) => {
      draft.loaded = false;
      draft.loading = true;
    });

    if (window.location.href.includes('spa=finished')) {
      const spa = await getProductProviderSPAStatus(localStorage, axios, account.productProviders[0].id, {
        accountId: account.id,
      });

      if (spa.connectedAt !== null && spa.errorAt == null) {
        updateState((draft) => {
          draft.spaStep = 'ok';
        });
      } else {
        updateState((draft) => {
          draft.spaStep = 'error';
        });
      }
    }

    const response = await getAllProductProviders(localStorage, axios, {
      accountId: account.id,
    });

    updateState((draft) => {
      draft.providers = response.providers;
      draft.loaded = true;
    });
  };

  useEffect(() => {
    load().finally(
      updateState((draft) => {
        draft.loading = false;
      }),
    );
  }, []);

  if (!state.loaded || state.loading) {
    return <SmallPlaceholder text="We are loading Amazon Data" />;
  }

  if (state.providers && state.providers.length > 0) {
    return (
      <div className="content content--no-padding" style={{ display: 'block' }}>
        <Switch>
          <Route
            path={`${match.url}/attribution`}
            render={(props) => (
              <Fragment>
                <AmazonOnBoardingPage account={account} />
              </Fragment>
            )}
          />
          <Route
            path={`${match.url}/faq`}
            render={(props) => (
              <Fragment>
                <AmazonFAQ />
              </Fragment>
            )}
          />
          <Route
            path={`${match.url}/account`}
            render={(props) => (
              <Fragment>
                <ProductProviderAccounts providers={state.providers} account={account} spaFlowStep={state.spaStep} />
              </Fragment>
            )}
          />
          <Route
            path={`${match.url}/products`}
            render={(props) => (
              <Fragment>
                <AmazonProductPage account={account} />
              </Fragment>
            )}
          />
          <Route
            path={`${match.url}/reports/:productProviderId`}
            render={(props) => (
              <Fragment>
                <AmazonReportTable match={props.match} accountId={account.id} />
              </Fragment>
            )}
          />
          <Redirect to={`${match.url}/attribution`} />
        </Switch>
      </div>
    );
  } else {
    return (
      <div className="content" style={{ display: 'block', backgroundColor: 'white' }}>
        <AmazonLoginPage accountId={account.id} account={account} />
      </div>
    );
  }
}
