import React, { Component } from 'react';

import _ from 'lodash';
import moment from 'moment';
import { DateRangePicker, isInclusivelyBeforeDay } from 'react-dates';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { Actions } from '../../../actions';

import 'react-dates/lib/css/_datepicker.css';
import { getSelectedCampaignStats } from '../../../lib/campaigns';
import { s, sd } from '../../../lib/safe';
import { strings } from '../../../locales/strings';
import { BlueTextButton } from '../../common/buttons/BlueTextButton';
import { RedTextButton } from '../../common/buttons/RedTextButton';
import { Polygon } from '../../common/icons/Polygon';

const Container = styled.label.withConfig({
  displayName: 'ContainerDateRangeFilter ',
})`
  margin-right: 3px;
  position: relative;
  cursor: pointer;
  flex-shrink: 0;
  flex-grow: 0;

  .DateRangePickerInput__withBorder:hover {
    border-color: var(--pixelme-color) !important;
    border-radius: 0.2rem;
  }

  .DateRangePickerInput__withBorder {
    border: var(--input-border);
    border-radius: 0.2rem;
  }

  .DateRangePickerInput {
    padding-right: 23px;
    height: 35px;
    padding-top: 3px;
  }

  .DateInput_input {
    text-align: center;
    padding-top: 9px;
    padding-bottom: 5px;
    font-size: 12px;
    letter-spacing: -0.1px;
  }

  .DateInput_input__small {
    padding-left: 3px;
    padding-right: 3px;
  }
  .DateInput__small {
    width: 45px;
  }

  .DateRangePickerInput_calendarIcon {
    padding: 0;
    margin-top: -2px;
  }

  .DateRangePickerInput_calendarIcon_svg {
    height: 18px;
    width: 18px;
  }

  .DateRangePickerInput_calendarIcon_svg {
    fill: rgb(176, 176, 176);
  }

  *:focus {
    outline: none !important;
  }

  .CalendarMonth_caption strong {
    font-size: var(--text-size) !important;
    width: 100%;
    display: block;
    text-align: center;
    font-weight: normal;
    font-size: var(--small-size) !important;
  }
  .DayPicker_weekHeader {
    margin-top: -10px;
  }
  .DayPickerNavigation_svg__horizontal {
    height: 11px;
    width: 11px;
  }
  .DateInput_input__focused {
    border-bottom: 2px solid var(--pixelme-color);
  }
  .CalendarDay__default {
    font-size: 10px;
  }
  .CalendarDay__selected_span {
    background: var(--pixelme-small-light-color);
    color: white;
    border: 0px;
  }

  .CalendarDay__selected_span:hover {
    background: var(--pixelme-color);
    color: white;
    border-color: var(--pixelme-color);
  }

  .CalendarDay__selected {
    background: var(--pixelme-color);
    color: white;
  }

  .CalendarDay__selected:hover {
    background: var(--pixelme-color);
    color: white;
  }

  .CalendarDay__hovered_span:hover,
  .CalendarDay__hovered_span {
    background: var(--pixelme-light-color);
    border-color: var(--pixelme-light-color);
  }

  .DayPicker > div > div:first-child {
    min-height: 232px;
  }

  .DayPicker__hidden {
    visibility: visible;
  }

  .CalendarMonthGrid__horizontal {
    min-height: 227px;
  }
`;

const DateRangeSelectorsContainer = styled.span.withConfig({
  displayName: 'DateRangeSelectorsContainer',
})`
  display: flex;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 10px;
  user-select: none;
`;

const DateRangeIconContainer = styled.span.withConfig({
  displayName: 'DateRangeIconContainer',
})`
  position: absolute;
  height: 11px;
  width: 11px;
  z-index: 1;
  margin: 12px;
`;
const DateRangeIconContainerRight = styled(DateRangeIconContainer)`
  margin-right: 0;
  right: 8px;
`;
const DateRangeButtonsContainer = styled.span.withConfig({
  displayName: 'DateRangeButtonsContainer',
})`
  display: flex;
  justify-content: space-between;
  padding: 20px;
`;

const DateRangeSelectorContainer = styled.span.withConfig({
  displayName: 'DateRangeSelectorContainer',
})`
  cursor: pointer;
  font-size: 10px;
  color: ${(props) => (props.selected ? 'var(--pixelme-color) !important' : 'inherit')};
`;

const DateRangeSelector = function (props) {
  return <DateRangeSelectorContainer {...props}>{props.children}</DateRangeSelectorContainer>;
};

type internalPropsT = { config: columnsConfigT, selectAction: WorkerAc };
type internalActionsT = { onSelect: Function };
type internalStateT = { config: columnsConfigT };
const defaultState: internalStateT = {};

class AbstractDateRangeFilter extends Component<internalPropsT | internalActionsT, internalStateT> {
  state = defaultState;

  render() {
    const { onChange, onChangeNoUpdate, apply, clear, from, to, loading, focusedInput, stats, toggle } = this.props;
    const now = moment(moment.now());

    now.hour = 0;
    now.minutes = 0;
    now.seconds = 0;
    now.milliseconds = 0;
    const now0 = now.clone().toISOString();
    const now7 = now.clone().add(-7, 'days').toISOString();
    const now14 = now.clone().add(-14, 'days').toISOString();
    const now30 = now.clone().add(-30, 'days').toISOString();
    const now90 = now.clone().add(-90, 'days').toISOString();

    return (
      <>
        {sd(s(stats).nbRedirects, 0) < sd(s(s(window).env).REACT_APP_MAX_REDIRECTS_TO_SORT, 1000) && (
          <Container>
            <DateRangePicker
              daySize={23}
              showDefaultInputIcon
              small={true}
              keepOpenOnDateSelect={true}
              isOutsideRange={(day) => !isInclusivelyBeforeDay(day, moment())}
              renderMonthText={(month) => moment(month).format('MMMM')}
              startDate={from ? moment(from) : undefined} // momentPropTypes.momentObj or null,
              startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
              endDate={to ? moment(to) : undefined} // momentPropTypes.momentObj or null,
              endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
              hideKeyboardShortcutsPanel={true}
              displayFormat="D MMM"
              startDatePlaceholderText="Start"
              endDatePlaceholderText="End"
              onClose={apply}
              onDatesChange={({ startDate, endDate }) =>
                onChangeNoUpdate(startDate ? startDate.toISOString() : '', endDate ? endDate.toISOString() : '')
              } // PropTypes.func.isRequired,
              focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
              onFocusChange={toggle} // PropTypes.func.isRequired,
              renderCalendarInfo={(props) => (
                <>
                  <DateRangeSelectorsContainer>
                    <DateRangeSelector onClick={() => onChange('', '')} selected={!from && !to}>
                      {strings.get('redirect.filter.dates.all')}
                    </DateRangeSelector>
                    <DateRangeSelector onClick={() => onChange(now7, now0)} selected={this.isSelected(now7, now)}>
                      {strings.get('redirect.filter.dates.day7')}
                    </DateRangeSelector>
                    <DateRangeSelector onClick={() => onChange(now14, now0)} selected={this.isSelected(now14, now0)}>
                      {strings.get('redirect.filter.dates.day14')}
                    </DateRangeSelector>
                    <DateRangeSelector onClick={() => onChange(now30, now0)} selected={this.isSelected(now30, now0)}>
                      {strings.get('redirect.filter.dates.day30')}
                    </DateRangeSelector>
                    <DateRangeSelector onClick={() => onChange(now90, now0)} selected={this.isSelected(now90, now0)}>
                      {strings.get('redirect.filter.dates.day90')}
                    </DateRangeSelector>
                  </DateRangeSelectorsContainer>
                  <DateRangeButtonsContainer>
                    <RedTextButton disabled={loading} onClick={clear}>
                      Clear
                    </RedTextButton>
                    <BlueTextButton
                      disabled={loading}
                      onClick={() => {
                        this.setState({
                          ...this.state,
                          focusedInput: undefined,
                        });
                        apply();
                      }}
                    >
                      Apply
                    </BlueTextButton>
                  </DateRangeButtonsContainer>
                </>
              )}
            />
            <DateRangeIconContainerRight>
              <Polygon />
            </DateRangeIconContainerRight>
          </Container>
        )}
      </>
    );
  }

  isSelected(fromToApply, toToApply) {
    const { from, to } = this.props;

    if (!from && !to) {
      return false;
    }

    const _from = new Date(from).setHours(0, 0, 0, 0);
    const _fromToApply = new Date(fromToApply).setHours(0, 0, 0, 0);
    const _to = new Date(to).setHours(0, 0, 0, 0);
    const _toToApply = new Date(toToApply).setHours(0, 0, 0, 0);

    return _from === _fromToApply && _to === _toToApply;
  }
}

// noinspection JSUnusedLocalSymbols
const stateToProps = (state: stateT) => ({
  from: state.abRedirects.filter.from,
  to: state.abRedirects.filter.to,
  loading: state.front.campaignColumnsConfig,
  focusedInput: state.front.dateFilterConfig.focusedInput,
  stats: getSelectedCampaignStats(state),
});

// noinspection JSUnusedGlobalSymbols
const dispatchToProps = (dispatch: Dispatch<*>, ownProps: Object) => ({
  onChange: (from, to) => {
    dispatch(Actions.front.audienceBuilder.redirect.filter.update.request({ from, to })).then(() =>
      dispatch(Actions.api.audienceBuilder.redirect.load.request()),
    );
  },
  onChangeNoUpdate: (from, to) => {
    dispatch(Actions.front.audienceBuilder.redirect.filter.update.request({ from, to }));
  },
  clear: () => {
    dispatch(Actions.api.audienceBuilder.redirect.search.request({ from: '', to: '' }));
  },
  apply: () => {
    dispatch(Actions.api.audienceBuilder.redirect.load.request());
  },
  toggle: (focusedInput) => {
    dispatch(
      Actions.front.audienceBuilder.redirect.filter.date.toggle.request({
        focusedInput,
      }),
    );
  },
});

// noinspection JSUnusedLocalSymbols
const areStatesEqual = (prev: stateT, next: stateT) =>
  _.isEqual(prev.abRedirects.filter.from, next.abRedirects.filter.from) &&
  _.isEqual(prev.abRedirects.filter.to, next.abRedirects.filter.to) &&
  _.isEqual(prev.front.campaignColumnsConfig, next.front.campaignColumnsConfig) &&
  _.isEqual(prev.front.dateFilterConfig, next.front.dateFilterConfig);

const reduxWrapper = connect(stateToProps, dispatchToProps, null, {
  areStatesEqual,
});

export const DateRangeFilter = reduxWrapper(AbstractDateRangeFilter);
