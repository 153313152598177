import React from 'react';

import { Button, Statistic } from 'antd';

import { useDataProviderModal } from './useDataProviderModal';

export const DataProviderStats = ({ account }) => {
  const [openModal, isAllowed] = useDataProviderModal({ account });

  if (!isAllowed) {
    return <></>;
  }

  return (
    <Statistic
      title="Data Providers"
      valueRender={() => (
        <Button
          type="link"
          onClick={openModal}
          style={{
            paddingBottom: 0,
            marginBottom: -5,
          }}
        >
          View last update
        </Button>
      )}
    />
  );
};
