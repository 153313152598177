import React from 'react';

import { Row } from 'antd';
import _ from 'lodash';
import styled from 'styled-components';

import { getCurrency } from 'utils/currency';

import { DataProviderStats } from '../DataProvider/DataProviderStats';
import {
  ACOSStats,
  AddToCardStats,
  ClicksStats,
  ImpressionsStats,
  NetMarginStats,
  PurchasesStats,
  ReferralBonusStats,
  RevenueStats,
  ROASStats,
  TotalCostStats,
} from '../Stats';

const Style = styled.div``;

export function AdGroupsStats({
  stats,
  account,
  productASIN,
  productProviderId,
}: {
  stats: adsReportDataT,
  account: accountT,
  productASIN: string,
  productProviderId: string,
}) {
  const { currencyCode, currencySymbol } = getCurrency(account);

  return (
    <Style>
      <Row
        gutter={24}
        style={{
          width: '100%',
          padding: '1em',
          flexWrap: 'nowrap',
          maxWidth: '100%',
          overflow: 'auto',
          height: '120px',
        }}
      >
        <ImpressionsStats impressions={stats.impressions || 0} />
        <ClicksStats clicks={stats.clicks || 0} />
        <TotalCostStats currency={currencySymbol} cost={stats.adSpent || 0} />
        <AddToCardStats adds={stats.amazonTotalAddToCart || 0} />
        <PurchasesStats purchases={stats.amazonTotalPurchases || 0} />
        <RevenueStats currency={currencySymbol} revenue={stats.amazonTotalSales || 0} />
        <NetMarginStats currency={currencySymbol} netMargin={stats.netMargin || 0} />
        <ROASStats roas={stats.roas || 0} />
        <ACOSStats acos={stats.acos || 0} />
        {stats.amazonTotalSales > 0 && (
          <ReferralBonusStats
            currencySymbol={currencySymbol}
            currencyCode={currencyCode}
            brbBonusAmount={stats.brbBonusAmount || 0}
            brbBonusAmountCalculated={stats.brbBonusAmountCalculated || 0}
            brbEnrolled={_.get(
              account.productProviders.find((p) => p.id === productProviderId),
              'brbEnrolled',
            )}
          />
        )}
        <DataProviderStats account={account} />
      </Row>
    </Style>
  );
}
