import React from 'react';

import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Card, Modal } from 'antd';
import { connect } from 'react-redux';

import { Amazon } from '../../../common/icons/Amazon';
import { AddANewButton } from '../../AdsProviders/common/AddANewButton';
import { AmazonLoginButton } from '../AmazonLoginPage';

const { confirm } = Modal;

export function NewProductProviderButtonA({ account, limitations, usage, limitationTest }) {
  if (limitationTest(limitations, usage)) {
    return (
      <AddANewButton
        onClick={() => {
          confirm({
            okButtonProps: { disabled: true, style: { display: 'none' } },
            title: 'Add a new provider',
            icon: <ExclamationCircleOutlined />,
            content: (
              <Card
                bodyStyle={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                title={
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '2em',
                      width: '100%',
                    }}
                  >
                    <Amazon />
                    Amazon
                  </div>
                }
              >
                <AmazonLoginButton account={account}></AmazonLoginButton>
              </Card>
            ),
          });
        }}
      >
        + Add a new ad platform
      </AddANewButton>
    );
  }

  return '';
}

const stateToProps = function (state): any {
  return {
    limitations: state.accounts.limitations,
    usage: state.accounts.usage,
  };
};

export const NewProductProviderButton = connect(stateToProps)(NewProductProviderButtonA);
