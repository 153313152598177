import React, { Fragment, useRef, useState } from 'react';

import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Form, Input, message, Modal, notification } from 'antd';
import deepmerge from 'deepmerge';
import _ from 'lodash';
import qs from 'query-string';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import axios from 'lib/axios.factory';

import { trackedEvents } from '../../../../config/trackedEvents.config';
import { overwriteMerge, useAdsCampaign } from '../../../../hooks/useAdsCampaign';
import { saveCampaignOnProductAndProvider } from '../../../../lib/adsPlateformApi';
import { generateDefaultCampaignName } from '../../../../lib/adsProvider/common';
import { track } from '../../../../lib/analytics';
import { MutedText } from '../../../common/MutedText';
import { SmallPlaceholder } from '../../../common/Placeholders';
import { PrettyPrintJson } from '../../../common/PrettyPrintJson';
import { AddDomainButton } from '../common/AddDomainButton';
import { AddPixelButton } from '../common/AddPixelButton';
import { Wizard } from '../common/Wizard';
import { AdsProviderSelector } from '../NewCampaign/AdsProviderSelector';
import { DeeplinkToggle } from '../NewCampaign/DeeplinkToggle';
import { DomainSelector } from '../NewCampaign/DomainSelector';
import { PixelSelector } from '../NewCampaign/PixelSelector';
import { ProductSelector } from '../NewCampaign/ProductSelector';

const Style = styled.div`
  min-width: 100%;
  min-height: 100%;
  padding: 1em;
  background: #f2f2f2;
  overflow-x: hidden;

  .ant-tag {
    height: 28px;
    display: flex;
    align-items: center;
  }

  h3 {
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    color: #595959;
    text-decoration: underline;
  }

  /* Keywords height fix*/

  input,
  .ant-form-item-control-input-content {
    height: 100%;
  }
`;

const { confirm } = Modal;

const initialValues = {};

export function GenericAdsProviderNewAdsCampaignPage(props: {
  account: accountT,
  campaignId: string,
  productId: string,
}) {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const wizardRef = useRef();
  const { campaign, setCampaign } = useAdsCampaign(props.campaignId, props.account.id, initialValues, 'GENERIC');

  const stepChanged = (form) => async (values) => {
    if (values.adsProvider) {
      values.providerId = values.adsProvider.id;
      values.providerType = values.adsProvider.providerType;
      delete values.adsProvider;
    }

    if (values.product) {
      values.suggestedKeywords = values.product.suggestedKeywords;
      values.productId = values.product.id;
      values.productExternalId = values.product.externalId;
      values.productUrl = values.product.productUrl;

      const name = generateDefaultCampaignName(values.product);

      _.set(values, ['name'], name);
    }

    const newCampaign = deepmerge(campaign, values, {
      arrayMerge: overwriteMerge,
      clone: true,
    });

    setCampaign(newCampaign);

    return newCampaign;
  };

  async function saveCampaign(values) {
    setLoading(true);

    if (!values.product) {
      message.error('Missing Product');

      return;
    }

    const newValues = { ...values };

    try {
      const newCampaign = await saveCampaignOnProductAndProvider(
        localStorage,
        axios,
        { accountId: props.account.id },
        {
          campaign: newValues,
        },
        values.product.providerId,
        values.product.externalId,
      );

      track(trackedEvents.addCampaign, { campaign: newValues });

      const redirectUrl = qs.stringifyUrl({
        url: `/${props.account.id}/adsprovider/new-generic-campaign-success/${newCampaign.campaign.id}`,
        query: {
          productProviderId: values.product.providerId,
          productExternalId: values.product.externalId,
        },
      });

      history.push(redirectUrl);
      setLoading(false);
    } catch (e) {
      setLoading(false);

      if (e.response && e.response.status === 409) {
        confirm({
          okText: 'Ok',
          cancelButtonProps: { disabled: true },
          title: 'Campaign already exists',
          icon: <ExclamationCircleOutlined />,
          content: (
            <MutedText>
              Campaign {newValues.name} already exists! <br />
              Please change campaign name
            </MutedText>
          ),
          afterClose: () => setTimeout(() => wizardRef.current.forceStep(1)),
        });

        return;
      }

      const errorMessage = _.get(e, 'errorMessage');

      if (errorMessage) {
        notification.error({
          style: { width: 600 },
          duration: 30,
          message: errorMessage
            .replace('Error during querying (status):', '')
            .replaceAll(/Error(((?!message).)|\n)*message:/g, ''),
        });
      }

      throw e;
    }
  }

  const [form1] = Form.useForm();
  const [form2] = Form.useForm();

  const campaignRequirementsOk = campaign && campaign.providerId && campaign.productId;

  function RequirementsOk({ children }) {
    return <Fragment>{campaignRequirementsOk && children()}</Fragment>;
  }

  let wizardSteps = [];

  if (campaign) {
    wizardSteps = [
      {
        title: 'Product',
        form: form1,
        onChange: stepChanged(form1),
        content: (
          <Form
            initialValues={campaign}
            onValuesChange={stepChanged(form1)}
            requiredMark="optional"
            form={form1}
            style={{ height: 'calc( 100% - 50px)' }}
            layout="vertical"
          >
            <div style={{ width: 589 }}>
              <AdsProviderSelector account={props.account} providerType="GENERIC" form={form1} />
              <ProductSelector
                account={props.account}
                refreshWhenThisValueChange={props.productId}
                productId={props.productId}
              />
            </div>
          </Form>
        ),
      },
      {
        title: 'Campaign settings',
        form: form2,
        onChange: stepChanged(form2),
        content: <RequirementsOk>{CAMPAIGN_SETTINGS_FORM}</RequirementsOk>,
      },
    ];
  }

  function CAMPAIGN_SETTINGS_FORM() {
    return (
      <Form
        initialValues={campaign}
        requiredMark="optional"
        form={form2}
        style={{ height: 'calc( 100% - 50px)' }}
        layout="vertical"
      >
        <div style={{ width: 500 }}>
          <Form.Item required name="name" label="Campaign Name" rules={[{ required: true, type: 'string' }]}>
            <Input size="large" placeholder="[Batterie acquisition] - Summer 2021"></Input>
          </Form.Item>
          {/*
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ display: "block" }}>
              <Form.Item
                name={["genericCampaign", "start"]}
                label={"Start date"}
              >
                <InputDateWithDisabledRange
                  startDate={new Date()}
                  placeholder={"Default Today"}
                  endDate={new Date(8640000000000000)}
                />
              </Form.Item>
            </div>
            <div style={{ display: "block" }}>
              <Form.Item
                tooltip={
                  <MutedText>
                    Your ads will continue to run
                    <br /> unless you specify an end date.
                  </MutedText>
                }
                name={["genericCampaign", "end"]}
                label={"End date"}
              >
                <InputDateWithDisabledRange
                  startDate={new Date()}
                  endDate={new Date(8640000000000000)}
                />
              </Form.Item>
            </div>
          </div>
          <Form.Item
            name={["genericCampaign", "budgetMicros"]}
            label={"Daily budget"}
            rules={[{ required: false, type: "number", min: 1 }]}
          >
            <CurrencyInput
              currencyCode={currencyCode}
              unit={1000000}
            ></CurrencyInput>
          </Form.Item>
          */}
          <PixelSelector account={props.account}></PixelSelector>
          <AddPixelButton accountId={props.account.id} />
          <DomainSelector account={props.account} />
          <AddDomainButton accountId={props.account.id} />
          <DeeplinkToggle account={props.account} />
        </div>
      </Form>
    );
  }

  if (loading) {
    return <SmallPlaceholder text="We are creating your campaign, please wait a few seconds" />;
  }

  return (
    <Style>
      <Wizard
        width="389px"
        steps={wizardSteps}
        currentStep={0}
        tRef={wizardRef}
        stepsTitle="Campaign resume"
        okText="Launch"
        previousText="Back"
        nextText="Next"
        loading={loading}
        onComplete={async () => {
          const values = await form2.validateFields();

          await saveCampaign({ ...campaign, ...values });
        }}
      />
      <div style={{ maxWidth: 400 }}>
        <PrettyPrintJson hide={true} data={campaign} />
      </div>
    </Style>
  );
}
