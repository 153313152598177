import React, { type Node } from 'react';

import { Form, InputNumber, Switch } from 'antd';
import styled from 'styled-components';

import { CurrencyInput } from 'components/common/antd/inputs/CurrencyInput';
import { strings } from 'locales/strings';

import { AutomationRule, OPTIONS } from './AutomationRule';
import { AutomationRuleOption } from './AutomationRuleOption';

const Container = styled.div`
  display: grid;
  row-gap: 1.5rem;

  .ant-form-item {
    margin-bottom: 0;
  }

  .ant-input-number,
  .ant-input-number-affix-wrapper {
    &,
    div {
      height: 100%;
    }
  }
`;

const StyledInputNumber = styled(InputNumber).attrs({ controls: false })`
  width: 110px;

  input {
    outline: none;
    text-align: right;

    &.ant-input-number-input {
      padding-right: 11px;
    }
  }
`;

const Highlight = styled.p`
  font-weight: 600;
`;

const defaultInitialValues = {
  automationRules: {
    keywordPauser: {
      enabled: OPTIONS.DEFAULT.value,
      maxAllowedAcosPerKw: 0,
      reviewPausedKeywords: true,
      maxAdSpendPerKw: 0,
      guaranteedActiveKwCount: 0,
    },
    automatedBidding: {
      enabled: OPTIONS.DEFAULT.value,
    },
  },
};

export const getAutomationsPayload = (values) => {
  const automationRules = {};

  for (const [key, value] of Object.entries(values.automationRules)) {
    automationRules[key] = value.enabled === OPTIONS.DEFAULT.value ? null : { ...value };
  }

  if (automationRules.keywordPauser) {
    const { maxAdSpendPerKw } = automationRules.keywordPauser;

    automationRules.keywordPauser.maxAdSpendPerKw = Math.round(maxAdSpendPerKw);
  }

  return { automationRules };
};

const getInitialFormValues = (initialValues) => {
  if (initialValues?.automationRules) {
    const automationRules = {};

    for (const [key, value] of Object.entries(defaultInitialValues.automationRules)) {
      automationRules[key] = initialValues.automationRules[key] ?? value;
    }

    return { automationRules };
  }

  return defaultInitialValues;
};

type propsT = {
  currencyCode: string,
  initialValues?: {
    automationRules: automationRulesT,
  },
  onFinish: (automationRulesT) => void,
};

export const CampaignAutomationsForm = ({ currencyCode, initialValues, onFinish, form }: propsT): Node => {
  const keywordPauserEnabled =
    Form.useWatch(['automationRules', 'keywordPauser', 'enabled'], form) === OPTIONS.ENABLED.value;
  const initialFormValues = getInitialFormValues(initialValues);

  const handleFinish = (values) => onFinish(getAutomationsPayload(values));

  return (
    <Form form={form} initialValues={initialFormValues} onFinish={handleFinish} requiredMark={false} colon={false}>
      <Container>
        <div>
          <p>{strings.get('campaignAutomations.description')}</p>
          <Highlight>{strings.get('campaignAutomations.descriptionHighlight')}</Highlight>
        </div>

        {/* maxAllowedAcosPerKw and maxAdSpendPerKw are strictly > 0 only when keywordPauser is enabled */}
        <AutomationRule
          name={['automationRules', 'keywordPauser', 'enabled']}
          title={strings.get('campaignAutomations.keywordPauser.title')}
          description={strings.get('campaignAutomations.keywordPauser.description')}
        >
          <AutomationRuleOption
            description={strings.get('campaignAutomations.keywordPauser.maxAllowedAcosPerKw.description')}
          >
            <Form.Item
              name={['automationRules', 'keywordPauser', 'maxAllowedAcosPerKw']}
              label={strings.get('campaignAutomations.keywordPauser.maxAllowedAcosPerKw.title')}
              rules={[
                {
                  required: true,
                  type: 'number',
                  min: keywordPauserEnabled ? 1 : 0,
                  message: 'Please enter a value > 0',
                },
              ]}
            >
              <StyledInputNumber prefix="%" min={0} precision={0} disabled={!keywordPauserEnabled} />
            </Form.Item>
          </AutomationRuleOption>
          <AutomationRuleOption
            description={strings.get('campaignAutomations.keywordPauser.reviewPausedKeywords.description')}
          >
            <Form.Item
              name={['automationRules', 'keywordPauser', 'reviewPausedKeywords']}
              label={strings.get('campaignAutomations.keywordPauser.reviewPausedKeywords.title')}
            >
              <Switch disabled checked />
            </Form.Item>
          </AutomationRuleOption>
          <AutomationRuleOption
            description={strings.get('campaignAutomations.keywordPauser.maxAdSpendPerKw.description')}
          >
            <Form.Item
              name={['automationRules', 'keywordPauser', 'maxAdSpendPerKw']}
              label={strings.get('campaignAutomations.keywordPauser.maxAdSpendPerKw.title')}
              rules={[
                {
                  required: true,
                  type: 'number',
                  min: keywordPauserEnabled ? 1 : 0,
                  message: 'Please enter a value > 0',
                },
              ]}
            >
              <CurrencyInput
                currencyCode={currencyCode}
                unit={100}
                precision={2}
                size="middle"
                disabled={!keywordPauserEnabled}
              />
            </Form.Item>
          </AutomationRuleOption>
          <AutomationRuleOption
            description={strings.get('campaignAutomations.keywordPauser.guaranteedActiveKwCount.description')}
          >
            <Form.Item
              name={['automationRules', 'keywordPauser', 'guaranteedActiveKwCount']}
              label={strings.get('campaignAutomations.keywordPauser.guaranteedActiveKwCount.title')}
              rules={[{ required: true, message: 'Please enter a value ≥ 0' }]}
            >
              <StyledInputNumber min={0} precision={0} disabled={!keywordPauserEnabled} />
            </Form.Item>
          </AutomationRuleOption>
        </AutomationRule>

        <AutomationRule
          name={['automationRules', 'automatedBidding', 'enabled']}
          title={strings.get('campaignAutomations.automatedBidding.title')}
          description={strings.get('campaignAutomations.automatedBidding.description')}
        />
      </Container>
    </Form>
  );
};

export { OPTIONS };
