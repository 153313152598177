import React, { useEffect, useState } from 'react';

import { DoubleLeftOutlined } from '@ant-design/icons';
import { Drawer } from 'antd';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { AdGroupsDashBoard } from './AdGroupsTable/AdGroupsDashBoard';
import { CampaignsDashBoard } from './CampaignsTable/CampaignsDashBoard';
import { ProductsDashBoard } from './ProductsTable/ProductsDashBoard';
import { VariationsDashBoard } from './VariationsTable/VariationsDashBoard';
import { RoundWithIcon } from '../../../common/buttons/RoundWithIcon';

const Style = styled.div`
  height: 100%;
  background-color: white;
  // remove spce on top of drawer
  .ant-drawer-header-close-only {
    position: absolute;
    background-color: #fff;
    padding: 0;
    padding-top: 0.75rem;

    button.ant-drawer-close {
      line-height: 1.25rem;
      font-size: 1.25rem;
      padding: 0.75rem;
      margin: 0;
    }
  }
`;

export function AdsProviderDashBoardPage(props: { account: accountT, match: any }) {
  const history = useHistory();
  const [adsProviderType, setAdsProviderType] = useState(props.match.params.adsProviderType);
  const [productProviderId, setProductProviderId] = useState(props.match.params.providerId);
  const [productASIN, setProductASIN] = useState(props.match.params.productASIN);
  const [campaignId, setCampaignId] = useState(props.match.params.campaignId);
  const [adGroupId, setAdGroupId] = useState(props.match.params.adGroupId);

  useEffect(() => {
    if (adsProviderType !== props.match.params.adsProviderType) {
      setAdsProviderType(props.match.params.adsProviderType);
    }

    if (productProviderId !== props.match.params.providerId) {
      setProductProviderId(props.match.params.providerId);
    }

    if (productASIN !== props.match.params.productASIN) {
      setProductASIN(props.match.params.productASIN);
    }

    if (campaignId !== props.match.params.campaignId) {
      setCampaignId(props.match.params.campaignId);
    }

    if (adGroupId !== props.match.params.adGroupId) {
      setAdGroupId(props.match.params.adGroupId);
    }
  }, [props.match.params]);

  return (
    <Style>
      <ProductsDashBoard account={props.account} productProviderId={productProviderId} />
      <Drawer
        visible={!!productASIN}
        width=" calc(100vw - 90px)"
        bodyStyle={{ backgroundColor: '#eee' }}
        getContainer={false}
        onClose={() => {
          setProductASIN(undefined);
          window.history.pushState(null, '', `/${props.account.id}/adsprovider`);
        }}
      >
        {productASIN && (
          <CampaignsDashBoard account={props.account} productProviderId={productProviderId} productASIN={productASIN} />
        )}
      </Drawer>
      <Drawer
        visible={!!campaignId}
        width=" calc(100vw - 90px)"
        getContainer={false}
        onClose={() => {
          setCampaignId(undefined);
          window.history.pushState(null, '', `/${props.account.id}/adsprovider`);
        }}
      >
        {campaignId && (
          <AdGroupsDashBoard
            account={props.account}
            campaignId={campaignId}
            productProviderId={productProviderId}
            productASIN={productASIN}
          />
        )}
      </Drawer>

      {adGroupId && (
        <div style={{ position: 'fixed', top: '5px', marginLeft: '15px', zIndex: 2147483647 }}>
          <RoundWithIcon
            onClick={() => {
              setAdGroupId(undefined);
              setCampaignId(undefined);
            }}
          >
            <DoubleLeftOutlined style={{ marginLeft: '-2px' }} />
          </RoundWithIcon>
        </div>
      )}
      <Drawer
        visible={!!adGroupId}
        width=" calc(100vw - 110px)"
        getContainer={false}
        onClose={() => {
          setAdGroupId(undefined);
          window.history.pushState(
            null,
            '',
            `/${props.account.id}/adsprovider/${productProviderId}/${productASIN}/${adsProviderType}/campaigns/${campaignId}/adgroups`,
          );
        }}
      >
        {adGroupId && (
          <VariationsDashBoard
            account={props.account}
            match={props.match}
            adGroupId={adGroupId}
            productProviderId={productProviderId}
            productASIN={productASIN}
          />
        )}
      </Drawer>
    </Style>
  );
}
