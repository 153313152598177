import React from 'react';

import { useImmer } from 'use-immer';

import Button from '../../common/Button';

import { track } from 'lib/analytics';

import { trackedEvents } from '../../../config/trackedEvents.config';
import './UsernameEditor.css';
import editorPicture from '../../../images/username-editor/editor-picture.svg';

export function UsernameEditor({ savePage, loadPage }) {
  const [userNameState, updateState] = useImmer({ username: '' });

  async function createPage() {
    try {
      await savePage({ username: userNameState.username });
      track(trackedEvents.addUsername, { username: userNameState.username });
      await loadPage();
    } catch (e) {
      console.error(e);

      if (e.errorMessage === 'alreadyExist') {
      }
    }
  }

  return (
    <div className="username-edit__main_container">
      <div className="username-edit__input_container">
        <h1>Welcome to PixelMe One Link!</h1>
        <h2>PixelMe One-link is the best way to build a micro-website in a few minutes without coding</h2>
        <p>Choose the URL of your micro landing page</p>
        <div style={{ marginTop: '0.5rem' }}>
          <div className="user-edit__input">
            <span>https://pxlme.co/</span>
            <input
              title="Eight or more characters"
              name="username"
              placeholder="yournamehere"
              onChange={(event) => {
                if (event.target) {
                  const v = event.target.value.replace(/[^a-zA-Z\d\s_]/, '');

                  updateState((draft) => {
                    draft.username = v.trim();
                  });
                }
              }}
              value={userNameState.username}
            />
          </div>
        </div>
        <div style={{ marginTop: '1.5rem' }}>
          <Button style={{ width: '145px' }} handleClick={createPage}>
            Create my page
          </Button>
        </div>
      </div>
      <div className="username-edit__picture_container">
        <img alt="editorpicture" src={editorPicture} />
      </div>
    </div>
  );
}
