import React, { useEffect } from 'react';

import { navigate } from 'hookrouter';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useImmer } from 'use-immer';

import axios from 'lib/axios.factory';

import { EasySteps } from './components/EasySteps';
import { EasyContainer } from './EsayContainer';
// @ts-ignore
import { IncreaseCtrForm } from './IncreaseCtrForm';
import { hasExpired } from './utils/links.utils';
import { getRedirectsByIds } from '../../../lib/api';
import { sd } from '../../../lib/safe';

// @ts-ignore

const Style = styled.div`
  .ant-select-selection-item {
    font-weight: 400;
  }
`;

export function IncreaseCtr({ redirectId }: { redirectId: string }) {
  const [state, updateState] = useImmer({ redirect: { id: redirectId } });

  const account = useSelector((states) => {
    const acc = sd(states.accounts.accounts, [])[0];

    return acc;
  });

  useEffect(() => {
    async function loadRedirect(redirectId) {
      const { redirects } = await getRedirectsByIds(localStorage, axios, {
        accountId: account.id,
        redirectsIds: [redirectId],
      });

      if (hasExpired(_.get(redirects, '0'))) {
        navigate(`/easy/link-expired/${redirects[0].id}`);
      }

      updateState((draft) => {
        draft.redirect = redirects[0];
        draft.pixel = redirects[0].pixels[0];
        draft.providers = account.providers.map(({ id, name }) => ({
          id,
          value: name,
        }));
      });
    }

    if (redirectId) {
      loadRedirect(redirectId).then();
    }
  }, [redirectId, JSON.stringify(account)]);

  if (!account) {
    return <>Loading</>;
  }

  return (
    <EasyContainer
      title="Drive 34% more clicks with branded domain & customized slug "
      description={
        <>
          PixelMe is an <b>URL shortener</b> that embed retargeting pixels from Facebook, Adwords, Linkedin and more
          into shortened links. <br />
          Brand, track & share your own <b>branded short URLs</b> and retarget anyone who clicked on your links!
        </>
      }
    >
      <Style>
        <EasySteps current={1} />
        <IncreaseCtrForm account={account} redirectId={redirectId} updateState={updateState} state={state} />
      </Style>
    </EasyContainer>
  );
}
