import { useState } from 'react';

import { Spinner, SpinnerSize } from '@blueprintjs/core';
import { useHistory } from 'react-router-dom';
import { useToggle } from 'react-use';

import { strings } from 'locales/strings';
import { useGetAuditsQuery } from 'stores/auditsSlice';

import { AuditHistoryTable } from './AuditHistoryTable';
import { CONTAINER_PADDING } from './constants';
import { NewAuditView } from './NewAuditView';

export const AuditHistoryView = () => {
  const [currentAudit, setCurrentAudit] = useState({});
  const [isEditing, toggleEditing] = useToggle(false);
  const history = useHistory();
  const { data: historyData = [], error, isLoading: historyLoading } = useGetAuditsQuery();

  if (isEditing) {
    return (
      <NewAuditView
        audit={currentAudit}
        backText={strings.get('asinAudit.auditHistory.back')}
        onBack={() => {
          toggleEditing();
        }}
      />
    );
  }

  return (
    <div>
      {historyLoading ? (
        <Spinner size={SpinnerSize.STANDARD} />
      ) : (
        <AuditHistoryTable
          data={historyData}
          sticky={{ offsetHeader: -CONTAINER_PADDING }}
          error={error ? strings.get('error.apiError') : ''}
          history={history}
          currentAudit={currentAudit}
          setCurrentAudit={setCurrentAudit}
          toggleEditing={toggleEditing}
        />
      )}
    </div>
  );
};
