import axiosInstance from 'lib/axios.factory';
import type { queryT } from 'stores/analytics';

import { getMessageFromAxiosError } from './error';
import { toCamelCaseKeys, toSnakeCase, toSnakeCaseKeys } from './util';
import { addApiError, trackFromBackend } from '../actions';
import { store } from '../stores/index.js';

export const API = {
  TEAM_MEMBER_ADD: { url: 'team', method: 'post' },
  TEAM_MEMBER_DELETE: { url: 'team', method: 'delete' },
  STATS: {
    campaign: { url: 'ab/campaign-stats', method: 'get' },
    campaigns: { url: 'ab/campaigns-stats', method: 'post' },
    usage: { url: 'ab/stats/usage', method: 'get' },
  },
  SEGMENT: {
    GET: {
      url: '/sa/segments',
      method: 'get',
    },
    CREATE: {
      url: '/sa/segments',
      method: 'post',
    },
    UPDATE: {
      url: '/sa/segments',
      method: 'patch',
    },
    DELETE: {
      url: '/sa/segments',
      method: 'delete',
    },
  },
  EVENT: {
    GET: {
      url: '/sa/events',
      method: 'get',
    },
    CREATE: {
      url: '/sa/events',
      method: 'post',
    },
    UPDATE: {
      url: '/sa/events',
      method: 'patch',
    },
    DELETE: {
      url: '/sa/events',
      method: 'delete',
    },
  },
  INTEGRATIONS: {
    GET: {
      url: '/integrations',
      method: 'get',
    },
  },
  PAGE: {
    GET: { url: 'pb/pages', method: 'get' },
    POST: { url: 'pb/pages', method: 'post' },
  },
  IMAGE: {
    POST: { url: 'images/upload', method: 'post' },
  },
};

export type errorT = {
  message: string,
  code: string,
  data?: {},
};

export type axiosParams = {
  method: string,
  url: string,
  data?: {},
  config?: {},
  params?: { filter?: { accountId: string, limit: number } },
};
type authentT = {
  accessToken: string,
};

export type allCallsTypes = authentT | userT | { accounts: Array<accountT> } | { month: string, usage: number };

// const instance = axios.create({
//   baseURL: "apihttps://some-domain.com/api/",
//   timeout: 10000,
//   headers: { "X-Custom-Header": "foobar" }
// });

// generic api call factory with dep injection to allow easy mocking
export function apiCall({
  localStorage = window.localStorage,
  axios = axiosInstance,
  secure,
  method,
  url,
  params = {},
  data = {},
  headers = {},
}: {
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  secure: boolean,
  method: string,
  url: string,
  params?: {},
  data?: {},
  headers?: {
    Authorization: string,
    AuthorizationAuth0: string,
    Impersonate?: string,
    ImpersonateAccessOverride?: string,
  },
}): Promise<any> {
  data = toSnakeCaseKeys(data);
  params = toSnakeCaseKeys(params);

  if (secure) {
    const accessToken = localStorage.getItem('access-token');
    const auth0Token = localStorage.getItem('auth0-access-token');

    if (auth0Token) {
      headers.AuthorizationAuth0 = `Bearer ${auth0Token}`;
    } else if (accessToken) {
      headers.Authorization = `Bearer ${accessToken}`;
    } else {
      return Promise.reject({
        message: 'Must be logged to execute this query.',
        code: 'NOT_LOGGED',
      });
    }

    // Add impersonate header
    const impersonate = localStorage.getItem('impersonate') || '';

    if (impersonate.length > 0) {
      headers.Impersonate = impersonate;

      if (localStorage.getItem('impersonate_access_override') === 'true') {
        headers.ImpersonateAccessOverride = 'true';
      }
    }
  }

  return axios({ method, url, params, data, headers })
    .then((response) => {
      store.dispatch(trackFromBackend(response.data));

      return Promise.resolve(toCamelCaseKeys(response.data));
    })
    .catch((error) => {
      if (store.dispatch) {
        store.dispatch(addApiError(error, url, method));
      }

      const errRes = getMessageFromAxiosError(error);

      return Promise.reject({ ...error, ...errRes });
    });
}

export function apiCallNoErrorHandler({
  localStorage,
  axios,
  secure,
  method,
  url,
  params = {},
  data = {},
  headers = {},
}: {
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  secure: boolean,
  method: string,
  url: string,
  params?: {},
  data?: {},
  headers?: { Authorization: string, AuthorizationAuth0: string },
}): Promise<any> {
  data = toSnakeCaseKeys(data);
  params = toSnakeCaseKeys(params);

  if (secure) {
    const accessToken = localStorage.getItem('access-token');
    const auth0Token = localStorage.getItem('auth0-access-token');

    if (auth0Token) {
      headers.AuthorizationAuth0 = `Bearer ${auth0Token}`;
    } else if (accessToken) {
      headers.Authorization = `Bearer ${accessToken}`;
    } else {
      return Promise.reject({
        message: 'Must be logged to execute this query.',
        code: 'NOT_LOGGED',
      });
    }

    // Add impersonate header
    const impersonate = localStorage.getItem('impersonate') || '';

    if (impersonate.length > 0) {
      headers.Impersonate = impersonate;

      if (localStorage.getItem('impersonate_access_override') === 'true') {
        headers.ImpersonateAccessOverride = 'true';
      }
    }
  }

  return axios({ method, url, params, data, headers })
    .then((response) => {
      store.dispatch(trackFromBackend(response.data));

      return Promise.resolve(toCamelCaseKeys(response.data));
    })
    .catch((error) => {
      const errRes = getMessageFromAxiosError(error);

      return Promise.reject({ ...error, ...errRes });
    });
}
export function apiCallFormData({ localStorage, secure, method, url, params = {}, data, axios, headers = {} }) {
  params = toSnakeCaseKeys(params);

  if (secure) {
    const accessToken = localStorage.getItem('access-token');
    const auth0Token = localStorage.getItem('auth0-access-token');

    if (auth0Token) {
      headers.AuthorizationAuth0 = `Bearer ${auth0Token}`;
    } else if (accessToken) {
      headers.Authorization = `Bearer ${accessToken}`;
    } else {
      return Promise.reject({
        message: 'Must be logged to execute this query.',
        code: 'NOT_LOGGED',
      });
    }

    // Add impersonate header
    const impersonate = localStorage.getItem('impersonate') || '';

    if (impersonate.length > 0) {
      headers.Impersonate = impersonate;

      if (localStorage.getItem('impersonate_access_override') === 'true') {
        headers.ImpersonateAccessOverride = 'true';
      }
    }
  }

  return axios({ method, url, params, data, headers })
    .then((response) => {
      store.dispatch(trackFromBackend(response.data));

      return Promise.resolve(toCamelCaseKeys(response.data));
    })
    .catch((error) => {
      if (store.dispatch) {
        store.dispatch(addApiError(error, url, method));
      }

      return Promise.reject(getMessageFromAxiosError(error));
    });
}

// get accounts
export function getAccounts(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
): Promise<{ accounts: Array<accountT> }> {
  // $FlowFixMe
  return apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'get',
    url: 'accounts',
  });
}

// get accounts usage
export function getAccountsUsage(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  params: {
    rnd: string, // requests with the same value will be cached for 30 seconds, good value here is User ID
    accountId: string,
  },
): Promise<{
  usage: {
    pixels: number,
    teamMembers: number,
    workspaces: number,
    brandedDomains: number,
  },
}> {
  // $FlowFixMe
  return apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'get',
    url: 'accounts/usage',
    params,
  });
}

// // get redirects
// export function exportLinks(
//   localStorage: any,
//   axios: axiosParams => Promise<{ data: {} }>,
//   filter: {
//     accountId: string,
//     limit: number,
//     search?: string,
//     searchField?: string,
//     tags?: Array<string>,
//     sort?: string,
//     from?: string,
//     to?: string,
//     start?: number,
//     cookieConsent?: "all" | "true" | "false",
//     linksIds?: Array<string>,
//     campaignId: string,
//     subCampaignId: string,
//     exportFields: Array<string>
//   }
// ): Promise<{ total: number, redirects: Array<accountT> }> {
//   const sort = filter.sort ? toSnakeCase(filter.sort) : undefined;
//   // $FlowFixMe
//   return apiCall({
//     localStorage,
//     axios,
//     secure: true,
//     method: "get",
//     url: "export-links",
//     params: { ...filter, limit: -1, sort }
//   });
// }

//
// export function getRedirectsClicks(
//   localStorage: any,
//   axios: axiosParams => Promise<{ data: {} }>,
//   params: {
//     redirectsIds: Array<string>,
//     accountId: string
//   }
// ): Promise<{}> {
//   // $FlowFixMe
//   if (params.redirectsIds.length === 0) {
//     return Promise.resolve({clicks:[]});
//   }
//   return apiCall({
//     localStorage,
//     axios,
//     secure: true,
//     method: "get",
//     url: "stats/redirects-clicks",
//     params: params
//   });
// }

export function addPixel(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  params: { accountId: string },
  data: { key: string, providerKey: string, name: string },
): Promise<{ account: accountT }> {
  // $FlowFixMe
  return apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'post',
    url: 'pixels/add',
    params,
    data,
  });
}

export function deletePixel(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  params: { accountId: string },
  data: { pixelKey: string },
): Promise<{ account: accountT }> {
  // $FlowFixMe
  return apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'post',
    url: 'pixels/delete',
    params,
    data,
  });
}

export function updatePixels(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  params: { accountId: string },
  data: { pixels: Array<pixelT> },
): Promise<{ account: accountT }> {
  // $FlowFixMe
  return apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'patch',
    url: 'pixels/update',
    params,
    data,
  });
}

export function updateCustomPixels(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  params: { accountId: string },
  data: { customHeader: string, customBody: string },
): Promise<{ account: accountT }> {
  // $FlowFixMe
  return apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'patch',
    url: 'pixels/custom',
    params,
    data,
  });
}

export function getTeam(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  params: { accountId: string },
): Promise<{ members: Array<memberT> }> {
  // $FlowFixMe
  return apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'get',
    url: 'team',
    params,
  });
}

export function resendInvitation(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  params: { accountId: string, userId: string },
): Promise<{ message: string }> {
  // $FlowFixMe
  return apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'post',
    url: 'team/resend',
    params,
  });
}

export function deleteMember(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  params: { accountId: string },
  data: { memberId: number },
): Promise<{ message: string }> {
  // $FlowFixMe
  return apiCall({
    localStorage,
    axios,
    secure: true,
    method: API.TEAM_MEMBER_DELETE.method,
    url: API.TEAM_MEMBER_DELETE.url,
    params,
    data,
  });
}

export function addMember(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  params: { accountId: string },
  data: { email: string, fullname: string },
): Promise<{ message: string }> {
  // $FlowFixMe
  return apiCall({
    localStorage,
    axios,
    secure: true,
    method: API.TEAM_MEMBER_ADD.method,
    url: API.TEAM_MEMBER_ADD.url,
    params,
    data,
  });
}

export function addDomain(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  params: { accountId: string },
  data: { domain: string },
): Promise<{ account: accountT }> {
  // $FlowFixMe
  return apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'post',
    url: 'domain',
    params,
    data,
  });
}

export function notifySSL(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  params: { accountId: string },
  data: { domain: string },
): Promise<{ sent: Boolean }> {
  return apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'post',
    url: 'domain/notify',
    params,
    data,
  });
}

export function deleteDomain(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  params: { accountId: string },
  data: { domain: string },
): Promise<{ account: accountT }> {
  // $FlowFixMe
  return apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'delete',
    url: 'domain',
    params,
    data,
  });
}

export function updateDomain(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  params: { accountId: string },
  data: { domain: domainT },
): Promise<{ account: accountT }> {
  // $FlowFixMe
  return apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'patch',
    url: 'domain',
    params,
    data,
  });
}

export function updatePassword(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  data: {
    currentPassword: string,
    newPassword: string,
    renewPassword: string,
  },
): Promise<{ user: userT }> {
  // $FlowFixMe
  return apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'patch',
    url: 'user/password',
    data,
  });
}

export function updateUser(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  params: { accountId: string },
  data: {
    email?: string,
    fullname?: string,
    onboarded?: boolean,
    weeklyEmail?: boolean,
  },
): Promise<{ user: userT }> {
  // $FlowFixMe
  return apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'patch',
    url: 'user',
    params,
    data,
  });
}

export function sendResetPassword(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  data: {
    email: string,
    captcha: string,
  },
): Promise<{ user: userT }> {
  // $FlowFixMe
  return apiCall({
    localStorage,
    axios,
    secure: false,
    method: 'post',
    url: 'user/password/send-reset',
    data,
  });
}

export function sendValidationEmail(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  data: {
    oldEmail: string,
    newEmail: string,
  },
): Promise<{ user: userT }> {
  // $FlowFixMe
  return apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'post',
    url: 'user/validate/send',
    data,
  });
}

export function validateEmail(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  data: {
    email: string,
    code: string,
  },
): Promise<{ user: userT }> {
  // $FlowFixMe
  return apiCall({
    localStorage,
    axios,
    secure: false,
    method: 'post',
    url: 'user/validate',
    data,
  });
}

export function resetPasswordFromCode(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  data: {
    email: string,
    resetCode: string,
    newPassword: string,
    renewPassword: string,
  },
): Promise<{ user: userT }> {
  // $FlowFixMe
  return apiCall({
    localStorage,
    axios,
    secure: false,
    method: 'patch',
    url: 'user/password/reset',
    data,
  });
}

export function addAccount(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  params: { accountId: string },
  data: {
    name: string,
    orgId: string,
  },
): Promise<{ account: accountT }> {
  // $FlowFixMe
  return apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'post',
    url: 'account',
    params,
    data,
  });
}

export function updateAccount(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  params: { accountId: string },
  data: {
    name?: string,
    cookieConsent?: {
      company: string,
      website: string,
      terms: string,
      active: boolean,
    },
    type?: 'MARKETER' | 'SELLER',
  },
): Promise<{ account: accountT }> {
  // $FlowFixMe
  return apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'patch',
    url: 'account',
    params,
    data,
  });
}

// export function updateLink(
//   localStorage: any,
//   axios: axiosParams => Promise<{ data: {} }>,
//   params: { accountId: string },
//   data: {
//     linkId: string,
//     pixelsIds: Array<string>,
//     tags: Array<string>,
//     domain: string,
//     title: string,
//     url: string,
//     slug: string,
//     campaignId: string,
//     subCampaignId: string
//   }
// ): Promise<{ redirect: redirectT }> {
//   // $FlowFixMe
//   return apiCall({
//     localStorage,
//     axios,
//     secure: true,
//     method: "patch",
//     url: "link",
//     params,
//     data
//   });
// }

// export function deleteLinks(
//   localStorage: any,
//   axios: axiosParams => Promise<{ data: {} }>,
//   params: { accountId: string },
//   data: {
//     linksIds: Array<string>
//   }
// ): Promise<{ link: linkT }> {
//   // $FlowFixMe
//   return apiCall({
//     localStorage,
//     axios,
//     secure: true,
//     method: "delete",
//     url: "sa/links",
//     params,
//     data
//   });
// }

export function resetAnalytics(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  params: { accountId: string },
  data: {},
): Promise<{ account: accountT }> {
  // $FlowFixMe
  return apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'patch',
    url: 'account/analytics/reset',
    params,
    data,
  });
}

export function addTag(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  params: { accountId: string },
  data: {
    tag: { name: string, description: string },
  },
): Promise<{ account: accountT }> {
  // $FlowFixMe
  return apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'patch',
    url: 'account/tags',
    params,
    data,
  });
}

export function removeTag(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  params: { accountId: string },
  data: {
    tag: { name: string, description: string },
  },
): Promise<{ account: accountT }> {
  // $FlowFixMe
  return apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'delete',
    url: 'account/tags',
    params,
    data,
  });
}

export function removePlatform(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  params: { accountId: string },
  data: {
    platform?: string,
    medium?: string,
  },
): Promise<{ account: accountT }> {
  // $FlowFixMe
  return apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'delete',
    url: 'account/platforms',
    params,
    data,
  });
}

export function removeSource(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  params: { accountId: string },
  data: {
    source: { value: string, description: string },
  },
): Promise<{ account: accountT }> {
  // $FlowFixMe
  return apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'delete',
    url: 'account/utms/sources',
    params,
    data,
  });
}

export function removeMedium(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  params: { accountId: string },
  data: {
    medium: { value: string, description: string },
  },
): Promise<{ account: accountT }> {
  // $FlowFixMe
  return apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'delete',
    url: 'account/utms/mediums',
    params,
    data,
  });
}

export function removeCampaign(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  params: { accountId: string },
  data: {
    campaign: { value: string, description: string },
  },
): Promise<{ account: accountT }> {
  // $FlowFixMe
  return apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'delete',
    url: 'account/utms/campaigns',
    params,
    data,
  });
}

export function addUtms(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  params: { accountId: string },
  data: { url: string },
): Promise<{ account: accountT }> {
  // $FlowFixMe
  return apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'patch',
    url: 'account/utms',
    params,
    data,
  });
}

export function getCard(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  params: { accountId: string },
): Promise<{ card: cardT }> {
  // $FlowFixMe
  return apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'get',
    url: 'billing/card',
    params,
    data: {},
  });
}

export function getOrgCoupon(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  params: { accountId: string },
): Promise<{ coupon: orgCouponT | null }> {
  // $FlowFixMe
  return apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'get',
    url: 'billing/org-coupon',
    params,
    data: {},
  });
}

export function checkOrgCoupon(
  localStorage: any,
  axios: (axiosParams) => Promise<*>,
  data: {
    coupon: string,
  },
): Promise<{ coupon: orgCouponT | null }> {
  return apiCall({
    localStorage,
    axios,
    secure: false,
    method: 'post',
    url: 'billing/org-coupon',
    data,
  });
}

export function getVAT(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  params: { accountId: string },
): Promise<{ vatPercent: float, country: string }> {
  // $FlowFixMe
  return apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'get',
    url: 'billing/vat',
    params,
    data: {},
  });
}

export function postCard(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  params: { accountId: string, token: string },
): Promise<{ card: cardT }> {
  // $FlowFixMe
  return apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'post',
    url: 'billing/card',
    params,
    data: {},
  });
}

export function postDefaultCard(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  params: { accountId: string, setupIntent: string },
): Promise<{ card: cardT }> {
  // $FlowFixMe
  return apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'post',
    url: 'billing/default-card',
    params,
    data: {},
  });
}

export function getBankAccount(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  params: { accountId: string },
): Promise<{ bankAccount: bankAccountT }> {
  // $FlowFixMe
  return apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'get',
    url: 'billing/bank-account',
    params,
    data: {},
  });
}

export function postBankAccount(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  params: { accountId: string, sourceId: string },
): Promise<{ bankAccount: bankAccountT }> {
  // $FlowFixMe
  return apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'post',
    url: 'billing/bank-account',
    params,
    data: {},
  });
}

export function getCoupon(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  params: { accountId: string, planId: string, coupon: string },
): Promise<{ coupon: couponT }> {
  // $FlowFixMe
  return apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'get',
    url: 'billing/coupon',
    params,
    data: {},
  });
}

export function addCoupon(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  params: { accountId: string },
  data: { orgId: string, code: string },
): Promise<{ sub: subscriptionT }> {
  // $FlowFixMe
  return apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'post',
    url: 'billing/coupon',
    params,
    data,
  });
}

export function trial(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  params: { accountId: string },
  data: { planId: string },
): Promise<{ sub: subT, limitations: {} }> {
  // $FlowFixMe
  return apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'post',
    url: 'billing/trial',
    params,
    data,
  });
}

export function subscribe(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  params: { accountId: string },
  data: { planId: string, coupon: string },
): Promise<{ sub: subT, limitations: {} }> {
  // $FlowFixMe
  return apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'post',
    url: 'billing/subscribe',
    params,
    data,
  });
}

export function invoices(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  params: { accountId: string },
): Promise<{ invoices: Array<invoiceT> }> {
  // $FlowFixMe
  return apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'get',
    url: 'billing/invoices',
    params,
  });
}

export function upcomingInvoice(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  params: { accountId: string },
): Promise<{ invoice: null | invoiceT }> {
  // $FlowFixMe
  return apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'get',
    url: 'billing/upcoming-invoice',
    params,
  });
}

export function generatePreviewPriceUrl(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  params: { accountId: string },
  data: {
    planId: string,
    quantity: number,
    returnUrl: string,
  },
): Promise<{
  url: string,
}> {
  // $FlowFixMe
  return apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'post',
    url: 'billing/preview-price-url',
    params,
    data,
  });
}

export function generateManageSubscriptionUrl(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  params: { accountId: string },
  data: {
    returnUrl: string,
  },
): Promise<{
  url: string,
}> {
  // $FlowFixMe
  return apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'post',
    url: 'billing/manage-subscription-url',
    params,
    data,
  });
}

export function refreshSubscription(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  params: { accountId: string },
  data: {
    sessionId: string,
  },
): Promise<any> {
  // $FlowFixMe
  return apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'post',
    url: 'billing/refresh-subscription',
    params,
    data,
  });
}

// Get every integrations connected to accountId
export function integrations(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  params: { accountId: string },
): Promise<{ integrations: integrationsT }> {
  // $FlowFixMe
  return apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'get',
    url: 'integrations',
    params,
  });
}

export function amazonOauthCallback(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  params: { accountId: string },
  data: { code: string, amazonMarketplaceId: string },
): Promise<{
  productProvider: productProviderT,
}> {
  // $FlowFixMe
  return apiCall({
    localStorage,
    axios,
    secure: false,
    method: 'post',
    url: 'product-providers/amazon',
    params,
    data,
  });
}

export function reconnectAmazonProductProvider(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  productProviderId: string,
  params: { accountId: string, code: string },
): Promise {
  // $FlowFixMe
  return apiCallNoErrorHandler({
    localStorage,
    axios,
    secure: true,
    method: 'put',
    url: `product-providers/${productProviderId}`,
    params,
  });
}

export function bufferOauthCallback(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  params: { accountId: string },
  data: { code: string },
): Promise<{
  success: boolean,
}> {
  // $FlowFixMe
  return apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'post',
    url: 'integrations/buffer',
    params,
    data,
  });
}

export function bufferDisconnect(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  params: { accountId: string },
): Promise<{
  success: boolean,
}> {
  // $FlowFixMe
  return apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'delete',
    url: 'integrations/buffer',
    params,
  });
}

export function bufferUpdateProfile(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  params: { accountId: string },
  data: {
    id: string,
    active: boolean,
    pixelsIds: Array<string>,
    domain: string,
  },
): Promise<{
  profile: bufferProfileT,
}> {
  // $FlowFixMe
  return apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'patch',
    url: 'integrations/buffer',
    params,
    data,
  });
}

// get campaigns
export function getCampaigns(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  filter: {},
): Promise<{ total: number, campaigns: Array<campaignT> }> {
  // $FlowFixMe
  return apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'get',
    url: 'ab/campaigns',
    params: { ...filter },
  });
}

// AUDIENCE BUILDER

// get redirects
export function getRedirectsWithStats(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  filter: {
    accountId: string,
    searchField?: string,
    tags?: Array<string>,
    sort?: string,
    from?: string,
    to?: string,
    start?: number,
    limit: number,
    search?: string,
    cookieConsent?: 'all' | 'true' | 'false',
    redirectsIds?: Array<string>,
    campaignId: string,
    subCampaignId: string,
    productProviderType: 'AMAZON',
  },
): Promise<{ nbRedirects: number, redirects: Array<accountT> }> {
  const sort = filter.sort ? toSnakeCase(filter.sort) : undefined;

  if (!filter.start) {
    filter.start = 0;
  }

  // $FlowFixMe
  return apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'get',
    url: 'ab/redirects-with-stats',
    params: { ...filter, sort },
  });
}

// get redirects by ids
export function getRedirectsByIds(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  filter: {
    accountId: string,
    redirectsIds?: Array<string>,
  },
): Promise<{ redirects: Array<accountT> }> {
  // $FlowFixMe
  return apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'get',
    url: 'ab/redirects-by-ids',
    params: { ...filter },
  });
}

// // get redirects
// export function exportRedirects(
//   localStorage: any,
//   axios: axiosParams => Promise<{ data: {} }>,
//   filter: {
//     accountId: string,
//     limit: number,
//     search?: string,
//     searchField?: string,
//     tags?: Array<string>,
//     sort?: string,
//     from?: string,
//     to?: string,
//     start?: number,
//     cookieConsent?: "all" | "true" | "false",
//     redirectsIds?: Array<string>,
//     campaignId: string,
//     subCampaignId: string,
//     exportFields: Array<string>
//   }
// ): Promise<{ total: number, redirects: Array<accountT> }> {
//   const sort = filter.sort ? toSnakeCase(filter.sort) : undefined;
//   // $FlowFixMe
//   return apiCall({
//     localStorage,
//     axios,
//     secure: true,
//     method: "get",
//     url: "ab/export-redirects",
//     params: { ...filter, limit: -1, sort }
//   });
// }

export function editCampaign(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  params: { accountId: string },
  data: { campaign: campaignT },
): Promise<{}> {
  // $FlowFixMe
  return apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'patch',
    url: 'ab/campaigns',
    params,
    data,
  });
}

export function editSubCampaign(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  params: { accountId: string },
  data: { subCampaign: subCampaignT },
): Promise<{}> {
  // $FlowFixMe
  return apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'patch',
    url: 'ab/sub-campaigns',
    params,
    data,
  });
}

export function deleteCampaign(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  params: { accountId: string },
  data: { campaign: campaignT },
): Promise<{}> {
  // $FlowFixMe
  return apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'delete',
    url: 'ab/campaigns',
    params,
    data,
  });
}

export function deleteSubCampaign(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  params: { accountId: string },
  data: { subCampaign: subCampaignT },
): Promise<{}> {
  // $FlowFixMe
  return apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'delete',
    url: 'ab/sub-campaigns',
    params,
    data,
  });
}

// load account usage
export function getAccountUsage(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  accountId: string,
): Promise<{ total: number, limit: number, month: string }> {
  // We're only tracking usage in production so this avoids throwing errors
  if (process.env.NODE_ENV !== 'production') {
    return Promise.resolve({ total: 0, month: 'Test' });
  }

  // $FlowFixMe
  return apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'get',
    url: 'ab/stats/usage',
    params: { accountId },
  });
}

// load analytics
export function getFacets(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  params: queryT,
): Promise<{}> {
  params.linkId = params.redirectId;
  delete params.redirectId;

  // $FlowFixMe
  return apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'get',
    url: 'ab/stats/facets',
    params,
  });
}

export function getTimeline(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  params: {
    period: string,
    redirectId: string,
    accountId: string,
  },
): Promise<{}> {
  // $FlowFixMe
  params.linkId = params.redirectId;
  delete params.redirectId;

  return apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'get',
    url: 'ab/stats/timeline',
    params,
  });
}

export function exportTimeline(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  params: {
    period: string,
    redirectId: string,
    accountId: string,
  },
): Promise<{}> {
  // $FlowFixMe
  params.linkId = params.redirectId;
  delete params.redirectId;

  return apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'get',
    url: 'ab/stats/export-timeline',
    params,
  });
}

export function getAccountStats(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  params: {
    accountId: string,
  },
): Promise<{}> {
  // $FlowFixMe
  return apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'get',
    url: 'ab/stats/account',
    params,
  });
}

export function addRedirect(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  params: { accountId: string },
  data: {
    url: string,
    pixelsIds: Array<string>,
    domain: string,
    tags: Array<string>,
    cookieConsent: boolean,
  },
): Promise<{ redirect: redirectT }> {
  // $FlowFixMe
  return apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'post',
    url: 'ab/redirect',
    params,
    data,
  });
}

export function importRedirects(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  params: { accountId: string },
  data: {
    urls: Array<string>,
    pixelsIds: Array<string>,
    domain: string,
    tags: Array<string>,
    cookieConsent: boolean,
  },
): Promise<{ ok: boolean }> {
  // $FlowFixMe
  return apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'post',
    url: 'ab/redirects',
    params,
    data,
  });
}

export function updateRedirect(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  params: { accountId: string },
  data: {
    redirectId: string,
    pixelsIds: Array<string>,
    tags: Array<string>,
    domain: string,
    title: string,
    url: string,
    slug: string,
    campaignId: string,
    subCampaignId: string,
  },
): Promise<{ redirect: redirectT }> {
  // $FlowFixMe
  return apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'patch',
    url: 'ab/redirect',
    params,
    data,
  });
}

export function updateRedirectsCampaign(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  params: { accountId: string },
  data: {
    redirectsIds?: Array<string>,
    campaignId?: string,
    subCampaignId?: string,
  },
): Promise<{ redirects: Array<redirectT> }> {
  // $FlowFixMe
  return apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'patch',
    url: 'ab/redirects-campaigns',
    params,
    data,
  });
}

export function updateRedirects(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  params: { accountId: string },
  data: {
    redirectsIds?: Array<string>,
    pixelsIds?: Array<string>,
    deletedPixelsIds?: Array<string>,
    tags?: Array<string>,
    deletedTags?: Array<string>,
    cookieConsent?: boolean,
    campaignId?: string,
    subCampaignId?: string,
  },
): Promise<{ redirects: Array<redirectT> }> {
  // $FlowFixMe
  return apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'patch',
    url: 'ab/redirects',
    params,
    data,
  });
}

export function deleteRedirects(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  params: { accountId: string },
  data: {
    redirectsIds: Array<string>,
  },
): Promise<{ redirect: redirectT }> {
  // $FlowFixMe
  return apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'delete',
    url: 'ab/redirects',
    params,
    data,
  });
}

// get campaigns stats
// noinspection JSUnusedGlobalSymbols
export function getCampaignStats(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  filter: {},
): Promise<{ campaignStats: Array<campaignStatsT> }> {
  // $FlowFixMe
  return apiCall({
    localStorage,
    axios,
    secure: true,
    method: API.STATS.campaign.method,
    url: API.STATS.campaign.url,
    params: { ...filter },
  });
}

// get campaigns stats
// noinspection JSUnusedGlobalSymbols
export function getCampaignsStats(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  filter: {},
  data: { campaigns: campaignT[] },
): Promise<{ campaignStats: Array<campaignStatsT> }> {
  // $FlowFixMe
  return apiCall({
    localStorage,
    axios,
    secure: true,
    method: API.STATS.campaigns.method,
    url: API.STATS.campaigns.url,
    params: { ...filter },
    data,
  });
}

// TODO: Add SA/AB diffenrentiation
export function addCampaign(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  params: { accountId: string },
  data: { campaign: campaignT },
): Promise<{}> {
  // $FlowFixMe
  return apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'post',
    url: 'ab/campaigns',
    params,
    data,
  });
}

// TODO: Add SA/AB diffenrentiation
export function addSubCampaign(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  params: { accountId: string },
  data: { subCampaign: subCampaignT },
): Promise<{}> {
  // $FlowFixMe
  return apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'post',
    url: 'ab/sub-campaigns',
    params,
    data,
  });
}
