import React, { useContext, useEffect, useState } from 'react';

import { PauseOutlined, CaretRightOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, Card, Popconfirm } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import styled from 'styled-components';
import { useImmer } from 'use-immer';

import DismissableInfo from 'components/common/DismissableInfo';
import { DashboardFilterContext } from 'context/DashboardFilterProvider';
import { useLoading } from 'hooks/useLoading';
import {
  getAllVariationsForAdGroup,
  getAllVariationsForCampaign,
  pauseVariation,
  runVariation,
} from 'lib/adsPlateformApi';
import axios from 'lib/axios.factory';
import { zeroIfNegative } from 'lib/util';

import { VariationsStats } from './VariationsStats';
import { VariationsTable } from './VariationsTable';
import type { adsCampaignT } from '../../../../../../flow-typed/pixelme/adsPlateform';
import { GOOGLE_RMF, useFeatureFlag } from '../../../../../lib/configcat';
import { AdsProviderLogo } from '../../common/AdsProviderLogo';
import { calculateStats } from '../../common/adsStats';
import { DashboardFilter } from '../../common/DashboardFilter';
import { ShowProduct } from '../../common/ShowProduct';
import { NewKeywordButton } from '../../NewCampaign/NewKeywordButton';
import { HeaderStyle } from '../Style';

export type StateT = {
  variations: Array<variationT>,
  allVariations: Array<variationT>,
  products: Array<productT>,
  stats: adsReportDataT,
  campaign: adsCampaignT,
};

const Style = styled.div`
  .ant-table-column-sorters {
    width: 100%;
    justify-content: space-between;
  }
`;

export function VariationsDashBoard({
  account,
  adGroupId,
  productProviderId,
  productASIN,
}: {
  account: accountT,
  adGroupId: string,
  productProviderId: string,
  productASIN: string,
}) {
  const { ctx, updateCtx } = useContext(DashboardFilterContext);
  const initialState: StateT = {
    products: [],
    stats: {},
    campaign: {},
    product: {},
  };
  const [state, updateState] = useImmer(initialState);
  const { doAction, ExclusiveInlineLoadingContainer } = useLoading(true);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [canDeleteKeywordsForRMF] = useFeatureFlag(GOOGLE_RMF);

  const onProductSave = (p) => {
    updateState((draft) => {
      draft.product = p;
    });
  };

  async function loadVariations() {
    if (adGroupId) {
      let from = _.get(ctx, ['adsReportsRange', 0]);

      if (from) {
        from = moment(from).toISOString();
      }

      let to = _.get(ctx, ['adsReportsRange', 1]);

      if (to) {
        to = moment(to).toISOString();
      }

      const getVariationsResponse = await getAllVariationsForAdGroup(
        localStorage,
        axios,
        { accountId: account.id, from, to, with: ctx.showRank ? 'volumes' : '' },
        adGroupId,
      );
      const { campaign } = getVariationsResponse;
      const variations = getVariationsResponse.variations.map((variation) => {
        const adsReport = (variation.selectedAdsReports || {}).data;
        const result = { ...variation, ...adsReport };
        const splitNames = variation.name.split('|');

        if (result.type === 'CREATIVE') {
          result.keyword = _.get(splitNames, 1);
          result.matchType = _.get(splitNames, 2);
        } else if (result.type === 'KEYWORD') {
          result.keyword = _.get(splitNames, 1);
          result.matchType = _.get(splitNames, 2);
        }

        return result;
      });
      const { product } = getVariationsResponse;
      const stats = calculateStats(variations);

      updateState((draft) => {
        draft.allVariations = variations;
        draft.variations = variations;
        draft.stats = stats;
        draft.campaign = campaign;
        draft.product = product;
      });
      filterOnSearch(variations);
    }
  }

  // Empty search when navigate
  useEffect(() => {
    doAction(async () => {
      updateCtx((draft) => {
        draft.searchVariation = '';
      });
      await loadVariations();
    });
  }, []);

  function filterOnSearch(allVariations) {
    let tmpVariations = allVariations || [];

    if (ctx.searchVariation) {
      tmpVariations = tmpVariations.filter((c) => c.name.toLowerCase().includes(ctx.searchVariation));
    }

    if (ctx.filterStatus) {
      tmpVariations = tmpVariations.filter((c) => c.status === ctx.filterStatus);
    }

    const tmpStats = calculateStats(tmpVariations);

    updateState((draft) => {
      draft.variations = tmpVariations;
      draft.stats = tmpStats;
    });
  }

  useEffect(() => {
    filterOnSearch(state.allVariations);
  }, [ctx.searchVariation, ctx.filterStatus]);

  useEffect(() => {
    doAction(async () => {
      await loadVariations();
    });
  }, [JSON.stringify(ctx.adsReportsRange), adGroupId, ctx.showRank]);

  return (
    <Style>
      <DismissableInfo
        style={{ margin: '20px 0' }}
        name="shopper-privacy"
        learnMore="https://www.carbon6.io/pixelme-help/why-am-i-seeing-less-purchases-and-add-to-carts-on-my-keywords-than-my-campaign-2"
      >
        <p>
          The data displayed here is limited by Amazon's shopper privacy policy, which only shows purchase data for
          keywords with more than 10 clicks.{' '}
        </p>
        <p>Keyword with less than 10 clicks will be display as zero until the click threshold is reached.</p>
      </DismissableInfo>

      <VariationsDashBoardHeader
        account={account}
        productName={_.get(state.allVariations, [0, 'productName'])}
        campaign={state.campaign}
        adGroupId={adGroupId}
        product={state.product}
        productASIN={productASIN}
        productProviderId={productProviderId}
        selectedRowKeys={selectedRowKeys}
        reload={() =>
          doAction(async () => {
            await loadVariations();
          })
        }
      />
      <ExclusiveInlineLoadingContainer>
        <VariationsStats
          account={account}
          stats={state.stats}
          productASIN={productASIN}
          productProviderId={productProviderId}
        />
        <VariationsTable
          account={account}
          product={state.product}
          variationType={_.get(state.variations, '0.type')}
          variations={state.variations}
          setSelectedRowKeys={setSelectedRowKeys}
          onProductSave={onProductSave}
          productProviderId={productProviderId}
        />
      </ExclusiveInlineLoadingContainer>
    </Style>
  );
}

function VariationsDashBoardHeader({ account, campaign, product, adGroupId, selectedRowKeys, reload }) {
  const [canDeleteKeywordsForRMF] = useFeatureFlag(GOOGLE_RMF);

  return (
    <HeaderStyle>
      <Card bordered={false}>
        <Card.Meta
          avatar={<AdsProviderLogo style={{ height: 30 }} providerType={campaign.providerType} />}
          title={campaign.name || 'Google Campaign'}
        />
      </Card>
      <ShowProduct product={product} />
      {(selectedRowKeys || []).length > 0 && (
        <>
          <Popconfirm
            title="Are you sure you want to pause all selected keywords?"
            disabled={!selectedRowKeys?.length}
            onConfirm={async () => {
              for (const k of selectedRowKeys || []) {
                try {
                  await pauseVariation(localStorage, axios, { accountId: account.id }, k);
                } catch (e) {
                  // ignore pause error
                }
              }
              reload();
            }}
          >
            <Button
              style={{
                color: 'rgb(248, 165, 27)',
                borderColor: 'rgb(248, 165, 27)',
              }}
            >
              <PauseOutlined />
            </Button>
          </Popconfirm>
          <Popconfirm
            title="Are you sure to active all selected keywords?"
            disabled={!selectedRowKeys?.length}
            onConfirm={async () => {
              for (const k of selectedRowKeys || []) {
                try {
                  await runVariation(localStorage, axios, { accountId: account.id }, k);
                } catch (e) {
                  // ignore run error
                }
              }
              reload();
            }}
          >
            <Button
              style={{
                color: 'rgb(82, 196, 26)',
                borderColor: 'rgb(82, 196, 26)',
              }}
            >
              <CaretRightOutlined />
            </Button>
          </Popconfirm>
          {canDeleteKeywordsForRMF && (
            <Popconfirm
              title="Are you sure you want to delete the selected keywords? This action is irreversible, and all keyword history and analytics will be lost"
              disabled={!selectedRowKeys?.length}
              onConfirm={() => {
                reload();
              }}
            >
              <Button
                style={{
                  color: 'rgb(248, 165, 27)',
                  borderColor: 'rgb(248, 165, 27)',
                }}
              >
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          )}
        </>
      )}
      <DashboardFilter searchVariation activeFilter rankColumns />
      {campaign && campaign.id && campaign.providerType === 'GOOGLE' && (
        <div>
          <NewKeywordButton account={account} campaignId={campaign.id} adGroupId={adGroupId} onClose={reload} />
        </div>
      )}
    </HeaderStyle>
  );
}
