import React from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import ScriptTag from 'react-script-tag';

import { C6_NAVBAR_HEIGHT } from 'consts/consts';

export default function Navbar() {
  const { getAccessTokenSilently } = useAuth0();

  const scriptUrl = window.env.REACT_NAVBAR_SCRIPT_URL || 'https://dev-navbar.carbon6.io/dev/c6-navbar.js';

  return (
    <>
      <div
        id="c6_navbar"
        style={{ zIndex: 1000, width: '100%', height: `${C6_NAVBAR_HEIGHT}px`, background: '#fff', outline: 'none' }}
      />
      <ScriptTag
        type="text/javascript"
        src={scriptUrl}
        onLoad={() => {
          window.globalThis.c6Navbar.updateProps({
            currentPage: 'PIXEL_ME',
            signOutUrl: `${window.location.origin}/sign-out`,
            getAccessToken: getAccessTokenSilently,
          });
        }}
      ></ScriptTag>
    </>
  );
}
