import { strings } from 'locales/strings';

export const LOW_AMAZON_SEARCH_VOLUME = 1000;
export const MIN_AMAZON_SEARCH_VOLUME = 2000;

export const MATCH_TYPES = {
  EXACT: 2,
  PHRASE: 3,
  BROAD: 4,
};
export const MATCH_TYPE_DEFAULT = MATCH_TYPES.BROAD;

export const MatchTypeLabel = () => (
  <span>
    {strings.get('campaigns.matchType.label')}{' '}
    <a
      target="_blank"
      href="https://support.google.com/google-ads/answer/7478529?hl=en&ref_topic=3119131#zippy=%2Cbroad-match%2Cphrase-match%2Cexact-match"
      rel="noreferrer"
    >
      {strings.get('campaigns.matchType.learnMore')}
    </a>
  </span>
);
