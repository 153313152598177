import React, { useContext } from 'react';

import { Checkbox, Select } from 'antd';
import styled from 'styled-components';

import { MultiSelect } from 'components/common/antd/MultiSelect';

const StyledCheckbox = styled(Checkbox)`
  padding: 5px 12px;
`;

const MenuColumns = styled.div`
  .rc-virtual-list-holder-inner {
    columns: ${(props) => props.$columns};
    display: block !important;
  }
`;

export const CheckboxMultiSelect = ({
  options = [],
  name = '',
  placeholder = '',
  hasDarkPlaceholder = true,
  hideTags = true,
  context,
  selectAllLabel,
  columns = 1,
  footer,
  dontShowSelectAllOption = false,
  ...props
}) => {
  const { ctx, updateCtx } = useContext(context);

  return (
    <MultiSelect
      placeholder={placeholder}
      hasDarkPlaceholder={hasDarkPlaceholder}
      hideTags={hideTags}
      value={ctx[name]}
      onChange={(v) => {
        updateCtx((draft) => {
          if (dontShowSelectAllOption) {
            draft[name] = v;
          } else {
            draft[name] = v.length === options.length ? [] : v;
          }
        });
      }}
      dropdownRender={(menu) => (
        <>
          {!dontShowSelectAllOption && (
            <StyledCheckbox
              checked={ctx[name].length === 0}
              onChange={() => {
                updateCtx((draft) => {
                  draft[name] = [];
                });
              }}
            >
              {selectAllLabel ?? placeholder}
            </StyledCheckbox>
          )}
          {columns === 1 ? menu : <MenuColumns $columns={columns}>{menu}</MenuColumns>}
          {footer}
        </>
      )}
      {...props}
    >
      {options.map((item) => (
        <Select.Option key={item.value} value={item.value} label={item.label}>
          <Checkbox indeterminate={ctx[name].length <= 0} checked={ctx[name].includes(item.value)} />
          &nbsp;
          {item.label}
        </Select.Option>
      ))}
    </MultiSelect>
  );
};
