import React from 'react';

import { BarToolTip } from '../BarToolTip';
// import { strings } from "locales/strings";

const AudienceBuilder = () => (
  <BarToolTip title="Link Manager">
    <svg
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="bar__icon"
    >
      <g>
        <rect width="33" height="33" rx="4" className="icon-background" />
        <path
          d="M17.524 8.609l-3.567 3.565a5.041 5.041 0 0 1-7.13 0 5.035 5.035 0 0 1-.767-1.017L7.718 9.5c.078-.08.176-.125.269-.179.114.392.314.762.622 1.07a2.523 2.523 0 0 0 3.565 0l3.566-3.565a2.523 2.523 0 0 0 0-3.566 2.524 2.524 0 0 0-3.566 0l-1.268 1.269a6.326 6.326 0 0 0-3.216-.35l2.702-2.702a5.043 5.043 0 0 1 7.132 7.132zm-9.429 5.863L6.826 15.74a2.526 2.526 0 0 1-3.566 0 2.525 2.525 0 0 1 0-3.567L6.826 8.61a2.523 2.523 0 0 1 3.566 0c.307.307.507.677.623 1.068.093-.054.19-.098.268-.177l1.657-1.657a4.955 4.955 0 0 0-.766-1.017 5.043 5.043 0 0 0-7.131 0l-3.566 3.566a5.044 5.044 0 0 0 7.132 7.131l2.703-2.702c-1.08.16-2.189.05-3.217-.35z"
          transform="translate(7 7)"
          className="icon-main-fill"
        />
      </g>
    </svg>
  </BarToolTip>
);

export default AudienceBuilder;
