import { Actions } from 'actions';
import { trackedEvents } from 'config/trackedEvents.config';
import { track } from 'lib/analytics';
import { removeCampaign, removeMedium, removeSource, removeTag, resetAnalytics, removePlatform } from 'lib/api';
import { signUp } from 'lib/auth';
import axios from 'lib/axios.factory';
import { gtag_report_conversion_signUp } from 'lib/ga_conv';
import { selectCurrentAccountId } from 'stores/accounts';

export default (store: any) => (next: any) => async (action: any) => {
  next(action);

  const accountFilter = { accountId: selectCurrentAccountId(store.getState()) };

  switch (action.type) {
    case Actions.api.account.settings.analytics.reset.request().type:
      resetAnalytics(localStorage, axios, accountFilter, action.payload)
        .then((data) => {
          store.dispatch(action.workerAction.success({ account: data.account }));
        })
        .catch((error) => {
          store.dispatch(action.workerAction.error({ error }));
        });
      break;
    case Actions.api.account.tags.remove.request().type:
      removeTag(localStorage, axios, accountFilter, action.payload)
        .then((data) => {
          store.dispatch(action.workerAction.success({ account: data.account }));
        })
        .catch((error) => {
          store.dispatch(action.workerAction.error({ error }));
        });
      break;
    case Actions.api.account.utms.sources.remove.request().type:
      removeSource(localStorage, axios, accountFilter, action.payload)
        .then((data) => {
          store.dispatch(action.workerAction.success({ account: data.account }));
        })
        .catch((error) => {
          store.dispatch(action.workerAction.error({ error }));
        });
      break;
    case Actions.api.account.utms.mediums.remove.request().type:
      removeMedium(localStorage, axios, accountFilter, action.payload)
        .then((data) => {
          store.dispatch(action.workerAction.success({ account: data.account }));
        })
        .catch((error) => {
          store.dispatch(action.workerAction.error({ error }));
        });
      break;
    case Actions.api.account.utms.campaigns.remove.request().type:
      removeCampaign(localStorage, axios, accountFilter, action.payload)
        .then((data) => {
          store.dispatch(action.workerAction.success({ account: data.account }));
        })
        .catch((error) => {
          store.dispatch(action.workerAction.error({ error }));
        });
      break;
    case Actions.api.account.signUp.request().type:
      const { onSignIn, type }: { onSignIn: Function, type: 'ltdf' | undefined } = action.payload;
      const { captcha, company, emailAddress, fullName, password, code } = store.getState().user.signup.form;
      const payload = {
        captcha,
        code,
        company,
        email: emailAddress,
        fullname: fullName,
        password,
        type,
      };

      try {
        payload.partnerKey = window.growsumo.data.partner_key;
      } catch (e) {}

      signUp(localStorage, axios, payload)
        .then((user: userT) => {
          track(trackedEvents.signUp);
          gtag_report_conversion_signUp();
        })
        .then((user) => {
          store.dispatch(action.workerAction.success());
        })
        .catch((error) => {
          store.dispatch(action.workerAction.error({ error }));
        });
      break;
    case Actions.api.account.platforms.remove.request().type:
      removePlatform(localStorage, axios, accountFilter, action.payload)
        .then((data) => {
          store.dispatch(action.workerAction.success({ account: data.account }));
        })
        .catch((error) => {
          store.dispatch(action.workerAction.error({ error }));
        });
      break;
    default:
      break;
  }
};
