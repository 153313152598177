import React, { Fragment, useContext, useEffect, useState } from 'react';

import { PauseCircleTwoTone, PlayCircleTwoTone } from '@ant-design/icons';
import { Card, Divider, message, notification, Table, Tooltip } from 'antd';
import { parse } from 'json2csv';
import _ from 'lodash';
import moment from 'moment';
import fileDownload from 'react-file-download';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { HoverLink } from 'components/common/HoverLink';
import { Csv } from 'components/common/illustrations/Csv';
import { MutedText } from 'components/common/MutedText';
import { SmallPlaceholder } from 'components/common/Placeholders';
import { DATE_FORMAT } from 'consts/consts';
import { DashboardFilterContext } from 'context/DashboardFilterProvider';
import { useLoading } from 'hooks/useLoading';
import { getAdGroupTimeSeries, getAllVariationsForAdGroup, pauseAdGroup, runAdGroup } from 'lib/adsPlateformApi';
import axios from 'lib/axios.factory';
import { getCurrency } from 'utils/currency';

import { DeleteAdGroup } from './DeleteAdGroup';
import { EditAdGroup } from './EditAdGroup';
import {
  ACOSValue,
  CurrencyValue,
  NumberVal,
  ROASValue,
} from '../../../AudienceBuilder/Campaigns/campaigns/RedirectsListLine';
import { AdsProviderLogo } from '../../common/AdsProviderLogo';
import { FloatingRowAction } from '../../common/FloatingRowAction';
import { BrbValue } from '../BrbValue';

const Style = styled.div`
  overflow: auto;
  max-width: 100%;

  .ant-table-row:hover .floating-row-action-container {
    width: 100px !important;
  }

  .ant-table-pagination.ant-pagination {
    justify-content: left;
    margin-left: 1em;
  }

  .ant-table-thead > tr > th {
    text-align: center;
  }

  .ant-table-column-title {
    font-size: 13px;
  }
`;

export function AdGroupsTable(props: {
  product: productT,
  adGroups: adGroupT[],
  account: accountT,
  productASIN: string,
  adGroupType: string,
  onProductSave: (product: productT) => void,
  setSelectedRowKeys: any,
}) {
  const { ctx } = useContext(DashboardFilterContext);
  const { currencyCode, currencySymbol } = getCurrency(props.account);
  const [loading] = useState(false);
  const history = useHistory();
  const [adGroups, setAdGroups] = useState(props.adGroups);

  const onAdGroupUpdate = (a: adGroupT) => {
    setAdGroups(
      adGroups.map((e) => {
        if (e.id === a.id) {
          return { ...a };
        }

        return e;
      }),
    );
  };

  useEffect(() => {
    setAdGroups(props.adGroups);
  }, [props.adGroups]);

  let columns = [
    {
      title: '',
      dataIndex: 'providerType',
      key: 'providerType',
      width: 60,
      render: (value, row) => (
        <div className="small-provider">
          <AdsProviderLogo providerType={value} />
        </div>
      ),
    },
  ];

  columns.push({
    title: 'Ad Group name',
    dataIndex: 'name',
    key: 'name',
    width: 200,
    sorter: (a, b) => a.name.localeCompare(b.name),
  });

  if (adGroups.length > 0 && (adGroups[0].providerType === 'TIKTOK' || adGroups[0].providerType === 'GOOGLE')) {
    columns.push({
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 140,
      sorter: (a, b) => a.status - b.status,
      render: (value, adGroup) => (
        <div
          style={{ display: 'flex', justifyContent: 'space-between' }}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          {value === -5 && <span style={{ color: '#FFEA00' }}>Waiting (import)</span>}
          {value === 1 && <span style={{ color: '#F8A51B' }}>Paused</span>}
          {value === 2 && <span style={{ color: 'red' }}>Deleted</span>}
          {value === 3 && <span style={{ color: 'red' }}>Rejected</span>}
          {(value === 4 || value === -10) && <span style={{ color: '#0F993E' }}>Active</span>}
          {value === 5 && <span style={{ color: '#0F993E' }}>Finished</span>}
          {adGroup.status === 4 /* ACTIVE*/ && (
            <PauseCircleTwoTone
              style={{ fontSize: '16px' }}
              twoToneColor="#F8A51B"
              title="Pause AdGroup"
              onClick={async () => {
                let updatedAdGroups = [...adGroups];

                updatedAdGroups.forEach((c, i) => {
                  if (c.id === adGroup.id) {
                    updatedAdGroups[i].status = -10;
                  }
                });
                setAdGroups(updatedAdGroups);
                const adGroupResult = await pauseAdGroup(
                  localStorage,
                  axios,
                  { accountId: props.account.id },
                  adGroup.id,
                );

                updatedAdGroups = [...adGroups];
                updatedAdGroups.forEach((c, i) => {
                  if (c.id === adGroup.id) {
                    updatedAdGroups[i].status = adGroupResult.adgroup.status;
                  }
                });

                setAdGroups(updatedAdGroups);
                message.success('Paused');
              }}
            />
          )}
          {adGroup.status === 1 /* PAUSED*/ && (
            <PlayCircleTwoTone
              style={{ fontSize: '16px' }}
              twoToneColor="#52c41a"
              title="Launch AdGroup"
              onClick={async () => {
                let updatedAdGroups = [...adGroups];

                updatedAdGroups.forEach((c, i) => {
                  if (c.id === adGroup.id) {
                    updatedAdGroups[i].status = -10;
                  }
                });
                setAdGroups(updatedAdGroups);
                const adGroupResult = await runAdGroup(
                  localStorage,
                  axios,
                  { accountId: props.account.id },
                  adGroup.id,
                );

                updatedAdGroups = [...adGroups];
                updatedAdGroups.forEach((c, i) => {
                  if (c.id === adGroup.id) {
                    updatedAdGroups[i].status = adGroupResult.adgroup.status;
                  }
                });
                setAdGroups(updatedAdGroups);
                message.success('Enabled');
              }}
            />
          )}
        </div>
      ),
    });
  }

  columns.push(
    {
      title: 'Live Impressions',
      dataIndex: 'liveImpressions',
      key: 'liveImpressions',
      hidden: ctx.showAttributedData,
      width: 120,
      render: (v, adGroup) => <NumberVal clicks={v}></NumberVal>,
      sorter: (a, b) => a.impressions - b.impressions,
    },
    {
      title: 'Live Clicks',
      dataIndex: 'liveClicks',
      key: 'liveClicks',
      hidden: ctx.showAttributedData,
      width: 120,
      render: (v, adGroup) => (
        <>
          <NumberVal clicks={v}></NumberVal>
        </>
      ),
      sorter: (a, b) => a.clicks - b.clicks,
    },
    {
      title: 'Live Ad Cost',
      dataIndex: 'liveAdSpent',
      key: 'liveAdSpent',
      hidden: ctx.showAttributedData,
      width: 120,
      sorter: (a, b) => a.adSpent - b.adSpent,
      render: (adSpent, adGroup) => (
        <>
          <CurrencyValue amount={adSpent} currency={currencySymbol}></CurrencyValue>
        </>
      ),
    },
    {
      title: 'Live Cpc',
      dataIndex: 'liveAverageCpc',
      key: 'liveAverageCpc',
      hidden: ctx.showAttributedData,
      width: 140,
      sorter: (a, b) => a.liveAverageCpc - b.liveAverageCpc,
      render: (v, adGroup) => (
        <>
          <CurrencyValue amount={v} currency={currencySymbol}></CurrencyValue>
        </>
      ),
    },
    {
      title: 'Attributed Impressions',
      dataIndex: 'impressions',
      key: 'impressions',
      hidden: !ctx.showAttributedData,
      width: 120,
      render: (v, adGroup) => <NumberVal clicks={v}></NumberVal>,
      sorter: (a, b) => a.impressions - b.impressions,
    },
    {
      title: 'Attributed Clicks',
      dataIndex: 'clicks',
      key: 'clicks',
      hidden: !ctx.showAttributedData,
      width: 120,
      render: (v, adGroup) => <NumberVal clicks={v}></NumberVal>,
      sorter: (a, b) => a.clicks - b.clicks,
    },
    {
      title: 'Amazon Clickthroughs',
      dataIndex: 'amazonClickThroughs',
      key: 'amazonClickThroughs',
      hidden: true,
      width: 120,
      render: (v, adGroup) => <NumberVal clicks={v}></NumberVal>,
      sorter: (a, b) => a.clicks - b.clicks,
    },
    {
      title: 'Attributed Ad Cost',
      dataIndex: 'adSpent',
      key: 'adSpent',
      hidden: !ctx.showAttributedData,
      width: 120,
      sorter: (a, b) => a.adSpent - b.adSpent,
      render: (adSpent, adGroup) => <CurrencyValue amount={adSpent} currency={currencySymbol}></CurrencyValue>,
    },
    {
      title: 'Attributed Cpc',
      dataIndex: 'averageCpc',
      key: 'averageCpc',
      hidden: !ctx.showAttributedData,
      width: 140,
      sorter: (a, b) => a.averageCpc - b.averageCpc,
      render: (v, adGroup) => <CurrencyValue amount={v} currency={currencySymbol}></CurrencyValue>,
    },
    {
      title: 'Raw Data ',
      width: 140,
      style: { padding: 0 },
      hidden: ctx.showAttributedData,
      className: 'no-padding',
      render: (v, adGroup) => (
        <div>
          <ExportAdGroup adGroup={adGroup} account={props.account} />
        </div>
      ),
    },
    {
      title: 'Add to Cart',
      dataIndex: 'amazonTotalAddToCart',
      key: 'amazonTotalAddToCart',
      hidden: !ctx.showAttributedData,
      className: 'amazon',
      width: 140,
      sorter: (a, b) => a.amazonTotalAddToCart - b.amazonTotalAddToCart,
      render: (v, adGroup) => <NumberVal clicks={v}></NumberVal>,
    },

    {
      title: 'Purchases',
      dataIndex: 'amazonTotalPurchases',
      key: 'amazonTotalPurchases',
      className: 'amazon',
      hidden: !ctx.showAttributedData,
      width: 120,
      sorter: (a, b) => a.amazonTotalPurchases - b.amazonTotalPurchases,
      render: (v, adGroup) => <NumberVal clicks={v}></NumberVal>,
    },
    {
      title: 'Revenue',
      dataIndex: 'amazonTotalSales',
      key: 'amazonTotalSales',
      className: 'amazon',
      hidden: !ctx.showAttributedData,
      width: 120,
      render: (v, adGroup) => <CurrencyValue amount={v} currency={currencySymbol}></CurrencyValue>,
      sorter: (a, b) => a.amazonTotalSales - b.amazonTotalSales,
    },
    {
      title: 'Net Margin',
      dataIndex: 'netMargin',
      key: 'netMargin',
      hidden: !ctx.showAttributedData,
      width: 120,
      sorter: (a, b) => a.netMargin - b.netMargin,
      render: (netMargin) => <CurrencyValue amount={netMargin} currency={currencySymbol}></CurrencyValue>,
    },
    {
      title: 'ROAS',
      dataIndex: 'roas',
      key: 'roas',
      hidden: !ctx.showAttributedData,
      width: 100,
      render: (v, adGroup) => <ROASValue roas={v} />,
      sorter: (a, b) => a.roas - b.roas,
    },
    {
      title: 'ACOS',
      dataIndex: 'acos',
      key: 'acos',
      hidden: !ctx.showAttributedData,
      width: 100,
      render: (v, adGroup) => <ACOSValue acos={v} />,
      sorter: (a, b) => a.acos - b.acos,
    },
    {
      title: (
        <Tooltip placement="bottom" title="On average, Brands can earn a 10% bonus from their qualifying sales">
          <span>
            Average Brand
            <br /> Referral Bonus ℹ
          </span>
        </Tooltip>
      ),
      dataIndex: 'amazonTotalSales',
      key: 'amazonTotalSales',
      hidden: !ctx.showAttributedData,
      width: 160,
      render: (v, adGroup) => (
        <Fragment>
          {adGroup.amazonTotalSales > 0 && (
            <BrbValue
              brbBonusAmountCalculated={adGroup.brbBonusAmountCalculated}
              brbBonusAmount={adGroup.brbBonusAmount}
              currencyCode={currencyCode}
              currencySymbol={currencySymbol}
              brbEnrolled={_.get(
                props.account.productProviders.find((p) => p.id === props.productProviderId),
                'brbEnrolled',
              )}
            />
          )}
        </Fragment>
      ),
      sorter: (a, b) => a.amazonTotalSales - b.amazonTotalSales,
    },
    {
      title: 'CreatedAt',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 120,
      render: (v) => v && moment(v).format(DATE_FORMAT),
    },
    {
      title: '',
      width: 1,
      fixed: 'right',
      render: (v, adGroup: adGroupT) => (
        <FloatingRowAction>
          {(adGroup.status === 4 || adGroup.status === 1) && (
            <Fragment>
              <EditAdGroup account={props.account} adGroup={adGroup} onUpdate={onAdGroupUpdate} />
              <Divider type="vertical"></Divider>
            </Fragment>
          )}
          <Divider type="vertical"></Divider>
          <DeleteAdGroup account={props.account} adGroups={adGroups} setAdGroups={setAdGroups} adGroup={adGroup} />
        </FloatingRowAction>
      ),
    },
  );
  columns = columns.filter((c) => !c.hidden);
  const rowSelection = {
    selectionType: 'checkbox',
    onChange: (selectedRowKeys: any, selectedRows: any) => {
      props.setSelectedRowKeys(selectedRowKeys);
    },
  };

  if (loading) {
    return <SmallPlaceholder />;
  }

  if (!adGroups || adGroups.length === 0) {
    return (
      <Fragment>
        <br />
        <Card style={{ width: '100%', textAlign: 'center' }}>
          <MutedText>
            There is no data for the campaign.
            <br />
            Data are fetched every day for active campaigns.
          </MutedText>
          <br />
        </Card>
        <br />
      </Fragment>
    );
  }

  return (
    <Style>
      <Table
        style={{ cursor: 'pointer' }}
        rowKey="id"
        tableLayout="fixed"
        dataSource={adGroups}
        columns={columns}
        rowSelection={rowSelection}
        pagination={{
          defaultPageSize: 50,
        }}
        onRow={(adGroup, rowIndex) => ({
          onClick: async (event) => {
            const variationsData = await getAllVariationsForAdGroup(
              localStorage,
              axios,
              {
                accountId: props.account.id,
                from: undefined,
                to: undefined,
              },
              adGroup.id,
            );

            if (!variationsData.variations || variationsData.variations.length === 0) {
              return notification.error({
                message: 'No Creative Found',
                description: 'No Creative Found for this Ad Group',
              });
            } else if (variationsData.variations.length > 0) {
              history.push(
                `/${props.account.id}/adsprovider/${props.productProviderId}/${props.productASIN}/${adGroup.providerType}/campaigns/${adGroup.campaignId}/adgroups/${adGroup.id}/variations`,
              );
            }

            return false;
          },
        })}
        rowClassName={(record, index) =>
          `${record.totalCampaignsCount === 0 || !record.lastClicks ? 'row-empty' : ''} ${
            record.outOfLimits ? 'row-out-of-limits' : ''
          }`
        }
      />
    </Style>
  );
}

function ExportAdGroup(props: { account: accountT, adGroup: adGroupT }) {
  const { doAction, ExclusiveInlineLoadingContainer } = useLoading(false);

  return (
    <ExclusiveInlineLoadingContainer>
      <HoverLink
        style={{ width: '100%' }}
        content="Download data"
        onClick={(e) => {
          doAction(async () => {
            const data = await getAdGroupTimeSeries(
              localStorage,
              axios,
              { accountId: props.account.id },
              _.get(props, 'adGroup.id'),
            );

            const result = parse(_.get(data, 'timeseriesAdsReports'), []);

            fileDownload(result, `${_.get(props, 'adGroup.name')}.csv`);
          });
        }}
        children={<Csv />}
      />
    </ExclusiveInlineLoadingContainer>
  );
}
