import React from 'react';

import { DeleteOutlined } from '@ant-design/icons';
import { Avatar, Button, Card, Popconfirm } from 'antd';
import styled from 'styled-components';

import { ExportButton } from 'components/common/buttons/ExportButton';
import { deleteCampaign } from 'lib/adsPlateformApi';
import axios from 'lib/axios.factory';
import { useFeatureFlag, CAMPAIGN_ANALYTICS_UPDATE, HIDE_RESEARCH_ACCOUNT } from 'lib/configcat';
import { getCurrency } from 'utils/currency';

import { CategoryRank } from './CategoryRank';
import type { productT, productStatsT } from '../../../../../../flow-typed/pixelme/adsPlateform';
import { ShowProduct } from '../../common/ShowProduct';
import { NewCampaignButton } from '../../NewCampaign/NewCampaignButton';
import { NewLinkButton } from '../../NewCampaign/NewLinkButton';
import {
  AmazonAdSpendMetrics,
  OrganicAmazonSalesMetrics,
  TotalAdSpendMetrics,
  TotalAmazonSalesMetrics,
} from '../Metrics';
import { GRAPH_TOOLTIP_WIDTH } from '../Style';

const HeaderContainer = styled.div`
  background-color: #fff;
  margin: -1rem;
  margin-bottom: 0.5rem;
  border-bottom: 1px solid #828282;
`;

const Navbar = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem 1rem;
  flex-wrap: wrap;
  padding: 1rem 1.5rem 1rem 3rem;

  h1 {
    font-size: 1rem;
    display: inline;
  }

  .ant-card {
    max-width: 700px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    .ant-card-body {
      padding: 4px 0;
    }
    .ant-card-meta {
      display: flex;
      align-items: center;
    }
  }
`;

const DailyBestSellerRanksContainer = styled.div`
  display: flex;
  padding: 0.75rem 2rem;
  line-height: 1.5rem;

  h2 {
    font-size: 1rem;
    display: flex;
    align-items: center;
    margin-bottom: 0;
    min-width: ${GRAPH_TOOLTIP_WIDTH}px;
  }

  border-top: 1px solid #d9d9d9;
  :not(:first-of-type) {
    border-top-width: 2px;
  }
`;

const DailyBestSellerRanks = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 0.5rem;
  > div {
    border-left: 1px solid #d9d9d9;
    padding: 0 2.5rem;
    :last-child {
      padding-right: 0;
    }
  }
`;

const ProductMetricsContainer = styled(DailyBestSellerRanksContainer)`
  padding-right: 1rem;
  h2 {
    min-width: unset;
    padding-right: 2rem;
    text-wrap: nowrap;
  }
`;

const ProductMetrics = styled(DailyBestSellerRanks)`
  width: 100%;
  > div {
    padding: 0 1rem;
    > div.ant-statistic {
      justify-content: center;
    }
  }
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  column-gap: 1rem;
`;

const FlexGrow = styled(Flex)`
  justify-content: space-between;
  flex: 1;
`;

export const CampaignsDashboardHeader = ({
  account,
  product,
  productStats,
  selectedRowKeys = [],
}: {
  account: { id: string, productProviders: Array<productProviderT> },
  product: productT,
  productStats: productStatsT,
  selectedRowKeys?: string[],
}) => {
  const [showMetricsUpdate] = useFeatureFlag(CAMPAIGN_ANALYTICS_UPDATE);
  const [hideResearchAccount] = useFeatureFlag(HIDE_RESEARCH_ACCOUNT);

  const { currencySymbol } = getCurrency(account);
  const isAmazonProduct = product.type === '';

  const dailyBsrComparisons = product.bsrComparison?.length
    ? product.bsrComparison.filter((bsr) => bsr.lastValue != null && bsr.firstValue != null).slice(0, 2)
    : [];

  return (
    <HeaderContainer>
      <Navbar>
        <Card bordered={false}>
          {/* This Card has max-width and shows ellipsis when smaller */}
          <Card.Meta
            avatar={<Avatar src={product.productImageUrl} />}
            title={<h1 title={product.name}>{product.name}</h1>}
          />
        </Card>
        <FlexGrow>
          {/* Expands remaining width next to title Card */}
          <ShowProduct product={product} />
          <Flex>
            {selectedRowKeys.length > 0 && (
              <Popconfirm
                title="Are you sure to delete all selected campaigns?"
                disabled={selectedRowKeys.length === 0}
                onConfirm={async () => {
                  for (const campaignId of selectedRowKeys) {
                    try {
                      await deleteCampaign(localStorage, axios, { accountId: account.id }, campaignId);
                    } catch (e) {
                      // ignore delete error
                    }
                  }
                  window.location.reload();
                }}
              >
                <Button danger>
                  <DeleteOutlined />
                </Button>
              </Popconfirm>
            )}
            {!hideResearchAccount && <ExportButton account={account} product={product} />}
            <NewLinkButton account={account} product={product} />
            <NewCampaignButton account={account} productId={product.id} />
          </Flex>
        </FlexGrow>
      </Navbar>
      {showMetricsUpdate && isAmazonProduct && (
        <>
          {dailyBsrComparisons.length > 0 && (
            <DailyBestSellerRanksContainer>
              <h2>Daily Best Seller Rank:</h2>
              <DailyBestSellerRanks>
                {dailyBsrComparisons.map((bsr) => (
                  <CategoryRank
                    key={bsr.categoryId}
                    category={bsr.category}
                    rank={bsr.lastValue}
                    previousRank={bsr.firstValue}
                  />
                ))}
              </DailyBestSellerRanks>
            </DailyBestSellerRanksContainer>
          )}
          <ProductMetricsContainer>
            <h2>Product Metrics:</h2>
            <ProductMetrics>
              <AmazonAdSpendMetrics cost={productStats.amazonAdCost} currency={currencySymbol} />
              <TotalAdSpendMetrics cost={productStats.allAdCost} currency={currencySymbol} />
              <TotalAmazonSalesMetrics sales={productStats.totalSales} currency={currencySymbol} />
              <OrganicAmazonSalesMetrics sales={productStats.organicSales} currency={currencySymbol} />
            </ProductMetrics>
          </ProductMetricsContainer>
        </>
      )}
    </HeaderContainer>
  );
};
