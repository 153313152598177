import React, { useState, type Node, useContext, useMemo } from 'react';

import styled from 'styled-components';

import { Skeleton } from 'components/common/antd/Skeleton';
import { DashboardFilterContext } from 'context/DashboardFilterProvider.jsx';
import { useFeatureFlag, CAMPAIGN_ANALYTICS_UPDATE } from 'lib/configcat';
import { getRanges, DATE_FORMAT, RANGES, COMPARISON_LABELS, COMPARISON_DEFAULT } from 'selectors/ranges.selector';

import { KeyMetric } from './KeyMetric';
import { METRICS, TOOLTIPS, roasComparisonFormatter, roasFormatter } from '../constants';

const Container = styled.div`
  display: flex;
  column-gap: 0.625rem;
  overflow: auto;
`;

type propsT = {
  keyMetrics?: KeyMetrics,
  loading: boolean,
};
export const KeyMetricsContainer = ({ keyMetrics, loading = false }: propsT): Node => {
  const [showMetricsUpdate] = useFeatureFlag(CAMPAIGN_ANALYTICS_UPDATE);
  const { ctx } = useContext(DashboardFilterContext);
  const [start, end] = ctx.adsReportsRange;

  const [isBlendedAcos, setIsBlendedAcos] = useState(true);
  const [isBlendedRoas, setIsBlendedRoas] = useState(true);

  const caption = useMemo(() => {
    const ranges = getRanges();

    // Find if the user selected a preset from the date range picker
    for (const [presetName, presetRange] of Object.entries(ranges)) {
      const [rangeStart, rangeEnd]: [moment$Moment, moment$Moment] = presetRange;

      if (
        rangeStart.format(DATE_FORMAT) === start.format(DATE_FORMAT) &&
        rangeEnd.format(DATE_FORMAT) === end.format(DATE_FORMAT)
      ) {
        return COMPARISON_LABELS[presetName];
      }
    }

    return COMPARISON_DEFAULT;
  }, [start, end]);

  const acosProps = keyMetrics?.acos
    ? {
        title: METRICS.ACOS,
        tooltip: TOOLTIPS.ACOS,
        toggleText: METRICS.BLENDED_ACOS,
        value: keyMetrics.acos.now,
        previousValue: keyMetrics.acos.before,
      }
    : null;
  const blendedAcosProps = keyMetrics?.blendedAcos
    ? {
        title: METRICS.BLENDED_ACOS,
        tooltip: TOOLTIPS.BLENDED_ACOS,
        toggleText: METRICS.ACOS,
        value: keyMetrics.blendedAcos.now,
        previousValue: keyMetrics.blendedAcos.before,
      }
    : null;

  const roasProps = keyMetrics?.roas
    ? {
        title: METRICS.ROAS,
        tooltip: TOOLTIPS.ROAS,
        toggleText: METRICS.BLENDED_ROAS,
        value: keyMetrics.roas.now,
        previousValue: keyMetrics.roas.before,
      }
    : null;
  const blendedRoasProps = keyMetrics?.blendedRoas
    ? {
        title: METRICS.BLENDED_ROAS,
        tooltip: TOOLTIPS.BLENDED_ROAS,
        toggleText: METRICS.ROAS,
        value: keyMetrics.blendedRoas.now,
        previousValue: keyMetrics.blendedRoas.before,
      }
    : null;

  if (!showMetricsUpdate || (!loading && !keyMetrics)) {
    return null;
  }

  if (loading) {
    return (
      <Container>
        {/* Match these with the number of Key Metrics to render */}
        {Array.from({ length: 4 }).map((_, i) => (
          <Skeleton key={i} height={110} />
        ))}
      </Container>
    );
  }

  return (
    <Container>
      {acosProps && blendedAcosProps && (
        <KeyMetric
          previousValueCaption={caption}
          onToggle={() => setIsBlendedAcos((prev) => !prev)}
          inverse
          {...(isBlendedAcos ? blendedAcosProps : acosProps)}
        />
      )}
      {roasProps && blendedRoasProps && (
        <KeyMetric
          previousValueCaption={caption}
          onToggle={() => setIsBlendedRoas((prev) => !prev)}
          suffix=""
          valueFormatter={roasFormatter}
          comparisonFormatter={roasComparisonFormatter}
          {...(isBlendedRoas ? blendedRoasProps : roasProps)}
        />
      )}
      {keyMetrics.tacos && (
        <KeyMetric
          title={METRICS.TACOS}
          tooltip={TOOLTIPS.TACOS}
          value={keyMetrics.tacos.now}
          previousValue={keyMetrics.tacos.before}
          previousValueCaption={caption}
          inverse
        />
      )}
      {keyMetrics.troas && (
        <KeyMetric
          title={METRICS.TROAS}
          tooltip={TOOLTIPS.TROAS}
          value={keyMetrics.troas.now}
          previousValue={keyMetrics.troas.before}
          previousValueCaption={caption}
          suffix=""
          valueFormatter={roasFormatter}
          comparisonFormatter={roasComparisonFormatter}
        />
      )}
    </Container>
  );
};
