import React from 'react';

import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { getStringsKey } from 'lib/modalConfig';

import { CampaignModal } from './campaigns/CampaignModal';
import RedirectsList from './campaigns/RedirectsList';
import { Actions } from '../../../../actions';
import { strings } from '../../../../locales/strings';
import Box from '../../../common/Box';
import { LargeModalForRedux } from '../../../common/ModalForRedux';
import OnBoardingPage from '../../onBoarding/onBoarding';

const StyledBox = styled(Box).withConfig({
  displayName: 'StyledBox',
})`
  margin-bottom: 1em;
`;

const CampaignName = styled.span.withConfig({ displayName: 'CampaignName' })`
  color: var(--text-muted);
  margin-bottom: 7px;
  display: inline-block;
`;

const ModalsCampaign = () => {
  const { addCampaignModalConfig, editCampaignModalConfig, deleteCampaignModalConfig } = useSelector((states) => ({
    addCampaignModalConfig: states.front.addCampaignModalConfig,
    editCampaignModalConfig: states.front.editCampaignModalConfig,
    deleteCampaignModalConfig: states.front.deleteCampaignModalConfig,
  }));

  return (
    <>
      <CampaignModal
        mode="add"
        config={addCampaignModalConfig}
        actionSuccess={(payload) => Actions.api.audienceBuilder.campaigns.add.request(payload)}
        actionCancel={(payload) => Actions.front.audienceBuilder.campaigns.add.hide.request(payload)}
      />

      <CampaignModal
        mode="edit"
        config={editCampaignModalConfig}
        actionSuccess={(payload) => Actions.api.audienceBuilder.campaigns.edit.request(payload)}
        actionCancel={(payload) => Actions.front.audienceBuilder.campaigns.edit.hide.request(payload)}
      />
      <LargeModalForRedux
        config={deleteCampaignModalConfig}
        okMessage={strings.get(`${getStringsKey(deleteCampaignModalConfig)}.delete.button`)}
        okStyle={{ backgroundColor: 'var(--bad-color)' }}
        okOnly={true}
        actionSuccess={(_) => Actions.api.audienceBuilder.campaigns.delete.request(deleteCampaignModalConfig)}
        actionCancel={(_) => Actions.front.audienceBuilder.campaigns.delete.hide.request(deleteCampaignModalConfig)}
        title={strings.get(`${getStringsKey(deleteCampaignModalConfig)}.delete.title`)}
      >
        {!!deleteCampaignModalConfig.campaignOrSub && (
          <>
            <span>{`${strings.get(`${getStringsKey(deleteCampaignModalConfig)}.title`)}: `}</span>
            <CampaignName>{deleteCampaignModalConfig.campaignOrSub.name}</CampaignName>
            <div style={{ color: 'var(--text-muted)' }}>
              {strings.get(`${getStringsKey(deleteCampaignModalConfig)}.delete.help`)}
            </div>
          </>
        )}
      </LargeModalForRedux>
    </>
  );
};

const Campaigns = ({ account }) => {
  const { displayOnboarding } = useSelector((states) => ({
    displayOnboarding: !(states.accounts.usage.shortens > 0),
  }));

  return (
    <>
      <StyledBox>
        {displayOnboarding ? (
          <>
            <OnBoardingPage></OnBoardingPage>
            <ModalsCampaign />
          </>
        ) : (
          <RedirectsList account={account} />
        )}
      </StyledBox>
    </>
  );
};

export default Campaigns;
