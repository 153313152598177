import React, { Component } from 'react';

import { connect } from 'react-redux';
import styled from 'styled-components';

import { Actions } from 'actions';
import { getCampaignFromId, getSubCampaignFromId } from 'lib/campaigns';
import { s } from 'lib/safe';
import { selectProductProvider, selectCurrentAccount } from 'stores/accounts';
import { loadRedirectsSuccess, updateRedirect } from 'stores/audienceBuilder/redirects';
import { addNotice } from 'stores/notices';

import { ComposerEditCampaign } from './inputs/ComposerEditCampaign';
import { ComposerEditDomain } from './inputs/ComposerEditDomain';
import { ComposerEditPixels } from './inputs/ComposerEditPixels';
import { ComposerEditRotatorRedirects } from './inputs/ComposerEditRotatorRedirects';
import { ComposerEditSlug } from './inputs/ComposerEditSlug';
import { ComposerEditTitle } from './inputs/ComposerEditTitle';
import { ComposerEditUrl } from './inputs/ComposerEditUrl';
import { ComposerEditUtms } from './inputs/ComposerEditUtms';
import './Composer.css';
import { InputContainer, InputContainerHeader } from './InputWithLabel';
import ComposerEditLink from './inputs/ComposerEditLink';
import { ComposerEditDynamicRedirects } from './inputs/ComposerEditDynamicRedirects';

import { Switch } from 'antd';

const ComposerUtmsPixelDomainContainer = styled.div.withConfig({
  displayName: 'ComposerUtmsPixelDomainContainer',
})`
  display: block;
  grid-column-gap: 50px !important;
  grid-template-areas:
    'utms-title pixel-title'
    'utms-content pixel-content'
    'utms-content domain-title'
    'utms-content domain-content';
`;

const ComposerUrlContainer = styled.div.withConfig({
  displayName: 'ComposerUrlContainer',
})`
  .${InputContainerHeader.styledComponentId} {
    width: 110px;
  }

  .${InputContainer.styledComponentId} {

`;

type internalPropsT = { config: columnsConfigT, selectAction: WorkerAc };
type internalActionsT = { onSelect: Function };
type internalStateT = { config: columnsConfigT };
const defaultState: internalStateT = {
  config: {},
};

class AbstractComposer extends Component<internalPropsT | internalActionsT, internalStateT> {
  state = defaultState;
  render() {
    const { redirect, account, editPreview, activeAmazonProfile } = this.props;

    if (editPreview) {
      return (
        <>
          <div className="composer">
            <ComposerEditLink {...this.props} updateTitle={true} />
          </div>
        </>
      );
    }

    function isAmazonUrl(url) {
      try {
        return new URL(url).hostname.replace('www.', '') === 'amazon.com';
      } catch (e) {
        return false;
      }
    }

    return (
      <>
        <div className="composer">
          <ComposerUrlContainer className="composer__line">
            {!redirect.pageRedirect && <ComposerEditUrl {...this.props} />}

            {isAmazonUrl(redirect.url) && (
              <InputContainer>
                <InputContainerHeader isBig style={{ width: '100%' }}>
                  <label>Deep Link to Amazon Mobile App</label>
                </InputContainerHeader>

                <Switch
                  checkedChildren="On"
                  unCheckedChildren="Off"
                  checked={redirect.useDeepLinks}
                  onChange={(v) => {
                    this.props.onChange({ redirect: { ...redirect, useDeepLinks: v } });
                  }}
                />
              </InputContainer>
            )}

            {!redirect.pageRedirect && redirect.id && (
              <div style={{ maxWidth: '600px' }}>
                <ComposerEditTitle {...this.props} />
              </div>
            )}
            {redirect.id && (
              <div style={{ maxWidth: '600px' }}>
                <ComposerEditSlug {...this.props} account={account} disabled={true} />
              </div>
            )}
          </ComposerUrlContainer>

          {redirect.pageRedirect && <ComposerEditDomain {...this.props} />}
          <>
            <ComposerUtmsPixelDomainContainer>
              {!redirect.pageRedirect && <ComposerEditDomain {...this.props} />}
              <ComposerEditPixels {...this.props} />
              {!redirect.pageRedirect && <ComposerEditUtms {...this.props} />}
            </ComposerUtmsPixelDomainContainer>
            {!redirect.pageRedirect && <ComposerEditCampaign {...this.props} />}
            {redirect.id && <ComposerEditLink {...this.props} />}
            {!redirect.pageRedirect && s(account.limitations).allowDynamicUrl && (
              <ComposerEditDynamicRedirects redirect={redirect} onChange={this.props.onChange} />
            )}
            <ComposerEditRotatorRedirects redirect={redirect} onChange={this.props.onChange} />
          </>
        </div>
      </>
    );
  }
}

const mapStateToProps = function (state): {} {
  return {
    activeAmazonProfile: selectProductProvider(state),
    account: selectCurrentAccount(state),
    limitations: state.accounts.limitations,
    campaigns: state.campaigns.campaigns,
    redirect: state.front.abComposerModalConfig.redirect,
    isFormValid: state.front.abComposerModalConfig.isFormValid,
    selectedCampaign: getCampaignFromId(state.campaigns.selectedCampaignId),
    selectedSubCampaign: getSubCampaignFromId(state.campaigns.selectedSubCampaignId),
    loading: state.front.composerModalConfig.loading,
  };
};

const dispatchToProps = (dispatch: Dispatch<*>, ownProps: Object) => ({
  loadRedirectsSuccess: (payload) => dispatch(loadRedirectsSuccess(payload)),
  updateRedirect: (payload) => dispatch(updateRedirect(payload)),
  addNotice: (payload) => dispatch(addNotice(payload)),
  addCampaign: ({ campaignOrSub, parent }) => {
    dispatch(
      Actions.api.audienceBuilder.campaigns.add.request({
        campaignOrSub,
        parent,
      }),
    );
  },
  // noinspection JSUnusedGlobalSymbols
  switchCampaign: ({ campaign, subCampaign }) =>
    dispatch(
      Actions.front.audienceBuilder.campaigns.select.request({
        campaign,
        subCampaign,
      }),
    ),
  localUpdateOfAccount: ({ account }) => dispatch(Actions.api.account.update.success({ account })),
  onChangeUrl: ({ redirect }) => {
    dispatch(Actions.front.audienceBuilder.redirect.url.update.request({ redirect }));
  },
  onChange: ({ redirect }) => {
    dispatch(Actions.front.audienceBuilder.redirect.update.request({ redirect }));
  },
});

export default connect(mapStateToProps, dispatchToProps, null)(AbstractComposer);
