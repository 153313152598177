import './RedirectCreator.css';

import React, { useState } from 'react';

import Tooltip from 'antd/lib/tooltip';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { Actions } from 'actions';
import Button, { LockedButton } from 'components/common/Button';
import { AffixedInfoCircleFilled } from 'components/common/icons/AffixedInfoCircleFilled';
import SideModal from 'components/common/SideModal';
import { ShortensCTATooltip } from 'components/common/tooltip';
import { strings } from 'locales/strings';
import { store } from 'stores';
import { selectCurrentAccount } from 'stores/accounts';

import Composer from './composer/Composer';
import { ComposerSubmit } from './composer/inputs/ComposerSubmit';

function LinkCreator({
  account,
  composerOpen,
  toggleComposer,
  redirect,
  isFormValid,
  handleSubmit,
  disabled,
  cardRegistered,
  usage,
  limitations,
}: {
  account: accountT,
  composerOpen: boolean,
  toggleComposer: (boolean) => void,
  redirect: redirectT,
  isFormValid: boolean,
  selectedLinks: Array<LinkT>,
  handleSubmit: () => void,
  disabled: boolean,
  cardRegistered: boolean,
  usage: { shortens: number },
  limitations: { shortens: number },
}) {
  const isEditMode = redirect && redirect.id;
  const [editPreview, setEditPreview] = useState(false);

  if (!composerOpen && usage && limitations && limitations.shortens !== 0 && usage.shortens >= limitations.shortens) {
    return (
      <ShortensCTATooltip>
        <LockedButton className="create-redirect__button">{strings.get('composer.button')}</LockedButton>
      </ShortensCTATooltip>
    );
  }

  const title = isEditMode ? strings.get('composer.edit.head') : strings.get('composer.create.head');

  const errorMessage = strings.get('composer.create.cardAlert');
  const tooltipMessage = strings.get('composer.create.cardAlertTooltip');

  return (
    <>
      {/* // TODO: 2 different composers ! Add state to change this css as well */}
      {composerOpen && (
        <div className="create-redirect__modal  with--dropdown">
          <SideModal
            displayButtons={false}
            title={title}
            handleCancel={(_) => {
              toggleComposer(
                false,
                store.getState().campaigns.selectedCampaignId,
                store.getState().campaigns.selectedSubCampaignId,
              );
              setEditPreview(false);
            }}
            footer={
              <>
                {!cardRegistered && (
                  <div className="card-required-message-link">
                    <Link to={`/${account.id}/b/card`}>
                      <label className="card-required-message">{errorMessage}</label>
                      <Tooltip title={tooltipMessage}>
                        <span>
                          <AffixedInfoCircleFilled />
                        </span>
                      </Tooltip>
                    </Link>
                  </div>
                )}
                <ComposerSubmit
                  account={account}
                  redirect={redirect}
                  isFormValid={isFormValid}
                  disabled={disabled || !cardRegistered}
                  handleSubmit={handleSubmit}
                  handlePreview={() => setEditPreview(!editPreview)}
                  editPreview={editPreview}
                />
              </>
            }
          >
            <Composer
              toggleComposer={toggleComposer}
              editPreview={editPreview}
              ref={(element) => {
                if (element) {
                  window.composer = element.getWrappedInstance();
                }
              }}
              handleClose={() =>
                toggleComposer(
                  false,
                  store.getState().campaigns.selectedCampaignId,
                  store.getState().campaigns.selectedSubCampaignId,
                )
              }
            />
          </SideModal>
        </div>
      )}
      <div className="create-redirect__button-container">
        <Button
          className="create-redirect__button"
          handleClick={(_) =>
            toggleComposer(
              true,
              store.getState().campaigns.selectedCampaignId,
              store.getState().campaigns.selectedSubCampaignId,
            )
          }
        >
          {strings.get('composer.button')}
        </Button>
      </div>
    </>
  );
}

const mapStateToProps = function (state): {} {
  return {
    account: selectCurrentAccount(state),
    composerOpen: state.front.abComposerModalConfig.open,
    isFormValid: state.front.abComposerModalConfig.isFormValid,
    redirect: state.front.abComposerModalConfig.redirect,
    limitations: state.accounts.limitations,
    usage: state.accounts.usage,
    disabled: !!state.abRedirects.loading || !!state.campaigns.loading || !!state.front.abComposerModalConfig.loading,
  };
};
const dispatchToProps = (dispatch: Dispatch<*>, ownProps: Object) => ({
  toggleComposer: (open, selectedCampaignId, selectedSubCampaignId) =>
    open
      ? dispatch(
          Actions.front.audienceBuilder.redirect.create.show.request({
            selectedCampaignId,
            selectedSubCampaignId,
          }),
        )
      : dispatch(Actions.front.audienceBuilder.redirect.create.hide.request()),
  handleSubmit: ({ redirect, preview }) =>
    dispatch(Actions.api.audienceBuilder.redirect.edit.request({ redirect, preview })),
});

export default connect(mapStateToProps, dispatchToProps)(LinkCreator);
