import _ from 'lodash';

import { strings } from 'locales/strings';

import { Actions } from '../actions';
import { ALL_REDIRECTS, ALL_REDIRECTS_FOR_CAMPAIGN } from '../consts/consts';
import { getOldCampaignStats, getOldSubCampaignStats, updateCampaignStats } from '../lib/campaigns';

type internalStateT = {
  loading: boolean,
  campaigns: campaignT[],
  selectedRedirects: redirectT[],
  selectedCampaignId: string,
  selectedSubCampaignId: string,
};

// STORE
export const initialFrontState: stateT = {
  loading: false,
  campaigns: [],
  selectedRedirects: [],
  selectedCampaignId: ALL_REDIRECTS,
  selectedSubCampaignId: ALL_REDIRECTS_FOR_CAMPAIGN,
};

export function campaigns(state: internalStateT, action: actionT): stateT {
  if (!state) {
    state = initialFrontState;
  }

  switch (action.type) {
    case Actions.front.audienceBuilder.campaigns.toggleRedirect.request().type: {
      if (action.payload && action.payload.redirect) {
        const updatedRedirect = { ...action.payload.redirect };
        const selectedRedirects = state.selectedRedirects.filter(
          (redirect) => redirect && redirect.id !== updatedRedirect.id,
        );

        if (selectedRedirects.length === state.selectedRedirects.length) {
          selectedRedirects.push(updatedRedirect);
        }

        return {
          ...state,
          selectedRedirects,
        };
      } else if (action.payload && action.payload.redirects && action.payload.redirects.length > 0) {
        const selectedRedirects = [...action.payload.redirects];

        return {
          ...state,
          selectedRedirects,
        };
      } else {
        return {
          ...state,
          selectedRedirects: [],
        };
      }
    }

    case Actions.api.audienceBuilder.campaigns.load.success().type:
      let { campaigns } = action.payload;

      campaigns.push({
        id: ALL_REDIRECTS,
        name: strings.get('campaigns.allCampaigns'),
        position: -1,
      });
      campaigns = _.orderBy(action.payload.campaigns, ['position'], ['asc']);
      campaigns.forEach((campaign) => {
        campaign.stats = getOldCampaignStats(campaign.id, state);
        campaign.subCampaigns = [..._.orderBy(campaign.subCampaigns, ['position'], ['asc'])];
        campaign.subCampaigns.forEach((subCampaign) => {
          subCampaign.stats = getOldSubCampaignStats(subCampaign.id, state);
        });
      });

      return {
        ...state,
        campaigns,
      };

    case Actions.front.audienceBuilder.campaigns.select.request().type:
      return {
        ...state,
        selectedCampaignId: action.payload.campaignId,
        selectedSubCampaignId: action.payload.subCampaignId ? action.payload.subCampaignId : ALL_REDIRECTS_FOR_CAMPAIGN,
      };

    case Actions.api.audienceBuilder.redirect.editSelected.success().type:
    case Actions.api.audienceBuilder.campaigns.addRedirects.success().type:
      return {
        ...state,
        selectedRedirects: [],
      };
    case Actions.api.audienceBuilder.campaigns.reloadAllStats.request().type:
    case Actions.api.audienceBuilder.campaigns.loadStats.request().type:
      return {
        ...state,
        loading: true,
      };
    case Actions.api.audienceBuilder.campaigns.reloadAllStats.error().type:
    case Actions.api.audienceBuilder.campaigns.loadStats.error().type:
      return {
        ...state,
        loading: false,
      };
    case Actions.api.audienceBuilder.campaigns.reloadAllStats.success().type:
    case Actions.api.audienceBuilder.campaigns.loadStats.success().type:
      const { stats } = action.payload;

      const updatedCampaigns = updateCampaignStats(state.campaigns, stats);

      return {
        ...state,
        campaigns: updatedCampaigns,
        loading: false,
      };

    case Actions.api.audienceBuilder.redirect.load.request().type:
    case Actions.api.audienceBuilder.redirect.delete.success().type: {
      return {
        ...state,
        selectedRedirects: [],
      };
    }

    case Actions.front.audienceBuilder.redirect.delete.show.request().type: {
      const { redirect } = action.payload;

      let { selectedRedirects } = state;

      if (redirect) {
        selectedRedirects = [redirect];
      }

      return {
        ...state,
        selectedRedirects,
      };
    }

    default:
      return state;
  }
}
