import './Content.css';
import React, { Component, useMemo } from 'react';

import { connect, useSelector } from 'react-redux';
import { Location, Redirect, Route, Switch, withRouter } from 'react-router-dom';

import AudienceBuilderAnalytics from 'components/pages/analytics/Analytics'; // TODO: Change path
import AudienceBuilderImport from 'components/pages/AudienceBuilder/BulkImport/';
import AudienceBuilderCampaigns from 'components/pages/AudienceBuilder/Campaigns';
import AudienceBuilderRedirectCreator from 'components/pages/AudienceBuilder/Campaigns/RedirectCreator';
import AudienceBuilderSettings from 'components/pages/AudienceBuilder/Settings';
import CardDetails from 'components/pages/billing/CardDetails';
import ErrorPaiementModal from 'components/pages/billing/ErrorPaiementModal/ErrorPaiementModal';
import Invoices from 'components/pages/billing/Invoices';
import Lifetime from 'components/pages/billing/Lifetime';
import { Ltdf } from 'components/pages/billing/Ltdf';
import Plans from 'components/pages/billing/Plans';
import SepaDetails from 'components/pages/billing/SepaDetails';
import UsagePage from 'components/pages/billing/UsagePage/UsagePage';
import { SearchButton } from 'components/pages/redirects/SearchButton';
import Profile from 'components/pages/settings/Profile';
import TeamMembers from 'components/pages/settings/teamMembers/TeamMembers';
import { s, sn } from 'lib/safe';
import { isLTDF } from 'selectors/sub.selector';
import {
  addBankAccount,
  addCard,
  callAddCoupon,
  callSubscribe,
  callTrial,
  loadBankAccount,
  loadCard,
  loadOrgCoupon,
  loadVAT,
  setDefaultCard,
} from 'stores/subscription';

import { CampaignBreadCrumb } from './CampaignBreadCrumb';

export function SettingsContent({ account, match }: { account: accountT, match: Match }) {
  return (
    <div className="content">
      <Switch>
        <Route path={`${match.url}/team`} render={(props) => <TeamMembers account={account} />} />
        <Route path={`${match.url}/profile`} render={(props) => <Profile account={account} />} />
        <Redirect to={`${match.url}/team`} />
      </Switch>
    </div>
  );
}

function AudienceBuilderContent({
  account,
  match,
  loadCard,
}: {
  account: accountT,
  match: Match,
  loadCard: ({ accountId: string }) => void,
}) {
  useMemo(() => {
    loadCard({ accountId: account.id });
  }, [account]);

  const { needsCard } = useSelector((states) => {
    const planID = sn(states.subscription, 'sub.plan.id', '');

    return {
      needsCard: s(states.subscription.card).name === undefined && !planID.startsWith('lifetimedeal_'),
    };
  });

  return (
    <div className="content">
      <Switch>
        <Route
          path={`${match.url}/campaigns`}
          render={() => (
            <>
              <nav className="content__breadcrumb box">
                <CampaignBreadCrumb />
                <SearchButton />
                <AudienceBuilderRedirectCreator cardRegistered={!needsCard} />
                <ErrorPaiementModal />
              </nav>
              <AudienceBuilderCampaigns account={account} />
            </>
          )}
        />
        <Route
          path={`${match.url}/analytics/:entity?/:redirectId?`}
          render={(props) => <AudienceBuilderAnalytics account={account} match={props.match} />}
        />
        <Route path={`${match.url}/bulk-import`} render={() => <AudienceBuilderImport account={account} />} />
        <Route
          path={`${match.url}/settings`}
          render={() => <AudienceBuilderSettings account={account} match={match} />}
        />
        {/* Default redir */}
        <Redirect to={`${match.url}/campaigns`} />
      </Switch>
    </div>
  );
}

const AudienceBuilderContentC = connect(null, { loadCard })(AudienceBuilderContent);

export { AudienceBuilderContentC as AudienceBuilderContent };

class BillingContent extends Component<{
  user: userT,
  account: accountT,
  sub: subscriptionT,
  plans: Array<planT>,
  card: cardT,
  updating: boolean,
  cardUpdating: boolean,
  orgCoupon: orgCouponT,
  orgCouponLoading: boolean,
  match: Match,
  location: Location,
  addCard: ({ accountId: string, token: string }) => void,
  loadCard: ({ accountId: string }) => void,
  loadOrgCoupon: ({ accountId: string }) => void,
  loadVAT: ({ accountId: string }) => void,
  callSubscribe: ({ accountId: string, coupon: string, plan: planT, vat: vatT, token?: string }) => void,
  callTrial: ({ accountId: string, plan: planT }) => void,
  callAddCoupon: ({ accountId: string, orgId: string, code: string }) => void,
  setDefaultCard: ({ accountId: string, setupIntent: string }) => void,
}> {
  render() {
    const {
      user,
      account,
      sub,
      plans,
      orgCoupon,
      updating,
      cardUpdating,
      orgCouponLoading,
      bankAccountUpdating,
      match,
      card,
      bankAccount,
      vat,
      addCard,
      addBankAccount,
      callSubscribe,
      callTrial,
      callAddCoupon,
      limitations,
      setDefaultCard,
    } = this.props;

    if (sub === undefined) {
      return null;
    }

    if (isLTDF(sub) || window.location.href.indexOf('ltdf') !== -1) {
      return (
        <Switch>
          <Route
            path={`${match.url}/ltdf`}
            render={() => (
              <Ltdf
                user={user}
                account={account}
                plans={plans}
                updating={updating}
                sub={sub}
                limitations={limitations}
                addCoupon={(code: string) => {
                  callAddCoupon({
                    accountId: account.id,
                    orgId: Object.keys(user.orgsRights)[0],
                    code,
                  });
                }}
              />
            )}
          />
          <Route path={`${match.url}/usage`} render={() => <UsagePage></UsagePage>} />
          <Redirect to={`${match.url}/ltdf`} />
        </Switch>
      );
    }

    if (sub.status === 'lifetime_coupon' || sub.status === 'revoked') {
      return (
        <Switch>
          <Route
            path={`${match.url}/plans`}
            render={() => (
              <div className="content">
                <Lifetime
                  updating={updating}
                  sub={sub}
                  limitations={limitations}
                  addCoupon={(code: string) => {
                    callAddCoupon({
                      accountId: account.id,
                      orgId: Object.keys(user.orgsRights)[0],
                      code,
                    });
                  }}
                />
              </div>
            )}
          />
          <Route
            path={`${match.url}/ltdf`}
            render={(props) => (
              <div className="content">
                <Lifetime
                  updating={updating}
                  sub={sub}
                  limitations={limitations}
                  addCoupon={(code: string) => {
                    callAddCoupon({
                      accountId: account.id,
                      orgId: Object.keys(user.orgsRights)[0],
                      code,
                    });
                  }}
                />
              </div>
            )}
          />

          <Route path={`${match.url}/usage`} render={() => <UsagePage></UsagePage>} />
          <Redirect to={`${match.url}/plans`} />
        </Switch>
      );
    }

    const queryParameters = new URLSearchParams(location.search);

    return (
      <div className="content">
        <Switch>
          <Route path={`${match.url}/invoices`} render={() => <Invoices account={account} user={user} />} />

          <Route path={`${match.url}/usage`} render={() => <UsagePage></UsagePage>} />
          <Route
            path={`${match.url}/plans`}
            render={() => (
              <Plans
                user={user}
                account={account}
                sub={sub}
                plans={plans}
                orgCoupon={orgCoupon}
                card={card}
                vat={vat}
                updating={updating || cardUpdating || orgCouponLoading}
                callSubscribe={callSubscribe}
                callTrial={callTrial}
                sessionId={queryParameters.get('session_id')}
              />
            )}
          />
          {!limitations.allowSepa && (
            <Route
              path={`${match.url}/card`}
              render={() => (
                <CardDetails
                  updating={updating || cardUpdating}
                  card={card}
                  setDefaultCard={setDefaultCard}
                  account={account}
                  user={user}
                />
              )}
            />
          )}
          {limitations.allowSepa && (
            <Route
              path={`${match.url}/sepa`}
              render={() => (
                <SepaDetails
                  vat={vat}
                  accountId={account.id}
                  bankAccountUpdating={bankAccountUpdating}
                  addBankAccount={addBankAccount}
                  bankAccount={bankAccount}
                />
              )}
            />
          )}

          <Redirect to={`${match.url}/plans`} />
        </Switch>
      </div>
    );
  }

  componentDidMount() {
    this.props.loadCard({ accountId: this.props.account.id });
    this.props.loadOrgCoupon({ accountId: this.props.account.id });
    this.props.loadVAT({ accountId: this.props.account.id });
    this.props.loadBankAccount({ accountId: this.props.account.id });
  }
}

const mapBillingStateToProps = function (state): { card: cardT, plans: Array<planT>, updating: boolean } {
  const {
    card,
    bankAccount,
    plans,
    updating,
    cardUpdating,
    bankAccountUpdating,
    sub,
    vat,
    orgCoupon,
    orgCouponLoading,
  } = state.subscription;
  const { user } = state.user;
  const { limitations } = state.accounts;

  return {
    card,
    bankAccount,
    sub,
    vat,
    plans,
    updating,
    user,
    cardUpdating,
    bankAccountUpdating,
    limitations,
    orgCoupon,
    orgCouponLoading,
  };
};

const BillingContentC = withRouter(
  connect(mapBillingStateToProps, {
    addCard,
    loadCard,
    loadOrgCoupon,
    addBankAccount,
    loadBankAccount,
    loadVAT,
    callSubscribe,
    callTrial,
    callAddCoupon,
    setDefaultCard,
  })(BillingContent),
);

export { BillingContentC as BillingContent };
