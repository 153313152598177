import React, { useState, Fragment } from 'react';

import { Divider, Form, message, Select } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import Modal from 'antd/lib/modal/Modal';
import styled from 'styled-components';

import axios from 'lib/axios.factory';

import { editAdGroup } from '../../../../../lib/adsPlateformApi';
import { getCurrencyCode } from '../../../../../lib/amazon';
import { CurrencyInput } from '../../../../common/antd/inputs/CurrencyInput';

const Title = styled.div`
  margin: 0 0 0.6em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  word-wrap: break-word;
`;

export function AdGroupEditModal(props: {
  account: accountT,
  adGroup: adGroupT,
  onFinish: Function,
  onUpdate: (a: adGroupT) => void,
  visible: boolean,
}) {
  const [form] = useForm();

  const currencyCode = getCurrencyCode(props.account.productProviders);
  const [saving, setSaving] = useState(false);

  const options = [
    { value: 1, label: 'Paused' },
    { value: 4, label: 'Active' },
  ];

  return (
    <Modal
      visible={props.visible}
      title="Edit Ad Group"
      width="580px"
      height="100vh"
      okButtonProps={{ loading: saving }}
      onCancel={props.onFinish}
      onOk={form.submit}
    >
      <Form
        layout="vertical"
        form={form}
        initialValues={{
          budget: props.adGroup.budgetInCents,
          status: props.adGroup.status,
        }}
        onFinish={async (values) => {
          setSaving(true);

          try {
            const response = await editAdGroup(
              localStorage,
              axios,
              { accountId: props.account.id },
              {
                budgetInCents: Math.round(values.budget),
                status: values.status,
              },
              props.adGroup.id,
            );

            message.success('Ad Group updated');
            props.onUpdate(response.adGroup);
            // Close modal
            props.onFinish();
          } catch (e) {
            message.error('Error on Campaign Budget');

            throw e;
          } finally {
            setSaving(false);
          }
        }}
      >
        <Fragment>
          <Form.Item label="Daily Budget" name="budget">
            <CurrencyInput currencyCode={currencyCode} unit={100}></CurrencyInput>
          </Form.Item>
          <Form.Item label="Ad Group Status" name="status">
            <Select style={{ width: 110 }} options={options} />
          </Form.Item>
        </Fragment>
      </Form>

      <Divider orientation="left"></Divider>

      <Title>Ad Group Settings</Title>
      <div>
        <b>Ad Group Name:</b> {props.adGroup.name}
      </div>
    </Modal>
  );
}
