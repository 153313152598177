import React from 'react';

import { strings } from 'locales/strings';

import { BarToolTip } from '../BarToolTip';

export const Accounts = () => (
  <BarToolTip title={strings.get('head.accounts')}>
    <svg
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      className="bar__icon"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="33" height="33" rx="4" className="icon-background" />
      <g>
        <path
          d="M9.5 25.5C8.95 25.5 8.47917 25.3042 8.0875 24.9125C7.69583 24.5208 7.5 24.05 7.5 23.5V9.5C7.5 8.95 7.69583 8.47917 8.0875 8.0875C8.47917 7.69583 8.95 7.5 9.5 7.5H23.5C24.05 7.5 24.5208 7.69583 24.9125 8.0875C25.3042 8.47917 25.5 8.95 25.5 9.5V23.5C25.5 24.05 25.3042 24.5208 24.9125 24.9125C24.5208 25.3042 24.05 25.5 23.5 25.5H9.5ZM9.5 23.5H12.825V20.175H9.5V23.5ZM14.825 23.5H18.175V20.175H14.825V23.5ZM20.175 23.5H23.5V20.175H20.175V23.5ZM9.5 18.175H12.825V14.825H9.5V18.175ZM14.825 18.175H18.175V14.825H14.825V18.175ZM20.175 18.175H23.5V14.825H20.175V18.175ZM9.5 12.825H12.825V9.5H9.5V12.825ZM14.825 12.825H18.175V9.5H14.825V12.825ZM20.175 12.825H23.5V9.5H20.175V12.825Z"
          className="icon-main-fill"
        />
      </g>
    </svg>
  </BarToolTip>
);
