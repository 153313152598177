import { type Node } from 'react';

import { Modal, Input, Form, Button } from 'antd';
import { useToggle } from 'react-use';
import styled from 'styled-components';

import { strings } from 'locales/strings';

import { MATCH_TYPE_DEFAULT, MatchTypeLabel } from './constants';
import { MatchTypeSelect } from './MatchTypeSelect';
import { authorizedTextInKeywordsWithNewline } from '../constants';

const StyledButton = styled(Button)`
  span {
    text-transform: capitalize;
  }
`;

export const BulkAddKeywords = ({ onSubmit }: { onSubmit: (string[], string) => void }): Node => {
  const [isModalOpen, toggleModal] = useToggle(false);
  const [form] = Form.useForm();

  const handleClose = () => {
    form.resetFields();
    toggleModal();
  };

  const handleSubmit = (values) => {
    const { keywords, matchType } = values;

    onSubmit(
      keywords
        .split('\n')
        .map((kw) => kw.trim())
        .filter(Boolean),
      matchType,
    );
    handleClose();
  };

  return (
    <>
      <StyledButton type="primary" ghost onClick={toggleModal}>
        {strings.get('campaigns.keywords.bulkAdd')}
      </StyledButton>
      <Modal
        title={strings.get('campaigns.keywords.bulkAdd')}
        visible={isModalOpen}
        okText={strings.get('global.add')}
        onOk={form.submit}
        onCancel={handleClose}
        destroyOnClose // Allows input to auto focus on subsequent opens
      >
        <Form
          form={form}
          initialValues={{ keywords: '', matchType: MATCH_TYPE_DEFAULT }}
          onFinish={handleSubmit}
          layout="vertical"
          requiredMark={false}
        >
          <Form.Item
            label={strings.get('campaigns.keywords.label')}
            name="keywords"
            rules={[
              {
                required: true,
                pattern: authorizedTextInKeywordsWithNewline,
                min: 1,
                max: 100,
                message: strings.get('campaigns.keywords.invalid'),
              },
            ]}
          >
            <Input.TextArea
              autoSize={{ minRows: 3, maxRows: 8 }}
              placeholder={strings.get('campaigns.keywords.bulkAddPlaceholder')}
              autoFocus
            />
          </Form.Item>
          <Form.Item label={<MatchTypeLabel />} name="matchType" rules={[{ required: true }]}>
            {MatchTypeSelect}
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
