import React, { Fragment } from 'react';

import qs from 'query-string';
import { Location, Match, Route, RouterHistory, Switch } from 'react-router-dom';
import styled from 'styled-components';

import DashboardFilterProvider from 'context/DashboardFilterProvider';

import { AdsProviderPageSwitcher } from './AdsProviderPageSwitcher';
import { AdsProviderDashBoardPage } from './Dashboard/AdsProviderDashBoardPage';
import { FacebookNewAdsCampaignPage } from './Facebook/FacebookNewAdsCampaignPage';
import { FacebookSettingsPage } from './Facebook/FacebookSettingsPage';
import { FacebookSuccess } from './Facebook/FacebookSuccess';
import { TiktokNewAdsCampaignPage } from './Tiktok/TiktokNewAdsCampaignPage';
import { TiktokSuccess } from './Tiktok/TiktokSuccess';
import { TiktokSettingsPage } from './Tiktok/TiktokSettingsPage';
import { GenericAdsProviderNewAdsCampaignPage } from './GenericAdsProvider/GenericAdsProviderNewAdsCampaignPage';

import './AdsProviderContent.css';
import { GoogleImportAdsCampaignPage } from './Google/GoogleImportAdsCampaignPage';
import { GoogleNewAdsCampaignPage } from './Google/GoogleNewAdsCampaignPage';
import { GoogleSettingsPage } from './Google/GoogleSettingsPage';
import { GoogleSuccess } from './Google/GoogleSuccess';
import { NewCampaignSuccess } from './NewCampaign/NewCampaignSuccess';
import { AdsProviderSettingsPage } from './Settings/AdsProviderSettingsPage';
import { TiktokImportAdsCampaignPage } from './Tiktok/TiktokImportAdsCampaignPage';
import { BigSubMenu } from '../../layout/BigSubMenu';
import { withTrial } from '../../layout/MainLayout';
import { MenuItem } from '../../layout/MenuItem';
import { TopContent } from '../../layout/TopContent';

const Style = styled.div`
  min-height: 100%;
  min-width: 1000px;
  overflow-x: auto;
  height: 100%;
  //do not touch, its for estimated placing

  .ant-statistic {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: space-between;
    height: 100%;
  }
  .ant-statistic-content-value,
  .ant-statistic-content-suffix,
  .ant-statistic-content-prefix {
    font-size: 12px;
    font-weight: normal;
  }
  .ant-statistic-title {
    color: #595959;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .ant-statistic:not(:last-child) {
    border-right: 1px solid #f2f2f2;
  }
  .ant-statistic {
    text-align: center;
  }
  .ant-table-cell .ant-statistic {
    height: 100%;
    display: flex;
    align-items: center;
  }
  .ant-table-cell .ant-statistic-content {
    height: 100%;
    display: flex;
    align-items: center;
  }
  .ant-table-tbody > tr > td {
    border-right: 1px solid #eee;
  }
  .ant-drawer-close,
  .ant-drawer-header-close-only {
    z-index: 9;
  }
  .ant-drawer-body {
    padding: 1rem;
  }
  .ant-table-cell {
    height: 69px;
  }
`;

export const withAdsProviderTopContent = (props: any, renderChildren: Function) => {
  props.title = () => <div style={{ display: 'flex', gap: '10px' }}>Amazon Attribution Campaigns</div>;
  props.renderSubMenu = () => (
    <BigSubMenu>
      <MenuItem label="Dashboard" to={`/${props.account.id}/adsprovider/dashboard`} />
      <MenuItem label="Settings" to={`/${props.account.id}/adsprovider/settings`} />
    </BigSubMenu>
  );

  return (
    <TopContent {...props} hideUsage>
      {renderChildren(props)}
    </TopContent>
  );
};

export function AdsProviderContent({ match, routeProps, account, user }) {
  return (
    <Fragment>
      <DashboardFilterProvider account={account}>
        <Switch>
          <Route
            path={`${match.url}/google/success`}
            render={({ match, history, location }: { match: Match, history: RouterHistory, location: Location }) =>
              withAdsProviderTopContent(
                {
                  ...routeProps,
                  match,
                },
                (props) =>
                  withTrial(props, ({ account, match }) => (
                    <Fragment>
                      <Style>
                        <GoogleSuccess accountId={account.id} />
                      </Style>
                    </Fragment>
                  )),
              )
            }
          />
          <Route
            path={`${match.url}/facebook/success`}
            render={({ match, history, location }: { match: Match, history: RouterHistory, location: Location }) =>
              withAdsProviderTopContent(
                {
                  ...routeProps,
                  match,
                },
                (props) =>
                  withTrial(props, ({ account, match }) => (
                    <Fragment>
                      <Style>
                        <FacebookSuccess account={account} />
                      </Style>
                    </Fragment>
                  )),
              )
            }
          />
          <Route
            path={`${match.url}/facebook`}
            render={({ match, history, location }: { match: Match, history: RouterHistory, location: Location }) => (
              <Fragment>
                <Style>
                  <FacebookSettingsPage accountId={account.id} />
                </Style>
              </Fragment>
            )}
          />
          <Route
            path={`${match.url}/google`}
            render={({ match, history, location }: { match: Match, history: RouterHistory, location: Location }) => (
              <Fragment>
                <Style>
                  <GoogleSettingsPage accountId={account.id} />
                </Style>
              </Fragment>
            )}
          />
          <Route
            path={`${match.url}/tiktok/success`}
            render={({ match, history, location }: { match: Match, history: RouterHistory, location: Location }) =>
              withAdsProviderTopContent(
                {
                  ...routeProps,
                  match,
                },
                (props) =>
                  withTrial(props, ({ account, match }) => (
                    <Fragment>
                      <Style>
                        <TiktokSuccess accountId={account.id} />
                      </Style>
                    </Fragment>
                  )),
              )
            }
          />
          <Route
            path={`${match.url}/settings`}
            render={({ match }) =>
              withAdsProviderTopContent(
                {
                  ...routeProps,
                  match,
                },
                (props) =>
                  withTrial(props, ({ account, match }) => (
                    <Style>
                      <AdsProviderSettingsPage account={account} match={match} />
                    </Style>
                  )),
              )
            }
          />
          <Route
            path={`${match.url}/new-tiktok-campaign-success/:campaignId`}
            render={({ match, location }) => {
              const { productProviderId, productExternalId } = qs.parse(location.search) || {};

              return withAdsProviderTopContent(
                {
                  ...routeProps,
                  match,
                },
                (props) =>
                  withTrial(props, ({ account, match }) => (
                    <Style>
                      <NewCampaignSuccess
                        campaignId={match.params.campaignId}
                        productProviderId={productProviderId}
                        productExternalId={productExternalId}
                        adsProviderType="TIKTOK"
                        account={account}
                      />
                    </Style>
                  )),
              );
            }}
          />
          <Route
            path={`${match.url}/new-generic-campaign-success/:campaignId`}
            render={({ match, location }) => {
              const { productProviderId, productExternalId } = qs.parse(location.search) || {};

              return withAdsProviderTopContent(
                {
                  ...routeProps,
                  match,
                },
                (props) =>
                  withTrial(props, ({ account, match }) => (
                    <Style>
                      <NewCampaignSuccess
                        campaignId={match.params.campaignId}
                        productProviderId={productProviderId}
                        productExternalId={productExternalId}
                        adsProviderType="GENERIC"
                        account={account}
                      />
                    </Style>
                  )),
              );
            }}
          />
          <Route
            path={`${match.url}/new-google-campaign-success/:campaignId`}
            render={({ match, location }) => {
              const { productProviderId, productExternalId } = qs.parse(location.search) || {};

              return withAdsProviderTopContent(
                {
                  ...routeProps,
                  match,
                },
                (props) =>
                  withTrial(props, ({ account, match }) => (
                    <Style>
                      <NewCampaignSuccess
                        campaignId={match.params.campaignId}
                        productProviderId={productProviderId}
                        productExternalId={productExternalId}
                        adsProviderType="GOOGLE"
                        account={account}
                      />
                    </Style>
                  )),
              );
            }}
          />
          <Route
            path={`${match.url}/new-facebook-campaign-success/:campaignId`}
            render={({ match, location }) => {
              const { productProviderId, productExternalId } = qs.parse(location.search) || {};

              return withAdsProviderTopContent(
                {
                  ...routeProps,
                  match,
                },
                (props) =>
                  withTrial(props, ({ account, match }) => (
                    <Style>
                      <NewCampaignSuccess
                        campaignId={match.params.campaignId}
                        productProviderId={productProviderId}
                        productExternalId={productExternalId}
                        adsProviderType="FACEBOOK"
                        account={account}
                      />
                    </Style>
                  )),
              );
            }}
          />
          <Route
            path={`${match.url}/new-google-campaign/:campaignId`}
            render={({ match }) =>
              withAdsProviderTopContent(
                {
                  ...routeProps,
                  match,
                },
                (props) =>
                  withTrial(props, ({ account, match }) => (
                    <Style>
                      <GoogleNewAdsCampaignPage account={account} campaignId={match.params.campaignId} />
                    </Style>
                  )),
              )
            }
          />
          <Route
            path={`${match.url}/new-tiktok-campaign/:campaignId`}
            render={({ match }) =>
              withAdsProviderTopContent(
                {
                  ...routeProps,
                  match,
                },
                (props) =>
                  withTrial(props, ({ account, match }) => (
                    <Style>
                      <TiktokNewAdsCampaignPage account={account} campaignId={match.params.campaignId} />
                    </Style>
                  )),
              )
            }
          />
          <Route
            path={`${match.url}/import-tiktok-campaign`}
            render={({ match, location }) => {
              const { productId } = qs.parse(location.search) || {};

              return withAdsProviderTopContent(
                {
                  ...routeProps,
                  match,
                },
                (props) =>
                  withTrial(props, ({ account, match }) => (
                    <Style>
                      <TiktokImportAdsCampaignPage productId={productId} account={account} />
                    </Style>
                  )),
              );
            }}
          />
          <Route
            path={`${match.url}/import-google-campaign`}
            render={({ match, location }) => {
              const { productId } = qs.parse(location.search) || {};

              return withAdsProviderTopContent(
                {
                  ...routeProps,
                  match,
                },
                (props) =>
                  withTrial(props, ({ account, match }) => (
                    <Style>
                      <GoogleImportAdsCampaignPage productId={productId} account={account} />
                    </Style>
                  )),
              );
            }}
          />
          <Route
            path={`${match.url}/new-generic-campaign/:campaignId`}
            render={({ match }) =>
              withAdsProviderTopContent(
                {
                  ...routeProps,
                  match,
                },
                (props) =>
                  withTrial(props, ({ account, match }) => (
                    <Style>
                      <GenericAdsProviderNewAdsCampaignPage account={account} campaignId={match.params.campaignId} />
                    </Style>
                  )),
              )
            }
          />
          <Route
            path={`${match.url}/new-facebook-campaign/:campaignId`}
            render={({ match }) =>
              withAdsProviderTopContent(
                {
                  ...routeProps,
                  match,
                },
                (props) =>
                  withTrial(props, ({ account, match }) => (
                    <Style>
                      <FacebookNewAdsCampaignPage account={account} campaignId={match.params.campaignId} />
                    </Style>
                  )),
              )
            }
          />
          <Route
            path={`${match.url}/new-tiktok-campaign`}
            render={({ match, location }) => {
              const { productId } = qs.parse(location.search) || {};

              return withAdsProviderTopContent(
                {
                  ...routeProps,
                  match,
                },
                (props) =>
                  withTrial(props, ({ account, match }) => (
                    <Style>
                      <TiktokNewAdsCampaignPage productId={productId} account={account} />
                    </Style>
                  )),
              );
            }}
          />
          <Route
            path={`${match.url}/new-generic-campaign`}
            render={({ match, location }) => {
              const { productId } = qs.parse(location.search) || {};

              return withAdsProviderTopContent(
                {
                  ...routeProps,
                  match,
                },
                (props) =>
                  withTrial(props, ({ account, match }) => (
                    <Style>
                      <GenericAdsProviderNewAdsCampaignPage productId={productId} account={account} />
                    </Style>
                  )),
              );
            }}
          />
          <Route
            path={`${match.url}/new-google-campaign`}
            render={({ match, location }) => {
              const { productId } = qs.parse(location.search) || {};

              return withAdsProviderTopContent(
                {
                  ...routeProps,
                  match,
                },
                (props) =>
                  withTrial(props, ({ account, match }) => (
                    <Style>
                      <GoogleNewAdsCampaignPage productId={productId} account={account} />
                    </Style>
                  )),
              );
            }}
          />
          <Route
            path={`${match.url}/new-facebook-campaign`}
            render={({ match, location }) => {
              const { productId } = qs.parse(location.search) || {};

              return withAdsProviderTopContent(
                {
                  ...routeProps,
                  match,
                },
                (props) =>
                  withTrial(props, ({ account, match }) => (
                    <Style>
                      <FacebookNewAdsCampaignPage productId={productId} account={account} />
                    </Style>
                  )),
              );
            }}
          />
          <Route
            path={[
              `${match.url}/dashboard`,
              `${match.url}/:providerId/:productASIN/:adsProviderType/campaigns/:campaignId/adgroups/:adGroupId/variations`,
              `${match.url}/:providerId/:productASIN/:adsProviderType/campaigns/:campaignId/adgroups`,
              `${match.url}/:providerId/:productASIN/campaigns`,
            ]}
            render={({ match }) =>
              withAdsProviderTopContent(
                {
                  ...routeProps,
                  match,
                },
                (props) =>
                  withTrial(props, ({ account, match }) => (
                    <Style>
                      <AdsProviderDashBoardPage account={account} match={match} />
                    </Style>
                  )),
              )
            }
          />
          <Route
            path={`${match.url}/tiktok`}
            render={({ match, history, location }: { match: Match, history: RouterHistory, location: Location }) => (
              <Fragment>
                <Style>
                  <TiktokSettingsPage accountId={account.id} />
                </Style>
              </Fragment>
            )}
          />
          <Route
            path={`${match.url}/`}
            render={({ match }) =>
              withAdsProviderTopContent(
                {
                  ...routeProps,
                  match,
                },
                (props) =>
                  withTrial(props, ({ account, match }) => (
                    <Style>
                      <AdsProviderPageSwitcher account={account} match={match} />
                    </Style>
                  )),
              )
            }
          />
        </Switch>
      </DashboardFilterProvider>
    </Fragment>
  );
}
