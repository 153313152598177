import React from 'react';

import { Keys, MenuItem } from '@blueprintjs/core';

import { strings } from 'locales/strings';
import { getListItemFromString, getListItems, getValue } from 'selectors/utms.selector';

import { addMediumToUtmsValues } from '../../../../../../lib/utms';
import { DropDownWithLabel } from '../DropDownWithLabel';

export function ComposerMediumSelect({ localUpdateOfAccount, onChange, redirect, account }) {
  function updateMedium(medium) {
    const newRedirect = {
      ...redirect,
      utms: {
        ...redirect.utms,
        medium: getValue(medium),
      },
    };

    redirect.utms = newRedirect.utms;
    console.log('update redirect comp', newRedirect, redirect, getValue(medium));
    onChange({ redirect: newRedirect });
    account.utmsValues = addMediumToUtmsValues(account.utmsValues, medium);
    localUpdateOfAccount({ account });
  }

  return (
    <DropDownWithLabel
      account={account}
      values={getListItems(account.utmsValues.medium)}
      selected={getListItemFromString(redirect.utms.medium)}
      createNewItemFromQuery={(medium) => medium}
      createNewItemRenderer={(query: string, active: boolean) => (
        <MenuItem
          icon="add"
          className="bp3-add-option"
          text={`Add "${query}"`}
          active={active}
          onClick={() => {
            const medium = query.trim();

            updateMedium(medium);
          }}
          shouldDismissPopover={false}
        />
      )}
      onKeyUp={(event) => {
        event.preventDefault();

        if (event.keyCode === Keys.ENTER) {
          const medium = event.target.value;

          updateMedium(medium);
        }
      }}
      title={strings.get('utm.medium.select.title')}
      placeholder={redirect.utms.medium || strings.get('utm.medium.select.placeholder')}
      onSelect={(selectedMedium) => {
        const newRedirect = {
          ...redirect,
          utms: {
            ...redirect.utms,
            medium: getValue(selectedMedium.id),
          },
        };

        onChange({ redirect: newRedirect });
      }}
      displayLabel
    />
  );
}
