import React, { useState } from 'react';

import { Settings } from 'components/common/icons/Settings';
import { strings } from 'locales/strings';

import { handleChangeUtm } from './composerEditUtils';
import { ComposerTitle } from '../ComposerTitle';
import { InputWithLabelUTMs } from '../InputWithLabelUTMs';
import { ComposerMediumSelect } from '../selects/ComposerMediumSelect';
import { ComposerSourceSelect } from '../selects/ComposerSourceSelect';
import { ComposerUtmCampaignSelect } from '../selects/ComposerUtmCampaignSelect';

export function ComposerEditUtms(props) {
  const { onChange, account, localUpdateOfAccount, redirect } = props;
  const [isDropDownOpen, setDropDownOpen] = useState(false);

  return (
    <>
      <div className="composer__line composer__dropdowns">
        <ComposerTitle
          renderIcon={() => <Settings />}
          style={{ gridArea: 'utms-title' }}
          title={strings.get('composer.utms.title')}
          setDropDownOpen={setDropDownOpen}
          showToggle={true}
          // help={strings.get("composer.utms.help")}
        />
        {isDropDownOpen && (
          <>
            <br />
            <div className="composer__line_grid composer__utms" style={{ gridArea: 'utms-content' }}>
              <ComposerSourceSelect
                account={account}
                redirect={redirect}
                localUpdateOfAccount={localUpdateOfAccount}
                onChange={onChange}
              />
              <ComposerMediumSelect
                account={account}
                redirect={redirect}
                localUpdateOfAccount={localUpdateOfAccount}
                onChange={onChange}
              />
              <ComposerUtmCampaignSelect
                account={account}
                redirect={redirect}
                localUpdateOfAccount={localUpdateOfAccount}
                onChange={onChange}
              />

              <InputWithLabelUTMs
                name="term"
                displayLabel
                onChange={(e) => handleChangeUtm(e, props)}
                title={strings.get('utm.term.input.title')}
                placeholder={strings.get('utm.term.input.placeholder')}
                value={redirect.utms.term}
              />
              <InputWithLabelUTMs
                name="content"
                displayLabel
                onChange={(e) => handleChangeUtm(e, props)}
                title={strings.get('utm.content.input.title')}
                placeholder={strings.get('utm.content.input.placeholder')}
                value={redirect.utms.content}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
}
