import React from 'react';

import { message, Popconfirm, Tooltip } from 'antd';

import axios from 'lib/axios.factory';

import type { adsCampaignT } from '../../../../../../flow-typed/pixelme/adsPlateform';
import type { accountT } from '../../../../../../flow-typed/pixelme/types';
import { trackedEvents } from '../../../../../config/trackedEvents.config';
import { useLoading } from '../../../../../hooks/useLoading';
import { deleteCampaign } from '../../../../../lib/adsPlateformApi';
import { track } from '../../../../../lib/analytics';
import { Delete } from '../../../../common/icons';
import { IconInsideButton } from '../../../AudienceBuilder/Campaigns/campaignTree/Common';

export function DeleteCampaign(props: {
  account: accountT,
  campaigns: adsCampaignT[],
  setCampaigns: (adsCampaignT[]) => void,
  campaign: adsCampaignT,
}) {
  const { doAction, ExclusiveInlineLoadingContainer } = useLoading(false);

  return (
    <ExclusiveInlineLoadingContainer>
      <Popconfirm
        title="Are you sure to want to delete"
        onConfirm={async () => {
          doAction(async () => {
            try {
              await deleteCampaign(localStorage, axios, { accountId: props.account.id }, props.campaign.id);
              props.setCampaigns(props.campaigns.filter((c) => c.id !== props.campaign.id));
              track(trackedEvents.deleteAdsCampaign, {
                campaign: props.campaign,
              });
              message.success('Campaign deleted');
            } catch (e) {
              message.error('Unable to delete campaign');
            }
          });
        }}
      >
        <div>
          <IconInsideButton>
            <Tooltip title="Delete Campaign" placement="bottom">
              <Delete />
            </Tooltip>
          </IconInsideButton>
        </div>
      </Popconfirm>
    </ExclusiveInlineLoadingContainer>
  );
}
