import React, { Fragment, useEffect, useState } from 'react';

import { useDetectAdBlock } from 'adblock-detect-react';
import { Alert, Spin } from 'antd';
import styled from 'styled-components';

import { apiCall } from 'lib/api';
import axios from 'lib/axios.factory';

import { AdWordsLogo } from './AdWordsLogo';
import { LimitedButton } from '../../../common/antd/LimitedButton';

const Style = styled.div`
  a {
    text-decoration: none !important;
  }
  .btn-text {
    width: 180px;
  }
`;

const ProviderButton = styled(LimitedButton)`
  height: 51px;
  padding: 1em;
  display: flex;
  gap: 1em;
`;

export function GoogleLoginButton(props: { account: accountT }) {
  const [url, setUrl] = useState(undefined);
  const adBlockDetected = useDetectAdBlock();

  async function load() {
    if (!url) {
      const response = await apiCall({
        localStorage,
        axios,
        secure: true,
        method: 'get',
        url: '/ads-providers/google/oauth/redirect-url',
        params: { accountId: props.account.id },
      });

      setUrl(response.redirectUrl);
    }
  }

  useEffect(() => {
    load().then();
  }, []);

  if (adBlockDetected && !url) {
    return (
      <Alert
        message="We are not able to connect to your Google Ads Account if you are using AdBlock,"
        type="error"
        description={
          <Fragment>
            <a
              href="https://help.getadblock.com/support/solutions/articles/6000055743-how-to-disable-adblock-on-specific-sites"
              target="_blank"
              rel="noreferrer"
            >
              How do I turn off my Ad Blocker?
            </a>
          </Fragment>
        }
      ></Alert>
    );
  }

  if (!url) {
    return <Spin tip="Waiting for Google" />;
  }

  return (
    <Style>
      <a href={url}>
        <ProviderButton
          icon={<AdWordsLogo size={22} />}
          feature="Google Ads"
          limitationTest={(limitations, usage) => usage.googleAdsProvider < limitations.limitGoogleAdsProvider}
        >
          <p className="btn-text">Connect Adwords</p>
        </ProviderButton>
      </a>
    </Style>
  );
}
