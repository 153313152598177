import styled from 'styled-components';

import { strings } from 'locales/strings';

const Style = styled.h1`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const PageNotAvailable = () => <Style>{strings.get('asinAudit.pageNotAvailable')}</Style>;
