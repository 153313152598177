import './Plans.css';

import type { Node } from 'react';
import React, { useEffect, useState } from 'react';

import { Alert, InputNumber } from 'antd';
import numeral from 'numeral';
import styled from 'styled-components';

import Box from 'components/common/Box';
import Button from 'components/common/Button';
import Modal from 'components/common/Modal';
import { Line } from 'components/common/Placeholders';
import Separator from 'components/common/Separator';
import Switch from 'components/common/Switch';
import { generateManageSubscriptionUrl, generatePreviewPriceUrl, refreshSubscription } from 'lib/api';
import axios from 'lib/axios.factory';
import { strings } from 'locales/strings';

import { WhiteChip } from './Chip';
import SubscriptionCheckout from './SubscriptionCheckout';

const numeralFormat = '0,0';

type propsT = {
  account: accountT,
  user: userT,
  sub: subscriptionT,
  plans: Array<planT>,
  card?: cardT,
  vat?: vatT,
  updating: boolean,
  callSubscribe: ({ accountId: string, coupon: string, plan: planT, vat: vatT, token?: string }) => void,
  callTrial: ({ accountId: string, plan: planT }) => void,
};

const includedLi = (
  <li className="plans__plan__limit-item">
    {' '}
    <i className="icon-included"></i>{' '}
  </li>
);
const excludedLi = (
  <li className="plans__plan__limit-item">
    {' '}
    <i className="icon-excluded"></i>{' '}
  </li>
);

const PlanSwitcher = (props: { tab: string, setTab: (string) => void }) => (
  <div>
    <div className="plans__type-switcher">
      <a
        onClick={() => {
          props.setTab('amazon');
        }}
        className={`pix-button pix-button--normal plans__button_swicth_pan  ${
          props.tab === 'amazon' ? ' plans__button_swicth_pan--active' : ' '
        }`}
      >
        For Amazon Seller
      </a>
      <a
        onClick={() => {
          props.setTab('attribution');
        }}
        className={`pix-button pix-button--normal plans__button_swicth_pan  ${
          props.tab === 'attribution' ? ' plans__button_swicth_pan--active' : ' '
        }`}
      >
        Attribution Links Only
      </a>
      <a
        onClick={() => {
          props.setTab('marketer');
        }}
        className={`pix-button pix-button--normal plans__button_swicth_pan  ${
          props.tab === 'marketer' ? ' plans__button_swicth_pan--active' : ' '
        }`}
      >
        For Marketer
      </a>
    </div>
  </div>
);

export default function Plans({
  account,
  user,
  sub,
  plans,
  card,
  orgCoupon,
  vat,
  updating,
  callSubscribe,
  callTrial,
  sessionId,
}: propsT) {
  const [period, setPeriod] = useState('yearly');
  const [subscribingPlans, setSubscribingPlans] = useState([]);

  const previewPrice = (plan: planT, quantity: number) => {
    const returnUrl = new URL(window.location.href);

    // Remove any query parameters
    returnUrl.search = '';

    generatePreviewPriceUrl(
      localStorage,
      axios,
      {
        accountId: account.id,
      },
      {
        planId: plan.id,
        quantity,
        returnUrl: window.location.href,
      },
    ).then((response) => {
      window.open(response.url, '_blank');
    });
  };
  const manageSubscription = () => {
    generateManageSubscriptionUrl(
      localStorage,
      axios,
      {
        accountId: account.id,
      },
      {
        returnUrl: window.location.href,
      },
    ).then((response) => {
      window.open(response.url, '_blank');
    });
  };

  const [tab, setTab] = useState('amazon'); // "amazon", "marketer", "attribution"

  const [isAmazonSeller, setIsAmazonSeller] = useState(tab !== 'marketer');

  useEffect(() => {
    setPeriod(sub ? sub.plan.period : 'yearly');
  }, [sub]);

  useEffect(() => {
    if (sessionId) {
      refreshSubscription(
        localStorage,
        axios,
        {
          accountId: account.id,
        },
        { sessionId },
      ).then(() => {
        const returnUrl = new URL(window.location.href);

        returnUrl.search = '';

        window.location.replace(returnUrl);
      });
    }
  }, [sessionId]);

  useEffect(() => {
    setIsAmazonSeller(tab !== 'marketer');
  }, [tab]);

  function switchPeriod() {
    const nextPeriod = period === 'yearly' ? 'monthly' : 'yearly';

    setPeriod(nextPeriod);
  }

  function renderPeriodSwitcher() {
    return (
      <div className="feature_header">
        <div className="plans__period-switcher">
          <span
            onClick={() => setPeriod('monthly')}
            className={`plans__period-switcher--clickable${period === 'yearly' ? ' muted' : ' disabled'}`}
          >
            {strings.get('billing.plans.periodSwitcher.monthly')}
          </span>
          <Switch position={period === 'yearly' ? 'right' : 'left'} handleClick={switchPeriod} withShadow />
          <span
            onClick={() => setPeriod('yearly')}
            className={`plans__period-switcher--clickable${period === 'yearly' ? ' disabled' : ' muted'}`}
            style={{ fontWeight: '600' }}
          >
            {strings.get('billing.plans.periodSwitcher.yearly')}
          </span>
        </div>
        <div className="plans__text-center">
          <span
            className="notice"
            style={{
              backgroundColor: '#2E89E2',
              borderRadius: '14px',
              color: 'white',
              fontSize: '9px',
              padding: '4px',
              opacity: period === 'monthly' ? ' 40%' : ' 100%',
            }}
          >
            {strings.get('billing.plans.periodSwitcher.yearlyBonus')}
          </span>
          <div style={{ marginTop: '10px' }}>{period === 'yearly' ? 'Billed yearly' : '\u00A0'}</div>
        </div>
      </div>
    );
  }

  function renderFeatures() {
    return (
      <>
        <div className="plans__plans__container">
          <div className="plans__plan">
            <div className="plans__plan_header">{!isAmazonSeller && renderPeriodSwitcher()}</div>
            <Separator />
            {isAmazonSeller && (
              <ul>
                <li className="plans__plan__feature-item">Campaign Creation And Management</li>
                <li className="plans__plan__feature-item">Amazon Attribution</li>
                <li className="plans__plan__feature-item">Google ad campaigns</li>
                <li className="plans__plan__feature-item">Facebook ad campaigns</li>
                <li className="plans__plan__feature-item">TikTok ad campaign</li>
                <li className="plans__plan__feature-item">Retargeting pixels</li>
                <li className="plans__plan__feature-item">Amazon products</li>
                <li className="plans__plan__feature-item">Micro landing pages</li>
                <li className="plans__plan__feature-item">Organic rank tracking</li>
                <li className="plans__plan__feature-item">Keyword level conversions</li>
                <li className="plans__plan__feature-item">Dedicated account manager</li>
                <li className="plans__plan__feature-item">Dedicated phone support</li>
                <li className="plans__plan__feature-item">Custom creative services</li>
                <li className="plans__plan__feature-item">Multiple amazon account</li>
                <li className="plans__plan__feature-item">Tracked clicks per month</li>
              </ul>
            )}
            {!isAmazonSeller && (
              <ul>
                <li className="plans__plan__feature-item">Tracked clicks per month</li>
                <li className="plans__plan__feature-item">Retargeting pixels</li>
                <li className="plans__plan__feature-item">Micro landing</li>
                <li className="plans__plan__feature-item">Custom domains</li>
                <li className="plans__plan__feature-item">Deeplink</li>
                <li className="plans__plan__feature-item">Dedicated customer support</li>
                <li className="plans__plan__feature-item">Seats</li>
              </ul>
            )}
          </div>
        </div>
      </>
    );
  }

  if (!plans) {
    return null;
  }

  // Hide plans for "lifetime" users
  if (!period || period === 'lifetime') {
    return null;
  }

  const freePlan = plans.filter((p) => p.type === 'free')[0];
  const currentPlans = plans.filter((p) => {
    switch (tab) {
      case 'amazon':
        return p.targetAudience === 'amazon';
      case 'attribution':
        return p.targetAudience === 'attribution';
      case 'marketer':
        return p.targetAudience === '' && p.period === period;
      default:
        return false;
    }
  });

  function renderPlan(p: planT) {
    let amount = p.amountInCents;

    if (orgCoupon && orgCoupon.plans.includes(p.id)) {
      amount = ((100 - orgCoupon.percent) / 100) * p.amountInCents;
    }

    const planAmount = Math.ceil(amount / 100 / (period === 'yearly' && !isAmazonSeller ? 12 : 1));

    return (
      <>
        <div className="plans__plans__container plans__text-center">
          <div key={p.id} className="plans__plan">
            <div className="plans__plan_header">
              {p.targetAudience !== 'amazon' && p.targetAudience !== 'attribution' && (
                <div className="plans__plan__title ">
                  {orgCoupon && orgCoupon.plans.includes(p.id) && (
                    <div className="plans__plan_light_chip">COUPON CODE APPLIED</div>
                  )}
                  {p.type === 'starter' && p.period === 'yearly' && (
                    <div className="plans__plan_light_chip">SAVE $144</div>
                  )}
                  {p.type === 'growth' && p.period === 'yearly' && (
                    <div className="plans__plan_light_chip">SAVE $359</div>
                  )}
                  {p.type === 'scale' && p.period === 'yearly' && (
                    <div className="plans__plan_light_chip">SAVE $539</div>
                  )}
                  {p.type === 'elite' && p.period === 'yearly' && (
                    <div className="plans__plan_light_chip">SAVE $899</div>
                  )}
                  {p.period === 'monthly' || (p.type === 'business' && <WhiteChip>&nbsp;</WhiteChip>)}
                </div>
              )}
              <div className="plans__plan__plan-name"> {p.type === 'business' ? 'Enterprise' : p.name} </div>
              {p.type === 'business' ? (
                <div className="plans__plan__amount_business">Custom price</div>
              ) : (
                <div style={{ position: 'relative' }}>
                  <span className="plans__plan__amount" style={p.period === 'yearly' ? { textAlign: 'center' } : {}}>
                    ${planAmount}
                  </span>
                  <span className="plans__plan__info_billed">
                    {p.hasQuantity && <>per ASIN</>}
                    {strings.get('billing.plans.planDetails.perMonth')}{' '}
                    {p.period === 'yearly' ? strings.get('billing.plans.planDetails.billedYearly') : ''}
                  </span>
                </div>
              )}

              <div>
                {p.type === 'business' ? (
                  <div className="plans__plan__button">
                    <a
                      className="pix-button pix-button--normal"
                      href="https://share.hsforms.com/1fo2y4Ox6QpmNDof9Znvt5w4vo3c"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Talk to us
                    </a>
                  </div>
                ) : (
                  <PlanButton
                    user={user}
                    account={account}
                    sub={sub}
                    p={p}
                    plans={currentPlans}
                    setSubscribingPlans={setSubscribingPlans}
                    previewPrice={previewPrice}
                    manageSubscription={manageSubscription}
                    callTrial={callTrial}
                  />
                )}
                {(p.targetAudience === 'amazon' || p.targetAudience === 'attribution') &&
                  p.limitations.limitGoogleProducts <= 0 && (
                    <div style={{ marginTop: '10px' }}>
                      <Alert
                        type="error"
                        message={
                          <small>No Ads in this plan. To create Google, Facebook, Tiktok Ads upgrade to Pro Plan</small>
                        }
                      />
                    </div>
                  )}
              </div>
            </div>
            <Separator />
            <Feats plan={p} isAmazonSeller={isAmazonSeller} />
          </div>
          <div>
            {/* {sub.plan.type === p.type &&*/}
            {/*  sub.status === "paying" &&*/}
            {/*  renderCancelButton()}*/}
          </div>
        </div>
      </>
    );
  }

  if (updating || sessionId) {
    return <PlaceHolder />;
  }

  let plan = subscribingPlans.length > 0 ? subscribingPlans.filter((p) => p.period === period)[0] : undefined;

  if (subscribingPlans.length > 0 && subscribingPlans[0].type === 'free') {
    plan = freePlan;
  }

  const displayPlans = subscribingPlans.length === 0;
  const displayDowngradeToFree = plan && plan.type === 'free';
  const displayUpdateSub = plan && sub.status === 'paying' && plan.type !== 'free';
  const displayCheckout = plan && sub.status !== 'paying' && plan.type !== 'free';

  return (
    <Box className="plans">
      {displayDowngradeToFree && (
        <Modal
          className="plans__content__downgrade-free-modal"
          okOnly
          handleCancel={() => {
            setSubscribingPlans([]);
          }}
          handleOk={(_) => {
            callSubscribe({
              accountId: account.id,
              plan,
              vat,
            });
            setSubscribingPlans([]);
          }}
          // disabled={calling}
          title={
            <>
              <WarnSVG />
              Downgrade to Free plan
            </>
          }
          okMessage="Confirm your downgrade"
        >
          <>
            The Free plan includes
            <Feats plan={freePlan} />
          </>
        </Modal>
      )}
      {displayUpdateSub && (
        <Modal
          handleCancel={() => {
            setSubscribingPlans([]);
          }}
          handleOk={(_) => {
            callSubscribe({
              accountId: account.id,
              plan,
              vat,
            });
            setSubscribingPlans([]);
          }}
          // disabled={calling}
          okMessage="Update subscription"
        >
          Subscribe to {plan.name} - {period} plan?
        </Modal>
      )}

      <PlanSwitcher tab={tab} setTab={setTab} />

      {displayPlans && (
        <div className="plans__content">
          <div className="plans__content__block">
            {renderFeatures()}
            {currentPlans.map(renderPlan)}
          </div>

          {!isAmazonSeller && (
            <div className="plans__footer_call_us">
              Enterprise?{' '}
              <a href="https://share.hsforms.com/1fo2y4Ox6QpmNDof9Znvt5w4vo3c" target="_blank" rel="noreferrer">
                {' '}
                Contact us!{' '}
              </a>
            </div>
          )}
        </div>
      )}
      {displayCheckout && (
        <div>
          <SubscriptionCheckout
            callSubscribe={callSubscribe}
            card={card}
            vat={vat}
            updating={updating}
            period={period}
            account={account}
            coupon={orgCoupon}
            plans={subscribingPlans}
            handleCancel={(_) => setSubscribingPlans([])}
          />
        </div>
      )}
    </Box>
  );
} // end

const CancelStyledButton = styled(Button).withConfig({
  displayName: 'CancelStyledButton',
})`
  display: inline-block;
  margin: 0 auto;
  width: 100%;
  margin-bottom: 20px;
  opacity: 0.7;
  :hover {
    opacity: 1;
  }
`;

export function PlanButton({
  user,
  account,
  sub,
  p,
  plans,
  setSubscribingPlans,
  previewPrice,
  manageSubscription,
  callTrial,
}) {
  const [displayTrialModal, setDisplayTrialModal] = useState(false);
  const [quantity, setQuantity] = useState(2);
  const isCurrentPlan = sub.plan && p.id === sub.plan.id;
  const emptyDate = '0001-01-01T00:00:00Z';

  const buttonsMap = {
    paying: {
      text: strings.get('billing.plans.planButtons.current'),
    },
    default: {
      handleClick: () => setDisplayTrialModal(true),
      text: strings.get('billing.plans.planButtons.trial'),
    },
    free: {
      default(sub: subscriptionT) {
        // already started a trial in the past
        if (sub.trialEnd && sub.trialEnd !== emptyDate) {
          return {
            handleClick: () => setSubscribingPlans(plans.filter((lp) => lp.name === p.name)),
            text: strings.get('billing.plans.planButtons.upgrade'),
          };
        }

        return {
          handleClick: () => setDisplayTrialModal(true),
          text: strings.get('billing.plans.planButtons.trial'),
        };
      },
      business(sub: subscriptionT) {
        // already started a trial in the past
        if (sub.businessTrialEnd && sub.businessTrialEnd !== emptyDate) {
          return {
            handleClick: () => setSubscribingPlans(plans.filter((lp) => lp.name === p.name)),
            text: strings.get('billing.plans.planButtons.upgrade'),
          };
        }

        return {
          handleClick: () => setDisplayTrialModal(true),
          text: strings.get('billing.plans.planButtons.businessTrial'),
        };
      },
      free: {
        text: strings.get('billing.plans.planButtons.current'),
      },
    },
    trialing: {
      free: {
        handleClick: () => setSubscribingPlans(plans.filter((lp) => lp.name === p.name)),
        text: strings.get('billing.plans.planButtons.downgrade'),
      },
      business: {
        handleClick: () => setDisplayTrialModal(true),
        text: strings.get('billing.plans.planButtons.businessTrial'),
      },
      default: {
        handleClick: () => setSubscribingPlans(plans.filter((lp) => lp.name === p.name)),
        text: strings.get('billing.plans.planButtons.upgrade'),
      },
    },
    business_trialing: {
      business: {
        handleClick: () => setSubscribingPlans(plans.filter((lp) => lp.name === p.name)),
        text: strings.get('billing.plans.planButtons.upgrade'),
      },
      default: {
        handleClick: () => setSubscribingPlans(plans.filter((lp) => lp.name === p.name)),
        text: strings.get('billing.plans.planButtons.downgrade'),
      },
    },
    paying_business_trialing: {
      business: {
        handleClick: () => setSubscribingPlans(plans.filter((lp) => lp.name === p.name)),
        text: strings.get('billing.plans.planButtons.upgrade'),
      },
      default: {
        handleClick: () => setSubscribingPlans(plans.filter((lp) => lp.name === p.name)),
        text: strings.get('billing.plans.planButtons.downgrade'),
      },
    },
    paying_business_trial_ended: {
      business: {
        handleClick: () => setSubscribingPlans(plans.filter((lp) => lp.name === p.name)),
        text: strings.get('billing.plans.planButtons.upgrade'),
      },
      default: {
        handleClick: () => setSubscribingPlans(plans.filter((lp) => lp.name === p.name)),
        text: strings.get('billing.plans.planButtons.downgrade'),
      },
    },
    legacy: {
      default: {
        handleClick: () => setSubscribingPlans(plans.filter((lp) => lp.name === p.name)),
        text: strings.get('billing.plans.planButtons.upgrade'),
      },
      free: {
        handleClick: () => setSubscribingPlans(plans.filter((lp) => lp.name === p.name)),
        text: strings.get('billing.plans.planButtons.downgrade'),
      },
    },
    churned: {
      default: {
        handleClick: () => setSubscribingPlans(plans.filter((lp) => lp.name === p.name)),
        text: strings.get('billing.plans.planButtons.upgrade'),
      },
      free: {
        handleClick: () => setSubscribingPlans(plans.filter((lp) => lp.name === p.name)),
        text: strings.get('billing.plans.planButtons.downgrade'),
      },
    },
    not_paying: {
      default: {
        handleClick: () => setSubscribingPlans(plans.filter((lp) => lp.name === p.name)),
        text: strings.get('billing.plans.planButtons.upgrade'),
      },
      free: {
        handleClick: () => setSubscribingPlans(plans.filter((lp) => lp.name === p.name)),
        text: strings.get('billing.plans.planButtons.downgrade'),
      },
    },
  };

  let b = buttonsMap.default;

  const subStatus = sub.status !== 'trialing' ? 'legacy' : sub.status;

  if (buttonsMap[subStatus]) {
    if (buttonsMap[subStatus].default) {
      if (buttonsMap[subStatus].default instanceof Function) {
        b = buttonsMap[subStatus].default(sub);
      } else {
        b = buttonsMap[subStatus].default;
      }
    }

    if (buttonsMap[subStatus][p.type]) {
      if (buttonsMap[subStatus][p.type] instanceof Function) {
        b = buttonsMap[subStatus][p.type](sub);
      } else {
        b = buttonsMap[subStatus][p.type];
      }
    }
  }

  // manage paying special cases
  // if (sub.status === "paying" && !legacy) {
  // i dont know why using !legacy, revert back if this make issue
  if (sub.status === 'paying') {
    if (sub.plan.type === 'business') {
      b = {
        handleClick: () => setSubscribingPlans(plans.filter((lp) => lp.name === p.name)),
        text: strings.get('billing.plans.planButtons.downgrade'),
      };
    } else {
      if (p.type === 'business') {
        b = {
          handleClick: () => setDisplayTrialModal(true),
          text: strings.get('billing.plans.planButtons.businessTrial'),
        };
      } else {
        let activePlanPrice = sub.plan.amountInCents;

        if (sub.plan.period === 'yearly' && p.period !== 'yearly') {
          activePlanPrice = activePlanPrice / 12;
        }

        b = {
          handleClick: () => setSubscribingPlans(plans.filter((lp) => lp.name === p.name)),
          text:
            activePlanPrice < p.amountInCents
              ? strings.get('billing.plans.planButtons.upgrade')
              : strings.get('billing.plans.planButtons.downgrade'),
        };
      }
    }

    if (p.type === sub.plan.type) {
      if (p.period === sub.plan.period) {
        b = {
          text: strings.get('billing.plans.planButtons.current'),
        };
      } else if (p.period === 'monthly') {
        b = {
          handleClick: () => setSubscribingPlans(plans.filter((lp) => lp.name === p.name)),
          text: strings.get('billing.plans.planButtons.switchMonthly'),
        };
      } else {
        b = {
          handleClick: () => setSubscribingPlans(plans.filter((lp) => lp.name === p.name)),
          text: strings.get('billing.plans.planButtons.switchYearly'),
        };
      }
    }

    if (isCurrentPlan) {
      b = {
        text: strings.get('billing.plans.planButtons.current'),
      };
    }

    if (p.type === 'free') {
      b = {
        handleClick: () => setSubscribingPlans(plans.filter((lp) => lp.name === p.name)),
        text: strings.get('billing.plans.planButtons.downgrade'),
      };
    }
  }

  // Ignore all the mess above and just use special rules for "Amazon Seller" plans
  if (p.targetAudience === 'amazon' && p.hasQuantity) {
    // Default to "Preview Price"
    b = {
      handleClick: () => previewPrice(p, quantity),
      text: strings.get('billing.plans.planButtons.previewPrice'),
    };

    if (sub.status === 'paying') {
      // User is currently subscribed to given plan
      if (isCurrentPlan) {
        b = {
          text: strings.get('billing.plans.planButtons.current'),
        };
      } else {
        // User is currently subscribed to a different plan
        b = {
          text: strings.get('billing.plans.planButtons.notActive'),
        };
      }
    }
  }

  const names = user.fullname.split(' ');
  const firstName = names.shift();
  const lastName = names.join(' ');

  // TODO (matej): this section is really bad, but i don't have time to do it better right now
  const onBoarding = (
    <a
      style={{
        display: 'block',
        visibility:
          sub.status === 'paying' && p.ID === sub.plan?.ID && sub.plan?.hasQuantity && p.type === 'pxpro'
            ? 'visible'
            : 'hidden',
      }}
      target="_blank"
      href={`https://form.jotform.com/240087573835160?name[first]=${firstName}&name[last]=${lastName}&email=${user.email}`}
      rel="noreferrer"
    >
      Click Here to Onboard with Your Account Manager
    </a>
  );

  return (
    <>
      {displayTrialModal && (
        <Modal
          okOnly
          className="plans__plan__trial-modal"
          handleCancel={() => setDisplayTrialModal(false)}
          handleOk={() => {
            setDisplayTrialModal(false);
            callTrial({ accountId: account.id, plan: p });
          }}
          title={`💪 Start a ${p.type === 'business' ? 'business' : ''} trial?`}
          okMessage="Start"
        >
          You can start a {p.type === 'business' ? 'business' : ''} trial only once.
        </Modal>
      )}
      <div className="plans__plan__button">
        {p.hasQuantity && (sub.status !== 'paying' || sub.plan?.targetAudience !== 'amazon') && (
          <InputNumber min={1} max={99} defaultValue={2} onChange={setQuantity} addonAfter="ASINS" />
        )}
        {p.hasQuantity && onBoarding}
        <Button disabled={!b.handleClick} handleClick={b.handleClick}>
          {b.text}
        </Button>
      </div>
    </>
  );
}

function Feat({ children, active = true }: { children: Node, active?: boolean }) {
  return (
    <li className={`plans__plan__limit-item plans__plan__limit-item-${active ? 'included' : 'excluded'}`}>
      {children}
    </li>
  );
}

function Feats({ plan, isAmazonSeller }: { plan: planT, isAmazonSeller: true }) {
  const { shortens, brandedDomains, dataRetentionInDays, pixels, workspaces, teamMembers } = plan.limitations;

  if (plan.type === 'starter') {
    return <StarterFeats />;
  }

  if (plan.type === 'growth') {
    return <GrowthFeats isAmazonSeller={isAmazonSeller} />;
  }

  if (plan.type === 'scale') {
    return <ScaleFeats isAmazonSeller={isAmazonSeller} />;
  }

  if (plan.type === 'elite') {
    return <EliteFeats isAmazonSeller={isAmazonSeller} />;
  }

  if (plan.type === 'business') {
    return <BusinessFeats />;
  }

  if (plan.type === 'pxpro') {
    return <ProFeats plan={plan} />;
  }

  if (plan.type === 'pxself') {
    return <SelfServiceFeats />;
  }

  if (plan.type === 'standard') {
    return <StandardFeats />;
  }

  if (plan.type === 'advanced') {
    return <AdvancedFeats />;
  }

  if (plan.type === 'ultimate') {
    return <UltimateFeats />;
  }

  return (
    <ul>
      <Feat>
        <span>{shortens === 0 ? 'Unlimited' : numeral(shortens).format(numeralFormat)}</span> branded links
      </Feat>
      <Feat>
        <span>{brandedDomains === 0 ? 'Unlimited' : numeral(brandedDomains).format(numeralFormat)}</span> custom domain
        {brandedDomains > 1 ? 's' : ''}
      </Feat>
      <Feat>
        <span>{numeral(dataRetentionInDays).format(numeralFormat)}</span>
        -day data retention
      </Feat>
      <Feat active={pixels !== 0}>
        <span>{pixels === -1 ? 'Unlimited ' : pixels === 0 ? '' : numeral(pixels).format(numeralFormat)}</span>{' '}
        {pixels === 0 ? 'R' : 'r'}
        etargeting pixels
      </Feat>
      {/* <Feat active={plan.type === "business"}>
        Deep-linking &amp; link routing
      </Feat> */}
      <Feat active={workspaces > 1}>
        <span>{workspaces === 1 ? ' ' : numeral(workspaces).format(numeralFormat)}</span> Workspaces
      </Feat>
      <Feat active={teamMembers > 1}>
        <span>{teamMembers === 1 ? ' ' : numeral(teamMembers).format(numeralFormat)}</span> Team members
      </Feat>
    </ul>
  );
}

function StarterFeats() {
  return (
    <ul className="plans__text-center">
      <li className="plans__plan__limit-item">5,000</li>
      <li className="plans__plan__limit-item">2</li>
      <li className="plans__plan__limit-item">Unlimited</li>
      <li className="plans__plan__limit-item">1</li>
      <li className="plans__plan__limit-item">
        {' '}
        <i className="icon-excluded"></i>{' '}
      </li>
      <li className="plans__plan__limit-item">&#60; 48 hours response time</li>
      <li className="plans__plan__limit-item">1</li>
    </ul>
  );
}

function GrowthFeats({ isAmazonSeller }) {
  if (isAmazonSeller) {
    return (
      <ul className="plans__text-center">
        <li className="plans__plan__limit-item">
          {' '}
          <i className="icon-included"></i>{' '}
        </li>
        <li className="plans__plan__limit-item">5</li>
        <li className="plans__plan__limit-item">
          <i className="icon-excluded"></i>
        </li>
        <li className="plans__plan__limit-item">
          <i className="icon-excluded"></i>
        </li>
        <li className="plans__plan__limit-item">2</li>
        <li className="plans__plan__limit-item">10</li>
        <li className="plans__plan__limit-item">Unlimited</li>
        <li className="plans__plan__limit-item">
          {' '}
          <i className="icon-included"></i>{' '}
        </li>
        <li className="plans__plan__limit-item">
          {' '}
          <i className="icon-included"></i>{' '}
        </li>
        <li className="plans__plan__limit-item"> &#60; 48 hours response time</li>
        <li className="plans__plan__limit-item">
          {' '}
          <i className="icon-excluded"></i>{' '}
        </li>
        <li className="plans__plan__limit-item">
          {' '}
          <i className="icon-excluded"></i>{' '}
        </li>
        <li className="plans__plan__limit-item">
          {' '}
          <i className="icon-excluded"></i>{' '}
        </li>
        <li className="plans__plan__limit-item">
          {' '}
          <i className="icon-excluded"></i>{' '}
        </li>
      </ul>
    );
  }

  return (
    <ul className="plans__text-center">
      <li className="plans__plan__limit-item">20,000</li>
      <li className="plans__plan__limit-item">2</li>
      <li className="plans__plan__limit-item">Unlimited</li>
      <li className="plans__plan__limit-item">1</li>
      <li className="plans__plan__limit-item">
        {' '}
        <i className="icon-included"></i>{' '}
      </li>
      <li className="plans__plan__limit-item">&#60; 48 hours response time</li>
      <li className="plans__plan__limit-item">2</li>
    </ul>
  );
}

function ScaleFeats({ isAmazonSeller }) {
  if (isAmazonSeller) {
    return (
      <ul className="plans__text-center">
        <li className="plans__plan__limit-item">
          {' '}
          <i className="icon-included"></i>{' '}
        </li>
        <li className="plans__plan__limit-item">10</li>
        <li className="plans__plan__limit-item">5</li>
        <li className="plans__plan__limit-item">
          <i className="icon-excluded"></i>
        </li>
        <li className="plans__plan__limit-item">5</li>
        <li className="plans__plan__limit-item">25</li>
        <li className="plans__plan__limit-item">Unlimited</li>
        <li className="plans__plan__limit-item">
          {' '}
          <i className="icon-included"></i>{' '}
        </li>
        <li className="plans__plan__limit-item">
          {' '}
          <i className="icon-included"></i>{' '}
        </li>
        <li className="plans__plan__limit-item">&#60; 48 hours response time</li>
        <li className="plans__plan__limit-item">
          {' '}
          <i className="icon-excluded"></i>{' '}
        </li>
        <li className="plans__plan__limit-item">
          {' '}
          <i className="icon-excluded"></i>{' '}
        </li>
        <li className="plans__plan__limit-item">
          {' '}
          <i className="icon-excluded"></i>{' '}
        </li>
        <li className="plans__plan__limit-item">
          {' '}
          <i className="icon-excluded"></i>{' '}
        </li>
      </ul>
    );
  }

  return (
    <ul className="plans__text-center">
      <li className="plans__plan__limit-item">50,000</li>
      <li className="plans__plan__limit-item">5</li>
      <li className="plans__plan__limit-item">Unlimited</li>
      <li className="plans__plan__limit-item">5</li>
      <li className="plans__plan__limit-item">
        {' '}
        <i className="icon-included"></i>{' '}
      </li>
      <li className="plans__plan__limit-item">&#60; 48 hours response time</li>
      <li className="plans__plan__limit-item">3</li>
    </ul>
  );
}

function EliteFeats({ isAmazonSeller }) {
  if (isAmazonSeller) {
    return (
      <ul className="plans__text-center">
        <li className="plans__plan__limit-item">
          {' '}
          <i className="icon-included"></i>{' '}
        </li>
        <li className="plans__plan__limit-item">Unlimited</li>
        <li className="plans__plan__limit-item">Unlimited</li>
        <li className="plans__plan__limit-item">Unlimited</li>
        <li className="plans__plan__limit-item">10</li>
        <li className="plans__plan__limit-item">50</li>
        <li className="plans__plan__limit-item">Unlimited</li>
        <li className="plans__plan__limit-item">
          {' '}
          <i className="icon-included"></i>{' '}
        </li>
        <li className="plans__plan__limit-item">
          {' '}
          <i className="icon-included"></i>{' '}
        </li>
        <li className="plans__plan__limit-item">&#60; 48 hours response time</li>
        <li className="plans__plan__limit-item">
          {' '}
          <i className="icon-excluded"></i>{' '}
        </li>
        <li className="plans__plan__limit-item">
          {' '}
          <i className="icon-excluded"></i>{' '}
        </li>
        <li className="plans__plan__limit-item">
          {' '}
          <i className="icon-excluded"></i>{' '}
        </li>
        <li className="plans__plan__limit-item">
          {' '}
          <i className="icon-excluded"></i>{' '}
        </li>
      </ul>
    );
  }

  return (
    <ul className="plans__text-center">
      <li className="plans__plan__limit-item">100,000</li>
      <li className="plans__plan__limit-item">10</li>
      <li className="plans__plan__limit-item">Unlimited</li>
      <li className="plans__plan__limit-item">10</li>
      <li className="plans__plan__limit-item">
        {' '}
        <i className="icon-included"></i>{' '}
      </li>
      <li className="plans__plan__limit-item">&#60; 48 hours response time</li>
      <li className="plans__plan__limit-item">5</li>
    </ul>
  );
}

function BusinessFeats() {
  return (
    <ul className="plans__text-center">
      {includedLi}
      {includedLi}
      <li className="plans__plan__limit-item">Unlimited</li>
      <li className="plans__plan__limit-item">Unlimited</li>
      <li className="plans__plan__limit-item">Unlimited</li>
      <li className="plans__plan__limit-item">Unlimited</li>
      <li className="plans__plan__limit-item">Unlimited</li>
      <li className="plans__plan__limit-item">Unlimited</li>
      {includedLi}
      {includedLi}
      {includedLi}
      {includedLi}
      {includedLi}
      {includedLi}
      <li className="plans__plan__limit-item">Unlimited</li>
    </ul>
  );
}

function ProFeats(props: { plan: planT }) {
  return (
    <ul className="plans__text-center">
      {includedLi}
      {includedLi}
      {props.plan.hasQuantity && <li className="plans__plan__limit-item">per ASIN pricing</li>}
      {!props.plan.hasQuantity && (
        <li className="plans__plan__limit-item">{props.plan.limitations.limitGoogleProducts}</li>
      )}
      <li className="plans__plan__limit-item">Unlimited</li>
      <li className="plans__plan__limit-item">Unlimited</li>
      <li className="plans__plan__limit-item">10</li>
      <li className="plans__plan__limit-item">50</li>
      <li className="plans__plan__limit-item">Unlimited</li>
      {includedLi}
      {includedLi}
      {includedLi}
      {excludedLi}
      {excludedLi}
      {includedLi}
      <li className="plans__plan__limit-item">100,000</li>
    </ul>
  );
}

function SelfServiceFeats() {
  return (
    <ul className="plans__text-center">
      {excludedLi}
      {includedLi}
      <li className="plans__plan__limit-item">per ASIN pricing</li>
      <li className="plans__plan__limit-item">Unlimited</li>
      <li className="plans__plan__limit-item">Unlimited</li>
      <li className="plans__plan__limit-item">10</li>
      <li className="plans__plan__limit-item">50</li>
      <li className="plans__plan__limit-item">Unlimited</li>
      {includedLi}
      {includedLi}
      {includedLi}
      {excludedLi}
      {excludedLi}
      {includedLi}
      <li className="plans__plan__limit-item">100,000</li>
    </ul>
  );
}

function StandardFeats() {
  return (
    <ul className="plans__text-center">
      {excludedLi}
      {includedLi}
      {excludedLi}
      {excludedLi}
      {excludedLi}
      <li className="plans__plan__limit-item">5</li>
      <li className="plans__plan__limit-item">50</li>
      <li className="plans__plan__limit-item">Unlimited</li>
      {includedLi}
      {excludedLi}
      {excludedLi}
      {excludedLi}
      {excludedLi}
      {includedLi}
      <li className="plans__plan__limit-item">20,000</li>
    </ul>
  );
}

function AdvancedFeats() {
  return (
    <ul className="plans__text-center">
      {excludedLi}
      {includedLi}
      {excludedLi}
      {excludedLi}
      {excludedLi}
      <li className="plans__plan__limit-item">5</li>
      <li className="plans__plan__limit-item">50</li>
      <li className="plans__plan__limit-item">Unlimited</li>
      {includedLi}
      {excludedLi}
      {excludedLi}
      {excludedLi}
      {excludedLi}
      {includedLi}
      <li className="plans__plan__limit-item">50,000</li>
    </ul>
  );
}

function UltimateFeats() {
  return (
    <ul className="plans__text-center">
      {excludedLi}
      {includedLi}
      {excludedLi}
      {excludedLi}
      {excludedLi}
      <li className="plans__plan__limit-item">5</li>
      <li className="plans__plan__limit-item">50</li>
      <li className="plans__plan__limit-item">Unlimited</li>
      {includedLi}
      {excludedLi}
      {excludedLi}
      {excludedLi}
      {excludedLi}
      {includedLi}
      <li className="plans__plan__limit-item">100,000</li>
    </ul>
  );
}

function PlaceHolder() {
  const plans = Array.apply(null, Array(4));
  const lines = Array.apply(null, Array(11));

  return (
    <div className="plans__content">
      {plans.map((_, i) => (
        <div key={i} className="plans__plan plans__plan__placeholder">
          {lines.map((_, i) => (
            <Line key={i} />
          ))}
        </div>
      ))}
    </div>
  );
}

function WarnSVG() {
  return (
    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.6052 14.2473L11.8934 1.90935C10.8151 0.0939227 8.18636 0.0915106 7.10655 1.90935L0.395099 14.2473C-0.707272 16.1024 0.627367 18.4515 2.78799 18.4515H16.2118C18.3706 18.4515 19.7076 16.1043 18.6052 14.2473ZM9.49999 16.2249C8.88628 16.2249 8.38671 15.7254 8.38671 15.1116C8.38671 14.4979 8.88628 13.9984 9.49999 13.9984C10.1137 13.9984 10.6133 14.4979 10.6133 15.1116C10.6133 15.7254 10.1137 16.2249 9.49999 16.2249ZM10.6133 11.7718C10.6133 12.3855 10.1137 12.8851 9.49999 12.8851C8.88628 12.8851 8.38671 12.3855 8.38671 11.7718V6.20539C8.38671 5.59168 8.88628 5.09211 9.49999 5.09211C10.1137 5.09211 10.6133 5.59168 10.6133 6.20539V11.7718Z"
        fill="#2E89E2"
      />
    </svg>
  );
}
