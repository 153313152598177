import React, { useState } from 'react';

import { AdGroupEditModal } from './AdGroupEditModal';
import type { accountT } from '../../../../../../flow-typed/pixelme/types';
import { Edit } from '../../../../common/icons';
import { IconInsideButton } from '../../../AudienceBuilder/Campaigns/campaignTree/Common';

export function EditAdGroup(props: { account: accountT, adGroup: adGroupT, onUpdate: (a: adGroupT) => void }) {
  const [visible, setVisible] = useState(false);

  return (
    <div>
      <AdGroupEditModal
        account={props.account}
        adGroup={props.adGroup}
        visible={visible}
        onFinish={() => setVisible(false)}
        onUpdate={props.onUpdate}
      />

      <IconInsideButton>
        <Edit
          onClick={(e) => {
            setVisible(true);
          }}
        ></Edit>
      </IconInsideButton>
    </div>
  );
}
