import React, { useContext, useEffect, useState } from 'react';

import { DeleteOutlined } from '@ant-design/icons';
import { Button, Form, Popconfirm } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import styled from 'styled-components';
import { useImmer } from 'use-immer';

import { ExportButton } from 'components/common/buttons/ExportButton';
import ShortInfo from 'components/common/ShortInfo';
import { DashboardFilterContext } from 'context/DashboardFilterProvider';
import { useLoading } from 'hooks/useLoading';
import { deleteProduct, getAllProductsForTable } from 'lib/adsPlateformApi';
import axios from 'lib/axios.factory';
import { HIDE_RESEARCH_ACCOUNT, useFeatureFlag } from 'lib/configcat';

import { ProductsStats } from './ProductsStats';
import { ProductsTable } from './ProductsTable';
import { ACTIVE_CAMPAIGN_OPTIONS } from '../../../../../consts/consts';
import { calculateStats } from '../../common/adsStats';
import { DashboardFilter } from '../../common/DashboardFilter';
import NewProductModal from '../../NewCampaign/NewProductModal';
import { HeaderStyle } from '../Style';

export type StateT = {
  allProducts: Array<productForTableT>,
  products: Array<productForTableT>,
  stats: adsReportDataT,
  adsReportsRange: Date[],
};

const Style = styled.div`
  .ant-table-column-sorters {
    width: 100%;
    justify-content: space-between;
  }

  .ant-select-selection-item-content {
    font-size: 10px !important;
    line-height: 22px;
  }
`;

export function ProductsDashBoard({ account, productProviderId }: { account: accountT, productProviderId: string }) {
  const { ctx, updateCtx } = useContext(DashboardFilterContext);
  const initialState: StateT = {
    products: [],
    stats: {},
  };
  const [state, updateState] = useImmer(initialState);
  const { doAction, ExclusiveInlineLoadingContainer } = useLoading(true);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  async function loadProducts() {
    let from = _.get(ctx, ['adsReportsRange', 0]);

    if (from) {
      from = moment(from).toISOString();
    }

    let to = _.get(ctx, ['adsReportsRange', 1]);

    if (to) {
      to = moment(to).toISOString();
    }

    let filterProviderType = _.get(ctx, 'filterProviderType', []);

    filterProviderType =
      filterProviderType && filterProviderType.length > 0 ? filterProviderType.join(',') : filterProviderType;

    // TODO (Matej): implement backend pagination - why not now? because we will also have to create a new endpoint to fetch stats for all the product...
    const getProductsResponse = await getAllProductsForTable(localStorage, axios, {
      accountId: account.id,
      from,
      to,
      filterProviderType,
    });

    if (ctx.selectedTags.length > 0) {
      getProductsResponse.products = getProductsResponse.products.filter((product) =>
        ctx.selectedTags.some((tag) => product.tags?.includes(tag)),
      );
    }

    switch (ctx.filterProductByActiveCampaign) {
      case ACTIVE_CAMPAIGN_OPTIONS.ACTIVE_GOOGLE:
        getProductsResponse.products = getProductsResponse.products.filter(
          (product) => product.activeGoogleCampaignsCount > 0,
        );
        break;
      case ACTIVE_CAMPAIGN_OPTIONS.ACTIVE_FACEBOOK:
        getProductsResponse.products = getProductsResponse.products.filter(
          (product) => product.activeFacebookCampaignsCount > 0,
        );
        break;
      case ACTIVE_CAMPAIGN_OPTIONS.ACTIVE_TIKTOK:
        getProductsResponse.products = getProductsResponse.products.filter(
          (product) => product.activeTikTokCampaignsCount > 0,
        );
        break;
      default:
        break;
    }

    const products = getProductsResponse.products.map((product) => {
      const adsReport = (product.selectedAdsReport || {}).data;

      return { ...adsReport, ...product };
    });

    const stats = calculateStats(products);

    updateState((draft) => {
      draft.allProducts = products;
      draft.products = products;
      draft.stats = stats;
    });
    filterOnSearch(products);
  }

  // Empty search when navigate
  useEffect(() => {
    updateCtx((draft) => {
      draft.searchProduct = '';
    });
  }, []);

  useEffect(() => {
    doAction(async () => {
      await loadProducts();
    });
  }, [
    JSON.stringify(ctx.adsReportsRange),
    ctx.selectedTags,
    ctx.filterProviderType,
    ctx.filterProductByActiveCampaign,
  ]);

  function filterOnSearch(allProducts) {
    let tmpProducts = allProducts || [];

    if (ctx.searchProduct) {
      const searchTerm = ctx.searchProduct.toLowerCase();

      tmpProducts = tmpProducts.filter(
        (p) =>
          p.name.toLowerCase().includes(searchTerm) ||
          p.externalId.toLowerCase().includes(searchTerm) ||
          p.description.toLowerCase().includes(searchTerm),
      );
    }

    const tmpStats = calculateStats(tmpProducts);

    updateState((draft) => {
      draft.products = tmpProducts;
      draft.stats = tmpStats;
    });
  }
  useEffect(() => {
    filterOnSearch(state.allProducts);
  }, [ctx.searchProduct]);

  return (
    <Style>
      <ProductsDashBoardHeader
        account={account}
        products={state.products}
        setProducts={(ps) =>
          updateState((draft) => {
            draft.products = ps;
          })
        }
        selectedRowKeys={selectedRowKeys}
      />
      <ExclusiveInlineLoadingContainer>
        <ProductsStats account={account} stats={state.stats} productProviderId={productProviderId} />
        <ProductsTable
          account={account}
          setSelectedRowKeys={setSelectedRowKeys}
          products={state.products}
          productProviderId={productProviderId}
        />
      </ExclusiveInlineLoadingContainer>
    </Style>
  );
}

function ProductsDashBoardHeader({ account, setProducts, products, selectedRowKeys }) {
  const [hideResearchAccount] = useFeatureFlag(HIDE_RESEARCH_ACCOUNT);

  return (
    <div style={{ marginTop: '20px' }}>
      <HeaderStyle>
        {(selectedRowKeys || []).length > 0 && (
          <Popconfirm
            title="Are you sure to delete all selected products?"
            disabled={(selectedRowKeys || []).length === 0}
            onConfirm={async () => {
              for (const k of selectedRowKeys || []) {
                const product = products.find((p) => p.id === k);

                try {
                  await deleteProduct(
                    localStorage,
                    axios,
                    { accountId: account.id },
                    product.providerId,
                    product.externalId,
                  );
                } catch (e) {
                  // ignore delete error
                }

                products = products.filter((p) => p.id !== product.id);
                setProducts(products);
              }
            }}
          >
            <Button danger>
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        )}
        <DashboardFilter tagsFilter searchProduct productByActiveCampaignFilter />
        <Form.Item>
          <div style={{ display: 'flex', gap: '1em' }}>
            {!hideResearchAccount && <ExportButton account={account} />}
            <ShortInfo>Select a product below to create a campaign</ShortInfo>
            <AddNewProductButton account={account} />
          </div>
        </Form.Item>
      </HeaderStyle>
    </div>
  );
}

export function AddNewProductButton({ account }) {
  const [showAddNewProduct, setShowAddNewProduct] = useState(false);

  return (
    <>
      <Button style={{ width: '100%' }} type="primary" onClick={() => setShowAddNewProduct(true)}>
        New product
      </Button>
      <NewProductModal
        account={account}
        visible={showAddNewProduct}
        onFinish={() => {
          setShowAddNewProduct(false);
          window.location.reload();
        }}
      ></NewProductModal>
    </>
  );
}
