import React, { Component, Fragment } from 'react';

import _ from 'lodash';
import isEqual from 'lodash/isEqual';
import { connect } from 'react-redux';
import styled from 'styled-components';

import Checkbox from 'components/common/Checkbox';
import { RedirectsListDatatableContentHeader } from 'components/common/CopiableCell';
import { getGridTemplate } from 'lib/columns';
import { s, sd } from 'lib/safe';
import { strings } from 'locales/strings';
import { selectProductProvider, selectCurrentAccount } from 'stores/accounts';

import { CampaignRedirectsDragDropSource } from './CampaignRedirectsDragDropSource';
import RedirectsListLine from './RedirectsListLine';
import {
  Campaign,
  Check,
  Clicks,
  Content,
  CookieConsent,
  CreatedAt,
  Domain,
  Drag,
  Medium,
  PixelCol,
  Shorten,
  Slug,
  Source,
  Tags,
  Term,
  Title,
  Url,
} from './RedirectsListLineElements';

type internalPropsT = {
  nbOfColumns: number,
  checkAllChecked: boolean,
  toggleAll: Function,
  redirects: redirectT[],
  selectedRedirects: redirectT,
  account: accountT,
  activeAmazonProfile: any,
};
type internalStateT = { loading: boolean };
const defaultState: internalStateT = {};

const RedirectsListDatatableContentContainer = styled.div.withConfig({
  displayName: 'RedirectsListDatatableContentContainer',
})`
  position: relative;
  height: 100%;
  width: fit-content;
  padding-bottom: 20px;
`;

const RedirectsListDatatableContentLines = styled.div.withConfig({
  displayName: 'RedirectsListDatatableContentLines',
})`
  max-height: calc(100% - 90px);
`;

class AbstractRedirectsListDatatableContent extends Component<internalPropsT, internalStateT> {
  state = defaultState;

  shouldComponentUpdate(nextProps) {
    if (isEqual(nextProps, this.props)) {
      return false;
    }

    return true;
  }

  componentWillReceiveProps(nextProps) {
    if (!_.isEqual(nextProps, this.props)) {
      this.setState({
        checkAllChecked: nextProps.selectedRedirects.length === nextProps.redirects.length,
      });
    }
  }

  render() {
    const { config, loading, toggleAll, redirects, selectedRedirects, stats, limitations, activeAmazonProfile } =
      this.props;

    console.log(
      '%c render front/src/components/pages/AudienceBuilder/Campaigns/campaigns/RedirectsListDatatableContent.jsx',
      'color: blue',
    );

    return (
      <RedirectsListDatatableContentContainer>
        <RedirectsListDatatableContentHeader
          style={{
            gridTemplateColumns: getGridTemplate(config.selectedColumns.length + 3),
          }}
        >
          <Drag />
          <Check>
            <Checkbox
              style={{
                width: '15px',
                cursor: loading ? 'auto' : 'pointer',
                opacity: loading ? '0.1' : '1',
              }}
              checked={this.state.checkAllChecked}
              handleClick={() => {
                if (loading) {
                  return;
                }

                this.setState(
                  {
                    checkAllChecked: !this.state.checkAllChecked,
                    loading: true,
                  },
                  () =>
                    setTimeout(() => {
                      if (this.state.checkAllChecked) {
                        toggleAll({ redirects });
                      } else {
                        toggleAll();
                      }

                      this.setState({
                        loading: false,
                      });
                    }, 0),
                );
              }}
            />
            )
          </Check>
          <Title config={config} sortable sortKey="title">
            {strings.get('redirects.redirect.title')}
          </Title>
          <Url config={config} sortable sortKey="redirect">
            {strings.get('redirects.redirect.url')}
          </Url>
          {stats && stats.nbRedirects < sd(s(s(window).env).REACT_APP_MAX_REDIRECTS_TO_SORT, 1000) && (
            <Clicks config={config} sortable sortKey="clicks">
              {strings.get('redirects.redirect.clicks')}
            </Clicks>
          )}
          {(!stats || stats.nbRedirects >= sd(s(s(window).env).REACT_APP_MAX_REDIRECTS_TO_SORT, 1000)) && (
            <Clicks config={config} sortKey="clicks">
              {strings.get('redirects.redirect.clicks')}
            </Clicks>
          )}
          <Shorten config={config} sortable sortKey="key">
            {strings.get('redirects.redirect.shorten')}
          </Shorten>
          <PixelCol config={config} sortable sortKey="pixels">
            {strings.get('redirects.redirect.pixel')}
          </PixelCol>
          <Tags config={config} sortable sortKey="tags">
            {strings.get('redirects.redirect.tags')}
          </Tags>
          <Source config={config} sortable sortKey="utms.source">
            {strings.get('redirects.redirect.source')}{' '}
          </Source>
          <Medium config={config} sortable sortKey="utms.medium">
            {strings.get('redirects.redirect.medium')}{' '}
          </Medium>
          <Term config={config} sortable sortKey="utms.term">
            {strings.get('redirects.redirect.term')}{' '}
          </Term>
          <Content config={config} sortable sortKey="utms.content">
            {strings.get('redirects.redirect.content')}{' '}
          </Content>
          <Campaign config={config} sortable sortKey="utms.campaign">
            {strings.get('redirects.redirect.campaign')}{' '}
          </Campaign>
          <Domain config={config} sortable sortKey="domain">
            {strings.get('redirects.redirect.domain')}
          </Domain>
          <Slug config={config} sortable sortKey="slug">
            {strings.get('redirects.redirect.slug')}
          </Slug>
          <CookieConsent config={config} sortable sortKey="cookieConsent">
            {strings.get('redirects.redirect.cookieConsent')}
          </CookieConsent>
          <CreatedAt config={config} sortable sortKey="createdAt">
            {strings.get('redirects.redirect.createdAt')}
          </CreatedAt>
        </RedirectsListDatatableContentHeader>
        <RedirectsListDatatableContentLines style={{ opacity: loading ? 0.1 : 1 }}>
          {redirects.map((redirect, i) => (
            <CampaignRedirectsDragDropSource
              key={redirect.id}
              redirect={redirect}
              selected={_.some(selectedRedirects, { id: redirect.id })}
              selectedRedirects={selectedRedirects}
            >
              <RedirectsListLine
                columns={config.selectedColumns}
                selected={_.some(selectedRedirects, { id: redirect.id })}
                style={{
                  gridTemplateColumns: `repeat(${config.selectedColumns.length + 1},min-content)`,
                }}
              />
            </CampaignRedirectsDragDropSource>
          ))}
        </RedirectsListDatatableContentLines>
      </RedirectsListDatatableContentContainer>
    );
  }
}

// noinspection JSUnusedLocalSymbols
const stateToProps = (state: stateT) => ({
  limitations: state.accounts.limitations,
  activeAmazonProfile: selectProductProvider(state),
  account: selectCurrentAccount(state),
  loading: state.front.campaignColumnsConfig.loading,
  config: state.front.audienceBuilder.campaigns.columnsConfig,
  stats: {
    nbRedirects: state.abRedirects.nbRedirects,
    nbClicks: state.abRedirects.nbClicks,
  },
});

// noinspection JSUnusedGlobalSymbols
const dispatchToProps = (dispatch: Dispatch<*>, ownProps: Object) => ({});

const reduxWrapper = connect(stateToProps, dispatchToProps);

export const RedirectsListDatatableContent = reduxWrapper(AbstractRedirectsListDatatableContent);

/*
function AmazonFunnel(props:{redirect:any,children:any}){
  var data = [
    {
      stage: '简历筛选',
      number: 253,
    },
    {
      stage: '初试人数',
      number: 151,
    },
    {
      stage: '复试人数',
      number: 113,
    },
    {
      stage: '录取人数',
      number: 87,
    },
    {
      stage: '入职人数',
      number: 59,
    },
  ];
  var config = {
    data: data,
    xField: 'stage',
    yField: 'number',
    isTransposed: true,
    minSize: 0.3,
    maxSize: 0.8,
    label: {
      formatter: function formatter(datum) {
        return ''.concat(datum.stage, ':').concat(datum.number);
      },
    },
    tooltip: {
      formatter: function formatter(datum) {
        return {
          name: datum.stage,
          value: ''.concat(datum.number, '个'),
        };
      },
    },
  };

  return <Popconfirm title={""} content={<Funnel{...config} />}>
    {props.children}
  </Popconfirm>

}
*/
