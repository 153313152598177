// @flow
import React, { useState, type Node, useContext, useEffect } from 'react';

import { DownOutlined, RightOutlined } from '@ant-design/icons';
import { Spin, Statistic, Table, Tooltip } from 'antd';
import moment from 'moment';
import styled from 'styled-components';

import axios from 'lib/axios.factory';

import { AccountsFilterContext } from './AccountsFilterProvider';
import {
  COLUMNS_WITH_DOLLARS,
  COLUMNS_WITH_PERCENTAGES,
  COLUMNS_WITH_REVERSED_COLORS,
  COLUMN_NAMES,
  calculatePercentages,
} from './constants';
import { CAMPAIGN_STATUSES } from '../../../consts/consts';
import { getAgencyAccountData } from '../../../lib/agencyAccounts';
import { getCurrency } from '../../../utils/currency';

const Style = styled.div`
  overflow: auto;
  max-width: 100%;
  padding: 16px;

  .ant-table-pagination.ant-pagination {
    justify-content: right;
    margin-left: 1em;
  }

  .ant-table-thead > tr > th {
    text-align: center;
  }

  .ant-table-column-title,
  .ant-statistic-content-suffix {
    font-size: 13px;
  }
`;

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30vh;
`;

const StyleCell = styled.div`
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
  white-space: nowrap;
  user-select: none;
`;

const StyleNameCell = styled.a`
  text-align: left;
  text-decoration: none;
  margin: 0;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  user-select: none;
  color: ${(props) => (props.$campaignStatus === CAMPAIGN_STATUSES.STATUS_ACTIVE ? '#1A21FF' : '#828282')};
  :hover {
    text-decoration: none;
  }
`;

const Tag = styled.span`
  text-align: center;
  color: ${(props) => (props.$campaignStatus === CAMPAIGN_STATUSES.STATUS_ACTIVE ? '#0F993E' : '#F8A51B')};
  :hover {
    text-decoration: none;
  }
`;

const comparisonColor = (percentageChange, column) => {
  if (percentageChange === 0) {
    return '#828282';
  } else {
    if (COLUMNS_WITH_REVERSED_COLORS.includes(column)) {
      return percentageChange > 0 ? '#D93F21' : '#0F993E';
    }

    return percentageChange > 0 ? '#0F993E' : '#D93F21';
  }
};

const StyledComparisonCell = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  color: ${(props) => comparisonColor(props.$percentageChange, props.$column)};
`;

const TableContainer = styled.div`
  max-height: 60vh;
  overflow: auto;
`;

const AccountNameCell = styled.span`
  user-select: none;
`;

type propsT = {
  account: accountT,
};

export const AccountsTable = ({ account }: propsT): Node => {
  const { ctx } = useContext(AccountsFilterContext);
  const [accountData, setAccountData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [accountLoading, setAccountLoading] = useState(false);
  const [error, setError] = useState('');
  const { currencySymbol } = getCurrency(account);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  let [from, to] = ctx.dateRange;

  from = from.toISOString();
  to = to.toISOString();

  const filterByCampaignStatus = (accounts) => {
    if (ctx.campaignStatus) {
      const filteredAccountData = accounts.map((acc) => ({
        ...acc,
        campaigns: acc.campaigns?.filter((c) => c.campaignStatus === ctx.campaignStatus),
      }));

      setFilteredData(filteredAccountData);
    } else {
      setFilteredData(accounts);
    }
  };

  const loadAgencyAccountData = async () => {
    try {
      setAccountLoading(true);
      const agencyAccountData = await getAgencyAccountData(localStorage, axios, {
        from,
        to,
      });

      // append accountId to each campaign since there is no way to fetch it properly in the Table otherwise
      const finalAgencyAccountData = agencyAccountData?.accounts.map((acc) => {
        acc.campaigns = acc?.campaigns?.map((c) => ({ ...c, accountId: acc.accountId }));

        return acc;
      });

      setAccountData(finalAgencyAccountData);
      setFilteredData(finalAgencyAccountData);
    } catch {
      setFilteredData([]);
      setError('An Error Occurred. Please contact your admin');
    } finally {
      setAccountLoading(false);
    }
  };

  useEffect(() => {
    loadAgencyAccountData();
  }, [...ctx.dateRange]);

  useEffect(() => {
    if (accountData.length) {
      filterByCampaignStatus(accountData);
    }
  }, [ctx.campaignStatus]);

  const handleExpand = (expanded, record) => {
    setExpandedRowKeys((prevKeys) =>
      expanded ? [...prevKeys, record.accountId] : prevKeys.filter((id) => id !== record.accountId),
    );
  };

  const columns = [
    {
      key: 'accountName',
      title: 'Account Name',
      dataIndex: 'name',
      width: 450,
      fixed: 'left',
      render: (v, record) => {
        if (record?.campaignStatus) {
          return (
            <Tooltip title={v}>
              <StyleNameCell
                href={
                  record?.productProviderId && record?.productExternalId
                    ? `/${record.accountId}/adsprovider/${record.productProviderId}/${record.productExternalId}/campaigns/`
                    : `/${record.accountId}/adsprovider/`
                }
                $campaignStatus={record.campaignStatus}
                target="_blank"
                rel="noopener noreferrer"
              >
                {v}
                <Tag $campaignStatus={record.campaignStatus}>
                  {record.campaignStatus === CAMPAIGN_STATUSES.STATUS_ACTIVE ? 'Enabled' : 'Paused'}
                </Tag>
              </StyleNameCell>
            </Tooltip>
          );
        } else {
          return (
            <Tooltip title={v}>
              <AccountNameCell>{v}</AccountNameCell>
            </Tooltip>
          );
        }
      },
    },
  ];

  const initialColumns = ctx.columns.map((col) => {
    if (col === 'activeAndTotalCampaigns') {
      return {
        key: col,
        title: COLUMN_NAMES[col],
        render: (val) => (
          <StyleCell>
            {val?.activeCampaignCount ?? 0} / {val?.totalCampaignCount ?? 0}
          </StyleCell>
        ),
        width: 150,
      };
    }

    if (col === 'googleAdsIds') {
      return {
        key: col,
        title: COLUMN_NAMES[col],
        dataIndex: col,
        render: (val) => (
          <Tooltip title={val?.join(' , ')}>
            <StyleCell>{val?.join(' , ')}</StyleCell>
          </Tooltip>
        ),
        width: 160,
      };
    }

    if (col === 'startDate') {
      return {
        key: col,
        title: COLUMN_NAMES[col],
        dataIndex: col,
        render: (val) => <StyleCell>{moment(val).format('MM/DD/YY')}</StyleCell>,
        width: 120,
      };
    }

    if (col === 'acosRange') {
      return {
        key: col,
        title: COLUMN_NAMES[col],
        dataIndex: col,
        render: (val) => (
          <StyleCell>
            <StyleCell>{val?.now}</StyleCell>
            <StyleCell>{val?.before}</StyleCell>
          </StyleCell>
        ),
        width: 160,
      };
    } else {
      return {
        key: col,
        dataIndex: col,
        title: COLUMN_NAMES[col],
        width: 200,
        render: (val) => {
          const shouldHavePercent = COLUMNS_WITH_PERCENTAGES.includes(col);
          const shouldHaveCurrency = COLUMNS_WITH_DOLLARS.includes(col);

          if (typeof val === 'object') {
            const percentageChange = calculatePercentages(val?.before, val?.now);

            return (
              <StyleCell>
                <Statistic
                  value={val?.now}
                  precision={shouldHaveCurrency || shouldHavePercent ? 2 : 0}
                  prefix={shouldHaveCurrency && currencySymbol}
                  suffix={shouldHavePercent && '%'}
                />
                <StyledComparisonCell $percentageChange={percentageChange} $column={col}>
                  <Statistic
                    value={val?.before}
                    precision={shouldHaveCurrency || shouldHavePercent ? 2 : 0}
                    prefix={shouldHaveCurrency && currencySymbol}
                    suffix={shouldHavePercent && '%'}
                    valueStyle={{ color: comparisonColor(percentageChange, col) }}
                  />
                  (
                  <Statistic
                    value={percentageChange.toFixed(2)}
                    precision={2}
                    suffix="%"
                    valueStyle={{ color: comparisonColor(percentageChange, col) }}
                  />
                  )
                </StyledComparisonCell>
              </StyleCell>
            );
          } else {
            return (
              <StyleCell>
                {(val === 0 || !!val) && (
                  <Statistic
                    value={val}
                    prefix={shouldHaveCurrency && currencySymbol}
                    suffix={shouldHavePercent && '%'}
                  />
                )}
              </StyleCell>
            );
          }
        },
      };
    }
  });

  columns.push(...initialColumns);

  return (
    <Style>
      {accountLoading ? (
        <SpinnerContainer>
          <Spin data-testid="loading-spinner" />
        </SpinnerContainer>
      ) : (
        <TableContainer>
          <Table
            style={{ cursor: 'pointer' }}
            locale={{ emptyText: error ?? '' }}
            rowKey={(record) => record.campaignId ?? record.accountId}
            bordered
            tableLayout="fixed"
            dataSource={filteredData}
            columns={columns}
            scroll={{ x: 1300 }}
            sticky
            pagination={{ defaultPageSize: 100, showSizeChanger: true }}
            childrenColumnName="campaigns"
            expandable={{
              expandIcon: ({ expanded, onExpand, record }) => {
                if (!record.campaigns) {
                  return <span></span>;
                }

                return expanded ? (
                  <DownOutlined onClick={(e) => onExpand(record, e)} style={{ paddingRight: '16px' }} />
                ) : (
                  <RightOutlined onClick={(e) => onExpand(record, e)} style={{ paddingRight: '16px' }} />
                );
              },
              indentSize: -15,
              expandedRowKeys,
              onExpand: handleExpand,
            }}
          />
        </TableContainer>
      )}
    </Style>
  );
};
