import React, { Fragment, useEffect, useRef, useState } from 'react';

import { Form, Modal, Result } from 'antd';
import deepmerge from 'deepmerge';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import axios from 'lib/axios.factory';

import type { accountT } from '../../../../../flow-typed/pixelme/types';
import { overwriteMerge } from '../../../../hooks/useAdsCampaign';
import { useLoading } from '../../../../hooks/useLoading';
import { bulkImportCampaignsOnProductAndProvider } from '../../../../lib/adsPlateformApi';
import { MutedText } from '../../../common/MutedText';
import { AddDomainButton } from '../common/AddDomainButton';
import { AddPixelButton } from '../common/AddPixelButton';
import { Wizard } from '../common/Wizard';
import { AdsProviderSelector } from '../NewCampaign/AdsProviderSelector';
import { DeeplinkToggle } from '../NewCampaign/DeeplinkToggle';
import { DomainSelector } from '../NewCampaign/DomainSelector';
import { ExternalCampaignSelector } from '../NewCampaign/ExternalCampaignSelector';
import { PixelSelector } from '../NewCampaign/PixelSelector';
import { ProductSelector } from '../NewCampaign/ProductSelector';

const Style = styled.div``;

export function TiktokImportAdsCampaignPage(props: { account: accountT }) {
  const [productForm] = Form.useForm();
  const [campaignForm] = Form.useForm();
  const history = useHistory();
  const wizardRef = useRef();
  const { doAction, loading } = useLoading(false);
  const [formData, setFormData] = useState({});
  const [adsProvider, setAdsProvider] = useState();

  const disabledPromotionFn = (c: ExternalCampaignT) =>
    c.notSupported
      ? 'This type of campaign is not supported (multiple urls found)'
      : c.alreadyExisting
        ? 'Campaign already exists'
        : false;

  const importCampaign = (values) =>
    doAction(async () => {
      await bulkImportCampaignsOnProductAndProvider(
        localStorage,
        axios,
        {
          accountId: props.account.id,
        },
        {
          ...values,
          adsProviderId: formData.adsProvider.id,
          campaigns: formData.campaigns,
        },
        formData.product.providerId,
        formData.product.externalId,
      );

      Modal.success({
        icon: null,
        okText: 'Close',
        afterClose: () => {
          history.push(
            `/${props.account.id}/adsprovider/${formData.product.providerId}/${formData.product.externalId}/campaigns`,
          );
        },
        content: (
          <Result
            status="success"
            title="Import in progress"
            extra={[
              <MutedText>
                We are importing your campaigns.
                <br />
                This can take <b>up to 24 hours</b>.<br />
                We will email you when this is complete.
              </MutedText>,
            ]}
          />
        ),
      });
    });

  const stepChanged = (form) => async (changes) => {
    if (!changes) {
      return;
    }

    if (changes.adsProvider && adsProvider !== changes.adsProvider.id) {
      setAdsProvider(changes.adsProvider);
    }

    const newFormData = deepmerge(formData, changes, {
      arrayMerge: overwriteMerge,
      clone: true,
    });

    setFormData(newFormData);
  };

  const wizardSteps = [
    {
      title: 'Product',
      form: productForm,
      onChange: stepChanged(productForm),
      content: (
        <Form
          onValuesChange={stepChanged(productForm)}
          requiredMark="optional"
          form={productForm}
          style={{ height: '100%' }}
          layout="vertical"
        >
          <AdsProviderSelector account={props.account} providerType="TIKTOK" form={productForm} />
          <ProductSelector
            account={props.account}
            refreshWhenThisValueChange={props.productId}
            productId={props.productId}
          />
          {adsProvider && (
            <ExternalCampaignSelector
              account={props.account}
              providerType="TIKTOK"
              adsProviderId={_.get(adsProvider, 'id')}
              disabledItemFn={disabledPromotionFn}
            />
          )}
          <br />
        </Form>
      ),
    },
    {
      title: 'Campaign Settings',
      form: campaignForm,
      onChange: stepChanged(campaignForm),
      content: (
        <Form
          onValuesChange={stepChanged(campaignForm)}
          requiredMark="optional"
          form={campaignForm}
          style={{ height: '100%' }}
          layout="vertical"
        >
          <PixelSelector account={props.account}></PixelSelector>
          <AddPixelButton accountId={props.account.id} />
          <DomainSelector account={props.account} />
          <AddDomainButton accountId={props.account.id} />
          <DeeplinkToggle account={props.account} />
          <br />
          <div style={{ position: 'absolute', marginLeft: '500px', width: 500, marginTop: -20 }}>
            <MutedText>
              Please note: We'll important your campaign(s) and <b>we'll edit the final destination URL</b> to add
              Amazon Attribution tags to your link(s). That can take up to 24 hours to import campaigns.
            </MutedText>
          </div>
        </Form>
      ),
    },
  ];

  return (
    <Style>
      <Fragment>
        <Wizard
          steps={wizardSteps}
          width="450px"
          tRef={wizardRef}
          okText="Import"
          previousText="Back"
          nextText="Next"
          currentStep={0}
          loading={loading}
          onComplete={importCampaign}
          stepsTitle="Campaign resume"
        />
      </Fragment>
    </Style>
  );
}
