import React from 'react';

import { strings } from 'locales/strings';

import { BarToolTip } from '../BarToolTip';

const Billing = () => (
  <BarToolTip title={strings.get('head.billing')}>
    <svg className="bar__icon" width="33" height="33" viewBox="0 0 33 33" xmlns="http://www.w3.org/2000/svg">
      <rect width="33" height="33" rx="4" className="icon-background" />
      <rect width="26" height="17" rx="3" className="icon-main-fill" transform="translate(3.5 8)" />
      <circle className="icon-back-fill" cx="2.5" cy="2.5" r="2.5" transform="translate(18.5 18)" />
      <circle className="icon-back-fill" cx="2.5" cy="2.5" r="2.5" transform="translate(22.5 18)" />
      <rect width="26" height="2" transform="translate(3.5 11)" className="icon-back-fill" />
      <path d="M0 0H11" transform="translate(5.5 21)" className="icon-back-stroke" />
    </svg>
  </BarToolTip>
);

export default Billing;
