import React, { Component, useRef, useState } from 'react';

import { Button, Form, Input } from 'antd';
import ReCAPTCHA from 'react-google-recaptcha';
import { Link } from 'react-router-dom';

import { sendResetPassword } from 'lib/api';
import axios from 'lib/axios.factory';

import { useLoading } from '../../../hooks/useLoading';
import SignLayout from '../../layout/Sign/SignLayout';
import { ForgotPassword } from '../easy/images/ForgotPassword';
import { SignupSuccess } from '../easy/images/SignupSuccess';

export function SendReset(props) {
  const captchaRef = useRef(null);
  const [error, setError] = useState();
  const [sent, setSent] = useState();
  const { doAction, loading: sending } = useLoading(false);

  function handleSubmit(values) {
    setSent(false);

    if (!values.captcha) {
      return;
    }

    doAction(async () => {
      try {
        await sendResetPassword(localStorage, axios, {
          email: values.email,
          captcha: values.captcha,
        });
        setSent(values.email);
      } catch (error) {
        setError('Unable to send the reset password email.');
        captchaRef.current.reset();
      }
    });
  }

  if (sent) {
    return (
      <SignLayout
        errorMessage={error}
        title="Check your inbox!"
        description={
          <div>
            An email has been sent to <span className="highlight">{sent}</span> with a password reset link. Check your
            email box.
          </div>
        }
        footer={
          <Link className="highlight" to="/sign-in">
            Back to Sign in page
          </Link>
        }
        image={<SignupSuccess />}
      >
        <></>
      </SignLayout>
    );
  }

  return (
    <SignLayout
      errorMessage={error}
      title="Reset your password"
      description={
        <div style={{ textAlign: 'center', width: '100%' }}>
          Not got an account yet?{' '}
          <Link className="highlight" to="/sign-up">
            Sign up for free
          </Link>
        </div>
      }
      footer={
        <Link className="highlight" to="/sign-in">
          Back to Sign in page
        </Link>
      }
      image={<ForgotPassword />}
    >
      <Form onFinish={handleSubmit} layout="vertical">
        <Form.Item name="email" label="Email" initialValue={props.email}>
          <Input size="large" placeholder="john@apple.com" />
        </Form.Item>
        <Form.Item name="captcha">
          <ReCAPTCHA ref={captchaRef} sitekey={window.env.REACT_APP_CAPTCHA_SITE_KEY} />
        </Form.Item>
        <Button
          size="large"
          block
          htmlType="submit"
          type="primary"
          loading={sending}
          style={{ background: '#2E0077', borderColor: '#2E0077' }}
        >
          Reset password
        </Button>
      </Form>
    </SignLayout>
  );
}
