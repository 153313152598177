import './API.css';

import React, { Component, Fragment } from 'react';

import CopyToClipboard from 'react-copy-to-clipboard';
import { connect } from 'react-redux';

import { integrations, bufferDisconnect, bufferUpdateProfile } from 'lib/api';
import axios from 'lib/axios.factory';
import { addNotice } from 'stores/notices';

import BufferProfile from './Buffer';
import Box from '../../../../common/Box';
import Modal from '../../../../common/Modal';
import { Line } from '../../../../common/Placeholders';

type state = {
  loading: boolean,
  displayBufferDisconnectModal: boolean,
  integrations: integrationsT,
};

class API extends Component<
  {
    account: accountT,
    addNotice: ({ message: string }) => void,
  },
  state,
> {
  constructor(props: { account: accountT, addNotice: ({ message: string }) => void }) {
    super(props);
    this.state = {
      loading: true,
      displayBufferDisconnectModal: false,
      integrations: {
        apiToken: '',
        buffer: { connected: false, oauthUrl: '' },
      },
    };
  }
  componentDidMount() {
    this.loadIntegrations();
  }

  loadIntegrations = () => {
    this.setState({ loading: true });
    const { account } = this.props;

    integrations(localStorage, axios, { accountId: account.id })
      .then(({ integrations }) => {
        // we have to keep the same order
        if (integrations.buffer && integrations.buffer.profiles) {
          integrations.buffer.profiles = integrations.buffer.profiles.sort((a, b) => (a.id < b.id ? -1 : 1));
        }

        this.setState({
          loading: false,
          integrations,
        });
      })
      .catch((_) => {
        this.setState({ loading: false });
      });
  };

  handleUpdateBufferProfile = (
    data: {
      id: string,
      active: boolean,
      pixelsIds: Array<string>,
      domain: string,
    },
    callback: () => void,
  ) => {
    const { account } = this.props;

    bufferUpdateProfile(localStorage, axios, { accountId: account.id }, data)
      .then(({ profile }) => {
        const { integrations } = this.state;

        // replace profile
        if (integrations.buffer && integrations.buffer.profiles) {
          const index: number = integrations.buffer.profiles.findIndex(
            (curProfile: bufferProfileT) => profile.id === curProfile.id,
          );

          integrations.buffer.profiles[index] = profile;
          this.setState({ integrations });
          callback();
        }
      })
      .catch((_) => {
        this.setState({ loading: false });
        callback();
      });
  };

  handleDisconnectBuffer = () => {
    this.setState({ loading: true, displayBufferDisconnectModal: false });
    const { account } = this.props;

    bufferDisconnect(localStorage, axios, { accountId: account.id })
      .then((_) => {
        this.loadIntegrations();
      })
      .catch((_) => {
        this.setState({ loading: false });
      });
  };

  render() {
    const { loading, integrations, displayBufferDisconnectModal } = this.state;
    const { account, addNotice } = this.props;

    if (loading) {
      return (
        <Box className="settings-integrations" header={<Line />}>
          <Fragment>
            <Line />
            <Line />
          </Fragment>
        </Box>
      );
    }

    return (
      <Fragment>
        <Box
          className="settings-integrations"
          header={
            <span>
              API Token{' '}
              <a href="https://api.pixelme.me/doc/" rel="noopener noreferrer" target="_blank">
                Documentation
              </a>
            </span>
          }
        >
          <CopyToClipboard
            text={integrations.apiToken}
            onCopy={() => {
              addNotice({ message: `Redirects Token copied to clipboard.` });
            }}
          >
            <div className="settings-integrations__token">
              <span>Click to copy to your clipboard</span>
              <span className="ellipsis">{integrations.apiToken}</span>
            </div>
          </CopyToClipboard>
        </Box>
        {integrations.buffer.connected && (
          <Box
            className="settings-integrations buffer"
            header={
              <span className="buffer__header">
                <BufferIcon />
                Buffer
                {integrations.buffer.connected && (
                  <a
                    href=""
                    onClick={(event: SyntheticEvent<HTMLInputElement>) => {
                      event.preventDefault();
                      this.setState({
                        displayBufferDisconnectModal: true,
                      });
                    }}
                  >
                    Disconnect
                  </a>
                )}
              </span>
            }
          >
            <div className="buffer-profiles">
              {integrations.buffer.profiles &&
                integrations.buffer.profiles.map((bufferProfile) => (
                  <BufferProfile
                    key={bufferProfile.id}
                    account={account}
                    bufferProfile={bufferProfile}
                    handleUpdate={this.handleUpdateBufferProfile}
                  />
                ))}
            </div>
          </Box>
        )}
        {displayBufferDisconnectModal && (
          <Modal
            handleCancel={() => {
              this.setState({ displayBufferDisconnectModal: false });
            }}
            handleOk={this.handleDisconnectBuffer}
            disabled={loading}
            okMessage="Disconnect"
          >
            Disconnect Buffer integration?
          </Modal>
        )}
      </Fragment>
    );
  }
}

// noinspection JSUnusedGlobalSymbols
const dispatchToProps = (dispatch: Dispatch<*>, ownProps: Object) => ({
  addNotice: (args) => dispatch(addNotice(args)),
});

export default connect(null, dispatchToProps)(API);

function BufferIcon() {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.75146 6.36963L0.101074 3.32227C-0.0336914 3.25977 -0.0336914 3.15723 0.101074 3.09473L6.75146 0.046875C6.88818 -0.015625 7.11084 -0.015625 7.24756 0.046875L13.8979 3.09473C14.0327 3.15723 14.0327 3.25977 13.8979 3.32227L7.24756 6.36963C7.11084 6.43213 6.88818 6.43213 6.75146 6.36963ZM13.8979 6.80518L12.3042 6.07812C12.1675 6.01562 11.9448 6.01562 11.8081 6.07812L7.24756 8.16016C7.11084 8.22217 6.88818 8.22217 6.75146 8.16016L2.19092 6.07812C2.0542 6.01562 1.83154 6.01562 1.69482 6.07812L0.101074 6.80518C-0.0336914 6.86768 -0.0336914 6.96973 0.101074 7.03174L6.75146 10.0679C6.88818 10.1304 7.11084 10.1304 7.24756 10.0679L13.8979 7.03174C14.0327 6.96973 14.0327 6.86768 13.8979 6.80518ZM13.8979 10.6909L12.3042 9.96387C12.1675 9.90137 11.9448 9.90137 11.8081 9.96387L7.24756 12.0459C7.11084 12.1079 6.88818 12.1079 6.75146 12.0459L2.19092 9.96387C2.0542 9.90137 1.83154 9.90137 1.69482 9.96387L0.101074 10.6909C-0.0336914 10.7534 -0.0336914 10.8555 0.101074 10.9175L6.75146 13.9536C6.88818 14.0161 7.11084 14.0161 7.24756 13.9536L13.8979 10.9175C14.0327 10.8555 14.0327 10.7534 13.8979 10.6909Z"
        fill="#041521"
      />
    </svg>
  );
}
