import './Buffer.css';

import React, { Component } from 'react';

import { arraysEquals } from 'lib/util';

import Button from '../../../../common/Button';
import { PixelsDropDown, DomainDropDown } from '../../../../common/dropDown/DropDowns';
import Switch from '../../../../common/Switch';

type propsT = {
  account: accountT,
  bufferProfile: bufferProfileT,
  handleUpdate: (
    {
      id: string,
      active: boolean,
      pixelsIds: Array<string>,
      domain: string,
    },
    () => void,
  ) => void,
};

type stateT = {
  active: boolean,
  pixelsIds: Array<string>,
  domain: string,
  saving: boolean,
};

export default class Profile extends Component<propsT, stateT> {
  constructor(props: propsT) {
    super(props);
    const {
      bufferProfile: { active, pixelsIds, domain },
    } = props;

    this.state = {
      active,
      pixelsIds,
      domain,
      saving: false,
    };
    // if (this.state.domain === "") {
    //   this.state.domain = defaultPixDomain;
    // }
  }

  render() {
    const { account, bufferProfile } = this.props;
    const { active, domain, pixelsIds } = this.state;

    return (
      <div className="buffer-profile">
        <div className="buffer-profile__title">
          <div style={{ backgroundImage: `url('${bufferProfile.avatar}'` }} className="buffer-profile__avatar" />
          <span className="buffer-profile__username">{bufferProfile.formattedUsername}</span>
          <span className="buffer-profile__service">({bufferProfile.formattedService})</span>
        </div>
        <Active active={active} onClick={(_) => this.setState({ active: !active })} />
        <PixelsDropDown
          displayLabel
          account={account}
          pixelsIds={pixelsIds}
          onSelect={(pixelsIds) => {
            this.setState({ pixelsIds });
          }}
        />
        <DomainDropDown
          displayLabel
          domain={domain}
          account={account}
          onSelect={(domain) => {
            this.setState({ domain });
          }}
        />
        {this.renderButton()}
      </div>
    );
  }
  renderButton = () => {
    const {
      bufferProfile: { active, pixelsIds, id },
      handleUpdate,
    } = this.props;
    const { active: newActive, pixelsIds: newPixelsIds, saving } = this.state;

    if (saving) {
      return <Button disabled>Saving...</Button>;
    }

    const newDomain = this.state.domain;
    const { domain } = this.props.bufferProfile;

    // if (newDomain === defaultPixDomain) {
    //   newDomain = "";
    // }
    // if (domain === defaultPixDomain) {
    //   domain = "";
    // }
    if (active === newActive && domain === newDomain && arraysEquals(pixelsIds, newPixelsIds)) {
      return <Button disabled>Save</Button>;
    }

    return (
      <Button
        handleClick={(_) => {
          this.setState({ saving: true });
          handleUpdate(
            {
              id,
              active: newActive,
              domain: this.state.domain, // can't be empty
              pixelsIds: newPixelsIds,
            },
            (_) => this.setState({ saving: false }),
          );
        }}
      >
        Save
      </Button>
    );
  };
}

function Active({ active, onClick }: { active: boolean, onClick: () => void }) {
  return (
    <div className="buffer-profile__active" onClick={onClick}>
      <Switch position={active ? 'right' : 'left'} /> {active ? 'On' : 'Off'}
    </div>
  );
}
