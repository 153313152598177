import React, { useEffect } from 'react';

import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useAuth0 } from '@auth0/auth0-react';
import { Modal } from 'antd';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { returnTo } from '../../../index';
import { getSignedUser } from '../../../lib/auth';
import axios from '../../../lib/axios.factory';
import { signIn } from '../../../stores';
import { MutedText } from '../../common/MutedText';
import { GlobalPlaceholder } from '../../common/Placeholders';

const { confirm } = Modal;

export function Auth0SignIn(props) {
  const { loginWithRedirect } = useAuth0();

  const dispatch = useDispatch();

  const history = useHistory();

  const { isLoading, isAuthenticated, getAccessTokenSilently, error, user } = useAuth0();

  useEffect(() => {
    if (isLoading) {
      return;
    }

    const runSignIn = async () => {
      if (isAuthenticated) {
        try {
          const auth0accessToken = await getAccessTokenSilently();

          localStorage.setItem('auth0-access-token', auth0accessToken);
          user.accessToken = auth0accessToken;
          localStorage.setItem('auth0-user', JSON.stringify(user));

          getSignedUser(localStorage, axios)
            .then((user) => {
              dispatch(signIn(user));

              // Location will be updated inside auth0 redirectCallback (appState.returnUrl is used)
              if (window.location.pathname !== '/sign-in') {
                window.location.reload();

                return;
              }

              // Get url params for returnTo
              const urlParams = new URLSearchParams(window.location.search);
              const returnUrl = urlParams.get('returnTo');

              if (returnUrl) {
                history.push(returnUrl);
              } else {
                history.push('/');
              }
            })
            .catch((error) => {
              if (error.response && error.response.status === 404) {
                history.push('/sign-up-c6');
              }
            });
        } catch (e) {
          await confirm({
            okText: 'Retry',
            title: 'SignIn Failed',
            icon: <ExclamationCircleOutlined />,
            content: (
              <MutedText>
                SignIn failed. <br />
                Please try again.
              </MutedText>
            ),
            onCancel() {
              window.location.href = 'https://www.pixelme.me/';
            },
            onOk() {
              window.location.href = '/sign-out';
            },
          });
          console.error(e);
        }
      } else if (error) {
        await confirm({
          okText: 'Retry',
          title: 'SignIn Failed',
          icon: <ExclamationCircleOutlined />,
          content: (
            <MutedText>
              SignIn failed. <br />
              Please try again.
            </MutedText>
          ),
          onCancel() {
            window.location.href = 'https://www.pixelme.me/';
          },
          onOk() {
            window.location.href = '/sign-out';
          },
        });
        console.error(error);
      } else {
        const urlParams = new URLSearchParams(window.location.search);

        if (localStorage.getItem('PIX_INVITATION_CODE') || urlParams.has('start_over')) {
          loginWithRedirect({
            authorizationParams: {
              screen_hint: 'signup',
            },
            appState: {
              returnTo,
            },
          });
        } else {
          loginWithRedirect({
            appState: {
              returnTo,
            },
          });
        }
      }
    };

    runSignIn();
  }, [isAuthenticated, isLoading]);

  return <GlobalPlaceholder loadingText="Loading account" />;
}
