// @flow
import React, { useContext, type Node } from 'react';

import { Button, Select } from 'antd';
import moment from 'moment';
import styled from 'styled-components';

import { CheckboxMultiSelect } from 'components/common/antd/CheckboxMultiSelect';
import { DateRangePicker } from 'components/common/antd/DateRangePicker';
import { DATE_FORMAT, getDefaultRange } from 'selectors/ranges.selector';

import { AccountsFilterContext } from './AccountsFilterProvider';
import { AgencyExportButton } from './AgencyExportButton.jsx';
import { CAMPAIGN_STATUS_OPTIONS, COLUMN_NAMES, DEFAULT_COLUMNS } from './constants';

const columnsOptions = Object.entries(COLUMN_NAMES).map(([value, label]) => ({ value, label }));

const Style = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ColumnOptionsFooter = styled.div`
  display: flex;
  columns: 2;
  column-gap: 36px;
  > * {
    flex: 1;
  }
  > button {
    font-weight: 600;
    padding: 0;
    margin: 0;
    border: 0;
    text-align: left;
  }
`;

const FiltersStyleContainer = styled.div`
  display: flex;
  gap: 8px;
`;

const Spacer = styled.div``;

export const Filters = (): Node => {
  const { ctx, updateCtx } = useContext(AccountsFilterContext);

  return (
    <Style>
      <FiltersStyleContainer>
        <DateRangePicker
          value={ctx.dateRange}
          onChange={(val, dateStrings) => {
            const [start, end] = dateStrings;
            const updatedDateRange =
              start && end
                ? [moment.utc(start, DATE_FORMAT), moment.utc(end, DATE_FORMAT).endOf('day')]
                : getDefaultRange();

            updateCtx((draft) => {
              draft.dateRange = updatedDateRange;
            });
          }}
        />
        <Select
          options={CAMPAIGN_STATUS_OPTIONS}
          placeholder="Campaign Status"
          style={{ width: 225 }}
          value={ctx.campaignStatus}
          onChange={(v) => {
            updateCtx((draft) => {
              draft.campaignStatus = v;
            });
          }}
        />
      </FiltersStyleContainer>
      <FiltersStyleContainer>
        <AgencyExportButton />
        <CheckboxMultiSelect
          options={columnsOptions}
          context={AccountsFilterContext}
          name="columns"
          placeholder="Column Options"
          dontShowSelectAllOption
          dropdownMatchSelectWidth={false}
          columns={2}
          listHeight={500}
          dropdownStyle={{ width: 500 }}
          tagRender={() => 'Column Options'}
          footer={
            <ColumnOptionsFooter>
              <Spacer />
              <Button
                type="link"
                onClick={() =>
                  updateCtx((draft) => {
                    draft.columns = DEFAULT_COLUMNS;
                  })
                }
              >
                Restore Default Table
              </Button>
            </ColumnOptionsFooter>
          }
        />
      </FiltersStyleContainer>
    </Style>
  );
};
