import React from 'react';

export function ForgotPassword() {
  return (
    <svg
      style={{ margin: 'auto' }}
      xmlns="http://www.w3.org/2000/svg"
      width="309"
      height="205"
      fill="none"
      viewBox="0 0 309 205"
    >
      <g clipPath="url(#clip0_2401_33163)">
        <path
          fill="#F0F0F0"
          d="M110.838 117.98c10.59-18.597 30.79-32.875 52.173-31.5a98.462 98.462 0 00-25.965 62.504c-.352 8.964.193 18.971-5.769 25.684-3.71 4.177-9.381 6.199-14.956 6.624-5.576.424-11.15-.582-16.651-1.583l-1.335.409c-.508-21.385 1.914-43.542 12.503-62.138z"
        ></path>
        <path
          fill="#fff"
          d="M163.043 86.928c-15.813 7.6-29.296 20.291-37.573 35.769-1.789 3.346-3.313 6.896-3.977 10.649-.665 3.756-.201 7.33 1.084 10.897 1.175 3.26 2.754 6.46 3.115 9.949.379 3.677-.976 7.116-3.416 9.845-2.985 3.339-6.994 5.408-10.985 7.295-4.431 2.096-9.067 4.202-12.205 8.113-.38.474-1.095-.143-.715-.616 5.46-6.804 14.807-8.083 21.288-13.565 3.025-2.558 5.295-6.041 5.152-10.122-.125-3.569-1.751-6.869-2.969-10.161-1.279-3.455-1.917-6.93-1.458-10.615.469-3.769 1.857-7.386 3.553-10.763 3.824-7.616 9.058-14.609 15.054-20.654a85.872 85.872 0 0123.746-16.913c.546-.263.848.631.306.892z"
        ></path>
        <path
          fill="#fff"
          d="M127.895 117.524a12.637 12.637 0 01-4.839-7.257 12.62 12.62 0 011.018-8.658c.277-.54 1.131-.137.854.404a11.685 11.685 0 003.584 14.797c.492.356-.128 1.068-.617.714zM121.763 142.668a24.41 24.41 0 0015.45-8.791c.382-.473 1.096.144.715.616a25.39 25.39 0 01-16.095 9.115c-.603.086-.669-.855-.07-.94zM146.349 97.16a7.166 7.166 0 006.405 2.28c.601-.094.666.847.069.94a8.043 8.043 0 01-7.091-2.506.483.483 0 01-.156-.323.482.482 0 01.107-.342.474.474 0 01.666-.049z"
        ></path>
        <path
          fill="#F0F0F0"
          d="M205.556 145.144c-.374.069-.748.138-1.125.214a94.483 94.483 0 00-14.756 4.072c-.373.13-.749.267-1.118.407a99.54 99.54 0 00-31.292 19.01 96.61 96.61 0 00-10.135 10.603c-4.286 5.228-8.517 11.237-14.119 14.646-.58.363-1.183.689-1.803.976l-32.27-13.361c-.058-.067-.119-.127-.177-.195l-1.313-.474c.146-.207.303-.418.449-.624.084-.12.176-.237.26-.357.059-.079.118-.158.166-.233l.056-.072c.048-.075.101-.139.146-.206.87-1.182 1.751-2.359 2.641-3.533.003-.007.003-.007.014-.011 6.805-8.923 14.417-17.358 23.069-24.37.26-.211.523-.43.798-.635a92.22 92.22 0 0112.463-8.415 81.89 81.89 0 017.392-3.65 67.896 67.896 0 0120.535-5.228c14.108-1.308 28.478 1.904 39.299 10.751.277.227.546.451.82.685z"
        ></path>
        <path
          fill="#fff"
          d="M205.314 145.523c-17.209-3.436-35.628-1.406-51.572 5.977-3.447 1.596-6.804 3.515-9.599 6.113-2.795 2.599-4.58 5.732-5.705 9.352-1.029 3.309-1.697 6.813-3.514 9.815-1.915 3.165-5.071 5.096-8.665 5.808-4.397.872-8.846.114-13.17-.777-4.802-.99-9.774-2.095-14.639-.858-.59.149-.788-.772-.2-.922 8.465-2.151 16.699 2.445 25.18 1.965 3.957-.225 7.871-1.641 10.218-4.986 2.052-2.925 2.745-6.538 3.757-9.897 1.063-3.528 2.65-6.686 5.238-9.352 2.648-2.728 5.937-4.781 9.328-6.459 7.647-3.782 16.044-6.22 24.477-7.442a86.025 86.025 0 0129.16.767c.594.119.296 1.014-.294.896z"
        ></path>
        <path
          fill="#fff"
          d="M158.798 148.826a12.621 12.621 0 01.513-8.702 12.65 12.65 0 016.035-6.301c.547-.265.985.57.438.836a11.691 11.691 0 00-6.063 13.968c.179.58-.745.776-.923.199zM138.738 165.218a24.442 24.442 0 0017.638 2.267c.59-.148.788.774.199.921a25.422 25.422 0 01-18.349-2.396c-.533-.293-.018-1.084.512-.792zM185.813 143.661a7.158 7.158 0 003.74 5.669c.536.287.021 1.077-.512.793a8.015 8.015 0 01-4.151-6.264.488.488 0 01.362-.56.477.477 0 01.561.362z"
        ></path>
        <path
          fill="#3F3D56"
          d="M144.086 167.189c-4.519-21.813-10.168-29.086-10.168-29.086l-2.158-1.671-2.595-2.012.012-.259-.615-.209-.145-.112-.237-.182-.038.041-.081.083-11.743-3.985-14.899-5.052-6.9-8.952a11.935 11.935 0 00-9.545-4.648l-22.034.163a11.942 11.942 0 00-8.027 3.178l-14.482 13.41-22.566 10.76-.039-.038-.237.168-3.486 1.665.144.747-2.159 1.555S6.44 149.52 1.92 169.817C.77 174.982.205 186.061 0 199.713a5.203 5.203 0 003.203 4.883 5.22 5.22 0 002.015.405h135.576a5.228 5.228 0 004.841-3.267 5.197 5.197 0 00.376-2.016c-.199-14.855-.765-26.928-1.925-32.529z"
        ></path>
        <path
          fill="#E5E5E5"
          d="M159.551 19.023c-4.689 0-9.187 1.86-12.503 5.17a17.634 17.634 0 000 24.963 17.699 17.699 0 0012.503 5.17h131.768c4.689 0 9.187-1.86 12.502-5.17A17.635 17.635 0 00309 36.674c0-4.68-1.863-9.17-5.179-12.48a17.695 17.695 0 00-12.502-5.17H159.551z"
        ></path>
        <path
          fill="#fff"
          d="M159.551 22.596a14.116 14.116 0 00-9.973 4.123 14.07 14.07 0 00-4.13 9.956 14.07 14.07 0 004.13 9.955 14.116 14.116 0 009.973 4.123h131.768c3.74 0 7.327-1.483 9.972-4.123a14.066 14.066 0 004.131-9.956c0-3.733-1.486-7.314-4.131-9.955a14.115 14.115 0 00-9.972-4.123H159.551z"
        ></path>
        <path
          fill="#9E616A"
          d="M77.417 102.882c15.942 0 28.866-12.902 28.866-28.817 0-15.915-12.924-28.817-28.866-28.817S48.551 58.15 48.551 74.065c0 15.915 12.924 28.817 28.866 28.817z"
        ></path>
        <path
          fill="#2F2E41"
          d="M70.032 101.47c-.123-.271-.246-.543-.368-.816.047.002.094.009.14.01l.228.806zM47.748 46.558c1.46-1.163 3.166-2.233 5.033-2.173 1.866.06 3.76 1.744 3.373 3.568a29.649 29.649 0 0115.413-13.165 29.712 29.712 0 0120.285-.199c4.64 1.634 9.189 4.903 10.255 9.698.273 1.23.332 2.58 1.102 3.58.97 1.26 2.827 1.545 4.356 1.101l.046-.013a1.368 1.368 0 011.477.403 1.356 1.356 0 01.171 1.519l-1.312 2.444c1.654.368 3.374.332 5.011-.106a1.365 1.365 0 011.708 1.336c-.004.281-.095.554-.261.78-4.328 5.933-11.56 9.764-18.94 9.719-5.244-.032-10.543-1.836-15.647-.632a13.603 13.603 0 00-5.4 2.637 13.551 13.551 0 00-3.742 16.41c-1.569-1.711-4.6-1.306-6.204.373s-2.02 4.184-1.858 6.499c.248 3.54 1.637 6.875 3.102 10.122-12.28-.386-23.894-8.985-27.804-20.616-3.925-11.679.189-25.603 9.836-33.285z"
        ></path>
        <path
          fill="#000"
          d="M28.412 160.602l8.096 43.975 4.858.422-12.954-44.397zM117.594 157.285l-8.096 47.26-4.858.454 12.954-47.714z"
          opacity="0.2"
        ></path>
        <path
          fill="#1A21FF"
          d="M54.654 31.758c-1.553.647-3.173-.062-3.852-1.686-.69-1.65-.045-3.323 1.532-3.98 1.577-.658 3.168.053 3.869 1.728.69 1.65.053 3.27-1.55 3.938zm-2.974-8.912l-1.234.514a1.334 1.334 0 01-1.692-.619l-.06-.12c-1.329-2.39-1.48-5.296-.45-8.632.955-2.992 1.351-5.097.553-7.003-.921-2.204-2.89-2.895-5.401-1.895-.974.406-1.284.4-2.05 1.159a1.76 1.76 0 01-1.937.374 1.701 1.701 0 01-.554-.39 1.733 1.733 0 01-.012-2.367A13.494 13.494 0 0143.406.74c5.394-2.249 8.11 1.071 9.352 4.042 1.216 2.906.532 5.602-.566 9.094-.921 2.902-.867 5.051.178 7.19a1.322 1.322 0 01-.69 1.78zM188.656 45.093h-26.217a.628.628 0 01-.587-.388.63.63 0 01.587-.873h26.217a.632.632 0 010 1.26zM221.914 45.302h-26.217a.633.633 0 01-.635-.63.629.629 0 01.635-.631h26.217a.631.631 0 110 1.26zM255.172 45.513h-26.217a.631.631 0 010-1.261h26.217a.63.63 0 110 1.26zM288.43 45.724h-26.217a.632.632 0 010-1.261h26.217a.63.63 0 010 1.26zM175.49 39.291a4.869 4.869 0 004.873-4.864 4.869 4.869 0 00-4.873-4.864 4.868 4.868 0 00-4.872 4.864 4.868 4.868 0 004.872 4.864zM208.949 39.291a4.869 4.869 0 004.873-4.864 4.869 4.869 0 00-4.873-4.864 4.868 4.868 0 00-4.872 4.864 4.868 4.868 0 004.872 4.864zM242.408 39.291a4.868 4.868 0 004.872-4.864 4.868 4.868 0 00-4.872-4.864 4.869 4.869 0 00-4.873 4.864 4.869 4.869 0 004.873 4.864zM275.866 39.291a4.869 4.869 0 004.873-4.864 4.869 4.869 0 00-4.873-4.864 4.868 4.868 0 00-4.872 4.864 4.868 4.868 0 004.872 4.864z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_2401_33163">
          <path fill="#fff" d="M0 0H309V205H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}
