import React from 'react';

import { Keys, MenuItem } from '@blueprintjs/core';

import { strings } from 'locales/strings';
import { getListItems, getListItemFromString, getValue } from 'selectors/utms.selector';

import { sArray } from '../../../../../../lib/safe';
import { addCampaignToUtmsValues } from '../../../../../../lib/utms';
import { DropDownWithLabel } from '../DropDownWithLabel';

export function ComposerUtmCampaignSelect({ localUpdateOfAccount, onChange, redirect, account }) {
  function updateCampaign(campaign) {
    const newRedirect = {
      ...redirect,
      utms: {
        ...redirect.utms,
        campaign: getValue(campaign),
      },
    };

    redirect.utms = newRedirect.utms;
    onChange({ redirect: newRedirect });
    account.utmsValues = addCampaignToUtmsValues(account.utmsValues, campaign);
    localUpdateOfAccount({ account });
  }

  return (
    <DropDownWithLabel
      account={account}
      values={sArray(getListItems(account.utmsValues.campaign))}
      selected={getListItemFromString(redirect.utms.campaign)}
      createNewItemFromQuery={(campaign) => campaign}
      createNewItemRenderer={(query: string, active: boolean) => (
        <MenuItem
          icon="add"
          className="bp3-add-option"
          text={`Add "${query}"`}
          active={active}
          onClick={() => {
            const campaign = query.trim();

            updateCampaign(campaign);
          }}
          shouldDismissPopover={false}
        />
      )}
      onKeyUp={(event) => {
        event.preventDefault();

        if (event.keyCode === Keys.ENTER) {
          const campaign = event.target.value.trim();

          updateCampaign(campaign);
        }
      }}
      title={strings.get('utm.campaign.select.title')}
      placeholder={redirect.utms.campaign || strings.get('utm.campaign.select.placeholder')}
      onSelect={(selectedCampaign) => {
        const campaign = selectedCampaign.id.trim();

        updateCampaign(campaign);
      }}
      displayLabel
    />
  );
}
