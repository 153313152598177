import React, { useState } from 'react';

import { Megaphone } from 'components/common/icons/Megaphone';
import { strings } from 'locales/strings';

import { handleChange } from './composerEditUtils';
import { GdprCheckBox } from '../../../../../common/CheckBoxes';
import { PixelsCTATooltip } from '../../../../../common/tooltip';
import { ComposerTitle } from '../ComposerTitle';
import { ComposerPixelsMultiSelect } from '../selects/ComposerPixelsMultiSelect';

export function ComposerEditPixels(props) {
  const { account, handleClose, redirect, onChange, limitations } = props;
  const [isDropDownOpen, setDropDownOpen] = useState(false);

  if (limitations && limitations.pixels === 0) {
    return (
      <div className="composer__line composer__dropdowns">
        <PixelsCTATooltip position="bottom-end">
          <ComposerTitle
            renderIcon={() => <Megaphone />}
            style={{ gridArea: 'pixel-title' }}
            title={strings.get('composer.redirect.title')}
            setDropDownOpen={setDropDownOpen}
            showToggle
            locked
          />
        </PixelsCTATooltip>
      </div>
    );
  }

  return (
    <div className="composer__line composer__dropdowns">
      <ComposerTitle
        renderIcon={() => <Megaphone />}
        style={{ gridArea: 'pixel-title' }}
        title={strings.get('composer.redirect.title')}
        setDropDownOpen={setDropDownOpen}
        showToggle
      />
      {isDropDownOpen && (
        <>
          <br />
          <div style={{ gridArea: 'pixel-content' }}>
            <div className="composer__line__after_title" style={{ alignItems: 'flex-end' }}>
              <>
                <ComposerPixelsMultiSelect
                  account={account}
                  onChange={onChange}
                  redirect={redirect}
                  handleCTA={handleClose}
                />
                <span
                  style={{
                    marginLeft: '1em',
                    marginBottom: '10px',
                    display: 'block',
                    width: '185px',
                  }}
                />
                <GdprCheckBox
                  cookieConsent={redirect.cookieConsent}
                  handleClick={(e) =>
                    handleChange(
                      [
                        {
                          name: 'cookieConsent',
                          value: !redirect.cookieConsent,
                        },
                      ],
                      props,
                    )
                  }
                  handleCTA={handleClose}
                  account={account}
                />
              </>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
