import React, { Fragment, useRef, useState } from 'react';

import { CheckCircleOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { Alert, Checkbox, Collapse, Form, Input, Modal, notification, Radio, Select } from 'antd';
import deepmerge from 'deepmerge';
import _ from 'lodash';
import moment from 'moment';
import qs from 'query-string';
import { FormInstance } from 'rc-field-form/lib/interface';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import axios from 'lib/axios.factory';
import { getCurrency } from 'utils/currency';

import { ActionCategoriesSelector } from './selectors/ActionCategoriesSelector';
import { AudiencesSelector } from './selectors/AudiencesSelector';
import { CTAsSelector } from './selectors/CTAsSelector';
import { DevicesSelector } from './selectors/DevicesSelector';
import { GeoTargetsSelector } from './selectors/GeoTargetSelector';
import { ImagesSelector } from './selectors/ImagesSelector';
import { InterestCategoriesSelector } from './selectors/InterestCategoriesSelector';
import { InterestKeywordsSelector } from './selectors/InterestKeywordsSelector';
import { LanguagesSelector } from './selectors/LanguagesSelector';
import { OSSelector } from './selectors/OSSelector';
import { VideosSelector } from './selectors/VideosSelector';
import { TiktokAdPreview } from './TiktokAdPreview';
import { TiktokEstimated } from './TiktokEstimated';
import { tiktokActionDays } from '../../../../assets/tiktokActionDays';
import { tiktokAges } from '../../../../assets/tiktokAges';
import { tiktokConnections } from '../../../../assets/tiktokConnections';
import { tiktokCreatorsInteractionTypes } from '../../../../assets/tiktokCreatorsInteractionTypes';
import { tiktokDeliverySpeeds } from '../../../../assets/tiktokDeliverySpeeds';
import { tiktokPlacements } from '../../../../assets/tiktokPlacements';
import { tiktokVideosInteractionTypes } from '../../../../assets/tiktokVideosInteractionTypes';
import { trackedEvents } from '../../../../config/trackedEvents.config';
import { overwriteMerge, useAdsCampaign } from '../../../../hooks/useAdsCampaign';
import { useLoading } from '../../../../hooks/useLoading';
import { saveCampaignOnProductAndProvider } from '../../../../lib/adsPlateformApi';
import { generateDefaultCampaignName } from '../../../../lib/adsProvider/common';
import { getAdsProviders } from '../../../../lib/adsProvider/google';
import { getCurrencyCode } from '../../../../lib/amazon';
import { track } from '../../../../lib/analytics';
import { FormInputGroup } from '../../../common/antd/FormInputGroup';
import { CurrencyInput } from '../../../common/antd/inputs/CurrencyInput';
import { FakeValueChangeFormItem } from '../../../common/antd/inputs/FakeValueChangeFormItem';
import { FixedValueFormItem } from '../../../common/antd/inputs/FixedValueFormItem';
import { InputDateWithDisabledRange } from '../../../common/antd/inputs/InputDateWithDisabledRange';
import { RadioButtonTabs } from '../../../common/antd/RadioButtonTabs';
import { InlineMutedText } from '../../../common/InlineMutedText';
import { MutedText } from '../../../common/MutedText';
import { PrettyPrintJson } from '../../../common/PrettyPrintJson';
import { AddANewButton } from '../common/AddANewButton';
import { AddDomainButton } from '../common/AddDomainButton';
import { AddPixelButton } from '../common/AddPixelButton';
import { Wizard } from '../common/Wizard';
import { AdsProviderSelector } from '../NewCampaign/AdsProviderSelector';
import { DeeplinkToggle } from '../NewCampaign/DeeplinkToggle';
import { DomainSelector } from '../NewCampaign/DomainSelector';
import { PixelSelector } from '../NewCampaign/PixelSelector';
import { ProductSelector } from '../NewCampaign/ProductSelector';

const { confirm } = Modal;
const Style = styled.div`
  min-width: 100%;
  min-height: 100%;
  padding: 1em;
  display: flex;

  .ant-tabs-tabpane,
  .ant-tabs-tab-btn {
    outline: none !important;
  }

  .ant-tabs-left {
    margin-bottom: 25px;
  }

  .ant-tabs-left > .ant-tabs-content-holder,
  .ant-tabs-left > div > .ant-tabs-content-holder {
    margin-left: -1px;
    border-left: 1px solid #f0f0f0;
    align-items: center;
    display: flex;
    padding-left: 2em;
  }

  .ant-tabs-left .ant-tabs-tab-active {
    background-color: aliceblue;
  }
`;

const initialState = {};

export function TiktokNewAdsCampaignPage(props: { account: accountT, campaignId: string, productId: string }) {
  const { campaign, setCampaign } = useAdsCampaign(props.campaignId, props.account.id, initialState, 'TIKTOK');
  const history = useHistory();
  const { doAction, loading } = useLoading(false);
  const { currencyCode } = getCurrency(props.account);
  const adsProvider = getAdsProviders(props.account).find((p) => p.id === _.get(campaign, ['providerId']));
  const wizardRef = useRef();
  const interestKeywordsTranslations = useRef();
  const [ages] = useState(tiktokAges);
  const [videosInteractionTypes] = useState(tiktokVideosInteractionTypes);
  const [creatorsInteractionTypes] = useState(tiktokCreatorsInteractionTypes);
  const [actionDays] = useState(tiktokActionDays);
  const [connections] = useState(tiktokConnections);

  const [productForm] = Form.useForm();
  const [campaignForm] = Form.useForm();
  const [strategyForm] = Form.useForm();
  const [adGroupForm] = Form.useForm();
  const [audienceForm] = Form.useForm();
  const [adForm] = Form.useForm();

  const campaignRequirementsOk = campaign && campaign.providerId && campaign.productId;

  function RequirementsOk({ children, hidden }) {
    return <Fragment>{!hidden && campaignRequirementsOk && children()}</Fragment>;
  }

  const stepChanged = (form) => async (values) => {
    if (!values) {
      return;
    }

    if (values.adsProvider) {
      values.providerId = values.adsProvider.id;
      values.providerType = values.adsProvider.providerType;
      delete values.adsProvider;
    }

    if (values.product) {
      values.productId = values.product.id;
      values.productExternalId = values.product.externalId;
      values.productUrl = values.product.productUrl;

      const name = generateDefaultCampaignName(values.product);

      _.set(values, ['name'], name);
      _.set(values, ['tiktokAd', 'adGroup', 'adgroupName'], name);
      _.set(values, ['tiktokAd', 'campaignName'], name);
      _.set(values, ['tiktokAd', 'campaign', 'campaignName'], name);
      _.set(values, ['tiktokAd', 'adName'], name);
    }

    if (_.get(values, ['tiktokAd', 'campaign', 'campaignName'])) {
      _.set(values, ['name'], _.get(values, ['tiktokAd', 'campaign', 'campaignName']));
      _.set(values, ['tiktokAd', 'name'], _.get(values, ['tiktokAd', 'campaign', 'campaignName']));
    }

    const newCampaign = deepmerge(campaign, values, {
      arrayMerge: overwriteMerge,
      clone: true,
    });

    setCampaign(newCampaign);

    return newCampaign;
  };

  /**
   * Need to create Campaign before to be able to play with adgroup audience
   * because audience size ask for a campaign id
   * @param form
   * @returns {(function(*): Promise<*>)|*}
   */
  const createCampaignBeforeConfiguringAdGroup = async (values) =>
    doAction(async () => {
      if (values.id) {
        setTimeout(() => wizardRef.current.forceStep(2), 500);

        return;
      }

      // need to remove creative right now to not save the full campaign and be mandatory to edit instead of save
      const creativesBackup = _.get(values, ['tiktokAd', 'creatives']);

      let newValues = _.omit({ ...values }, ['tiktokAd.creatives']);

      try {
        const newCampaignData = await saveCampaignOnProductAndProvider(
          localStorage,
          axios,
          { accountId: props.account.id },
          {
            campaign: newValues,
          },
          values.product.providerId,
          values.product.externalId,
        );

        newCampaignData.campaign.tiktokAd.adGroup = newCampaignData.campaign.tiktokAd.adGroups[0];
        newCampaignData.campaign.tiktokAd.adGroups = undefined;
        newValues = { ...newValues, ...newCampaignData.campaign };
        // need to restore backup creative right now to not save the full campaign and be mandatory to edit instead of save
        _.set(newValues, ['tiktokAd', 'creatives'], creativesBackup);
        setCampaign(newValues);
        // need to force next because the first time condition was not ok
        setTimeout(() => wizardRef.current.forceStep(2), 500);
      } catch (e) {
        if (e.response.status === 409) {
          confirm({
            onOk: async () => {
              const newCampaignData = e;

              newCampaignData.campaign.tiktokAd.adGroup = newCampaignData.campaign.tiktokAd.adGroups[0];
              newCampaignData.campaign.tiktokAd.adGroups = undefined;
              newValues = { ...newValues, ...newCampaignData.campaign };
              newValues.id = e.campaign.id;
              _.set(newValues, ['campaign', 'tiktokAd', ',campaign'], e.campaign.tiktokAd.campaign);
              // need to restore backup creative right now to not save the full campaign and be mandatory to edit instead of save
              _.set(newValues, ['tiktokAd', 'creatives'], creativesBackup);
              setCampaign(newValues);
              // need to force next because the first time condition was not ok
              setTimeout(() => wizardRef.current.forceStep(2), 500);
            },
            okText: 'Continue and edit previous campaign',
            cancelText: 'Change campaign name',
            title: 'Campaign already exists',
            width: 600,
            icon: <CheckCircleOutlined />,
            content: (
              <Fragment>
                <MutedText>
                  Campaign already exists with the same name. <br />
                  <br />
                  If you choose to continue, this is
                  <br />
                  going to edit your previous campaign.
                  <br />
                  <br />
                </MutedText>
                <Alert
                  message={
                    'it will only be possible if the Ad is not reviewed yet. ' +
                    'If already reviewed you will have to restart everything'
                  }
                  type="error"
                />
                <br />
                <br />
              </Fragment>
            ),
          });

          return;
        }

        const errorMessage = _.get(e, 'errorMessage');

        notification.error({
          duration: 30,
          message: errorMessage,
        });
      }
    });

  const saveCampaign = async (values) =>
    doAction(async () => {
      try {
        const newValues = { id: campaign.id, ...values };
        const newCampaignData = await saveCampaignOnProductAndProvider(
          localStorage,
          axios,
          { accountId: props.account.id },
          {
            campaign: newValues,
          },
          values.product.providerId,
          values.product.externalId,
        );

        newCampaignData.campaign.tiktokAd.adGroup = newCampaignData.campaign.tiktokAd.adGroups[0];
        newCampaignData.campaign.tiktokAd.adGroups = undefined;
        setCampaign({ ...newValues, ...newCampaignData.campaign });

        track(trackedEvents.addCampaign, { campaign });

        const redirectUrl = qs.stringifyUrl({
          url: `/${props.account.id}/adsprovider/new-tiktok-campaign-success/${newCampaignData.campaign.id}`,
          query: {
            productProviderId: values.product.providerId,
            productExternalId: values.product.externalId,
          },
        });

        history.push(redirectUrl);
      } catch (e) {
        console.error(e);
        const errorMessage = _.get(e, 'errorMessage');

        notification.error({
          duration: 20,
          message: errorMessage || 'Unknown error',
        });
      }
    });

  const wizardSteps = [
    {
      title: 'Product',
      form: productForm,
      onChange: stepChanged(productForm),
      disabled: () => _.get(campaign, ['tiktokAd', 'adGroup', 'campaignId']),
      content: (
        <Form
          onValuesChange={stepChanged(productForm)}
          initialValues={campaign}
          requiredMark="optional"
          form={productForm}
          style={{ height: '100%' }}
          layout="vertical"
        >
          <AdsProviderSelector account={props.account} providerType="TIKTOK" form={productForm} />
          <ProductSelector
            account={props.account}
            refreshWhenThisValueChange={props.productId}
            productId={props.productId}
          />
        </Form>
      ),
    },
    {
      title: 'Campaign settings',
      form: campaignForm,
      onChange: (v) =>
        doAction(async () => {
          const newValue = await stepChanged(campaignForm)(v);

          await createCampaignBeforeConfiguringAdGroup(newValue);
        }),
      disabled: () => _.get(campaign, ['tiktokAd', 'adGroup', 'campaignId']),
      content: () => <RequirementsOk>{CAMPAIGN_SETTINGS_FORM}</RequirementsOk>,
    },
    {
      title: 'Strategy & Schedule',
      form: strategyForm,
      onChange: stepChanged(strategyForm),
      disabled: () => !_.get(campaign, ['tiktokAd', 'adGroup', 'campaignId']),
      content: () => <RequirementsOk>{BUDGET_FORM}</RequirementsOk>,
    },
    {
      title: 'Ad Group',
      form: adGroupForm,
      onChange: stepChanged(adGroupForm),
      disabled: () => !_.get(campaign, ['tiktokAd', 'adGroup', 'campaignId']),
      content: () => <RequirementsOk>{ADGROUP_FORM}</RequirementsOk>,
    },
    {
      title: 'Audience',
      form: audienceForm,
      onChange: stepChanged(audienceForm),
      disabled: () => !_.get(campaign, ['tiktokAd', 'adGroup', 'campaignId']),
      content: () => <RequirementsOk>{AUDIENCE_FORM}</RequirementsOk>,
    },
    {
      title: 'Ad',
      form: adForm,
      disabled: () => !_.get(campaign, ['tiktokAd', 'adGroup', 'campaignId']),
      onChange: async (v) => await stepChanged(adForm)(v),
      content: () => <RequirementsOk>{AD_FORM}</RequirementsOk>,
    },
  ];

  function BUDGET_FORM() {
    const form3Campaign = {};

    _.set(
      form3Campaign,
      ['tiktokAd', 'adGroup', 'scheduleType'],
      _.get(campaign, ['tiktokAd', 'adGroup', 'scheduleType']),
    );
    _.set(
      form3Campaign,
      ['tiktokAd', 'adGroup', 'scheduleStartTime'],
      _.get(campaign, ['tiktokAd', 'adGroup', 'scheduleStartTime']),
    );
    _.set(
      form3Campaign,
      ['tiktokAd', 'adGroup', 'scheduleEndTime'],
      _.get(campaign, ['tiktokAd', 'adGroup', 'scheduleEndTime']),
    );
    _.set(form3Campaign, ['tiktokAd', 'adGroup', 'bidType'], _.get(campaign, ['tiktokAd', 'adGroup', 'bidType']));
    _.set(form3Campaign, ['tiktokAd', 'adGroup', 'bid'], _.get(campaign, ['tiktokAd', 'adGroup', 'bid']));
    _.set(form3Campaign, ['tiktokAd', 'adGroup', 'pacing'], _.get(campaign, ['tiktokAd', 'adGroup', 'pacing']));

    return (
      <Form
        initialValues={form3Campaign}
        requiredMark="optional"
        form={strategyForm}
        onValuesChange={stepChanged(strategyForm)}
        style={{ height: '100%' }}
        layout="vertical"
      >
        <Form.Item
          name={['tiktokAd', 'adGroup', 'scheduleType']}
          required
          label="Schedule"
          rules={[
            {
              required: true,
              type: 'string',
            },
          ]}
        >
          <RadioButtonTabs
            label=""
            options={[
              {
                value: 'SCHEDULE_START_END',
                label: 'Start-End',
                children: (
                  <div style={{ width: '100%' }}>
                    <br />
                    <FormInputGroup>
                      <Form.Item
                        name={['tiktokAd', 'adGroup', 'scheduleStartTime']}
                        required
                        label="Start"
                        style={{ flex: '1' }}
                        rules={[{ required: true, type: 'date' }]}
                      >
                        <InputDateWithDisabledRange
                          startDate={new Date()}
                          placeholder="Start date"
                          endDate={new Date(8640000000000000)}
                        />
                      </Form.Item>
                      <Form.Item
                        name={['tiktokAd', 'adGroup', 'scheduleEndTime']}
                        dependencies={['tiktokAd', 'adGroup', 'scheduleStartTime']}
                        required
                        label="End"
                        style={{ flex: '1' }}
                        rules={[
                          {
                            required: true,
                            message: 'End date must be minimum 1 days after start date.',
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (
                                !value ||
                                !getFieldValue(['tiktokAd', 'adGroup', 'scheduleStartTime']) ||
                                moment(value).diff(
                                  moment(getFieldValue(['tiktokAd', 'adGroup', 'scheduleStartTime'])),
                                  'days',
                                ) > 1
                              ) {
                                return Promise.resolve();
                              }

                              return Promise.reject(new Error('End date must be minimum 1 days after start date'));
                            },
                          }),
                        ]}
                      >
                        <InputDateWithDisabledRange
                          startDate={new Date()}
                          placeholder="End date"
                          endDate={new Date(8640000000000000)}
                        />
                      </Form.Item>
                    </FormInputGroup>
                  </div>
                ),
              },
              {
                value: 'SCHEDULE_FROM_NOW',
                label: 'Run continuously',
                disabled: () => _.get(campaign, ['tiktokAd', 'campaign', 'budgetMode']) == 'BUDGET_MODE_TOTAL',
                children: (
                  <Fragment>
                    <br />
                    <Form.Item
                      name={['tiktokAd', 'adGroup', 'scheduleStartTime']}
                      required
                      label="Start"
                      rules={[
                        {
                          required: true,
                          type: 'date',
                        },
                      ]}
                    >
                      <InputDateWithDisabledRange
                        startDate={new Date()}
                        placeholder="Start date"
                        endDate={new Date(8640000000000000)}
                      />
                    </Form.Item>
                  </Fragment>
                ),
              },
            ]}
          />
        </Form.Item>
        <Form.Item
          name={['tiktokAd', 'adGroup', 'bidType']}
          required
          label="Bidding & Optimization"
          rules={[
            {
              required: true,
              type: 'string',
            },
          ]}
        >
          <RadioButtonTabs
            label=""
            options={[
              {
                value: 'BID_TYPE_NO_BID',
                label: 'Lowest cost',
                children: <Fragment></Fragment>,
              },
              {
                value: 'BID_TYPE_CUSTOM',
                label: 'Bid cap',
                children: (
                  <div style={{ width: '100%' }}>
                    <br />
                    <FormInputGroup>
                      <Form.Item
                        name={['tiktokAd', 'adGroup', 'bid']}
                        required
                        label="Bid cap ($/click)"
                        rules={[
                          {
                            required: true,
                            type: 'number',
                          },
                        ]}
                      >
                        <CurrencyInput currencyCode={currencyCode}></CurrencyInput>
                      </Form.Item>
                      <Form.Item
                        name={['tiktokAd', 'adGroup', 'pacing']}
                        required
                        label="Delivery type"
                        rules={[
                          {
                            required: true,
                            type: 'string',
                          },
                        ]}
                      >
                        <Select
                          size="large"
                          placeholder="Select a delivery"
                          allowClear
                          options={tiktokDeliverySpeeds}
                        />
                      </Form.Item>
                    </FormInputGroup>
                  </div>
                ),
              },
            ]}
          />
        </Form.Item>
      </Form>
    );
  }

  function AD_FORM() {
    const form6Campaign = {};

    _.set(form6Campaign, ['tiktokAd', 'adName'], _.get(campaign, ['tiktokAd', 'adName']));
    _.set(form6Campaign, ['tiktokAd', 'creatives'], _.get(campaign, ['tiktokAd', 'creatives']));

    if (!_.get(form6Campaign, ['tiktokAd', 'creatives', 0, 'adTexts', 0])) {
      _.set(form6Campaign, ['tiktokAd', 'creatives', 0, 'adTexts'], ['']);
    }

    return (
      <Form
        initialValues={form6Campaign}
        requiredMark="optional"
        form={adForm}
        style={{ height: '100%' }}
        layout="vertical"
      >
        <Form.Item
          required
          name={['tiktokAd', 'adName']}
          label="Ad name"
          rules={[
            {
              required: true,
              type: 'string',
            },
          ]}
        >
          <Input size="large" />
        </Form.Item>
        {/*
            <Form.Item noStyle required name={["tiktokAd", "creatives", 0, "identity"]}>
                <FakeValueChangeFormItem tref={identityRef}/>
            </Form.Item>
            <Form.Item required name={["tiktokAd", "creatives", 0, "identityId"]}
                       label={"Identity"}
                       rules={[{
                           required: true
                       }]}>
                <IdentitiesSelector
                    onChangeFullObject={(i) => {
                        if (identityRef.current)
                            identityRef.current.onChange(i.label)
                    }}
                    adsProvider={adsProvider}
                    account={props.account}/>
            </Form.Item>
            */}
        <Form.Item
          required
          name={['tiktokAd', 'creatives', 0, 'videoId']}
          label="Video"
          style={{ height: '100%' }}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <VideosSelector adsProvider={adsProvider} account={props.account} />
        </Form.Item>
        <Form.Item
          required
          name={['tiktokAd', 'creatives', 0, 'imageIds', 0]}
          label="Cover"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <ImagesSelector adsProvider={adsProvider} account={props.account} />
        </Form.Item>
        <Form.List
          name={['tiktokAd', 'creatives', 0, 'adTexts']}
          label=""
          rules={[
            {
              required: true,
              type: 'array',
              min: 1,
              max: 3,
            },
          ]}
        >
          {(fields, { add, remove, move }, { errors }) => (
            <div>
              {fields.map(({ key, name, fieldKey, ...restField }) => (
                <div
                  key={`${name}_${key}`}
                  style={{
                    display: 'flex',
                    width: '100%',
                    alignItems: 'stretch',
                    gap: '1em',
                    height: '100%',
                  }}
                >
                  <Form.Item
                    required
                    style={{
                      flex: '1 1',
                      marginBottom: 5,
                    }}
                    name={[name]}
                    rules={[
                      {
                        required: true,
                        type: 'string',
                        min: 12,
                        max: 100,
                      },
                    ]}
                    label="Ad text"
                    {...restField}
                  >
                    <Input.TextArea
                      rows={1}
                      size="large"
                      placeholder="Tell people what your ad is about. "
                    ></Input.TextArea>
                  </Form.Item>
                  <MinusCircleOutlined className="dynamic-delete-button" onClick={() => remove(name)} />
                </div>
              ))}
              <AddANewButton onClick={() => add()}>+ Add a text</AddANewButton>
              <Form.ErrorList errors={errors} />
            </div>
          )}
        </Form.List>
        <Form.Item
          required
          name={['tiktokAd', 'creatives', 0, 'displayName']}
          label="Display name"
          rules={[
            {
              required: true,
              type: 'string',
              min: 2,
              max: 39,
            },
          ]}
        >
          <Input prefix="@" size="large" />
        </Form.Item>
        <Form.Item
          name={['tiktokAd', 'creatives', 0, 'callToAction']}
          required
          label=""
          rules={[
            {
              required: true,
              type: 'string',
            },
          ]}
        >
          <CTAsSelector adsProvider={adsProvider} account={props.account} />
        </Form.Item>
        <Form.Item label="" noStyle dependencies={['tiktokAd', 'creatives', 0]} name={['tiktokAd', 'creatives', 0]}>
          <TiktokAdPreview account={props.account} adsProvider={adsProvider} />
        </Form.Item>
      </Form>
    );
  }

  function ADGROUP_FORM() {
    const form4Campaign = {};

    _.set(
      form4Campaign,
      ['tiktokAd', 'adGroup', 'adgroupName'],
      _.get(campaign, ['tiktokAd', 'adGroup', 'adgroupName']),
    );
    _.set(
      form4Campaign,
      ['tiktokAd', 'adGroup', 'placementType'],
      _.get(campaign, ['tiktokAd', 'adGroup', 'placementType']),
    );
    _.set(form4Campaign, ['tiktokAd', 'adGroup', 'placement'], _.get(campaign, ['tiktokAd', 'adGroup', 'placement']));
    _.set(
      form4Campaign,
      ['tiktokAd', 'adGroup', 'isCommentDisable'],
      _.get(campaign, ['tiktokAd', 'adGroup', 'isCommentDisable']),
    );
    _.set(
      form4Campaign,
      ['tiktokAd', 'adGroup', 'videoDownload'],
      _.get(campaign, ['tiktokAd', 'adGroup', 'videoDownload']),
    );
    _.set(
      form4Campaign,
      ['tiktokAd', 'adGroup', 'inventoryFlow'],
      _.get(campaign, ['tiktokAd', 'adGroup', 'inventoryFlow']),
    );

    return (
      <Form
        initialValues={form4Campaign}
        requiredMark="optional"
        form={adGroupForm}
        onValuesChange={(values) => {
          console.log('Ad Set form', values);
        }}
        style={{ height: '100%' }}
        layout="vertical"
      >
        <div>
          <Form.Item
            required
            name={['tiktokAd', 'adGroup', 'adgroupName']}
            label="Ad group name"
            rules={[
              {
                required: true,
                type: 'string',
              },
            ]}
          >
            <Input size="large" />
          </Form.Item>
          <Form.Item
            required
            name={['tiktokAd', 'adGroup', 'placementType']}
            label="Placements"
            rules={[
              {
                required: true,
                type: 'string',
              },
            ]}
          >
            <RadioButtonTabs
              label=""
              options={[
                {
                  value: 'PLACEMENT_TYPE_AUTOMATIC',
                  label: 'Automatic',
                  children: (
                    <MutedText>Automatically show your ads across supported placements. Recommended.</MutedText>
                  ),
                },
                {
                  value: 'PLACEMENT_TYPE_NORMAL',
                  label: 'Select placement',
                  children: (placementType) => (
                    <Fragment>
                      <MutedText>Manually choose your targeting placement.</MutedText>
                      <Form.Item
                        required
                        name={['tiktokAd', 'adGroup', 'inventoryFlow']}
                        rules={[
                          {
                            required: placementType === 'PLACEMENT_TYPE_NORMAL',
                            type: 'array',
                          },
                        ]}
                        label="Placements"
                      >
                        <Select
                          mode="multiple"
                          showArrow={true}
                          size="large"
                          placeholder="Select a placement"
                          allowClear
                          options={tiktokPlacements}
                        />
                      </Form.Item>
                    </Fragment>
                  ),
                },
              ]}
            />
          </Form.Item>
          <Form.Item
            tooltip={
              <div>
                We recommend keeping user comments on to help your ads achieve more impressions and conversions.
                <br /> You can hide, pin, and reply to comments using our comment management tools.
                <a href="https://ads.tiktok.com/help/article?aid=10001612">How to manage comments</a>
              </div>
            }
            name={['tiktokAd', 'adGroup', 'isCommentDisable']}
            label="User comment"
          >
            <Select size="large">
              <Select.Option value={0}>Yes</Select.Option>
              <Select.Option value={1}>No</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            tooltip={
              <div>
                1. Users based in Japan won't be able to download your video ads.
                <br />
                2. Users can only download Spark Ad posts if this setting has been turned on in both Ads Manager and
                Tiktok.
              </div>
            }
            name={['tiktokAd', 'adGroup', 'videoDownload']}
            label="Video download"
          >
            <Select size="large">
              <Select.Option value="ALLOW_DOWNLOAD">Can download your video</Select.Option>
              <Select.Option value="PREVENT_DOWNLOAD">Can't download your video</Select.Option>
            </Select>
          </Form.Item>
        </div>
      </Form>
    );
  }

  function AUDIENCE_FORM() {
    const form5Campaign = {};

    _.set(
      form5Campaign,
      ['tiktokAd', 'adGroup', 'campaignId'],
      _.get(campaign, ['tiktokAd', 'campaign', 'campaignId']),
    );
    _.set(form5Campaign, ['tiktokAd', 'adGroup', 'audience'], _.get(campaign, ['tiktokAd', 'adGroup', 'audience']));
    _.set(
      form5Campaign,
      ['tiktokAd', 'adGroup', 'excludedAudience'],
      _.get(campaign, ['tiktokAd', 'adGroup', 'excludedAudience']),
    );
    _.set(form5Campaign, ['tiktokAd', 'adGroup', 'location'], _.get(campaign, ['tiktokAd', 'adGroup', 'location']));
    _.set(
      form5Campaign,
      ['tiktokAd', 'adGroup', 'gender'],
      _.get(campaign, ['tiktokAd', 'adGroup', 'gender'], 'GENDER_UNLIMITED'),
    );
    _.set(form5Campaign, ['tiktokAd', 'adGroup', 'age'], _.get(campaign, ['tiktokAd', 'adGroup', 'age']));
    _.set(form5Campaign, ['tiktokAd', 'adGroup', 'languages'], _.get(campaign, ['tiktokAd', 'adGroup', 'languages']));
    _.set(form5Campaign, ['tiktokAd', 'adGroup', 'isHfss'], _.get(campaign, ['tiktokAd', 'adGroup', 'isHfss']));
    _.set(
      form5Campaign,
      ['tiktokAd', 'adGroup', 'interestKeywords'],
      _.get(campaign, ['tiktokAd', 'adGroup', 'interestKeywords']),
    );
    _.set(
      form5Campaign,
      ['tiktokAd', 'adGroup', 'interestCategoryV2'],
      _.get(campaign, ['tiktokAd', 'adGroup', 'interestCategoryV2']),
    );
    _.set(form5Campaign, ['tiktokAd', 'adGroup', 'actionV2'], _.get(campaign, ['tiktokAd', 'adGroup', 'actionV2']));
    _.set(form5Campaign, ['tiktokAd', 'adGroup', 'androidOsv'], _.get(campaign, ['tiktokAd', 'adGroup', 'androidOsv']));
    _.set(form5Campaign, ['tiktokAd', 'adGroup', 'iosOsv'], _.get(campaign, ['tiktokAd', 'adGroup', 'iosOsv']));
    _.set(
      form5Campaign,
      ['tiktokAd', 'adGroup', 'deviceModels'],
      _.get(campaign, ['tiktokAd', 'adGroup', 'deviceModels']),
    );
    _.set(form5Campaign, ['tiktokAd', 'adGroup', 'campaignId'], _.get(campaign, ['tiktokAd', 'adGroup', 'campaignId']));

    const currencyCode = getCurrencyCode(props.account.productProviders);

    return (
      <Form
        initialValues={form5Campaign}
        requiredMark="optional"
        form={audienceForm}
        style={{ height: '100%', width: 600 }}
        layout="vertical"
      >
        <Form.Item
          name={['tiktokAd', 'adGroup', 'location']}
          label="Location"
          required
          rules={[{ required: true, type: 'array' }]}
        >
          <GeoTargetsSelector account={props.account} adsProvider={adsProvider} />
        </Form.Item>
        <Form.Item name={['tiktokAd', 'adGroup', 'gender']} label="Gender" required rules={[{ required: true }]}>
          <Radio.Group>
            <Radio.Button value="GENDER_UNLIMITED">All</Radio.Button>
            <Radio.Button value="GENDER_MALE">Men</Radio.Button>
            <Radio.Button value="GENDER_FEMALE">Women</Radio.Button>
          </Radio.Group>
        </Form.Item>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '2em',
            width: '100%',
          }}
        >
          <Collapse style={{ flex: '1 0 600px' }} defaultActiveKey={[]} destroyInactivePanel={false}>
            <Collapse.Panel
              key="Audience"
              header={
                <div>
                  <b>Audience</b>
                </div>
              }
            >
              <Form.Item
                name={['tiktokAd', 'adGroup', 'audience']}
                label="Audience - Include"
                rules={[{ required: false, type: 'array' }]}
              >
                <AudiencesSelector account={props.account} adsProvider={adsProvider} />
              </Form.Item>
              <Form.Item
                name={['tiktokAd', 'adGroup', 'excludedAudience']}
                label="Audience - Exclude"
                rules={[{ required: false, type: 'array' }]}
              >
                <AudiencesSelector account={props.account} adsProvider={adsProvider} />
              </Form.Item>
            </Collapse.Panel>
            <Collapse.Panel
              key="Demographics"
              header={
                <div>
                  <b>Demographics</b>
                </div>
              }
            >
              <Form.Item name={['tiktokAd', 'adGroup', 'age']} label="Age" rules={[{ required: false, type: 'array' }]}>
                <Select mode="multiple" size="large" placeholder="Select age" allowClear options={ages} />
              </Form.Item>
              <Form.Item
                name={['tiktokAd', 'adGroup', 'languages']}
                label="Languages"
                rules={[{ required: false, type: 'array' }]}
              >
                <LanguagesSelector adsProvider={adsProvider} account={props.account} />
              </Form.Item>
              <Form.Item
                name={['tiktokAd', 'adGroup', 'isHfss']}
                label="HFSS Product/Brand Check box if promoting or prominently featuring product/brand classed as high in fat, salt or sugar."
                rules={[{ required: false }]}
                valuePropName="checked"
              >
                <Checkbox />
              </Form.Item>
            </Collapse.Panel>
            <Collapse.Panel
              key="Interests"
              header={
                <div>
                  <b>Interests (optional)</b>
                  <InlineMutedText> - Include people with any of the following interests</InlineMutedText>
                </div>
              }
            >
              <Form.Item
                name={['tiktokAd', 'adGroup', 'interestKeywordsTranslations']}
                label=""
                noStyle
                rules={[{ required: false, type: 'array' }]}
              >
                <FakeValueChangeFormItem tref={interestKeywordsTranslations} />
              </Form.Item>
              <Form.Item
                name={['tiktokAd', 'adGroup', 'interestKeywords']}
                label=""
                rules={[{ required: false, type: 'array' }]}
              >
                <InterestKeywordsSelector
                  account={props.account}
                  initialTranslate={_.get(campaign, ['tiktokAd', 'adGroup', 'interestKeywordsTranslations'])}
                  onChangeTranslate={(v) => interestKeywordsTranslations.current.onChange(v)}
                  adsProvider={adsProvider}
                />
              </Form.Item>
              <Form.Item
                name={['tiktokAd', 'adGroup', 'interestCategoryV2']}
                label=""
                rules={[{ required: false, type: 'array' }]}
              >
                <InterestCategoriesSelector account={props.account} adsProvider={adsProvider} />
              </Form.Item>
            </Collapse.Panel>
            <Collapse.Panel
              key="BehaviorV"
              header={
                <div>
                  <b>Behavior (optional)</b> <InlineMutedText> - Videos interactions</InlineMutedText>
                </div>
              }
            >
              <MutedText>People who’ve carried out the following interactions with videos</MutedText>
              <Form.Item
                name={['tiktokAd', 'adGroup', 'actionV2', 0, 'actionScene']}
                label="How they’ve interacted with the video"
                hidden
                required
                rules={[{ required: false, type: 'string' }]}
              >
                <FixedValueFormItem fixedValue="VIDEO_RELATED" />
              </Form.Item>
              <Form.Item
                name={['tiktokAd', 'adGroup', 'actionV2', 0, 'userActions']}
                label="How they’ve interacted with the video"
                dependencies={['tiktokAd', 'adGroup', 'actionV2', 0, 'actionCategories']}
                required
                rules={[
                  (form: FormInstance) => {
                    const filed = form.getFieldValue(['tiktokAd', 'adGroup', 'actionV2', 0, 'actionCategories']);

                    return { required: filed && filed.length > 0 };
                  },
                ]}
              >
                <Select
                  mode="multiple"
                  size="large"
                  placeholder="Select type of interaction"
                  allowClear
                  required
                  options={videosInteractionTypes}
                />
              </Form.Item>

              <Form.Item
                name={['tiktokAd', 'adGroup', 'actionV2', 0, 'actionCategories']}
                label="Define what kind of videos they’ve interacted with"
                required
                rules={[{ required: false, type: 'array' }]}
              >
                <ActionCategoriesSelector
                  account={props.account}
                  actionScene="VIDEO_RELATED"
                  adsProvider={adsProvider}
                />
              </Form.Item>
              <Form.Item
                name={['tiktokAd', 'adGroup', 'actionV2', 0, 'actionPeriod']}
                initialValue={7}
                dependencies={['tiktokAd', 'adGroup', 'actionV2', 0, 'actionCategories']}
                label="Select a time period to include actions from"
                rules={[
                  (form: FormInstance) => {
                    const filed = form.getFieldValue(['tiktokAd', 'adGroup', 'actionV2', 0, 'actionCategories']);

                    return { required: filed && filed.length > 0 };
                  },
                ]}
              >
                <Select placeholder="Select time period" allowClear options={actionDays} />
              </Form.Item>
            </Collapse.Panel>

            <Collapse.Panel
              key="BehaviorC"
              header={
                <div>
                  <b>Behavior (optional)</b> <InlineMutedText> - Creator interactions</InlineMutedText>
                </div>
              }
            >
              <MutedText>Target users based on how they’ve interacted with creators on Tiktok.</MutedText>
              <Form.Item
                name={['tiktokAd', 'adGroup', 'actionV2', 1, 'actionScene']}
                label=""
                hidden
                required
                rules={[{ required: false, type: 'string' }]}
              >
                <FixedValueFormItem fixedValue="CREATOR_RELATED" />
              </Form.Item>
              <Form.Item
                name={['tiktokAd', 'adGroup', 'actionV2', 1, 'userActions']}
                label="Define how they’ve interacted with creators"
                required
                rules={[
                  (form: FormInstance) => {
                    const filed = form.getFieldValue(['tiktokAd', 'adGroup', 'actionV2', 1, 'actionCategories']);

                    return { required: filed && filed.length > 0 };
                  },
                ]}
              >
                <Select
                  mode="multiple"
                  size="large"
                  placeholder="Select type of interaction"
                  allowClear
                  options={creatorsInteractionTypes}
                />
              </Form.Item>
              <Form.Item
                name={['tiktokAd', 'adGroup', 'actionV2', 1, 'actionCategories']}
                label="Define what kind of creators they’ve interacted with"
                required
                rules={[{ required: false, type: 'array' }]}
              >
                <ActionCategoriesSelector
                  account={props.account}
                  actionScene="CREATOR_RELATED"
                  adsProvider={adsProvider}
                />
              </Form.Item>
              <Form.Item
                name={['tiktokAd', 'adGroup', 'actionV2', 1, 'actionPeriod']}
                initialValue={7}
                dependencies={['tiktokAd', 'adGroup', 'actionV2', 1, 'actionCategories']}
                label="Select a time period to include actions from"
                rules={[
                  (form: FormInstance) => {
                    const filed = form.getFieldValue(['tiktokAd', 'adGroup', 'actionV2', 1, 'actionCategories']);

                    return { required: filed && filed.length > 0 };
                  },
                ]}
              >
                <Select placeholder="Select time period" allowClear options={actionDays} />
              </Form.Item>
            </Collapse.Panel>

            <Collapse.Panel
              key="Device"
              header={
                <div>
                  <b>Device</b>
                </div>
              }
            >
              <Form.Item name={['tiktokAd', 'adGroup', 'androidOsv']} label="Android" rules={[{ required: false }]}>
                <OSSelector account={props.account} adsProvider={adsProvider} type="ANDROID" />
              </Form.Item>
              <Form.Item name={['tiktokAd', 'adGroup', 'iosOsv']} label="IOS" rules={[{ required: false }]}>
                <OSSelector account={props.account} adsProvider={adsProvider} type="IOS" />
              </Form.Item>
              <Form.Item
                name={['tiktokAd', 'adGroup', 'deviceModels']}
                label="Device Model"
                rules={[{ required: false, type: 'array' }]}
              >
                <DevicesSelector account={props.account} adsProvider={adsProvider} />
              </Form.Item>

              <Form.Item
                name={['tiktokAd', 'adGroup', 'connectionType']}
                label="Connection type"
                rules={[{ required: false, type: 'array' }]}
              >
                <Select
                  mode="multiple"
                  size="large"
                  placeholder="Select type of connection"
                  allowClear
                  options={connections}
                />
              </Form.Item>
            </Collapse.Panel>
          </Collapse>
          <Form.Item
            style={{
              width: 302,
              display: 'flex',
              alignItems: 'center',
            }}
            dependencies={['tiktokAd', 'adGroup', 'location']}
            name={['tiktokAd', 'adGroup']}
            label="Audience Size"
            required={true}
          >
            <TiktokEstimated adsProvider={adsProvider} account={props.account} />
          </Form.Item>
        </div>
      </Form>
    );
  }

  function CAMPAIGN_SETTINGS_FORM() {
    const form2Campaign = {};

    _.set(
      form2Campaign,
      ['tiktokAd', 'campaign', 'campaignName'],
      _.get(campaign, ['tiktokAd', 'campaign', 'campaignName']),
    );
    _.set(form2Campaign, ['domain'], _.get(campaign, ['domain']));
    _.set(form2Campaign, ['pixelsIds'], _.get(campaign, ['pixelsIds']));
    _.set(
      form2Campaign,
      ['tiktokAd', 'campaign', 'budgetMode'],
      _.get(campaign, ['tiktokAd', 'campaign', 'budgetMode']),
    );
    _.set(form2Campaign, ['tiktokAd', 'campaign', 'budget'], _.get(campaign, ['tiktokAd', 'campaign', 'budget']));

    return (
      <Form
        initialValues={form2Campaign}
        requiredMark="optional"
        form={campaignForm}
        onValuesChange={(v) => {
          if (_.get(v, ['tiktokAd', 'campaign', 'campaignName'])) {
            const c = { ...campaign };

            _.set(c, ['tiktokAd', 'adName'], v.tiktokAd.campaign.campaignName);
            _.set(c, ['tiktokAd', 'adGroup', 'adgroupName'], v.tiktokAd.campaign.campaignName);
            adForm.setFieldsValue(c);
            adGroupForm.setFieldsValue(c);
          }
        }}
        style={{ height: '100%' }}
        layout="vertical"
      >
        <Form.Item
          required
          name={['tiktokAd', 'campaign', 'campaignName']}
          label="Campaign Name"
          rules={[{ required: true, type: 'string' }]}
        >
          <Input size="large" placeholder="[Batterie acquisition] - Summer 2021"></Input>
        </Form.Item>
        <PixelSelector account={props.account}></PixelSelector>
        <AddPixelButton accountId={props.account.id} />
        <DomainSelector account={props.account} />
        <AddDomainButton accountId={props.account.id} />
        <DeeplinkToggle account={props.account} />
        <Form.Item
          name={['tiktokAd', 'campaign', 'budgetMode']}
          required
          label="Budget Type"
          rules={[
            {
              required: true,
              type: 'string',
            },
          ]}
        >
          <RadioButtonTabs
            label=""
            options={[
              {
                value: 'BUDGET_MODE_DAY',
                label: 'Daily Budget',
                children: (budgetMode) => (
                  <Fragment>
                    <br />
                    {budgetMode === 'BUDGET_MODE_DAY' && (
                      <Form.Item
                        required
                        dependencies={['tiktokAd', 'campaign', 'budgetMode']}
                        name={['tiktokAd', 'campaign', 'budget']}
                        label="Budget"
                        rules={[
                          {
                            required: true,
                            type: 'number',
                            min: 5,
                          },
                        ]}
                      >
                        <CurrencyInput currencyCode={currencyCode} unit={1}></CurrencyInput>
                      </Form.Item>
                    )}
                  </Fragment>
                ),
              },
              {
                value: 'BUDGET_MODE_TOTAL',
                label: 'Lifetime Budget',
                children: (budgetMode) => (
                  <Fragment>
                    <br />
                    {budgetMode === 'BUDGET_MODE_TOTAL' && (
                      <Form.Item
                        required
                        dependencies={['tiktokAd', 'campaign', 'budgetMode']}
                        name={['tiktokAd', 'campaign', 'budget']}
                        label="Budget"
                        rules={[
                          {
                            required: true,
                            type: 'number',
                            min: 50,
                          },
                        ]}
                      >
                        <CurrencyInput currencyCode={currencyCode} unit={1}></CurrencyInput>
                      </Form.Item>
                    )}
                  </Fragment>
                ),
              },
            ]}
          />
        </Form.Item>
      </Form>
    );
  }

  return (
    <Style>
      {campaign && (
        <Fragment>
          <Wizard
            steps={wizardSteps}
            width="389px"
            tRef={wizardRef}
            okText="Launch your ad"
            previousText="Back"
            nextText="Next"
            currentStep={0}
            loading={loading}
            onComplete={async (newValues) => await saveCampaign(newValues)}
            stepsTitle="Campaign resume"
          />
        </Fragment>
      )}
      <div style={{ maxWidth: 400 }}>
        <PrettyPrintJson hide={true} data={campaign} />
      </div>
    </Style>
  );
}
