import { Tooltip } from 'antd';
import styled from 'styled-components';

import { ReactComponent as ExternalLinkIcon } from 'icons/external-link.svg';
import { strings } from 'locales/strings';

import { COMPETITORS_LIMIT, GridCell } from './constants';
import { ProductImage } from './ProductImage';

const Style = styled.div`
  display: grid;
  row-gap: 0.25rem;
`;

const CompetitorCard = styled.a`
  background-color: white;
  color: var(--text-color);
  border-radius: 2px;
  box-shadow: 0px -1px 0px 0px #f2f2f2 inset;
  padding: 1rem 0.75rem;
  gap: 0.25rem 0.75rem;

  :hover {
    color: var(--text-color);
    text-decoration: none;
  }

  display: grid;
  grid-template-columns: max-content 1fr max-content;
  grid-template-areas:
    'image asin link'
    'image title link';
`;

const AlignImage = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

const AsinLabel = styled.div`
  > span:first-child {
    color: #595959;
  }
  > span:nth-child(2) {
    font-weight: 500;
  }
`;

export const CompetitorList = ({ competitors = [], limit = COMPETITORS_LIMIT }) => {
  const filteredCompetitors = limit ? competitors.slice(0, limit) : competitors;

  return (
    <Style>
      {filteredCompetitors.map((item) => (
        <CompetitorCard key={item.asin} href={item.url} target="_blank" rel="noreferrer">
          <GridCell $name="image">
            <ProductImage
              src={item.imageUrl}
              alt={item.title}
              width={40}
              height={40}
              onClick={(e) => e.preventDefault()}
            />
          </GridCell>
          <GridCell $name="asin">
            <AsinLabel>
              <span>{strings.get('asinAudit.asin.label')}: </span>
              <span>{item.asin}</span>
            </AsinLabel>
          </GridCell>
          <GridCell $name="title" className="ellipsis">
            <Tooltip title={item.title} placement="bottom">
              {item.title}
            </Tooltip>
          </GridCell>
          <GridCell $name="link">
            <AlignImage>
              <ExternalLinkIcon width={15} height={15} />
            </AlignImage>
          </GridCell>
        </CompetitorCard>
      ))}
    </Style>
  );
};
