import React, { useEffect } from 'react';

import { navigate } from 'hookrouter';
import _ from 'lodash';
import styled from 'styled-components';
import { useImmer } from 'use-immer';

import axios from 'lib/axios.factory';

// @ts-ignore
import { EasySteps } from './components/EasySteps';
import { EasyContainer } from './EsayContainer';
import { LinkPixelForm } from './LinkPixelForm';
import { hasExpired } from './utils/links.utils';
import { getRedirectsByIds } from '../../../lib/api';

const Style = styled.div`
  .ant-form-item {
    flex: 1;
  }
`;

export function LinkPixel({ account, redirectId }) {
  const [state, updateState] = useImmer({ redirect: {} });

  useEffect(() => {
    async function loadRedirect(redirectId) {
      const { redirects } = await getRedirectsByIds(localStorage, axios, {
        accountId: account.id,
        redirectsIds: [redirectId],
      });

      if (hasExpired(_.get(redirects, '0'))) {
        navigate(`/easy/link-expired/${redirects[0].id}`);
      }

      updateState((draft) => {
        draft.redirect = redirects[0];
        draft.pixel = redirects[0].pixels[0];
        draft.providers = account.providers.map(({ id, name }) => ({
          id,
          value: name,
        }));
      });
    }

    if (redirectId) {
      loadRedirect(redirectId).then();
    }
  }, [redirectId]);

  return (
    <EasyContainer
      title={
        <>
          Increase your sales by adding
          <br /> retargeting pixels into ALL your links.
        </>
      }
      description={
        <>
          PixelMe is an <b>URL shortener</b> that embed retargeting pixels from Facebook, Adwords, Linkedin and more
          into shortened links. Brand, track & share your own <b>branded short URLs</b> and retarget anyone who clicked
          on your links!
        </>
      }
    >
      <Style>
        <EasySteps current={3} />
        <LinkPixelForm state={state} updateState={updateState} account={account} />
      </Style>
    </EasyContainer>
  );
}
