import React, { useEffect, useState } from 'react';

import { notification } from 'antd';
import _, { debounce } from 'lodash';
import styled from 'styled-components';

import axios from 'lib/axios.factory';
import { getTiktokEstimated } from 'lib/tiktokPlateformApi';
import { transformBigNumberToHumanReadable } from 'lib/util';

import { BoxShadow } from '../../../BoxShadow';
import { SmallCompactPlaceholder } from '../../../common/Placeholders';

const Title = styled.span`
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 1em;
  color: #595959;
`;

const Paragraph = styled.div`
  text-align: center;
  margin-top: 2px;
  margin-bottom: 6px;
  width: 100%;
`;

const Value = styled.div`
  font-weight: normal;
  font-size: 14px;
  color: #595959 !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

/**
 * Faebook Estimated reach
 * @returns {JSX.Element}
 * @constructor
 * @param props
 */
export function TiktokEstimated(props: { adsProvider: adsProviderT, value: tiktokAdGroupT }) {
  const Style = styled.div`
    width: 274px;
  `;
  const [loading, setLoading] = useState(false);
  const [estimated, setEstimated] = useState({});

  async function load() {
    setLoading(true);

    try {
      const data = await getTiktokEstimated(localStorage, axios, props.adsProvider, props.value);

      setEstimated({
        low: _.get(data, ['userCount', '1']),
        high: _.get(data, ['userCount', 2]),
      });
      setLoading(false);
    } catch (e) {
      let errorMessage = _.get(e, 'errorMessage') || '';

      if (errorMessage.includes('action_v2.0.user_actions')) {
        errorMessage = 'Missing how users interacted for Video  related behaviors';
      }

      if (errorMessage.includes('action_v2.0.action_period')) {
        errorMessage = 'Missing day period for Video related behaviors';
      }

      if (errorMessage.includes('action_v2.0.action_categories')) {
        errorMessage = 'Missing what kind of videos they’ve interacted with';
      }

      if (errorMessage.includes('action_v2.1.user_actions')) {
        errorMessage = 'Missing how users interacted for Creator related behaviors';
      }

      if (errorMessage.includes('action_v2.1.action_period')) {
        errorMessage = 'Missing day period for Creator related behaviors';
      }

      if (errorMessage.includes('action_v2.1.action_categories')) {
        errorMessage = 'Missing what kind of Creator they’ve interacted with';
      }

      if (errorMessage) {
        notification.error({
          duration: 20,
          message: errorMessage,
        });
      }

      setLoading(false);

      throw e;
    }
  }

  const debouncedLoad = debounce(load, 500);

  useEffect(() => {
    if (props.value && !_.isEmpty(props.value) && props.value.location && props.value.location.length > 0) {
      debouncedLoad();
    }
  }, [JSON.stringify(props.value || {})]);

  return (
    <Style>
      {loading && <SmallCompactPlaceholder />}
      {!loading && estimated && estimated.low && (
        <BoxShadow style={{ margin: '3px' }}>
          <Paragraph>
            <Title>Estimated reach: </Title>
            <Value>
              {transformBigNumberToHumanReadable(estimated.low)} - {transformBigNumberToHumanReadable(estimated.high)}
            </Value>
          </Paragraph>
        </BoxShadow>
      )}
    </Style>
  );
}
