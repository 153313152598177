import './TeamMembers.css';

import React, { Component, Fragment } from 'react';

import { connect } from 'react-redux';

import { addMember, deleteMember, getTeam, resendInvitation } from 'lib/api';
import axios from 'lib/axios.factory';
import { User } from 'lib/user';
import { validateEmail } from 'lib/util';
import { strings } from 'locales/strings';

import { Owner } from './Owner';
import { TeamMembersList } from './TeamMembersList';
import { updateAccountsUsageThunk } from '../../../../stores/accounts';
import Box from '../../../common/Box';
import Button, { LockedButton } from '../../../common/Button';
import Input from '../../../common/Input';
import Modal from '../../../common/Modal';
import { Line } from '../../../common/Placeholders';
import { TeamMembersCTATooltip } from '../../../common/tooltip';

class TeamMembers extends Component<
  {
    account: accountT,
    user?: userT,
    limitations: { teamMembers: number },
    usage: { teamMembers: number },
    updateAccountsUsageThunk: () => void,
  },
  {
    callingAdd: boolean,
    callingResend: boolean,
    loading: boolean,
    displayModal: boolean,
    displayDeleteModal: boolean,
    members: Array<memberT>,
    resendMember?: userT,
    memberToDelete?: userT,
  },
> {
  constructor(props: {
    account: accountT,
    user?: userT,
    limitations: { teamMembers: number },
    usage: { teamMembers: number },
    updateAccountsUsageThunk: () => void,
  }) {
    super(props);
    this.state = {
      members: [],
      loading: true,
      callingAdd: false,
      callingResend: false,
      callingDelete: false,
      displayModal: false,
      displayDeleteModal: false,
    };
  }

  render() {
    const { user, account } = this.props;
    const { loading, members, callingAdd } = this.state;

    if (loading || !user) {
      return (
        <Box className="team-members" header={<Line />}>
          <Fragment>
            <Line />
            <Line />
          </Fragment>
        </Box>
      );
    }

    const owner = members.filter((m) => m.isOwner)[0];

    return User.isAccountOwner(user, account)
      ? this.renderTeamMembersAsOwner(owner, user, callingAdd, account, members)
      : this.renderTeamMembersAsMember(owner, user);
  }

  renderTeamMembersAsMember(owner, user) {
    return <div className="team-members">{owner && <Owner user={user} owner={owner} />}</div>;
  }

  renderTeamMembersAsOwner(owner, user, callingAdd, account, members) {
    return (
      <div className="team-members">
        {owner && (
          <Fragment>
            <Owner user={user} owner={owner} />
            <AddMemberForm
              calling={callingAdd}
              handleAddMember={this.handleAddMember}
              limitations={this.props.limitations}
              usage={this.props.usage}
            />
          </Fragment>
        )}
        <TeamMembersList
          account={account}
          user={user}
          owner={owner}
          members={members}
          allowToDelete={true}
          handleDelete={(member) => {
            this.setState({ displayDeleteModal: true, memberToDelete: member });
          }}
          handleResend={(member) => {
            this.setState({ displayModal: true, resendMember: member });
          }}
        />
        {this.displayModal()}
        {this.displayDeleteModal()}
      </div>
    );
  }

  componentDidMount() {
    this.loadMembers();
  }

  loadMembers = (loading = true) => {
    const { account } = this.props;

    this.setState({ loading });
    getTeam(localStorage, axios, { accountId: account.id }).then(({ members }) => {
      this.setState({ members, loading: false });
    });
  };

  handleInvitation = () => {
    const { account } = this.props;
    const { resendMember } = this.state;

    this.setState({ callingResend: true });

    const payload = {
      accountId: account.id,
    };

    if (resendMember.accepted) {
      payload.userId = resendMember.id;
    } else {
      payload.invitationId = resendMember.id;
    }

    resendInvitation(localStorage, axios, payload).then(({ message }) => {
      this.setState({ displayModal: false, callingResend: false });
    });
  };

  handleAddMember = async ({ email, fullname }) => {
    const { account } = this.props;
    const self = this;

    this.setState({ ...this.state, callingAdd: true });

    try {
      await addMember(
        localStorage,
        axios,
        {
          accountId: account.id,
        },
        { email, fullname },
      );
      this.setState({ ...this.state, callingAdd: false });
      this.loadMembers(false);
      this.props.updateAccountsUsageThunk();
    } catch (error) {
      self.setState({ ...this.state, callingAdd: false });

      throw error;
    }
  };

  handleDeleteMember = async ({ memberToDelete }) => {
    const { account } = this.props;
    const self = this;

    this.setState({ ...this.state, callingDelete: true });

    const payload = {};

    if (memberToDelete.accepted) {
      payload.memberId = memberToDelete.id;
    } else {
      payload.invitationId = memberToDelete.id;
    }

    try {
      await deleteMember(
        localStorage,
        axios,
        {
          accountId: account.id,
        },
        payload,
      );
      this.setState({
        ...this.state,
        callingDelete: false,
        displayDeleteModal: false,
      });
      this.loadMembers(false);
      this.props.updateAccountsUsageThunk();
    } catch (error) {
      self.setState({
        ...this.state,
        callingDelete: false,
        displayDeleteModal: false,
      });

      throw error;
    }
  };

  displayDeleteModal = () => {
    const { account } = this.props;
    const { displayDeleteModal, memberToDelete, callingDelete } = this.state;

    if (displayDeleteModal) {
      const message = "Yes I'm sure";
      const deleteConfirmation = strings.get('members.delete', {
        email: `<span class="text-pixelme"> ${memberToDelete.email}</span>`,
        orgName: account.name,
      });

      return (
        <Modal
          className="team-members__modal__delete"
          okMessage={message}
          disabled={callingDelete}
          handleCancel={() => this.setState({ displayDeleteModal: false })}
          handleOk={() => this.handleDeleteMember({ memberToDelete })}
        >
          <p
            dangerouslySetInnerHTML={{
              __html: deleteConfirmation,
            }}
          />
        </Modal>
      );
    }

    return null;
  };

  displayModal = () => {
    const { displayModal, resendMember, callingResend } = this.state;

    if (displayModal && resendMember) {
      const message = callingResend ? 'Sending...' : 'Please resend :-)';

      return (
        <Modal
          disabled={callingResend}
          okMessage={message}
          handleCancel={() => this.setState({ displayModal: false })}
          handleOk={() => this.handleInvitation()}
        >
          Resend invitation to {resendMember.email}?
        </Modal>
      );
    }

    return null;
  };
}

class AddMemberForm extends Component<
  {
    calling: boolean,
    limitations: { teamMembers: number },
    usage: { teamMembers: number },
    updateAccountsUsageThunk: () => void,
    handleAddMember: ({ fullname: string, email: string }) => void,
  },
  { fullname: string, email: string },
> {
  constructor(props) {
    super(props);
    this.state = {
      fullname: '',
      email: '',
    };
  }

  render() {
    const { fullname, email } = this.state;

    return (
      <Box className="team-members__add" header="Add a new team member">
        <form id="new-team-member" onSubmit={this.handleSubmit}>
          <Input value={fullname} id="fullname" placeholder="Name" onChange={this.handleChange} />
          <Input value={email} id="email" placeholder="Email" onChange={this.handleChange} />
          {this.renderButton()}
        </form>
      </Box>
    );
  }

  handleChange = (event: SyntheticEvent<HTMLInputElement>) => {
    const target = (event.currentTarget: HTMLInputElement);
    const { value } = target;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  handleSubmit = async (event: SyntheticEvent<HTMLInputElement>) => {
    event.preventDefault();

    if (this.validateFields()) {
      const { email, fullname } = this.state;

      try {
        await this.props.handleAddMember({ email, fullname });
        this.setState({ email: '', fullname: '' });
        this.props.updateAccountsUsageThunk();
      } catch (error) {
        console.debug('Handle Submit error', error);
      }
    }
  };

  validateFields = () => {
    const { email, fullname } = this.state;

    return validateEmail(email) && fullname.length > 1;
  };

  renderButton = () => {
    const { limitations, usage } = this.props;

    if (usage && limitations.teamMembers <= usage.teamMembers) {
      return (
        <TeamMembersCTATooltip>
          <LockedButton>Add a team member</LockedButton>
        </TeamMembersCTATooltip>
      );
    }

    if (this.validateFields()) {
      return (
        <Button loading={this.props.calling} loadingLabel="Adding...">
          Add a team member
        </Button>
      );
    }

    return <Button disabled>Add a team member</Button>;
  };
}

const mapStateToProps = function (state): { user: userT } {
  const { limitations, usage } = state.accounts;

  return {
    limitations,
    usage,
    user: state.user.user,
  };
};

export default connect(mapStateToProps, { updateAccountsUsageThunk })(TeamMembers);
