import React, { Fragment, useState } from 'react';

import { ExclamationCircleOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { Button, Form, Input, message, Modal, Select, Tooltip } from 'antd';
import { useForm } from 'antd/lib/form/Form';

import axios from 'lib/axios.factory';

import { useLoading } from '../../../../hooks/useLoading';
import { addVariation } from '../../../../lib/adsPlateformApi';
import { AddANewButton } from '../common/AddANewButton';
import { authorizedTextInKeywords } from '../constants';

export function NewKeywordButton({ account, campaignId, adGroupId, style, onClose }) {
  const [visible, setVisible] = useState(false);
  const { doAction, loading } = useLoading(false);
  const [form] = useForm();

  async function addKeywords(values) {
    doAction(async () => {
      try {
        await addVariation(localStorage, axios, { accountId: account.id }, campaignId, adGroupId, values.keywords);
        setVisible(false);
        form.resetFields();
        Modal.success({
          title: 'Keywords added',
          content: (
            <>
              <br />
              <h3>Processing...</h3> <br />
              You will see it tomorrow.
            </>
          ),
        });

        if (onClose) {
          await onClose();
        }
      } catch (e) {
        message.error('Unable to create keywords');
      }
    });
  }

  return (
    <Fragment>
      <Modal
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={null}
        loading={loading}
        width="880px"
        title="New keyword"
        icon={<ExclamationCircleOutlined />}
      >
        <Form form={form} onFinish={addKeywords} initialValues={{ keywords: [{ text: '', matchType: 'PHRASE' }] }}>
          <Form.Item name={['keywords']} rules={[{ required: true, type: 'array', min: 1, max: 100 }]}>
            <Form.List name={['keywords']} label="" rules={[{ required: true, type: 'array', min: 1, max: 100 }]}>
              {(fields, { add, remove, move }) => (
                <div>
                  {fields.map(({ key, name, fieldKey, ...restField }) => (
                    <div
                      key={`${name}_${key}`}
                      style={{
                        display: 'flex',
                        width: '100%',
                        alignItems: 'center',
                        gap: '1em',
                      }}
                    >
                      <Form.Item
                        required
                        style={{ flex: '1 0 200px', marginBottom: 5 }}
                        name={[name, 'text']}
                        rules={[
                          {
                            required: true,
                            pattern: authorizedTextInKeywords,
                            min: 1,
                            max: 100,
                            message: 'Invalid Text',
                          },
                        ]}
                        label="Keyword"
                        {...restField}
                      >
                        <Input size="large"></Input>
                      </Form.Item>
                      <Form.Item
                        required
                        style={{ flex: '0 0 360px', marginBottom: 5 }}
                        label={
                          <span>
                            Match Type{' '}
                            <a
                              target="_blank"
                              href="https://support.google.com/google-ads/answer/7478529?hl=en&ref_topic=3119131#zippy=%2Cbroad-match%2Cphrase-match%2Cexact-match"
                              rel="noreferrer"
                            >
                              Learn More
                            </a>
                          </span>
                        }
                        name={[name, 'matchType']}
                        rules={[{ required: true }]}
                        {...restField}
                      >
                        <Select>
                          <Select.Option value="PHRASE">
                            <Tooltip
                              placement="left"
                              title="Ads may show on searches that include the meaning of your keyword. The meaning of the keyword can be implied, and user searches can be a more specific form of the meaning. With phrase match, you can reach more searches than with exact match and fewer searches than with broad match, only showing your ads on the searches that include your product or service."
                            >
                              Phrase Match
                            </Tooltip>
                          </Select.Option>
                          <Select.Option value="BROAD">
                            <Tooltip
                              placement="left"
                              title="Ads may show on searches that are related to your keyword, which can include searches that don’t contain the keyword terms. This helps you attract more visitors to your website, spend less time building keyword lists, and focus your spending on keywords that work. Broad match is the default match type that all your keywords are assigned, so that you don't have to specify another match type (exact match, phrase match, or a negative match type)."
                            >
                              Broad Match
                            </Tooltip>
                          </Select.Option>
                          <Select.Option value="EXACT">
                            <Tooltip
                              placement="left"
                              title="Ads may show on searches that have the same meaning or same intent as the keyword. Of the 3 keyword matching options, exact match gives you the most control over who sees your ad, but reaches fewer searches than both phrase and broad match."
                            >
                              Exact Match
                            </Tooltip>
                          </Select.Option>
                        </Select>
                      </Form.Item>

                      <MinusCircleOutlined className="dynamic-delete-button" onClick={() => remove(name)} />
                    </div>
                  ))}
                  <AddANewButton onClick={() => add({ matchType: 'PHRASE' })}>+ Add a new keyword</AddANewButton>
                  <Button type="primary" htmlType="submit" loading={loading} style={{ float: 'right' }}>
                    Save keywords
                  </Button>
                </div>
              )}
            </Form.List>
          </Form.Item>
        </Form>
      </Modal>
      <Button style={style} type="primary" onClick={() => setVisible(true)}>
        New keyword
      </Button>
    </Fragment>
  );
}
