import './Profile.css';

import React, { Component } from 'react';

import { Select } from 'antd';
import { withConfigCatClient } from 'configcat-react';
import { connect } from 'react-redux';

import { updateAccount } from 'lib/api';
import axios from 'lib/axios.factory';
import * as string from 'locales/strings';
import { store } from 'stores';
import { updateAccount as updateAccountAction } from 'stores/accounts';

import Box from '../../common/Box';
import Button, { CancelButton, DeleteButton, LightButton } from '../../common/Button';
import Input from '../../common/Input';

class Profile extends Component<{
  user: userT,
  account: accountT,
  updateAccountAction: (account: accountT) => void,
}> {
  render() {
    const { account, user, updateAccountAction } = this.props;

    return (
      <div className="profile">
        <CompanyNameForm account={account} updateAccountAction={updateAccountAction} />
        <AccountDetailsForm account={account} user={user} />
        <Box className="profile__reset_cache" header={string.get('settings.profile.reset.title')}>
          <DeleteButton handleClick={store.clearCache}>
            {string.get('settings.profile.reset.button.title')}
          </DeleteButton>
        </Box>
      </div>
    );
  }
}

class AccountDetailsForm extends Component<
  {
    user: userT,
    account: accountT,
  },
  {
    email: string,
    fullname: string,
  },
> {
  constructor(props) {
    super(props);
    this.state = {
      email: props.user.email,
      fullname: props.user.fullname,
    };
  }
  render() {
    const { email, fullname } = this.state;

    return (
      <Box className="profile__account" header="Profile details">
        <div className="profile__form__container">
          <div className="profile__account__fullname">{fullname}</div>
          <div className="profile__account__email">{email}</div>
        </div>
      </Box>
    );
  }
}

class CompanyNameForm extends Component<
  {
    account: accountT,
    updateAccountAction: (account: accountT) => void,
  },
  {
    name: string,
    calling: boolean,
    edit: boolean,
  },
> {
  constructor(props) {
    super(props);
    this.state = {
      type: props.account.type,
      name: props.account.name,
      calling: false,
      edit: false,
    };
  }

  render() {
    const { account } = this.props;
    const { name, type, calling, edit } = this.state;

    return (
      <Box className="profile__company" header="Account details">
        {edit && (
          <form onSubmit={this.handleSubmit} className="profile__form__container">
            <Input value={name} id="name" placeholder="Name" onChange={this.handleChange} />
            <Select
              value={type}
              id="type"
              placeholder="type"
              onChange={(value) => {
                this.setState({
                  type: value,
                });
              }}
            >
              <option value="SELLER">Amazon seller</option>
              <option value="MARKETING">Marketer</option>
            </Select>
            <CancelButton
              handleClick={() =>
                this.setState({
                  edit: false,
                  calling: false,
                  name: account.name,
                })
              }
              className="profile__company__cancel"
            >
              Cancel
            </CancelButton>
            <Button
              className="profile__company__update"
              disabled={!this.validateFields()}
              loading={calling}
              loadingLabel="Updating..."
            >
              Update account
            </Button>
          </form>
        )}
        {!edit && (
          <div className="profile__form__container">
            <div className="profile__company__name">{name}</div>
            <LightButton className="profile__company__edit" handleClick={() => this.setState({ edit: true })}>
              Edit
            </LightButton>
          </div>
        )}
      </Box>
    );
  }

  handleChange = (event: SyntheticEvent<HTMLInputElement>) => {
    const target = (event.currentTarget: HTMLInputElement);
    const { value } = target;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  handleSubmit = (event: SyntheticEvent<HTMLInputElement>) => {
    event.preventDefault();
    const { name } = this.state;
    const { type } = this.state;
    const { account, updateAccountAction } = this.props;

    this.setState({ calling: true });

    if (this.validateFields()) {
      updateAccount(
        localStorage,
        axios,
        { accountId: account.id },
        {
          name,
          type,
        },
      ).then(({ account: newAccount }) => {
        this.setState({
          calling: false,
          edit: false,
        });

        return updateAccountAction(newAccount);
      });
    }
  };

  validateFields = () =>
    (this.state.name.length > 2 && this.state.name !== this.props.account.name) ||
    this.state.type !== this.props.account.type;
}

const mapStateToProps = function (state): { user: userT } {
  return {
    user: state.user.user,
  };
};

export default connect(mapStateToProps, {
  updateAccountAction,
})(withConfigCatClient(Profile));
