import { Statistic } from 'antd';
import styled from 'styled-components';

import { strings } from 'locales/strings';

import {
  IMPRESSION_THRESHOLDS,
  ImpressionEmoji,
  calculateAmazonSearchVolume,
  calculateGoogleSearchVolume,
  getImpression,
} from './constants';

const Style = styled.div`
  display: flex;
  overflow: auto;

  > *:not(:first-child) {
    border-left-width: 0;
  }

  > * {
    flex: 1;
  }
`;

const KeyMetric = styled.div`
  padding: 0.75rem;
  border: 1px solid #d9d9d9;
  background-color: white;
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;

  .ant-statistic {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;

    .ant-statistic-title {
      font-weight: 500;
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1;
      margin-bottom: 0;
    }

    .ant-statistic-content {
      font-weight: 600;
      white-space: nowrap;
      display: block;

      > .ant-statistic-content-value,
      > .ant-statistic-content-prefix {
        font-size: 1.25rem !important;
      }
    }
  }
`;

const EmojiContainer = styled.div`
  text-align: center;
  font-size: 1.25rem;
  height: 1.25rem;
  line-height: 1.25rem;
`;

export const ProductKeyMetrics = ({ auditItem = {} }) => {
  const { product, score, keywords } = auditItem;

  const totalAmazonSearchVolume = calculateAmazonSearchVolume(keywords);
  const totalGoogleSearchVolume = calculateGoogleSearchVolume(keywords);

  return (
    <Style>
      <KeyMetric>
        <Statistic
          title={strings.get('asinAudit.keyMetrics.profitScore')}
          value={score?.profit ?? 'N/A'}
          precision={1}
        />
        <EmojiContainer>
          {score?.profit != null && (
            <ImpressionEmoji impression={getImpression(score?.profit, IMPRESSION_THRESHOLDS.profit)} />
          )}
        </EmojiContainer>
      </KeyMetric>
      <KeyMetric>
        <Statistic
          title={strings.get('asinAudit.keyMetrics.rankingScore')}
          value={score?.ranking ?? 'N/A'}
          precision={1}
        />
        <EmojiContainer>
          {score?.ranking != null && (
            <ImpressionEmoji impression={getImpression(score?.ranking, IMPRESSION_THRESHOLDS.ranking)} />
          )}
        </EmojiContainer>
      </KeyMetric>
      <KeyMetric>
        <Statistic
          title={strings.get('asinAudit.keyMetrics.keywordProfitScore')}
          value={score?.keywordProfit ?? 'N/A'}
          precision={1}
        />
        <EmojiContainer>
          {score?.keywordProfit != null && (
            <ImpressionEmoji impression={getImpression(score?.keywordProfit, IMPRESSION_THRESHOLDS.keywordProfit)} />
          )}
        </EmojiContainer>
      </KeyMetric>
      <KeyMetric>
        <Statistic
          title={strings.get('asinAudit.keyMetrics.keywordRankingScore')}
          value={score?.keywordRanking ?? 'N/A'}
          precision={1}
        />
        <EmojiContainer>
          {score?.keywordRanking != null && (
            <ImpressionEmoji impression={getImpression(score?.keywordRanking, IMPRESSION_THRESHOLDS.keywordRanking)} />
          )}
        </EmojiContainer>
      </KeyMetric>
      <KeyMetric>
        <Statistic
          title={strings.get('asinAudit.keyMetrics.amazonSearchVolume')}
          value={totalAmazonSearchVolume}
          precision={0}
        />
        <EmojiContainer>
          <ImpressionEmoji
            impression={getImpression(totalAmazonSearchVolume, IMPRESSION_THRESHOLDS.amazonSearchVolume)}
          />
        </EmojiContainer>
      </KeyMetric>
      <KeyMetric>
        <Statistic
          title={strings.get('asinAudit.keyMetrics.googleSearchVolume')}
          value={totalGoogleSearchVolume}
          precision={0}
        />
        <EmojiContainer>
          <ImpressionEmoji
            impression={getImpression(totalGoogleSearchVolume, IMPRESSION_THRESHOLDS.googleSearchVolume)}
          />
        </EmojiContainer>
      </KeyMetric>
      <KeyMetric>
        <Statistic
          title={strings.get('asinAudit.keyMetrics.reviewScore')}
          value={product?.reviewScore ?? 'N/A'}
          precision={1}
        />
        <EmojiContainer>
          {product?.reviewScore != null && (
            <ImpressionEmoji impression={getImpression(product?.reviewScore, IMPRESSION_THRESHOLDS.reviewScore)} />
          )}
        </EmojiContainer>
      </KeyMetric>
      <KeyMetric>
        <Statistic title={strings.get('asinAudit.keyMetrics.reviewCount')} value={product?.reviewCount ?? 'N/A'} />
        <EmojiContainer />
      </KeyMetric>
      <KeyMetric>
        <Statistic
          title={strings.get('asinAudit.keyMetrics.price')}
          {...(product?.price == null
            ? { value: 'N/A' }
            : {
                value: product.price / 100,
                precision: 2,
                prefix: '$',
              })}
        />
        <EmojiContainer />
      </KeyMetric>
    </Style>
  );
};
