import { forwardRef, useState } from 'react';

import { SearchOutlined } from '@ant-design/icons';
import { Input, Tag } from 'antd';
import styled from 'styled-components';

export const KEYS = {
  BACKSPACE: 'Backspace',
  ENTER: 'Enter',
  SPACEBAR: ' ',
  COMMA: ',',
};

const defaultSeparators = [KEYS.COMMA, KEYS.ENTER, KEYS.SPACEBAR];
const defaultPasteSeparatorPattern = /[\n,]/;

const StyledInput = styled(Input)`
  flex-wrap: wrap;

  ::before {
    content: '';
  }

  .ant-input-prefix {
    flex: unset;
    overflow: auto;
  }
  .ant-input {
    flex: 1 1 75px;
  }
`;

const TagGroup = styled.div`
  display: flex;
  gap: 0.25rem;
  flex-wrap: wrap;
`;

const StyledTag = styled(Tag)`
  height: 28px;
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 0 0.75rem;
  margin: 0;
  border-radius: 20px;

  ${(props) =>
    props.color !== 'red' &&
    `
    border: none;
    background-color: #e6ebff;
  `}

  .ant-tag-close-icon {
    font-size: 0.75rem;
  }
`;

const StyledSearchOutlined = styled(SearchOutlined)`
  color: #828282;
  svg {
    width: 1rem;
    height: 1rem;
  }
  :not(:last-child) {
    margin-right: 0.5rem;
  }
`;

type propsT = {
  value: Array<string>,
  onChange: (Array<string>) => void,
  separators?: Array<string>,
  pasteSeparatorPattern?: string,
  maxTags?: number,
  icon?: Node,
  validate?: (string) => boolean,
};

/**
 * An input component that allows adding tags.
 * - Typing a string and then pressing a separator key defined by `props.separator` (Default: `,`, `Enter`, or `Spacebar`).
 * - Pasting a string with `props.pasteSeparatorPattern` (Default: commas and newlines) will add each item as a tag.
 * - Duplicate tags are filtered out.
 * - Input becomes disabled when `props.maxTags` is reached.
 * - Pressing the Backspace key when the input is empty will remove the last tag.
 */
export const TagInput = forwardRef(
  (
    {
      // Prop names `value` and `onChange` are required for parent form management
      value: tags,
      onChange: handleTagsChange,
      separators = defaultSeparators,
      pasteSeparatorPattern = defaultPasteSeparatorPattern,
      maxTags = 0, // 0 means no limit
      icon = <StyledSearchOutlined />,
      validate = () => true,
      ...props
    }: propsT,
    ref,
  ) => {
    const [inputValue, setInputValue] = useState('');

    const addTag = () => {
      const newTag = inputValue.trim();

      if (newTag && !tags.includes(newTag)) {
        handleTagsChange(tags.concat(newTag));
      }

      setInputValue('');
    };

    const handleInputChange = (e) => {
      setInputValue(e.target.value);
    };

    const handleDelete = (deleteTag: string) => {
      handleTagsChange(tags.filter((tag) => tag !== deleteTag));
    };

    const handleKeyDown = (e) => {
      if (e.key === KEYS.BACKSPACE && !inputValue) {
        handleTagsChange(tags.slice(0, -1));
      } else if (separators.includes(e.key)) {
        // Prevent triggering handleChange after pressing separator key
        e.preventDefault();

        addTag();
      }
    };

    const handlePaste = (e) => {
      // Prevent triggering handleChange after pasting
      e.preventDefault();

      const pasteText = e.clipboardData.getData('text');
      const pasteItems = pasteText.split(pasteSeparatorPattern).map((items) => items.trim());
      const concatTags = tags.concat(pasteItems);
      const uniqueNonEmptyTags = concatTags.filter((tag, i, arr) => tag && arr.indexOf(tag) === i);

      handleTagsChange(maxTags ? uniqueNonEmptyTags.slice(0, maxTags) : uniqueNonEmptyTags);
      setInputValue('');
    };

    return (
      <StyledInput
        prefix={
          <>
            {icon}
            <TagGroup>
              {tags.map((tag) => (
                <StyledTag
                  key={tag}
                  closable
                  onClose={() => handleDelete(tag)}
                  {...(validate && !validate(tag) && { color: 'red' })}
                >
                  {tag}
                </StyledTag>
              ))}
            </TagGroup>
          </>
        }
        value={inputValue}
        onBlur={addTag}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        onPaste={handlePaste}
        // Still want to keep invalid tags but disable input when max valid tags is reached
        disabled={maxTags && tags.filter((x) => validate(x)).length >= maxTags}
        ref={ref}
        {...props}
        {...(tags.length && { placeholder: '' })}
      />
    );
  },
);
