import React, { useEffect, useState, Fragment } from 'react';

import { Alert, Button, Divider, Form, Input, message } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import Modal from 'antd/lib/modal/Modal';
import _ from 'lodash';
import { CopyToClipboard } from 'react-copy-to-clipboard/lib/Component';

import { googleLanguages } from 'assets/googleLanguage';
import { InputDateWithDisabledRange } from 'components/common/antd/inputs/InputDateWithDisabledRange';
import { editCampaign } from 'lib/adsPlateformApi';
import axios from 'lib/axios.factory';
import { GOOGLE_RMF, useFeatureFlag } from 'lib/configcat';

import { useLoading } from '../../../../../hooks/useLoading';
import { getRawCampaign } from '../../../../../lib/adsPlateformApi';
import { getAdsProviders } from '../../../../../lib/adsProvider/google';
import { getCurrencyCode } from '../../../../../lib/amazon';
import { getRedirectsByIds, updateRedirect } from '../../../../../lib/api';
import { CurrencyInput } from '../../../../common/antd/inputs/CurrencyInput';
import { If } from '../../../../common/conditional/If';
import { MutedText } from '../../../../common/MutedText';
import { SmallCompactPlaceholder } from '../../../../common/Placeholders';
import { GoogleGeoTargetSelector } from '../../Google/GoogleGeoTargetSelector';
import { GoogleRawCampaignView } from '../../Google/GoogleRawCampaignView';
import { BiddingOptionsSelector } from '../../GoogleRMF/BiddingOptionsSelector';
import { LanguageSelector } from '../../NewCampaign/LanguageSelector';
import { PerformanceBoosterToggle } from '../../NewCampaign/PerformanceBoosterToggle';
import { PixelSelector } from '../../NewCampaign/PixelSelector';

export function CampaignEditModal(props: {
  account: accountT,
  campaign: campaignT,
  onFinish: Function,
  onUpdate: (c: campaignT) => void,
  visible: boolean,
}) {
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const [campaign, setCampaign] = useState(undefined);
  const [redirect, setRedirect] = useState({});
  const [slug, setSlug] = useState('');
  const adsProvider = getAdsProviders(props.account).find((p) => p.id === props.campaign.providerId);
  const [SPAConnected, setSPAConnected] = useState(false);

  const currencyCode = getCurrencyCode(props.account.productProviders);
  const { doAction, loading: initLoading } = useLoading(true);
  const [showRMFEditOptions] = useFeatureFlag(GOOGLE_RMF);

  useEffect(() => {
    const spa = props.account?.productProviders[0]?.spa;

    setSPAConnected(spa?.connectedAt !== null && spa?.errorAt === null);
  }, [props.account]);

  async function loadRawCampaign() {
    setCampaign(undefined);

    try {
      const campaignResponse = await getRawCampaign(
        localStorage,
        axios,
        { accountId: props.account.id },
        props.campaign.id,
      );

      setCampaign(campaignResponse);
    } catch (e) {
      message.error('Error while loading campaign');
      props.onFinish();
    }
  }

  async function loadRedirects() {
    if (props.campaign.redirectId) {
      const { redirects } = await getRedirectsByIds(localStorage, axios, {
        accountId: props.campaign.accountId,
        redirectsIds: [props.campaign.redirectId],
      });

      if (redirects.length > 0) {
        const r = _.get(redirects, '0');

        setRedirect(r);
        setSlug(r.slug);
      }
    }
  }

  useEffect(() => {
    if (props.visible) {
      doAction(async () => {
        await loadRedirects();
        await loadRawCampaign();
      });
    }
  }, [props.campaign.id, props.visible]);

  return (
    <Modal
      visible={props.visible}
      title="Edit - Preview Campaign"
      width="580px"
      height="100vh"
      loading={initLoading || loading}
      okButtonProps={{ loading }}
      onCancel={props.onFinish}
      onOk={form.submit}
    >
      <If _if={campaign} _else={<SmallCompactPlaceholder text="Loading current campaign" />}>
        {(c) => (
          <Fragment>
            {c && adsProvider.providerType === 'GOOGLE' && (
              <>
                <Form
                  layout="vertical"
                  form={form}
                  initialValues={{
                    budget: campaign.googleRawCampaign.campaignBudget.amountMicros / 1000000,
                    pixelsIds: props.campaign.pixelsIds,
                    performanceBooster: props.campaign.performanceBooster,
                  }}
                  onFinish={async (values) => {
                    if (adsProvider.providerType !== 'GOOGLE') {
                      props.onFinish();

                      return;
                    }

                    if (!_.get(props.campaign, 'id')) {
                      message.error('Error on Campaign Budget');

                      return;
                    }

                    setLoading(true);

                    try {
                      await editCampaign(
                        localStorage,
                        axios,
                        { accountId: props.account.id },
                        {
                          googleCampaign: {
                            budgetMicros: Math.round(values.budget * 1000000),
                            pixelsIds: values.pixelsIds,
                            performanceBooster: values.performanceBooster,
                          },
                        },
                        props.campaign.id,
                      );

                      if (props.campaign.useRedirect) {
                        await updateRedirect(
                          localStorage,
                          axios,
                          {
                            accountId: props.campaign.accountId,
                          },
                          {
                            ...redirect,
                            redirectId: props.campaign.redirectId,
                            pixelsIds: values.pixelsIds,
                          },
                        );
                      }

                      message.success('Campaign updated');
                      setCampaign(undefined);
                      props.onFinish();
                    } catch (e) {
                      message.error('Error on Campaign Update');

                      throw e;
                    } finally {
                      setLoading(false);
                    }
                  }}
                >
                  <Fragment>
                    <Form.Item label="Daily Budget" name="budget">
                      <CurrencyInput currencyCode={currencyCode}></CurrencyInput>
                    </Form.Item>

                    {props.campaign.useRedirect && <PixelSelector account={props.account} />}

                    <PerformanceBoosterToggle
                      account={props.account}
                      spaConnected={SPAConnected}
                      disabled={!SPAConnected}
                    />

                    {showRMFEditOptions && (
                      <div style={{ width: 500, marginTop: '1rem' }}>
                        <Form.Item name="name" label="Campaign Name">
                          <Input size="large" defaultValue={props.campaign.name}></Input>
                        </Form.Item>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <div style={{ display: 'block' }}>
                            <Form.Item name={['googleCampaign', 'start']} label="Start date">
                              <InputDateWithDisabledRange
                                startDate={new Date()}
                                placeholder="Default Today"
                                endDate={new Date(8640000000000000)}
                                defaultValue={props.campaign?.googleCampaign?.start}
                              />
                            </Form.Item>
                          </div>
                          <div style={{ display: 'block' }}>
                            <Form.Item name={['googleCampaign', 'end']} label="End date">
                              <InputDateWithDisabledRange
                                startDate={new Date()}
                                endDate={new Date(8640000000000000)}
                                defaultValue={props.campaign?.googleCampaign?.end}
                              />
                            </Form.Item>
                          </div>
                        </div>
                        <Form.Item
                          name={['googleCampaign', 'geoCriteria']}
                          label="Location"
                          rules={[{ required: false, type: 'array', min: 0, max: 30 }]}
                        >
                          <GoogleGeoTargetSelector account={props.account} />
                        </Form.Item>
                        <Form.Item
                          name={['googleCampaign', 'languagesCriteria']}
                          rules={[{ required: false, type: 'array', min: 0, max: 30 }]}
                          label="Languages"
                        >
                          <LanguageSelector
                            googleLanguages={googleLanguages}
                            value={googleLanguages.filter(
                              (v) => v.id === props.campaign.googleCampaign.languagesCriteria[0].id,
                            )}
                          />
                        </Form.Item>
                        <Form.Item
                          name={['googleCampaign', 'adGroup', 'cpcBidMicros']}
                          label={`Maximum cost per click bid limit in ${currencyCode}`}
                        >
                          <CurrencyInput
                            currencyCode={currencyCode}
                            unit={1000000}
                            initialValue={props.campaign.googleCampaign?.adGroup?.cpcBidMicros}
                          ></CurrencyInput>
                        </Form.Item>
                        <BiddingOptionsSelector defaultValue="Target CPA Portfolio" />
                      </div>
                    )}

                    <GoogleRawCampaignView rawQueryResult={campaign} account={props.account} />
                  </Fragment>
                </Form>
                <Button
                  style={{
                    bottom: '10px',
                    left: '10px',
                    position: 'absolute',
                  }}
                  type="link"
                  onClick={() => (window.location.href = 'https://ads.google.com')}
                  target="_blank"
                >
                  Edit on Adwords
                </Button>
              </>
            )}
            {adsProvider.providerType === 'FACEBOOK' && (
              <Fragment>
                <MutedText>
                  The best way to edit your Facebook Campaign <br />
                  is thought the Facebook Ads Manager.
                  <br />
                  <br />
                  Please use the following link to access <br />
                  your campaign on Facebook Ads Manager
                </MutedText>
                <Button
                  style={{
                    bottom: '10px',
                    left: '10px',
                    position: 'absolute',
                  }}
                  type="link"
                  onClick={() =>
                    (window.location.href = `https://www.facebook.com/adsmanager/manage/adsets/edit?act=${(
                      adsProvider || { externalAccountId: '' }
                    ).externalAccountId.replace('act_', '')}&selected_campaign_ids=${_.get(props.campaign, [
                      'facebookCampaign',
                      'id',
                    ])}`)
                  }
                  target="_blank"
                >
                  Edit on Facebook Ads Manager
                </Button>
              </Fragment>
            )}
            {adsProvider.providerType === 'TIKTOK' && (
              <Fragment>
                <MutedText>
                  The best way to edit your Tiktok Campaign <br />
                  is thought the Tiktok Business Manager.
                  <br />
                  <br />
                  Please use the following link to access <br />
                  your campaign on Tiktok Business Manager.
                </MutedText>
                <Button
                  style={{
                    bottom: '10px',
                    left: '10px',
                    position: 'absolute',
                  }}
                  type="link"
                  onClick={() =>
                    (window.location.href = `https://ads.tiktok.com/i18n/perf/campaign?aadvid=${
                      (adsProvider || { externalAccountId: '' }).externalAccountId
                    }`)
                  }
                  target="_blank"
                >
                  Edit on Tiktok Business Manager
                </Button>
              </Fragment>
            )}
            {adsProvider.providerType === 'GENERIC' && (
              <>
                <Form
                  layout="vertical"
                  form={form}
                  initialValues={{
                    name: props.campaign.name,
                    slug: redirect.slug,
                    pixelsIds: props.campaign.pixelsIds,
                  }}
                  onFinish={async (values) => {
                    if (!_.get(props.campaign, 'id')) {
                      message.error('We could not save campaign at the moment');

                      return;
                    }

                    setLoading(true);

                    try {
                      const c = props.campaign;

                      await editCampaign(
                        localStorage,
                        axios,
                        { accountId: props.account.id },
                        { genericCampaign: { name: values.name, pixelsIds: values.pixelsIds } },
                        props.campaign.id,
                      );
                      c.name = values.name;
                      c.pixelsIds = values.pixelsIds;
                      props.onUpdate(c);

                      await updateRedirect(
                        localStorage,
                        axios,
                        {
                          accountId: props.campaign.accountId,
                        },
                        {
                          ...redirect,
                          redirectId: props.campaign.redirectId,
                          slug: values.slug,
                          pixelsIds: values.pixelsIds,
                        },
                      );
                      message.success('Campaign updated');
                      props.onFinish();
                    } catch (e) {
                      message.error('We could not save campaign at the moment');

                      throw e;
                    } finally {
                      setLoading(false);
                    }
                  }}
                >
                  <Form.Item label="Campaign name" name="name">
                    <Input name="name" />
                  </Form.Item>

                  {props.campaign.useRedirect && <PixelSelector account={props.account} />}

                  <Divider orientation="horizontal" />

                  <div>
                    <div>
                      <Form.Item label="Short link">
                        <Alert
                          type="warning"
                          message={<div>Please be sure to not add any extra parameter to it.</div>}
                        ></Alert>
                        <br />

                        <div style={{ display: 'flex', justifyContent: 'right' }}>
                          <Form.Item name="slug" style={{ flexGrow: 1 }}>
                            <Input
                              size="small"
                              addonBefore={`${redirect.domain}/`}
                              onChange={(e) => setSlug(e.target.value)}
                            />
                          </Form.Item>
                          <CopyToClipboard
                            text={`https://${redirect.domain}/${slug}`}
                            onCopy={() => message.success(`Your campaign link is copied to clipboard.`)}
                          >
                            <div>
                              <Button style={{ marginLeft: '10px' }}>Copy</Button>
                            </div>
                          </CopyToClipboard>
                        </div>
                      </Form.Item>
                    </div>
                  </div>
                </Form>
              </>
            )}
            <br />
          </Fragment>
        )}
      </If>
    </Modal>
  );
}
