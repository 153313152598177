import React, { useState } from 'react';

import { connect } from 'react-redux';

import { s } from 'lib/safe';
import { addNotice } from 'stores/notices';

import { Actions } from '../../../actions';
import { sArray } from '../../../lib/safe';
import { strings } from '../../../locales/strings';
import { DropDownWithLabel } from '../../pages/AudienceBuilder/Campaigns/composer/DropDownWithLabel';
import { handleChange } from '../../pages/AudienceBuilder/Campaigns/composer/inputs/composerEditUtils';
import { InputWithLabelUTMs } from '../../pages/AudienceBuilder/Campaigns/composer/InputWithLabelUTMs';
import { ComposerCampaignSelect } from '../../pages/AudienceBuilder/Campaigns/composer/selects/ComposerCampaignSelect';
import { ComposerMediumSelect } from '../../pages/AudienceBuilder/Campaigns/composer/selects/ComposerMediumSelect';
import { ComposerPixelsMultiSelect } from '../../pages/AudienceBuilder/Campaigns/composer/selects/ComposerPixelsMultiSelect';
import { ComposerSourceSelect } from '../../pages/AudienceBuilder/Campaigns/composer/selects/ComposerSourceSelect';
import { ComposerSubCampaignSelect } from '../../pages/AudienceBuilder/Campaigns/composer/selects/ComposerSubCampaignSelect';
import { ComposerTagsMultiSelect } from '../../pages/AudienceBuilder/Campaigns/composer/selects/ComposerTagsMultiSelect';
import { ComposerUtmCampaignSelect } from '../../pages/AudienceBuilder/Campaigns/composer/selects/ComposerUtmCampaignSelect';
import { GdprCheckBox } from '../CheckBoxes';
import SideModal from '../SideModal';

/**
 * @return {null}
 */
export function BulkEditModal({
  selectedRedirects,
  editSelected,
  open,
  handleClose,
  account,
  localUpdateOfAccount,
  campaigns,
  disabled,
  addCampaign,
}) {
  const ADD_PIXEL = 'Add pixel(s)';
  const ADD_GDPR_CONSENT = 'Add GDPR consent';
  const ADD_UTM_SOURCE = 'Add an UTM source';
  const ADD_UTM_MEDIUM = 'Add an UTM Medium';
  const ADD_UTM_CAMPAIGN = 'Add an UTM Campaign';
  const ADD_UTM_TERM = 'Add an UTM Term';
  const ADD_UTM_CONTENT = 'Add an UTM Content';
  const ADD_TAG = 'Add a tag';
  const MOVE_TO_CAMPAIGN = 'Move to a campaign';
  const options = [
    { id: ADD_PIXEL, value: ADD_PIXEL },
    { id: ADD_GDPR_CONSENT, value: ADD_GDPR_CONSENT },
    { id: ADD_UTM_SOURCE, value: ADD_UTM_SOURCE },
    { id: ADD_UTM_MEDIUM, value: ADD_UTM_MEDIUM },
    { id: ADD_UTM_CAMPAIGN, value: ADD_UTM_CAMPAIGN },
    { id: ADD_UTM_TERM, value: ADD_UTM_TERM },
    { id: ADD_UTM_CONTENT, value: ADD_UTM_CONTENT },
    { id: ADD_TAG, value: ADD_TAG },
    { id: MOVE_TO_CAMPAIGN, value: MOVE_TO_CAMPAIGN },
  ];
  const [modeSelected, setModeSelected] = useState(ADD_PIXEL);
  const defaultRedirectChanges = {
    pixelsIds: [],
    utms: {},
    tags: [],
    cookieConsent: undefined,
  };
  const [redirectChanges, setRedirectChanges] = useState(defaultRedirectChanges);

  const updateRedirects = () => {
    const redirectsIds = selectedRedirects.map((r) => r.id);

    editSelected({ redirectsIds, ...redirectChanges });
    setRedirectChanges(defaultRedirectChanges);
    setModeSelected(undefined);
  };
  const selectedCampaign = campaigns.find((c) => c.id === redirectChanges.campaignId);
  const selectedSubCampaign = sArray(s(selectedCampaign).subCampaigns).find(
    (c) => c.id === redirectChanges.subCampaignId,
  );

  if (!open || !selectedRedirects) {
    return null;
  }

  if (s(modeSelected).id === ADD_GDPR_CONSENT && redirectChanges.cookieConsent === undefined) {
    redirectChanges.cookieConsent = false;
  }

  if (s(modeSelected).id !== ADD_GDPR_CONSENT) {
    redirectChanges.cookieConsent = undefined;
  }

  return (
    <SideModal
      title={`Edit ${selectedRedirects.length} links`}
      handleCancel={() => {
        handleClose();
      }}
      handleOk={() => {
        updateRedirects();
      }}
      okMessage={`Update ${selectedRedirects.length} redirects`}
      okOnly={true}
      disabled={disabled}
      okOnEnterKey={false}
    >
      <DropDownWithLabel
        filterable={false}
        disabled={selectedRedirects.length === 0}
        noResults={<></>}
        createNewItemFromQuery={() => ''}
        createNewItemRenderer={<></>}
        displayLabel
        onSelect={setModeSelected}
        values={options}
        selected={modeSelected}
        title="Select a bulk action"
      />
      <br />

      {s(modeSelected).id === ADD_PIXEL && (
        <ComposerPixelsMultiSelect
          account={account}
          redirect={redirectChanges}
          onChange={(_) => {
            setRedirectChanges({ pixelsIds: redirectChanges.pixelsIds });
          }}
        />
      )}
      {s(modeSelected).id === ADD_GDPR_CONSENT && (
        <GdprCheckBox
          cookieConsent={redirectChanges.cookieConsent}
          handleClick={(e) =>
            setRedirectChanges({
              cookieConsent: !redirectChanges.cookieConsent,
            })
          }
          account={account}
        />
      )}
      {s(modeSelected).id === ADD_UTM_SOURCE && (
        <ComposerSourceSelect
          account={account}
          redirect={redirectChanges}
          localUpdateOfAccount={localUpdateOfAccount}
          onChange={(updatedData) => {
            setRedirectChanges(updatedData.redirect);
          }}
        />
      )}
      {s(modeSelected).id === ADD_UTM_CAMPAIGN && (
        <ComposerUtmCampaignSelect
          account={account}
          redirect={redirectChanges}
          localUpdateOfAccount={localUpdateOfAccount}
          onChange={(updatedData) => {
            setRedirectChanges(updatedData.redirect);
          }}
        />
      )}
      {s(modeSelected).id === ADD_UTM_MEDIUM && (
        <ComposerMediumSelect
          account={account}
          redirect={redirectChanges}
          localUpdateOfAccount={localUpdateOfAccount}
          onChange={(updatedData) => {
            setRedirectChanges(updatedData.redirect);
          }}
        />
      )}
      {s(modeSelected).id === MOVE_TO_CAMPAIGN && (
        <>
          <ComposerCampaignSelect
            account={account}
            redirect={redirectChanges}
            selectedCampaign={selectedCampaign}
            campaigns={campaigns}
            addCampaign={addCampaign}
            onChange={(updatedData) => {
              setRedirectChanges(updatedData.redirect);
            }}
          />
          <ComposerSubCampaignSelect
            account={account}
            redirect={redirectChanges}
            selectedCampaign={selectedCampaign}
            selectedSubCampaign={selectedSubCampaign}
            addCampaign={addCampaign}
            onChange={(updatedData) => {
              setRedirectChanges(updatedData.redirect);
            }}
          />
        </>
      )}

      {s(modeSelected).id === ADD_TAG && (
        <ComposerTagsMultiSelect
          account={account}
          onSelect={(tags) => {
            handleChange([{ name: 'tags', value: tags }], {
              redirectChanges,
              onChange: (updatedData) => {
                setRedirectChanges(updatedData.redirect);
              },
            });
          }}
          onChange={(updatedData) => {
            setRedirectChanges(updatedData.redirect);
          }}
          redirect={redirectChanges}
          localUpdateOfAccount={localUpdateOfAccount}
        />
      )}

      {s(modeSelected).id === ADD_UTM_TERM && (
        <InputWithLabelUTMs
          name="term"
          displayLabel
          onChange={(event) => {
            setRedirectChanges({
              ...redirectChanges,
              utms: { term: event.target.value.trim() },
            });
          }}
          title={strings.get('utm.term.input.title')}
          placeholder={strings.get('utm.term.input.placeholder')}
          value={redirectChanges.utms.term || ''}
        />
      )}
      {s(modeSelected).id === ADD_UTM_CONTENT && (
        <InputWithLabelUTMs
          name="content"
          displayLabel
          onChange={(event) => {
            setRedirectChanges({
              ...redirectChanges,
              utms: { content: event.target.value.trim() },
            });
          }}
          title={strings.get('utm.content.input.title')}
          placeholder={strings.get('utm.content.input.placeholder')}
          value={redirectChanges.utms.content || ''}
        />
      )}
    </SideModal>
  );
}

const mapStateToProps = function (state): {} {
  return {
    open: state.front.bulkEditRedirectModalConfig.open,
    selectedRedirects: state.campaigns.selectedRedirects,
    campaigns: state.campaigns.campaigns,
    disabled: state.abRedirects.loading || state.campaigns.loading,
  };
};

const dispatchToProps = (dispatch: Dispatch<*>, ownProps: Object) => ({
  localUpdateOfAccount: ({ account }) => dispatch(Actions.api.account.update.success({ account })),
  handleClose: () => dispatch(Actions.front.audienceBuilder.redirect.bulkEdit.hide.request()),
  addNotice: (payload) => dispatch(addNotice(payload)),
  addCampaign: ({ campaignOrSub, parent }) => {
    dispatch(
      Actions.api.audienceBuilder.campaigns.add.request({
        campaignOrSub,
        parent,
      }),
    );
  },
  editSelected: (params: {
    redirectsIds?: Array<string>,
    pixelsIds?: Array<string>,
    deletedPixelsIds?: Array<string>,
    tags?: Array<string>,
    deletedTags?: Array<string>,
    cookieConsent?: boolean,
    campaignId?: string,
    subCampaignId?: string,
  }) => dispatch(Actions.api.audienceBuilder.redirect.editSelected.request({ ...params })),
});

export default connect(mapStateToProps, dispatchToProps)(BulkEditModal);
