import { combineReducers, configureStore } from '@reduxjs/toolkit';
import jwtDecode from 'jwt-decode';

import { signOut } from 'lib/auth';
import axios from 'lib/axios.factory';

import { accounts } from './accounts';
import { accountStats } from './accountStats';
import { analytics } from './analytics';
import { apiSlice } from './apiSlice';
import { abRedirects } from './audienceBuilder/redirects';
import { abRedirectStats } from './audienceBuilder/redirectStats';
import { campaigns } from './campaigns';
import { front } from './front';
import { linkStats } from './linkStats';
import { notices } from './notices';
import { subscription } from './subscription';
import { user } from './user';
import { version } from './version';
import { s } from '../lib/safe';
import { middlewares } from '../middlewares';

// Update this number when the store is not compatible anymore with last backuped. If you do that, last backup will be ignored and user prefs restore to default
export const storeVersion = 1.13;

let decodedToken = {};
let scopedStatePrefix = '';
// last dispatched action will be use to determinate if we need to save the store on the current subscribe call
let lastAction;

/**
 * Load the prefix used inside localstorage to store the state
 */
function loadPrefix() {
  const accessToken = localStorage.getItem('auth0-access-token');
  const selectedAccount = localStorage.getItem('selected-account');

  if (accessToken && selectedAccount) {
    decodedToken = jwtDecode(accessToken);
    scopedStatePrefix = `${decodedToken.sub}_${selectedAccount}`;
  }
}

function loadLastFrontStateFromLocalStorage() {
  let persistedState = {};

  if (!scopedStatePrefix) {
    loadPrefix();
  }

  if (scopedStatePrefix) {
    const encryptedPersistedState = localStorage.getItem(`${scopedStatePrefix}_state`);

    if (encryptedPersistedState) {
      try {
        persistedState = JSON.parse(encryptedPersistedState);
        s(s(s(persistedState).front).campaignColumnsConfig).loading = false;
      } catch (e) {
        console.log("Ignoring backup state because it's an old version encrypted");
      }

      if (persistedState && storeVersion !== persistedState.version) {
        console.log(
          `Ignoring backup state because it's an old version ${persistedState.version} current ${storeVersion}`,
        );
        persistedState = undefined;
      }
    }
  }

  return persistedState;
}

const appReducer = combineReducers({
  linkStats,
  user,
  accounts,
  analytics,
  accountStats,
  notices,
  subscription,
  front,
  campaigns,
  version,

  abRedirects,
  abRedirectStats,

  [apiSlice.reducerPath]: apiSlice.reducer,
});

/**
 * Apply last saved State but keep account and user
 * @param state
 * @returns {*}
 */
function applyLastStateExceptAccountAndUser(state) {
  const persistedState = loadLastFrontStateFromLocalStorage();

  state = {
    ...persistedState,
    accounts: state.accounts,
    user: state.user,
    subscription: state.subscription,
  };

  return state;
}

/**
 * Reducer that will decore add app reducer by adding the management of the persisted state
 * @param state
 * @param action
 * @returns {any}
 */
const persistedStateReducer = (state, action) => {
  lastAction = action;

  if (action.type === 'SWITCH_ACCOUNT') {
    localStorage.setItem('selected-account', action.accountId);
    loadPrefix();
    state = applyLastStateExceptAccountAndUser(state);
  } else if (action.type === 'SIGN_OUT') {
    localStorage.removeItem('selected-account');
    window.location.reload();
  } else if (action.type === 'SIGN_IN') {
    state = applyLastStateExceptAccountAndUser(state);
  }

  return appReducer(state, action);
};

const persistedState = loadLastFrontStateFromLocalStorage();

// $FlowFixMe
export const store = configureStore({
  reducer: persistedStateReducer,
  preloadedState: persistedState,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: { extraArgument: axios },
      serializableCheck: false, // TODO: Should have this on but WorkerAction is not serializable
      immutableCheck: false, // TODO: Should have this on but there is an existing mutation
    })
      .concat(apiSlice.middleware) // Must be after the default middleware
      .concat(middlewares),
  devTools: process.env.NODE_ENV !== 'production' || localStorage.getItem('pxadm') !== null,
});

/**
 * Clear persistate state for the current account + user
 */
store.clearCache = function () {
  if (!scopedStatePrefix) {
    loadPrefix();
  }

  if (scopedStatePrefix) {
    signOut(localStorage);
    localStorage.removeItem(`${scopedStatePrefix}_state`);
    window.location.reload();
  }
};

store.subscribe(() => {
  // Stop using btao because of not latin1 strings that can be store in the store
  setTimeout(() => {
    if (!scopedStatePrefix) {
      loadPrefix();
    }

    if (scopedStatePrefix && s(s(s(lastAction).workerAction).extraOpts).saveStore) {
      const stateToSave = {
        version: storeVersion,
        front: store.getState().front,
        campaigns: store.getState().campaigns,
        accounts: store.getState().accounts,
        abRedirects: store.getState().abRedirects,
        saLinks: store.getState().saLinks,
        saConversions: store.getState().saConversions,
        saEvents: store.getState().saEvents,
        saOverview: store.getState().saOverview,
        saSettings: store.getState().saSettings,
        saAdsCampaigns: store.getState().saAdsCampaigns,
      };

      try {
        localStorage.setItem(`${scopedStatePrefix}_state`, JSON.stringify(stateToSave));
      } catch (e) {
        console.log('Quota exceeded!', stateToSave);
      }
    }
  }, 0);
});
