import React, { useEffect } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch } from 'react-redux';

import { signOut as authSignOut } from 'lib/auth';

import { signOut } from '../../../stores/user';
import { GlobalPlaceholder } from '../../common/Placeholders';

export function SignOut() {
  const dispatch = useDispatch();

  localStorage.removeItem('impersonate');
  localStorage.removeItem('impersonate_access_override');

  const { isLoading, logout } = useAuth0();

  useEffect(() => {
    if (isLoading) {
      return;
    }

    const runSignOut = async () => {
      localStorage.removeItem('auth0-access-token');
      authSignOut(localStorage);
      dispatch(signOut());
      await logout({ logoutParams: { returnTo: window.location.origin } });
    };

    runSignOut();
  }, [isLoading]);

  return <GlobalPlaceholder />;
}
