import React from 'react';

import { Empty } from 'antd';
import styled from 'styled-components';

import { AdsProviderLogoutButton } from './AdsProviderLogoutButton';
import { AdsProviderReloginButton } from './AdsProviderReLoginButton';
import type { accountT } from '../../../../../flow-typed/pixelme/types';
import { adsAlreadyProcessed, adsNeverProcessed } from '../../../../lib/adsProvider/common';
import { getAdsProviders } from '../../../../lib/adsProvider/google';
import { BoxShadow } from '../../../BoxShadow';
import { If } from '../../../common/conditional/If';
import { MutedText } from '../../../common/MutedText';
import { AdsProviderLogo } from '../common/AdsProviderLogo';
import { NewAdsProviderButton } from '../common/NewAdsProviderButton';

const Style = styled.div`
  width: 100%;
  padding: 1em;
  margin-left: -1em;
`;

function ConnectedIndicator(props: { adsProvider: adsProviderT }) {
  return (
    <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
      <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="4" cy="4" r="4" fill="#2EC1B3" />
      </svg>
      <MutedText>
        {adsNeverProcessed(props.adsProvider) && (
          <span>
            <div>Waiting for processing</div>
            <div>Data will be automatically updated by Google every day</div>
          </span>
        )}
        {adsAlreadyProcessed(props.adsProvider) && (
          <span>
            <div>Connected</div>
            <div>Data is automatically updated every day</div>
          </span>
        )}
      </MutedText>
    </div>
  );
}

function NotConnectedIndicator(props: { adsProvider: adsProviderT, account: accountT }) {
  return (
    <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
      <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="4" cy="4" r="4" fill="#FF0000" />
      </svg>
      <MutedText>
        <span>
          <div style={{ display: 'flex', alignItems: 'center', gap: '30px' }}>
            Not connected.
            {props.adsProvider.providerType === 'FACEBOOK' && (
              <AdsProviderReloginButton account={props.account} provider={props.adsProvider}></AdsProviderReloginButton>
            )}
          </div>
        </span>
      </MutedText>
    </div>
  );
}

export function AdsProviderAccounts(props: { account: accountT, +onlyLoginNeeded: boolean }) {
  const adsProviders = getAdsProviders(props.account) || [];

  return (
    <Style>
      {adsProviders
        .filter((p) => !props.onlyLoginNeeded || p.loginNeeded)
        .filter((p) => !p.generated)
        .sort((p1, p2) => (p2.loginNeeded ? 1 : 0) - (p1.loginNeeded ? 1 : 0))
        .map((provider: adsProviderT) => (
          <BoxShadow style={{ width: '100%', display: 'block' }} warning={provider.loginNeeded}>
            <div>
              <b>Ad account connected: </b>
              {`${provider.externalAccountDescriptiveName} (${provider.externalAccountId})`}
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '2em',
                width: '100%',
              }}
            >
              <AdsProviderLogo providerType={provider.providerType} style={{ width: '20px', height: '20px' }} />
              {provider.providerType}
              <If
                _if={provider.loginNeeded}
                _else={
                  <>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '30px',
                      }}
                    >
                      <ConnectedIndicator adsProvider={provider} />
                      {provider.providerType === 'FACEBOOK' && (
                        <AdsProviderReloginButton
                          account={props.account}
                          provider={props.adsProvider}
                        ></AdsProviderReloginButton>
                      )}
                    </div>
                    {provider.providerType === 'GOOGLE' && (
                      <div>Currency: {provider.externalAccountCurrencyCode || 'USD'}</div>
                    )}
                  </>
                }
              >
                {(c) => <NotConnectedIndicator adsProvider={provider} account={props.account} />}
              </If>
            </div>
            <div>
              <AdsProviderLogoutButton account={props.account} provider={provider}></AdsProviderLogoutButton>
            </div>
          </BoxShadow>
        ))}
      {adsProviders.length === 0 && <Empty />}
      <br />
      <div style={{ marginLeft: '1em' }}>
        <NewAdsProviderButton account={props.account} />
      </div>
    </Style>
  );
}
