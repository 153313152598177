import React, { Component, useRef, useState } from 'react';

import { Button, Form, Input } from 'antd';
import ReCAPTCHA from 'react-google-recaptcha';
import { Link } from 'react-router-dom';

import { resetPasswordFromCode } from 'lib/api';
import { signIn } from 'lib/auth';
import axios from 'lib/axios.factory';

import { useLoading } from '../../../hooks/useLoading';
import SignLayout from '../../layout/Sign/SignLayout';
import { ForgotPassword } from '../easy/images/ForgotPassword';

export function ResetPassword(props: {
  onSignIn: (user: userT) => void,
  email: string,
  resetCode: string,
  invite?: boolean,
}) {
  const [error, setError] = useState();
  const [sent, setSent] = useState();
  const { doAction, loading: sending } = useLoading(false);
  const { email, invite } = props;

  function handleSubmit(values) {
    doAction(async () => {
      try {
        await resetPasswordFromCode(localStorage, axios, {
          email,
          resetCode: props.resetCode,
          newPassword: values.newPassword,
          renewPassword: values.renewPassword,
        });
      } catch (error) {
        setError('Your email or password is incorrect.');
      }

      try {
        signIn(localStorage, axios, email, values.newPassword).then((response) => {
          props.onSignIn(response);
        });
      } catch (error) {
        setError(error.errorMessage);
      }
    });
  }

  const captchaRef = useRef(null);

  return (
    <SignLayout
      errorMessage={error}
      description={
        <div style={{ textAlign: 'center', width: '100%' }}>
          Not got an account yet?{' '}
          <Link className="highlight" to="/sign-up">
            Sign up for free
          </Link>
        </div>
      }
      title={
        <span>
          Password {invite ? 'creation' : 'reset'}
          <br />
          {email}
        </span>
      }
      image={<ForgotPassword />}
      footer={
        <span>
          <br />
          <br />
          Not got an account yet? <Link to="/sign-up">Sign up</Link>
          <br />
          <br />
          Have an account already? <Link to="/sign-in">Sign in</Link>
        </span>
      }
    >
      <Form onFinish={handleSubmit} layout="vertical">
        <Form.Item name="newPassword" label="Password" rules={[{ required: true, type: 'string' }]}>
          <Input.Password size="large" placeholder="***********" />
        </Form.Item>
        <Form.Item name="renewPassword" label="Re enter password" rules={[{ required: true, type: 'string' }]}>
          <Input.Password size="large" placeholder="***********" />
        </Form.Item>
        <Form.Item name="captcha">
          <ReCAPTCHA ref={captchaRef} sitekey={window.env.REACT_APP_CAPTCHA_SITE_KEY} />
        </Form.Item>
        <Button
          size="large"
          block
          htmlType="submit"
          type="primary"
          loading={sending}
          style={{ background: '#2E0077', borderColor: '#2E0077' }}
        >
          Reset password
        </Button>
      </Form>
    </SignLayout>
  );
}
