import React, { useEffect, useState } from 'react';

import { Alert, Button, ConfigProvider, Empty, Form, Modal, Table, Tooltip } from 'antd';
import './KeywordSuggestionsModal.css';
import styled from 'styled-components';

import { getCurrency } from 'utils/currency';

import { LOW_AMAZON_SEARCH_VOLUME, MIN_AMAZON_SEARCH_VOLUME } from './constants';
import { BarChart2Grey } from '../../../../icons/bar-chart-2_grey';
import axios from '../../../../lib/axios.factory';
import { getKeywordSuggestions } from '../../../../lib/suggestions';
import { CompetitorsSelector } from '../../../common/CompetitorsSelector';

import { BarChartOutlined, ExclamationCircleOutlined, SearchOutlined } from '@ant-design/icons';

import { MutedText } from '../../../common/MutedText';
import { KeywordFloatingCellAction } from '../common/KeywordFloatingCellAction';
import { productURL } from '../common/productURL';

import Icon from '@ant-design/icons/lib/components/Icon';

const BadgeContainer = styled.span`
  border-radius: 16px;
  background: #f2f4f7;
  display: flex;
  padding: 6px 8px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  color: #667085;
  width: 40px;
`;

const Section = ({ children, title, help, onEdit, style }) => (
  <div className="suggestions-section" style={style}>
    <div className="suggestions-section__title">
      {title}
      {help && (
        <a href={help} target="_blank" className="suggestions-section__title-help" rel="noreferrer">
          <ExclamationCircleOutlined />
        </a>
      )}
      {onEdit && (
        <span className="suggestions-section__title-edit" onClick={onEdit}>
          Edit {title}
        </span>
      )}
    </div>
    {children}
  </div>
);

const Product = (props: { marketplaceId: string, product: productInfoT }) => {
  const url = productURL(props.marketplaceId, props.product.asin);

  let asinEl = props.product.asin;

  if (url) {
    asinEl = (
      <a target="_blank" href={url} rel="noreferrer">
        {asinEl}
      </a>
    );
  }

  return (
    <Tooltip title={props.product.title} placement="bottomLeft">
      <div className="suggestions-product-info">
        <div
          className="suggestions-product-info__img"
          style={{ backgroundImage: `url(${props.product.imageUrl})` }}
        ></div>
        <span className="suggestions-product-info__asin">{asinEl}</span>
      </div>
    </Tooltip>
  );
};

export const KeywordSuggestionsModal = (props: {
  account: accountT,
  product: productT,
  adsProvider: adsProviderT,
  selectedKeywords: string[],
  dailyBudget: number,
  languages: number[],
  locations: number[],
  visible: boolean,
  onFinish: () => void,
  onKeywordAdd: (kw: string) => void,
  onKeywordRemove: (kw: string) => void,
  onCompetitorsSelected: (competitors: productInfoT[]) => void,
}) => {
  const [fetchingSuggestions, setFetchingSuggestions] = useState(true);
  const [editingCompetitorAsins, setEditingCompetitorAsins] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [competitors, setCompetitors] = useState([]);
  const [error, setError] = useState(false);

  const myProductInfo = {
    asin: props.product?.externalId,
    title: props.product?.name,
    imageUrl: props.product?.productImageUrl,
    productUrl: props.product?.productUrl,
  };

  const customRefresher = JSON.stringify([myProductInfo, props.product?.competitors, props.visible]);

  const { currencySymbol } = getCurrency(props.account);

  const loadSuggestions = async (totalTimeInSeconds = 0) => {
    // Timeout after 3 minutes
    if (totalTimeInSeconds > 180) {
      setError(true);

      return;
    }

    try {
      const response = await getKeywordSuggestions(
        localStorage,
        axios,
        props.product.providerId,
        props.product.externalId,
        {
          accountId: props.account.id,
          adsProviderId: props.adsProvider.id,
          dailyBudget: props.dailyBudget,
          languages: props.languages,
          locations: props.locations,
        },
      );

      if (response.fetchStartedAt !== null) {
        // pull the data every 2 seconds, until it gets generated
        setTimeout(() => loadSuggestions(totalTimeInSeconds + 2), 2000);

        return;
      }

      setSuggestions(response.keywords);
      setFetchingSuggestions(false);
    } catch (e) {
      console.log('failed to fetch suggestions', e);
      setError(true);
      setFetchingSuggestions(false);
    }
  };

  useEffect(() => {
    // Only fetch suggestions when modal is open
    if (!props.visible) {
      return;
    }

    setCompetitors(props.product?.competitors || []);

    setSuggestions([]);
    setError(false);
    setFetchingSuggestions(true);

    if (props.product?.competitors !== null && props.product?.competitors?.length > 0) {
      loadSuggestions();
    } else {
      setEditingCompetitorAsins(true);
    }
  }, [customRefresher]);

  if (!props.product) {
    return <></>;
  }

  const columns = [
    {
      title: 'Rank',
      dataIndex: 'rank',
      key: 'rank',
      width: 70,
      sorter: (a, b) => a.rank - b.rank,
    },
    {
      title: 'Keyword',
      dataIndex: 'value',
      key: 'value',
      className: 'keyword',
      width: 170,
      render: (value) => {
        if (value === null) {
          return 'N/A';
        }

        return <KeywordFloatingCellAction keyword={value} marketplaceId={props.product.marketplaceId} />;
      },
    },
    {
      title: <span className="amazon">Amazon Search Volume</span>,
      dataIndex: 'monthlyAmazonImpressions',
      sorter: (a, b) => a.monthlyAmazonImpressions - b.monthlyAmazonImpressions,
      render: (v) => {
        if (v === null) {
          return 'N/A';
        }

        if (v < LOW_AMAZON_SEARCH_VOLUME) {
          return (
            <span style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
              {v.toLocaleString('en-US', { maximumFractionDigits: 0 })}{' '}
              <Tooltip title="Low Search Volume">
                <BadgeContainer>
                  <ExclamationCircleOutlined />
                  <SearchOutlined />
                </BadgeContainer>
              </Tooltip>
            </span>
          );
        } else {
          return v.toLocaleString('en-US', { maximumFractionDigits: 0 });
        }
      },
    },
    {
      title: <span className="amazon">Amazon Current Rank</span>,
      dataIndex: 'myRank',
      key: 'myRank',
      sorter: (a, b) => a.myRank - b.myRank,
    },
    {
      title: <span className="amazon">Amazon Competitor Rank</span>,
      dataIndex: 'minRank',
      key: 'minRank',
      sorter: (a, b) => a.minRank - b.minRank,
      render: (v) => {
        if (v) {
          return v;
        } else {
          return (
            <span>
              <Tooltip title="No Competitor Rank">
                <BadgeContainer>
                  <ExclamationCircleOutlined />
                  <Icon component={BarChart2Grey} />
                </BadgeContainer>
              </Tooltip>
            </span>
          );
        }
      },
    },
    {
      title: 'Google Est. Cost per Click',
      sorter: (a, b) => a.topOfPageBidLow - b.topOfPageBidLow,
      render: (_, record) => {
        if (record.topOfPageBidLow === null) {
          return 'N/A';
        }

        return `${currencySymbol + record.topOfPageBidLow.toFixed(2)} - ${record.topOfPageBidHigh.toFixed(2)}`;
      },
    },
    {
      title: 'Google Search Volume',
      dataIndex: 'monthlyGoogleImpressions',
      sorter: (a, b) => a.monthlyGoogleImpressions - b.monthlyGoogleImpressions,
      render: (_, record) => {
        if (record.monthlyGoogleImpressions === null) {
          return 'N/A';
        }

        if (record.monthlyGoogleImpressions === 0 && record.monthlyAmazonImpressions > MIN_AMAZON_SEARCH_VOLUME) {
          return 'N/A';
        } else {
          return record.monthlyGoogleImpressions.toLocaleString('en-US', {
            maximumFractionDigits: 0,
          });
        }
      },
    },
    {
      title: '',
      key: 'actions',
      render: (_, record) => {
        if (props.selectedKeywords.includes(record.value)) {
          return (
            <span style={{ color: '#E1473D', cursor: 'pointer' }} onClick={() => props.onKeywordRemove(record.value)}>
              Remove
            </span>
          );
        } else {
          return (
            <span style={{ color: '#1890FF', cursor: 'pointer' }} onClick={() => props.onKeywordAdd(record.value)}>
              Add
            </span>
          );
        }
      },
    },
  ];

  return (
    <Modal
      visible={props.visible}
      layout="vertical"
      title="View Suggested Keywords"
      width={880}
      footer={[
        <div
          style={{
            backgroundColor: '#FAFAFA',
            color: '#000000',
            float: 'left',
            fontSize: '12px',
            padding: '0.5em 1em',
          }}
        >
          Need help selecting keywords?{' '}
          <a
            href="https://go.oncehub.com/PixelMeFastTrack"
            target="_blank"
            style={{ color: '#000000', textDecoration: 'underline' }}
            rel="noreferrer"
          >
            Schedule a call
          </a>{' '}
          so we can help!
        </div>,
        <Button key="back" onClick={props.onFinish}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={props.onFinish}>
          OK
        </Button>,
      ]}
      onCancel={props.onFinish}
      onOk={props.onFinish}
    >
      <Section title="Your ASIN">
        <Product marketplaceId={props.product.marketplaceId} product={myProductInfo} />
      </Section>

      <Section
        title="Competitor ASINs"
        help="https://help.pixelme.me/en/articles/6873512-how-to-find-competing-asins"
        onEdit={editingCompetitorAsins ? null : () => setEditingCompetitorAsins(true)}
      >
        <MutedText>Enter one ASIN at a time. Up to five competitor ASIN(s) to your product</MutedText>
        {!editingCompetitorAsins && (
          <div className="competitors-list">
            {(props.product.competitors || []).map((p) => (
              <Product key={p.asin} marketplaceId={props.product.marketplaceId} product={p} />
            ))}
          </div>
        )}
        {editingCompetitorAsins && (
          <Form
            initialValues={props.product}
            onValuesChange={(values) => setCompetitors(values.competitors)}
            requiredMark="optional"
            style={{ height: 'calc( 100% - 50px)' }}
            layout="vertical"
          >
            <div style={{ display: 'flex', width: '100%' }}>
              <CompetitorsSelector
                style={{ flex: 1, marginRight: '10px' }}
                marketplaceId={props.product.marketplaceId}
              />
              <Button
                style={{ flexBasis: '180px', height: '40px' }}
                type="primary"
                ghost
                onClick={() => {
                  props.onCompetitorsSelected(competitors);

                  if (competitors.length > 0) {
                    setEditingCompetitorAsins(false);
                  }
                }}
              >
                Save & Find Keywords
              </Button>
            </div>
          </Form>
        )}
      </Section>

      <div style={{ display: 'flex', gap: '2em', marginBottom: '1em' }}>
        <h3
          style={{
            fontSize: '16px',
            color: 'rgba(0,0,0,0.85)',
            whiteSpace: 'nowrap',
            fontWeight: 500,
          }}
        >
          Suggested Keywords
        </h3>
        {(props.product.competitors || []).length > 0 && (
          <div
            style={{
              background: '#FAFAFA',
              color: '#000',
              fontSize: '12px',
              padding: '5px',
            }}
          >
            Here’s our list of the <b>best keyword opportunities</b> to rank with Google Ads. We searched your listing
            on Amazon, your competitors listing on Amazon and the Google Ads Platform to find you the best keywords to
            use.
          </div>
        )}
      </div>

      {(props.product.competitors || []).length <= 0 && (
        <div style={{ color: '#595959', paddingLeft: '2em', paddingBottom: '5em' }}>
          We will need at least one (up to five) competitor ASIN to suggest quality keywords. <br />
          Enter competitor ASIN(s) above and click “Save & Find Keywords”
        </div>
      )}

      {(props.product.competitors || []).length > 0 && (
        <ConfigProvider
          renderEmpty={() =>
            error ? (
              <Alert
                message="We were unable to generate keyword suggestions at the moment, please try again later."
                type="error"
              />
            ) : (
              <Empty description="No keyword suggestions at the moment" />
            )
          }
        >
          <Table
            rowKey="value"
            tableLayout="fixed"
            loading={fetchingSuggestions && !error}
            dataSource={suggestions}
            pagination={{
              defaultPageSize: 25,
              hideOnSinglePage: true,
              showSizeChanger: true,
            }}
            rowClassName={(record, index) => (props.selectedKeywords.includes(record.value) ? 'row-active' : '')}
            columns={columns}
          />
        </ConfigProvider>
      )}
    </Modal>
  );
};
