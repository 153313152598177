import React, { useEffect, useState } from 'react';

import rename from 'deep-rename-keys';

import { useLoading } from 'hooks/useLoading';
import axios from 'lib/axios.factory';
import { getTiktokTools } from 'lib/tiktokPlateformApi';

import { TiktokGenericSelector } from './TiktokGenericSelector';
import { buildActionCategoriesTree } from '../buildTree';

/**
 * Tiktok Action Categories selector
 * https://ads.tiktok.com/marketing_api/docs?id=1701890992972802
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export function ActionCategoriesSelector(props: {
  account: accountT,
  adsProvider: adsProviderT,
  actionScene: 'CREATOR_RELATED' | 'VIDEO_RELATED',
  value: any,
  onChange: any,
  defaultValue: any,
}) {
  const [treeData, setTreeData] = useState([]);
  const [flatData, setFlatData] = useState([]);
  const { doAction, ExclusiveInlineLoadingContainer } = useLoading(true);

  async function load() {
    const genericeResponse = await getTiktokTools(
      localStorage,
      axios,
      props.adsProvider,
      { accountId: props.account.id },
      'action_category',
    );

    setFlatData(genericeResponse.actionCategories.filter((el) => el.actionScene === props.actionScene));
    const d = buildActionCategoriesTree(genericeResponse.actionCategories, props.actionScene);

    setTreeData(d);
  }

  useEffect(() => {
    doAction(load);
  }, [JSON.stringify(props.adsProvider)]);

  return (
    <ExclusiveInlineLoadingContainer>
      <TiktokGenericSelector
        account={props.account}
        value={props.value}
        defaultValue={props.defaultValue}
        onChange={props.onChange}
        treeData={rename(treeData, (key) => (key === 'id' ? 'key' : key === 'name' ? 'title' : key))}
        flatData={rename(flatData, (key) => (key === 'id' ? 'key' : key === 'name' ? 'title' : key))}
        adsProvider={props.adsProvider}
      />
    </ExclusiveInlineLoadingContainer>
  );
}
