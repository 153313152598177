import React, { useState, useEffect } from 'react';

import { MenuItem } from '@blueprintjs/core';

import { Delete } from 'components/common/icons';
import { Settings } from 'components/common/icons/Settings';
import { strings } from 'locales/strings';

import {
  addRotatorInRedirect,
  computePercents,
  percentToVal,
  redirectFromState,
  updateUrlInRedirect,
  delRotatorInRedirect,
} from './rotatorUtils';
import { ComposerTitle } from '../ComposerTitle';
import { InputWithLabel } from '../InputWithLabel';
// import { handleChangeUtm } from "./composerEditUtils";

export function ComposerEditRotatorRedirects(props) {
  // const { onChange, account, localUpdateOfAccount, redirect } = props;
  const { redirect: sRedirect } = props;
  const [isDropDownOpen, setDropDownOpen] = useState(false);
  const [redirect, setRedirect] = useState(redirectFromState(sRedirect));

  useEffect(() => {
    setRedirect(redirectFromState(sRedirect));
  }, [sRedirect]);

  return (
    <div className="composer__line composer__dropdowns">
      <ComposerTitle
        renderIcon={() => <Settings />}
        title={strings.get('composer.rotatorRedirect.title')}
        setDropDownOpen={setDropDownOpen}
        showToggle={true}
        // help={strings.get("composer.utms.help")}
      />
      {isDropDownOpen && (
        <>
          <RotatorUrls
            rotatorUrls={redirect.rotatorUrls}
            onChange={function ({ idx, url, percent }) {
              // setRedirect(updateUrlInRedirect(redirect, idx, { url, percent }));
              props.onChange({
                redirect: updateUrlInRedirect(redirect, idx, { url, percent }),
              });
            }}
            onDelete={function (idx) {
              props.onChange({ redirect: delRotatorInRedirect(redirect, idx) });
            }}
          />
          <div key={-1} className="composer__rotator">
            <MenuItem
              icon="add"
              className="bp3-add-option"
              text="Add a new URL"
              style={{ color: 'var(--text-muted)' }}
              onClick={function () {
                // setRedirect(addRotatorInRedirect(redirect));
                props.onChange({
                  redirect: addRotatorInRedirect(redirect),
                });
              }}
              // href={`/${account.id}/ab/settings/domains`}
            />
          </div>
        </>
      )}
    </div>
  );
}

function RotatorUrls({ rotatorUrls, onChange, onDelete }) {
  const withPercent = computePercents(rotatorUrls);

  return (
    <>
      {withPercent.map(({ url, percent }, idx) => (
        <RotatorItem
          key={idx}
          idx={idx}
          url={url}
          disabled={idx === 0}
          percent={percent}
          onChange={onChange}
          onDelete={onDelete}
        />
      ))}
    </>
  );
}

function RotatorItem({ idx, disabled, url, percent, onChange, onDelete }) {
  const [{ url: lUrl, percent: lPercent }, setRotatorItem] = useState({
    url,
    percent,
  });

  useEffect(() => {
    setRotatorItem({ url, percent });
  }, [url, percent]);

  return (
    <div className="composer__rotator">
      <InputWithLabel
        displayLabel
        name={`url${idx}`}
        placeholder="enter URL"
        disabled={disabled}
        title={
          <div className="title-with-delete">
            <span>URL {idx + 1}</span>
            {!disabled && (
              <Delete
                className="delete-icon"
                onClick={function () {
                  onDelete(idx);
                }}
              />
            )}
          </div>
        }
        value={lUrl}
        onChange={function (event) {
          setRotatorItem({ url: event.target.value, percent: lPercent });
        }}
        onBlur={function () {
          onChange({ idx, url: lUrl, percent: lPercent });
        }}
      />
      <InputWithLabel
        displayLabel
        name={`percent${idx}`}
        placeholder="percent"
        title="Percentage of the traffic"
        value={lPercent}
        onChange={function (event) {
          let val = event.target.value;

          if (isNaN(percentToVal(val))) {
            val = percent;
          }

          setRotatorItem({ url: lUrl, percent: val });
        }}
        onBlur={function () {
          onChange({ idx, url: lUrl, percent: lPercent });
        }}
      />
    </div>
  );
}
