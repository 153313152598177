import './TeamMembersList.css';

import React from 'react';

import { strings } from 'locales/strings';

import { Me } from './Me';
import Box from '../../../common/Box';
import { LightButton } from '../../../common/Button';
import DeleteItem from '../../../common/DeleteItem';

export const TeamMembersList = ({
  account,
  members,
  owner,
  user,
  handleResend,
  handleDelete,
  allowToDelete,
}: {
  account: accountT,
  members: Array<memberT>,
  owner?: memberT,
  user: memberT,
  handleResend: (user: memberT) => void,
  handleDelete: (user: memberT) => void,
  allowToDelete: boolean,
}) => (
  <Box className="team-members__list" header={<div className="team-members__list__title">My team members</div>}>
    {members.length === 1 && 'No member'}
    {members.length > 1 && (
      <div className="team-members__list--container">
        <div className="team-members__list__header team-members__list__row">
          <div>{strings.get('members.list.name')}</div>
          <div>{strings.get('members.list.email')}</div>
          <div className="team-members__list__row--validated">
            <div>{strings.get('members.list.signedUp')}</div>
          </div>
          <div className="team-members__list__row--del">{strings.get('members.list.delete')}</div>
        </div>
        {members.map((member) => {
          if (owner && owner.email === member.email) {
            return null;
          }

          return (
            <div key={member.id} className="team-members__list__row">
              <div>
                {member.fullname}
                {member.email === user.email && <Me />}
              </div>
              <div>{member.email}</div>
              <div className="team-members__list__row--validated">
                {member.accepted && member.validated && <div>Yes</div>}
                {(!member.accepted || !member.validated) && (
                  <div>
                    <span className="team-members__list__row--no">No</span> -{' '}
                    <LightButton handleClick={() => handleResend(member)}>Resend invitation?</LightButton>
                  </div>
                )}
              </div>
              <div className="team-members__list__row--del">
                {!!allowToDelete && <DeleteItem handleClick={() => handleDelete(member)} />}
              </div>
            </div>
          );
        })}
      </div>
    )}
  </Box>
);
