import React, { useEffect, useState } from 'react';

import rename from 'deep-rename-keys';

import { useLoading } from 'hooks/useLoading';
import axios from 'lib/axios.factory';
import { getTiktokTools } from 'lib/tiktokPlateformApi';

import { TiktokGenericSelector } from './TiktokGenericSelector';
import { buildDeviceModelsTree } from '../buildTree';

/**
 * Tiktok Mobile Devices selector
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export function DevicesSelector(props: {
  account: accountT,
  adsProvider: adsProviderT,
  value: any,
  onChange: any,
  defaultValue: any,
}) {
  const [treeData, setTreeData] = useState([]);
  const [flatData, setFlatData] = useState([]);
  const { doAction, ExclusiveInlineLoadingContainer } = useLoading(true);

  async function load() {
    const devicesResponse = await getTiktokTools(
      localStorage,
      axios,
      props.adsProvider,
      { accountId: props.account.id },
      'device_model',
    );
    const d = buildDeviceModelsTree(devicesResponse.deviceModels);

    setFlatData(devicesResponse.deviceModels);
    setTreeData(d);
  }

  useEffect(() => {
    doAction(load);
  }, [JSON.stringify(props.adsProvider)]);

  return (
    <ExclusiveInlineLoadingContainer>
      <TiktokGenericSelector
        account={props.account}
        value={props.value}
        defaultValue={props.defaultValue}
        onChange={props.onChange}
        treeData={rename(treeData, (key) => (key === 'id' ? 'key' : key === 'name' ? 'title' : key))}
        flatData={rename(flatData, (key) => (key === 'id' ? 'key' : key === 'name' ? 'title' : key))}
        adsProvider={props.adsProvider}
      />
    </ExclusiveInlineLoadingContainer>
  );
}
