import audienceBuilder from './audienceBuilder';
import signup from './signup.actions';
import { WorkerAction } from '../framework/workerAction';

export default {
  signup,
  audienceBuilder,
  lastVisitedURL: new WorkerAction({ name: 'Actions.front.lastVisitedURL' }, {}),
  initLoading: new WorkerAction({ name: 'FRONT_INIT_LOADING' }, {}),
};
