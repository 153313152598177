import { Button, Empty } from 'antd';
import styled from 'styled-components';

import { strings } from 'locales/strings';

import { AuditResultsExpanded } from './AuditResultsExpanded';
import { GridCell, MultilineEllipsis } from './constants';
import { ProductImage } from './ProductImage';

const Style = styled.div`
  border-radius: 2px;
  border: 1px solid #d9d9d9;
`;

const ProductSummary = styled.div`
  padding: 1rem;
  background-color: #e6ebff;
  display: grid;
  gap: 0.5rem 1.5rem;
  grid-template-columns: max-content 1fr;
  grid-template-areas:
    'image title'
    'image asin'
    'image brand';
`;

const ExternalLink = styled(Button).attrs({ type: 'link' })`
  font-size: 1rem;
  font-weight: 500;
  padding: 0;
  text-align: left;
  height: max-content;
`;

const ExpandedContainer = styled.div`
  padding: 1rem;
  background-color: #f5f7ff;
`;

/** Displays a unique view with expanded audit results for a single ASIN */
export const AuditResultsSingleAsin = ({ auditItem }) => {
  if (!auditItem) {
    return <Empty description={strings.get('asinAudit.noResults')} style={{ marginTop: '5rem' }} />;
  }

  const { product = {} } = auditItem;

  return (
    <Style>
      <ProductSummary>
        <GridCell $name="image">
          <ProductImage src={product.imageUrl} width={100} height={100} />
        </GridCell>
        <GridCell $name="title">
          <ExternalLink href={product.url} target="_blank" rel="noreferrer">
            <MultilineEllipsis $lines={2}>
              {product.title || strings.get('asinAudit.product.defaultTitle')}
            </MultilineEllipsis>
          </ExternalLink>
        </GridCell>
        <GridCell $name="asin">
          <span>{strings.get('asinAudit.asin.label')}: </span>
          <span>{product.asin}</span>
        </GridCell>
        <GridCell $name="brand">
          <span>{strings.get('asinAudit.product.brand')}: </span>
          <span>{product.brand || 'N/A'}</span>
        </GridCell>
      </ProductSummary>
      <ExpandedContainer>
        <AuditResultsExpanded auditItem={auditItem} />
      </ExpandedContainer>
    </Style>
  );
};
