import React from 'react';

import { strings } from 'locales/strings';

import { BarToolTip } from '../BarToolTip';

const Settings = () => (
  <BarToolTip title={strings.get('head.settings')}>
    <svg
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      className="bar__icon"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="33" height="33" rx="4" className="icon-background" />
      <g transform="translate(1.5 2)">
        <path
          d="M25.3104 25.5078C25.2564 22.0511 24.1795 18.908 22.2648 16.6324C20.3613 14.3706 17.7815 13.125 15 13.125C12.2184 13.125 9.63865 14.3706 7.73511 16.6324C5.82226 18.9056 4.74607 22.0443 4.68982 25.4967C5.77146 26.0385 10.2474 28.125 15 28.125C20.1391 28.125 24.3073 26.0508 25.3104 25.5078Z"
          className="icon-main-fill"
        />
        <path
          d="M15 12.1875C17.8477 12.1875 20.1562 9.87897 20.1562 7.03125C20.1562 4.18353 17.8477 1.875 15 1.875C12.1523 1.875 9.84375 4.18353 9.84375 7.03125C9.84375 9.87897 12.1523 12.1875 15 12.1875Z"
          className="icon-main-fill"
        />
      </g>
    </svg>
  </BarToolTip>
);

export default Settings;
