import React from 'react';

import { MenuItem } from '@blueprintjs/core';

import { WorldWideWeb } from 'components/common/icons/WorldWideWeb';
import { strings } from 'locales/strings';

import { handleChange } from './composerEditUtils';
import { getListItemFromString, getListItems } from '../../../../../../selectors/domains.selector';
import { ComposerTitle } from '../ComposerTitle';
import { DropDownWithLabel } from '../DropDownWithLabel';

export function ComposerEditDomain(props) {
  const { account, redirect } = props;

  const domains = [...account.domains];

  if (!redirect.domain) {
    handleChange([{ name: 'domain', value: getListItems(domains)[0].id }], props);
  }

  return (
    <>
      <div className="composer__line composer__dropdowns">
        <ComposerTitle
          renderIcon={() => <WorldWideWeb />}
          style={{ gridArea: 'domain-title' }}
          title={strings.get('composer.domains.title')}
          help={strings.get('composer.domains.help')}
        />
        <div
          className="composer__line__after_title"
          style={{
            alignItems: 'flex-end',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <DropDownWithLabel
            values={domains ? getListItems(domains) : []}
            filterable={false}
            selected={getListItemFromString(redirect.domain)}
            title="Select a domain"
            placeholder="Select a domain"
            onSelect={(selectedDomain) => handleChange([{ name: 'domain', value: selectedDomain.id }], props)}
          />
          <MenuItem
            icon="add"
            className="bp3-add-option"
            text="Add a new Domain"
            style={{ color: 'var(--text-muted)' }}
            href={`/${account.id}/ab/settings/domains`}
          />
        </div>
      </div>
    </>
  );
}
