import React, { Fragment, useEffect, useState } from 'react';

import { Alert, Modal } from 'antd';

import type { accountT } from '../../../../../flow-typed/pixelme/types';
import { getAdsProviders } from '../../../../lib/adsProvider/google';
import { AdsProviderAccounts } from '../Google/AdsProviderAccounts';

let alreadyClose = false;

export function AdsProviderReLoginModal(props: { account: accountT }) {
  const [visible, setVisible] = useState(false);
  const adsProvidersLoginNeeded =
    getAdsProviders(props.account).filter((p) => p.loginNeeded).length > 0 && !alreadyClose;

  useEffect(() => {
    setVisible(adsProvidersLoginNeeded);
  }, []);

  return (
    <Modal
      visible={visible}
      okButtonProps={{ disabled: true }}
      title="Ads account was disconnected"
      width="680px"
      onCancel={() => {
        alreadyClose = true;
        setVisible(false);
      }}
    >
      <Fragment>
        <br />
        <Alert
          type="error"
          message="Your ads account was disconnected by Facebook. Please reconnect it."
          description={
            <>
              <span>If the problem persists, please contact us.</span>
            </>
          }
        ></Alert>
        <br />
        <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
          <AdsProviderAccounts onlyLoginNeeded account={props.account} />
        </div>
      </Fragment>
    </Modal>
  );
}
