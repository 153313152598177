import React, { Fragment } from 'react';

import { Statistic, Tooltip } from 'antd';

import { BrbNotOnboarded, BrbValue } from './BrbValue';

export function AmazonAdCostStats(props: { cost: number, currency: string }) {
  return <Statistic title="Amazon Ad Cost" value={props.cost} precision={2} prefix={props.currency} />;
}

export function TotalAdCostStats(props: { cost: number, currency: string }) {
  return <Statistic title="Total Ad Cost" value={props.cost} precision={2} prefix={props.currency} />;
}

export function TotalSalesStats(props: { sales: number, currency: string }) {
  return <Statistic title="Total Sales" value={props.sales} precision={2} prefix={props.currency} />;
}

export function OrganicSalesStats(props: { sales: number, currency: string }) {
  return <Statistic title="Organic Sales" value={props.sales} precision={2} prefix={props.currency} />;
}

export function TacosStats(props: { tacos: number }) {
  return (
    <Statistic
      title="TACOS"
      value={props.tacos}
      valueStyle={{ color: props.tacos <= 100 ? '#0F993E' : 'inherit' }}
      precision={0}
      suffix="%"
    />
  );
}

export function ImpressionsStats(props: { impressions: number }) {
  return (
    <Tooltip placement="bottom" title="Data coming from ad platforms. Can take a few hours to be updated.">
      <Statistic title="Impressions ℹ" value={props.impressions} />
    </Tooltip>
  );
}

export function ClicksStats(props: { clicks: number }) {
  return (
    <Tooltip placement="bottom" title="Data coming from ad platforms. Can take a few hours to be updated.">
      <Statistic title="Clicks ℹ" value={props.clicks} />
    </Tooltip>
  );
}

export function TotalCostStats(props: { cost: number, currency: string }) {
  return (
    <Tooltip placement="bottom" title="Data coming from ad platforms. Can take a few hours to be updated.">
      <Statistic title="Total Ad Cost ℹ" precision={2} prefix={props.currency} value={props.cost} />
    </Tooltip>
  );
}

export function PurchasesStats(props: { purchases: number }) {
  return (
    <Tooltip placement="bottom" title="Data coming from Amazon Attribution. Can take up to 3 days to be updated.">
      <Statistic title="Purchases ℹ" precision={0} value={props.purchases} />
    </Tooltip>
  );
}

export function AddToCardStats(props: { adds: number }) {
  return (
    <Tooltip placement="bottom" title="Data coming from Amazon Attribution. Can take up to 3 days to be updated.">
      <Statistic title="Add To Cart ℹ" precision={0} value={props.adds} />
    </Tooltip>
  );
}

export function RevenueStats(props: { revenue: number, currency: string }) {
  return (
    <Tooltip placement="bottom" title="Data coming from Amazon Attribution. Can take up to 3 days to be updated.">
      <Statistic title="Revenue ℹ" precision={2} prefix={props.currency} value={props.revenue} />
    </Tooltip>
  );
}

export function NetMarginStats(props: { netMargin: number, currency: string }) {
  return (
    <Tooltip placement="bottom" title="Data calculated by PixelMe based on the product created.">
      <Statistic title="NetMargin ℹ" precision={2} prefix={props.currency} value={props.netMargin} />
    </Tooltip>
  );
}

export function ROASStats(props: { roas: number }) {
  let { roas } = props;

  if (roas < 0) {
    roas = 0;
  }

  return (
    <Tooltip
      placement="bottom"
      title="Return on Ad Spend (ROAS) calculates how much revenue was generated from a specific ad or campaign."
    >
      <Statistic
        title="ROAS ℹ"
        precision={0}
        valueStyle={{ color: roas < 100 ? 'inherit' : '#0F993E' }}
        value={roas < 1000000 ? roas.toFixed(2) : '∞'}
        suffix="%"
      />
    </Tooltip>
  );
}

export function ACOSStats(props: { acos: number }) {
  let { acos } = props;

  if (acos < 0) {
    acos = 0;
  }

  return (
    <Tooltip
      placement="bottom"
      title="Advertising Cost of Sales (ACOS), is how much you spend on advertising per dollar of revenue you make."
    >
      <Statistic
        title="ACOS ℹ"
        precision={0}
        valueStyle={{ color: acos > 100 ? 'inherit' : '#0F993E' }}
        value={acos}
        suffix="%"
      />
    </Tooltip>
  );
}

export function ReferralBonusStats(props: {
  brbBonusAmount: number,
  brbBonusAmountCalculated: number,
  currencySymbol: string,
  currencyCode: string,
  brbEnrolled: boolean,
}) {
  return (
    <Fragment>
      {/* props.brbEnrolled  === undefined => non US Marketplace so we display the calculated Amount that is inside brbBonusAmount*/}
      {/* TODO change brbBonusAmountCalculated to brbBonusAmount when we will have the explanation from Amazon*/}
      {props.brbEnrolled !== false && (
        <Tooltip placement="bottom" title="On average, Brands can earn a 10% bonus from their qualifying sales">
          <Statistic
            style={{ width: '100%', minWidth: '' }}
            title={
              <span>
                Average Brand
                <br /> Referral Bonus ℹ
              </span>
            }
            precision={2}
            prefix={props.currency}
            value={BrbValue.formatCurrency(props.brbBonusAmountCalculated, props.currencyCode)}
          />
        </Tooltip>
      )}
      {props.brbEnrolled === false && (
        <BrbNotOnboarded
          brbBonusAmountCalculated={props.brbBonusAmountCalculated}
          style={{
            color: 'var(--bad-color)',
            width: '120px',
            padding: '0 !important',
            paddingLeft: 5,
            paddingRight: 5,
          }}
          currencyCode={props.currencyCode}
        />
      )}
    </Fragment>
  );
}
