import moment from 'moment';

import { strings } from 'locales/strings';

export const LIFETIME_START_DATE = '2022-01-01';
export const DATE_FORMAT = 'YYYY-MM-DD';

export const RANGES: { [key: string]: string } = {
  LAST_7D: strings.get('global.dates.day7'),
  LAST_1W: strings.get('global.dates.week1'),
  LAST_14D: strings.get('global.dates.day14'),
  LAST_30D: strings.get('global.dates.day30'),
  LAST_1M: strings.get('global.dates.month1'),
  LAST_60D: strings.get('global.dates.day60'),
  LAST_90D: strings.get('global.dates.day90'),
  LAST_365D: strings.get('global.dates.day365'),
  LIFETIME: strings.get('global.dates.allPixelMe'),
};

export const COMPARISON_DEFAULT = 'Compared to the previous period';
export const COMPARISON_LABELS = {
  [RANGES.LAST_7D]: 'Compared to the previous 7 days',
  [RANGES.LAST_1W]: 'Compared to the previous week',
  [RANGES.LAST_14D]: 'Compared to the previous 14 days',
  [RANGES.LAST_30D]: 'Compared to the previous 30 days',
  [RANGES.LAST_1M]: 'Compared to the previous month',
  [RANGES.LAST_60D]: 'Compared to the previous 60 days',
  [RANGES.LAST_90D]: 'Compared to the previous 90 days',
  [RANGES.LAST_365D]: 'Compared to the previous 365 days',
  [RANGES.LIFETIME]: 'Compared to the PixelMe lifetime',
};

export const getDefaultRange = (): [moment$Moment, moment$Moment] => {
  const now = () => moment().utc();

  return [now().subtract(30, 'd').startOf('day'), now()];
};

export const getRanges = (): {
  [key: string]: [moment$Moment, moment$Moment],
} => {
  const now = () => moment().utc();

  return {
    [RANGES.LAST_7D]: [now().subtract(7, 'd').startOf('day'), now()],
    [RANGES.LAST_1W]: [
      now().subtract(1, 'week').startOf('day').startOf('week'),
      now().subtract(1, 'week').endOf('day').endOf('week'),
    ],
    [RANGES.LAST_14D]: [now().subtract(14, 'd').startOf('day'), now()],
    [RANGES.LAST_30D]: [now().subtract(30, 'd').startOf('day'), now()],
    [RANGES.LAST_1M]: [
      now().subtract(1, 'month').startOf('day').startOf('month'),
      now().subtract(1, 'month').endOf('day').endOf('month'),
    ],
    [RANGES.LAST_60D]: [now().subtract(60, 'd').startOf('day'), now()],
    [RANGES.LAST_90D]: [now().subtract(90, 'd').startOf('day'), now()],
    [RANGES.LAST_365D]: [now().subtract(365, 'd').startOf('day'), now()],
    [RANGES.LIFETIME]: [moment.utc(LIFETIME_START_DATE, DATE_FORMAT), now()],
  };
};
