import React, { Fragment, useEffect, useState } from 'react';

import { PauseCircleTwoTone, PlayCircleTwoTone } from '@ant-design/icons';
import { Card, Divider, message, notification, Table, Tooltip } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { MutedText } from 'components/common/MutedText';
import { DATE_FORMAT } from 'consts/consts';
import { getAllAdGroupsForCampaign, pauseCampaign, runCampaign } from 'lib/adsPlateformApi';
import axios from 'lib/axios.factory';
import { CAMPAIGN_AUTOMATIONS, GOOGLE_RMF, useFeatureFlag } from 'lib/configcat';
import { getCurrency } from 'utils/currency';

import { AutomationsButton } from './CampaignAutomations/AutomationsButton';
import { CloneCampaign } from './CloneCampaign';
import { CopyCampaignLink } from './CopyCampaignLink';
import { DebugCampaign } from './DebugCampaign';
import { DeleteCampaign } from './DeleteCampaign';
import { EditCampaign } from './EditCampaign';
import { CampaignAlertButton } from './InfoCampaign/CampaignAlertButton';
import { InfoCampaignButton } from './InfoCampaign/InfoCampaignButton';
import type { accountT } from '../../../../../../flow-typed/pixelme/types';
import {
  ACOSValue,
  CurrencyValue,
  NumberVal,
  ROASValue,
} from '../../../AudienceBuilder/Campaigns/campaigns/RedirectsListLine';
import { AdsProviderLogo } from '../../common/AdsProviderLogo';
import { AttributionSteps } from '../../common/AttributionSteps';
import { FloatingRowAction } from '../../common/FloatingRowAction';
import { HideIfNoAmazonData } from '../../common/HideIfNoAmazonData';
import { NAValue } from '../../common/NAValue';
import { RowCellWithSpanOverflow } from '../../common/RowCellWithSpanOverflow';
import SearchTermsCampaignView from '../../GoogleRMF/SearchTermsCampaignView';
import { NewCampaignButton } from '../../NewCampaign/NewCampaignButton';
import { BrbValue } from '../BrbValue';

const Style = styled.div`
  overflow: auto;
  max-width: 100%;
  background-color: white;

  .ant-table-pagination.ant-pagination {
    justify-content: left;
    margin-left: 1em;
  }

  .ant-table-thead > tr > th {
    text-align: center;
  }

  .ant-table-column-title {
    font-size: 13px;
  }
`;

const Value = styled.div`
  display: inline-block;
  max-width: 200px;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  color: #595959 !important;
  white-space: nowrap;
  wrap: none;
  text-overflow: ellipsis;
  overflow: hidden;

  .ant-table-thead > tr > th {
    text-align: center;
  }
`;

const EmptyText = styled.p`
  font-size: 1rem;
  margin: 1rem;
`;

const AlertContainer = styled.div`
  display: flex;
  column-gap: 0.25rem;
  align-items: center;
`;

function ICampaignsTable(props: {
  campaigns: campaignT[],
  allCampaigns: campaignT[],
  account: accountT,
  setSelectedRowKeys: any,
  productProviderId: string,
  productASIN: string,
  productId: string,
}) {
  const history = useHistory();
  const { currencyCode, currencySymbol } = getCurrency(props.account);
  const [shouldDisplaySearchTerms] = useFeatureFlag(GOOGLE_RMF);
  const [isAutomationsEnabled] = useFeatureFlag(CAMPAIGN_AUTOMATIONS);

  const [campaigns, setCampaigns] = useState(props.campaigns);

  useEffect(() => {
    setCampaigns(props.campaigns);
  }, [props.campaigns]);

  const onCampaignUpdate = (c: campaignT) => {
    setCampaigns(
      campaigns.map((e) => {
        if (e.id === c.id) {
          return { ...c };
        }

        return e;
      }),
    );
  };

  const columns = [
    {
      title: '',
      dataIndex: 'providerType',
      key: 'providerType',
      width: 55,
      render: (v) => (
        <div className="small-provider">
          <AdsProviderLogo providerType={v} />
        </div>
      ),
    },
    {
      title: 'Campaign Name',
      dataIndex: 'name',
      key: 'name',
      width: 300,
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (v, campaign) => (
        <AlertContainer>
          {v}
          {campaign.invalidNameDetectedAt && <CampaignAlertButton campaign={campaign} />}
        </AlertContainer>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 160,
      sorter: (a, b) => a.status - b.status,
      render: (v, campaign) => (
        <div
          style={{ display: 'flex', justifyContent: 'space-between' }}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          {v === -5 && <span style={{ color: '#FFEA00' }}>Waiting (import)</span>}
          {v === 1 && <span style={{ color: '#F8A51B' }}>Paused</span>}
          {v === 2 && <span style={{ color: 'red' }}>Deleted</span>}
          {v === 3 && <span style={{ color: 'red' }}>Rejected</span>}
          {(v === 4 || v === -10) && <span style={{ color: '#0F993E' }}>Active</span>}
          {v === -50 && <span style={{ color: 'red' }}>Import failed</span>}
          {v === -51 && <span style={{ color: '#F8A51B' }}>Import failed (retrying)</span>}
          {v === -101 && <span style={{ color: '#F8A51B' }}>Invalid Campaign (please verify URL)</span>}
          {v === 5 && <span style={{ color: '#0F993E' }}>Finished</span>}
          {campaign.status === 4 /* ACTIVE*/ && (
            <PauseCircleTwoTone
              style={{ fontSize: '16px' }}
              twoToneColor="#F8A51B"
              title="Pause Campaign"
              onClick={async (e) => {
                let updatedCampaigns = [...campaigns];

                updatedCampaigns.forEach((c, i) => {
                  if (c.id === campaign.id) {
                    updatedCampaigns[i].status = -10;
                  }
                });
                setCampaigns(updatedCampaigns);
                const campaignResult = await pauseCampaign(
                  localStorage,
                  axios,
                  { accountId: props.account.id },
                  campaign.id,
                );

                updatedCampaigns = [...campaigns];
                updatedCampaigns.forEach((c, i) => {
                  if (c.id === campaign.id) {
                    updatedCampaigns[i].status = campaignResult.campaign.status;
                  }
                });
                setCampaigns(updatedCampaigns);
                message.success('Campaign paused');
                e.stopPropagation();
                e.preventDefault();
              }}
            />
          )}
          {campaign.status === 1 /* PAUSED*/ && (
            <PlayCircleTwoTone
              style={{ fontSize: '16px' }}
              twoToneColor="#52c41a"
              title="Launch Campaign"
              onClick={async (e) => {
                let updatedCampaigns = [...campaigns];

                updatedCampaigns.forEach((c, i) => {
                  if (c.id === campaign.id) {
                    updatedCampaigns[i].status = -10;
                  }
                });
                setCampaigns(updatedCampaigns);
                const campaignResult = await runCampaign(
                  localStorage,
                  axios,
                  { accountId: props.account.id },
                  campaign.id,
                );

                updatedCampaigns = [...campaigns];
                updatedCampaigns.forEach((c, i) => {
                  if (c.id === campaign.id) {
                    updatedCampaigns[i].status = campaignResult.campaign.status;
                  }
                });
                setCampaigns(updatedCampaigns);
                message.success('Campaign Launched');
                e.stopPropagation();
                e.preventDefault();
              }}
            />
          )}
        </div>
      ),
    },
    {
      title: 'Impressions',
      dataIndex: 'impressions',
      key: 'impressions',
      width: 120,
      render: (v, campaign) => {
        if (!campaign.lastClicks && campaign.status > 0) {
          return (
            <RowCellWithSpanOverflow>
              <AttributionSteps report={campaign} />
            </RowCellWithSpanOverflow>
          );
        }

        return campaign.status < 0 || campaign.providerType === 'GENERIC' ? <></> : <NumberVal clicks={v}></NumberVal>;
      },
      sorter: (a, b) => a.impressions - b.impressions,
    },
    {
      title: 'Clicks',
      dataIndex: 'clicks',
      key: 'clicks',
      width: 120,
      render: (v, campaign) => (
        <HideIfNoAmazonData report={campaign}>
          {campaign.status < 0 ? <NAValue /> : <NumberVal clicks={v}></NumberVal>}
        </HideIfNoAmazonData>
      ),
      sorter: (a, b) => a.clicks - b.clicks,
    },
    {
      title: 'Amazon Clickthroughs',
      dataIndex: 'amazonClickThroughs',
      key: 'amazonClickThroughs',
      hidden: true,
      width: 120,
      render: (v, campaign) => (
        <HideIfNoAmazonData report={campaign}>
          <Fragment>
            <NumberVal clicks={v}></NumberVal>
          </Fragment>
        </HideIfNoAmazonData>
      ),
      sorter: (a, b) => a.clicks - b.clicks,
    },
    {
      title: 'Ad Spend',
      dataIndex: 'adSpent',
      key: 'adSpent',
      width: 140,
      sorter: (a, b) => a.adSpent - b.adSpent,
      render: (v, campaign) => (
        <HideIfNoAmazonData report={campaign}>
          {campaign.status > 0 ? <CurrencyValue amount={v} currency={currencySymbol}></CurrencyValue> : <NAValue />}
        </HideIfNoAmazonData>
      ),
    },
    {
      title: 'CPC',
      dataIndex: 'averageCpc',
      key: 'averageCpc',
      width: 140,
      sorter: (a, b) => a.averageCpc - b.averageCpc,
      render: (v, campaign) => (
        <HideIfNoAmazonData report={campaign}>
          <Fragment>
            <CurrencyValue amount={v} currency={currencySymbol}></CurrencyValue>
          </Fragment>
        </HideIfNoAmazonData>
      ),
    },
    {
      title: 'Add To Cart',
      dataIndex: 'amazonTotalAddToCart',
      key: 'amazonTotalAddToCart',
      className: 'amazon',
      width: 140,
      sorter: (a, b) => a.amazonTotalAddToCart - b.amazonTotalAddToCart,
      render: (v, campaign) => (
        <HideIfNoAmazonData report={campaign}>
          {campaign.status > 0 ? <NumberVal clicks={v}></NumberVal> : <NAValue />}
        </HideIfNoAmazonData>
      ),
    },
    {
      title: 'Purchases',
      dataIndex: 'amazonTotalPurchases',
      key: 'amazonTotalPurchases',
      className: 'amazon',
      width: 120,
      sorter: (a, b) => a.amazonTotalPurchases - b.amazonTotalPurchases,
      render: (v, campaign) => (
        <HideIfNoAmazonData report={campaign}>
          {campaign.status > 0 ? <NumberVal clicks={v}></NumberVal> : <NAValue />}
        </HideIfNoAmazonData>
      ),
    },
    {
      title: 'Revenue',
      dataIndex: 'amazonTotalSales',
      key: 'amazonTotalSales',
      className: 'amazon',
      width: 120,
      render: (v, campaign) => (
        <HideIfNoAmazonData report={campaign}>
          {campaign.status > 0 ? <CurrencyValue amount={v} currency={currencySymbol}></CurrencyValue> : <NAValue />}
        </HideIfNoAmazonData>
      ),
      sorter: (a, b) => a.amazonTotalSales - b.amazonTotalSales,
    },
    {
      title: 'Net Margin',
      dataIndex: 'netMargin',
      key: 'netMargin',
      width: 120,
      sorter: (a, b) => a.netMargin - b.netMargin,
      render: (v, campaign) => (
        <HideIfNoAmazonData report={campaign}>
          {campaign.status > 0 ? <CurrencyValue amount={v} currency={currencySymbol}></CurrencyValue> : <NAValue />}
        </HideIfNoAmazonData>
      ),
    },
    {
      title: 'ROAS',
      dataIndex: 'roas',
      key: 'roas',
      width: 120,
      render: (v, campaign) => (
        <HideIfNoAmazonData report={campaign}>
          {campaign.status > 0 ? <ROASValue roas={v} /> : <NAValue />}
        </HideIfNoAmazonData>
      ),
      sorter: (a, b) => a.roas - b.roas,
    },
    {
      title: 'ACOS',
      dataIndex: 'acos',
      key: 'acos',
      width: 120,
      render: (v, campaign) => (
        <HideIfNoAmazonData report={campaign}>
          {campaign.status > 0 ? <ACOSValue acos={v} /> : <NAValue />}
        </HideIfNoAmazonData>
      ),
      sorter: (a, b) => a.acos - b.acos,
    },
    {
      title: (
        <Tooltip placement="bottom" title="On average, Brands can earn a 10% bonus from their qualifying sales">
          <span>
            Average Brand
            <br /> Referral Bonus ℹ
          </span>
        </Tooltip>
      ),
      dataIndex: 'amazonTotalSales',
      key: 'amazonTotalSales',
      width: 160,
      render: (v, campaign) => (
        <HideIfNoAmazonData report={campaign}>
          {campaign.status > 0 ? (
            <Fragment>
              {campaign.amazonTotalSales > 0 && (
                <BrbValue
                  brbBonusAmountCalculated={campaign.brbBonusAmountCalculated}
                  brbBonusAmount={campaign.brbBonusAmount}
                  currencySymbol={currencySymbol}
                  currencyCode={currencyCode}
                  brbEnrolled={_.get(
                    props.account.productProviders.find((p) => p.id === props.productProviderId),
                    'brbEnrolled',
                  )}
                />
              )}
            </Fragment>
          ) : (
            <NAValue />
          )}
        </HideIfNoAmazonData>
      ),
      sorter: (a, b) => a.amazonTotalSales - b.amazonTotalSales,
    },
    {
      title: 'Location',
      dataIndex: 'googleCampaign',
      key: 'googleCampaign',
      width: 300,
      render: (v, campaign) => {
        if (campaign.providerType === 'GOOGLE') {
          return (
            <Value>
              {_.get(campaign, 'googleCampaign.geoCriteria', [])
                .map((c) => c.canonicalName)
                .join(', ')}
            </Value>
          );
        } else if (campaign.providerType === 'FACEBOOK') {
          return (
            <Value>
              {_.get(campaign, 'facebookCampaign.ad.adset.value.geoLocations', [])
                .map((c) => c.name)
                .join(', ')}
            </Value>
          );
        }
      },
    },
    {
      title: 'Keywords',
      dataIndex: 'googleCampaign',
      key: 'googleCampaign',
      width: 300,
      render: (v, campaign) => (
        <Value>
          {_.get(campaign, 'googleCampaign.adGroup.keywords', [])
            .map((c) => c.text)
            .join(', ')}
        </Value>
      ),
      sorter: (a, b) => (a.totalVariationsCount || 0) - (b.totalVariationsCount || 0),
    },
    {
      title: 'CreatedAt',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 120,
      render: (v) => v && moment(v).format(DATE_FORMAT),
    },
    {
      title: 'Provider Status',
      dataIndex: 'providerStatus',
      key: 'providerStatus',
      width: 250,
    },
    {
      title: '',
      width: 1,
      fixed: 'right',
      render: (v, campaign: campaignT) => (
        <FloatingRowAction>
          {campaign.invalidNameDetectedAt ? (
            <CampaignAlertButton campaign={campaign} />
          ) : (
            <InfoCampaignButton campaign={campaign} />
          )}
          <Divider type="vertical" />
          {campaign.status !== 2 && (
            <Fragment>
              <EditCampaign account={props.account} campaign={campaign} onUpdate={onCampaignUpdate} />
              <Divider type="vertical" />
            </Fragment>
          )}
          {isAutomationsEnabled && campaign.providerType === 'GOOGLE' && campaign.status !== 2 && (
            <Fragment>
              <AutomationsButton account={props.account} campaign={campaign} />
              <Divider type="vertical" />
            </Fragment>
          )}
          {campaign.redirect && (
            <Fragment>
              <CopyCampaignLink account={props.account} campaign={campaign} />
              <Divider type="vertical" />
            </Fragment>
          )}
          {(props.impersonated || window.location.href.includes('https://localhost')) && (
            <>
              <DebugCampaign account={props.account} campaign={campaign} />
              <Divider type="vertical" />
            </>
          )}
          <CloneCampaign account={props.account} campaign={campaign} />
          <Divider type="vertical" />
          {shouldDisplaySearchTerms && (
            <>
              <SearchTermsCampaignView />
              <Divider type="vertical" />
            </>
          )}
          <DeleteCampaign
            account={props.account}
            campaigns={campaigns}
            setCampaigns={setCampaigns}
            campaign={campaign}
          />
        </FloatingRowAction>
      ),
    },
  ].filter((c) => !c.hidden);

  const rowSelection = {
    selectionType: 'checkbox',
    onChange: (selectedRowKeys: any, selectedRows: any) => {
      props.setSelectedRowKeys(selectedRowKeys);
    },
  };

  if (!props.allCampaigns.length) {
    return (
      <Card style={{ width: '100%', textAlign: 'center' }}>
        <EmptyText>There are no campaigns for this product</EmptyText>
        <NewCampaignButton account={props.account} productId={props.productId} />
      </Card>
    );
  } else if (!campaigns.length) {
    return (
      <Card style={{ width: '100%', textAlign: 'center' }}>
        <EmptyText>There is no data that matches the filters you have set</EmptyText>
      </Card>
    );
  }

  return (
    <Style>
      <Table
        style={{ cursor: 'pointer' }}
        rowKey="id"
        tableLayout="fixed"
        dataSource={campaigns}
        rowSelection={rowSelection}
        pagination={{
          defaultPageSize: 25,
        }}
        onRow={(campaign, rowIndex) => ({
          onClick: async (event) => {
            if (campaign.providerType === 'GENERIC') {
              return true;
            }

            // To skip Adgroups if only one is present
            const adGroupsData = await getAllAdGroupsForCampaign(
              localStorage,
              axios,
              {
                accountId: props.account.id,
                from: undefined,
                to: undefined,
              },
              campaign.id,
            );

            if (!adGroupsData.adgroups || adGroupsData.adgroups.length === 0) {
              return notification.error({
                message: 'No Ad Groups Found',
                description: 'No Ad Groups Found for this campaign',
              });
            } else if (adGroupsData.adgroups.length === 1) {
              history.push(
                `/${props.account.id}/adsprovider/${props.productProviderId}/${props.productASIN}/${campaign.providerType}/campaigns/${campaign.id}/adgroups/${adGroupsData.adgroups[0].id}/variations`,
              );
            } else {
              history.push(
                `/${props.account.id}/adsprovider/${props.productProviderId}/${props.productASIN}/${campaign.providerType}/campaigns/${campaign.id}/adgroups?skipAdGroup`,
              );
            }
          },
        })}
        rowClassName={(record, index) =>
          `${record.totalCampaignsCount === 0 || !record.lastClicks ? 'row-empty' : ''} ${
            record.outOfLimits ? 'row-out-of-limits' : ''
          } ${record.providerType !== 'GENERIC' ? '' : 'default-cursor'}`
        }
        columns={columns}
      />
      <div style={{ textAlign: 'center' }}>
        <MutedText>
          If you don't have attribution data then this may be due to hyper targeted audience and low audience size.
        </MutedText>
      </div>
    </Style>
  );
}

const stateToProps = (state: any) => ({
  impersonated: state.user.impersonated,
  user: state.user.user,
});

export const CampaignsTable = connect(stateToProps)(ICampaignsTable);
