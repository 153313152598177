import type { axiosParams } from './api';
import { apiCall } from './api';

export function getMetrics(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  productProviderID: string,
  productASIN: string,
  params: { accountId: string, from: string, to: string },
): Promise<GetProductMetricsResponse> {
  return apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'get',
    url: `/product-providers/${productProviderID}/products/${productASIN}/metrics`,
    params,
  });
}
