import React from 'react';

import './UsagePage.css';
import { useSelector } from 'react-redux';

const UsagePage = () => {
  const {
    planName,
    limitation,
    planLink,
    planCustomDomain,
    planPixel,
    planTeamMember,
    planWorkspace,
    usageClicks,
    usage,
  } = useSelector((states) => ({
    planName: states.subscription.sub.plan.name,
    limitation: states.accounts.limitations.monthlyUsage,
    planLink: states.accounts.limitations.shortens,
    planCustomDomain: states.accounts.limitations.brandedDomains,
    planPixel: states.accounts.limitations.pixels,
    planTeamMember: states.accounts.limitations.teamMembers,
    planWorkspace: states.accounts.limitations.workspaces,
    usageClicks: states.accounts.monthlyUsage.total,
    usage: states.accounts.usage,
  }));

  const showDate = () => {
    let date = new Date();

    const mm = String(date.getMonth() + 2).padStart(2, '0'); // January is 0!
    const yyyy = date.getFullYear();

    date = `${+mm}/${yyyy}`;

    return <span className="usage__value">01/{date}</span>;
  };

  // TODO si shorten = 0 alors ilimité / si pixel egal a -1 alors ilimité

  return (
    <>
      <div>
        {/* overview component */}
        <div className="usage__container">
          <h1 className="usage__title">Overview</h1>
          <hr />
          <div className="flex">
            <div style={{ width: '30%', marginRight: '3rem' }}>
              <div className="flex" style={{ justifyContent: 'space-between', marginTop: '1rem' }}>
                <p className="usage__description">Plan</p>
                <span className="usage__value">{planName}</span>
              </div>
              <div className="flex" style={{ justifyContent: 'space-between', marginTop: '1rem' }}>
                <p className="usage__description">Created</p>
                <span className="usage__value">-</span>
              </div>
            </div>
            <div style={{ width: '30%' }}>
              <div className="flex" style={{ justifyContent: 'space-between', marginTop: '1rem' }}>
                <p className="usage__description">Current Period</p>
                <span className="usage__value">-</span>
              </div>
              <div className="flex" style={{ justifyContent: 'space-between', marginTop: '1rem' }}>
                <p className="usage__description">Next invoice</p>
                <span className="usage__value">-</span>
              </div>
            </div>
          </div>
        </div>
        {/* overview component */}

        {/* monthly usage component */}
        <div className="usage__container" style={{ marginTop: '1rem' }}>
          <h1 className="usage__title">Monthly clicks usage</h1>
          <hr />
          <div style={{ width: '30%' }}>
            <div className="flex" style={{ justifyContent: 'space-between', marginTop: '1rem' }}>
              <p className="usage__description">Included</p>
              <span className="usage__value">{limitation}</span>
            </div>
            <div className="flex" style={{ justifyContent: 'space-between', marginTop: '1rem' }}>
              <p className="usage__description">Used</p>
              <span className="usage__value">{usageClicks}</span>
            </div>
            <div className="flex" style={{ justifyContent: 'space-between', marginTop: '1rem' }}>
              <p className="usage__description">Usage Reset</p>
              {showDate()}
            </div>
          </div>
        </div>
        {/* monthly usage component */}

        {/* limits */}
        <div className="usage__container" style={{ marginTop: '1rem' }}>
          <h1 className="usage__title">Limits</h1>
          <hr />
          <div className="flex">
            <div style={{ width: '30%', marginRight: '3rem' }}>
              <div className="flex" style={{ justifyContent: 'space-between', marginTop: '1rem' }}>
                <p className="usage__description">Branded links</p>
                {/* planLink > 0 && planLink < 100 ? (
                  <span className="usage__value">
                    {usage.shortens}/{planLink}
                  </span>
                ) : (
                  <span className="usage__value">
                    {usage.shortens}/unlimited
                  </span>
                )*/}
              </div>
              <div className="flex" style={{ justifyContent: 'space-between', marginTop: '1rem' }}>
                <p className="usage__description">Custom domains</p>
                {planCustomDomain > 0 && planCustomDomain < 100 ? (
                  <span className="usage__value">
                    {usage.brandedDomains}/{planCustomDomain}
                  </span>
                ) : (
                  <span className="usage__value">{usage.brandedDomains}/unlimited</span>
                )}
              </div>
            </div>
            <div style={{ width: '30%', marginRight: '3rem' }}>
              <div className="flex" style={{ justifyContent: 'space-between', marginTop: '1rem' }}>
                <p className="usage__description">Retargeting pixels</p>
                {planPixel > 0 && planPixel < 100 ? (
                  <span className="usage__value">
                    {usage.pixels}/{planPixel}
                  </span>
                ) : (
                  <span className="usage__value">{usage.pixels}/unlimited</span>
                )}
              </div>
              <div className="flex" style={{ justifyContent: 'space-between', marginTop: '1rem' }}>
                <p className="usage__description">Team members</p>
                {planTeamMember >= 2 ? (
                  <span className="usage__value">
                    {usage.teamMembers}/{planTeamMember}
                  </span>
                ) : (
                  <span className="usage__value">Not included in your plan</span>
                )}
              </div>
            </div>
            <div style={{ width: '30%' }}>
              <div className="flex" style={{ justifyContent: 'space-between', marginTop: '1rem' }}>
                <p className="usage__description">Workspaces</p>
                {planWorkspace >= 2 ? (
                  <span className="usage__value">
                    {usage.workspaces}/{planWorkspace}
                  </span>
                ) : (
                  <span className="usage__value">Not included in your plan</span>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* limits */}
      </div>
    </>
  );
};

export default UsagePage;
