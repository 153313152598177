import React from 'react';

import { Button, Popconfirm } from 'antd';
import { useHistory } from 'react-router-dom';
import { useImmer } from 'use-immer';

import axios from 'lib/axios.factory';

import { trackedEvents } from '../../../../config/trackedEvents.config';
import { deleteAdsProvider } from '../../../../lib/adsPlateformApi';
import { track } from '../../../../lib/analytics';
import { FacebookLoginButton } from '../Facebook/FacebookLoginButton';

export function AdsProviderReloginButton(props: { account: accountT, provider: adsProviderT, block: boolean }) {
  const history = useHistory();

  return (
    <FacebookLoginButton
      account={props.account}
      history={history}
      onConnected={() => {}}
      adsProvider={props.provider}
      block={props.block}
    />
  );
}
