import React from 'react';

import { Form, Select } from 'antd';

import { sd } from 'lib/safe';

import { pixelsFromAccount } from '../../../../lib/pixels';

export const PixelSelector = (props: { account: accountT }) => {
  const allPixels = pixelsFromAccount(props.account);

  return (
    <Form.Item name="pixelsIds" label="Retargeting pixels" style={{ marginBottom: 0 }}>
      <Select
        size="large"
        mode="multiple"
        disabled={allPixels.length <= 0}
        showArrow={true}
        placeholder="Select a pixel"
      >
        {sd(allPixels, []).map(({ id, value }) => (
          <Select.Option key={id} value={id}>
            {value}{' '}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};
