import React, { Fragment, useEffect, useState } from 'react';

import { message } from 'antd';
import Button from 'antd/lib/button';
import { CopyToClipboard } from 'react-copy-to-clipboard/lib/Component';
import styled from 'styled-components';

import axios from 'lib/axios.factory';

import { useLoading } from '../../../../hooks/useLoading';
import { getProduct } from '../../../../lib/adsPlateformApi';
import { TYPE_OTHER, TYPE_PRODUCT, TYPE_STORE } from '../NewCampaign/NewProductModal';

const Style = styled.div`
  ul {
    max-height: 300px;
    overflow: auto;
    margin-left: 0;
    padding-left: 20px;
  }

  button {
    margin-top: 5px;
    margin-bottom: 5px;

    span {
      max-width: 350px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

export function ProductPreviewForNewCampaign({ productId, account }) {
  const { doAction, ExclusiveInlineLoadingContainer } = useLoading(true);
  const [product, setProduct] = useState();

  async function load() {
    const data = await getProduct(localStorage, axios, { accountId: account.id }, productId);

    setProduct(data.product);
  }

  useEffect(() => {
    doAction(load);
  }, [productId]);

  if (!product) {
    return <Fragment />;
  }

  switch (product.type) {
    case TYPE_PRODUCT:
      return <ProductPreview product={product} />;
    case TYPE_STORE:
      return (
        <Style>
          <ExclusiveInlineLoadingContainer>
            <h3>Store</h3>
            <div>{product.name}</div>
          </ExclusiveInlineLoadingContainer>
        </Style>
      );
    case TYPE_OTHER:
      return (
        <Style>
          <ExclusiveInlineLoadingContainer>
            <h3>Other</h3>
            <div>{product.name}</div>
          </ExclusiveInlineLoadingContainer>
        </Style>
      );
  }
}

function ProductPreview({ product }) {
  return (
    <Style>
      <h3>
        <a target="_blank" href={product.productUrl} rel="noreferrer">
          Product
        </a>
      </h3>
      <div>{product.name}</div>
      <br />
      <h3>Suggested keywords</h3>
      <div style={{ maxHeight: '250px', overflow: 'auto' }}>
        {(product.amazonSuggestedKeywords || []).map((k) => (
          <CopyToClipboard
            key={k.keywordText}
            onCopy={() => message.success('Text copied to clipboard.')}
            text={k.keywordText}
          >
            <Button size="small" style={{ marginLeft: '10px' }}>
              {k.keywordText}
            </Button>
          </CopyToClipboard>
        ))}
        {(product.suggestedKeywords || []).map((k) => (
          <CopyToClipboard onCopy={() => message.success('Text copied to clipboard.')} key={k} text={k}>
            <Button size="small" style={{ marginLeft: '10px' }}>
              {k}
            </Button>
          </CopyToClipboard>
        ))}
      </div>
      <br />

      <h3>Description</h3>
      <div style={{ maxHeight: '250px', overflow: 'auto' }}>
        {product.description}
        <ul>
          {(product.featureBullets || []).map((k) => (
            <li key={k}>
              <CopyToClipboard onCopy={() => message.success('Text copied to clipboard.')} text={k}>
                <Button size="small" style={{ marginLeft: '10px' }}>
                  {k}
                </Button>
              </CopyToClipboard>
            </li>
          ))}
        </ul>
      </div>
    </Style>
  );
}
